// app.filter('prettyDateTime', function() {
//   return function(date) {
//     return moment(date).calendar(null,{sameElse: 'MM/DD/YYYY, h:mm a'});
//   };
// });

app.filter('prettyMonthYear', function() {
  return function(date) {
    return moment(date).format('MM/YYYY');
  };
});

function examReviewController($rootScope, $scope, $http, $stateParams, $timeout, $sce, flashMessages, uuid, couch, exams) {

  $rootScope.inGrading = true;
  $scope.$on('$destroy', function() {
    $rootScope.inGrading = false;
  });

  $scope.data = {
    working:0,
    districts:[],
    schools:[],
    classes:[],
    assignments:[],
    assignment:null,
    attempts:null,
    attemt:null,
    question:null,
    search:{
      status:2,
      name:'',
      title:'',
      start:undefined,
      stop:undefined,
      archivedClasses:false,
    },
    tmp:{
      status:2,
      searchText:'',
      archivedClasses:false
    },
    letters:'ABCDEFG'.split(''),
    statusIcons:['icon-circle-blank','icon-circle','icon-ok correct','icon-remove wrong'],
    typeIcons:{SR:'icon-pencil',ER:'icon-pencil',MC:'icon-check',TF:'icon-check'},
    assignmentTypes:[ 'Homework', 'Review', 'Exam', 'Test', 'Extra Credit'],
  };
  $scope.refresh = function(next){
    $timeout(function() {
      $scope.$apply();
      if(next){
        next();
      }
    });
  }
  $scope.dismiss = function(){
    if ($scope.data.showPicklist) {
      $scope.data.showPicklist = false;
    } else {
      $scope.data.showBackdrop = false;
      $scope.data.showNewAssignment = false;
      $scope.data.showAssignmentInfo = false;
      $scope.data.showAttemptInfo = false;
    }
  }
  $scope.preventDef = function(e){
    if ($scope.data.showPicklist) {
      $scope.data.showPicklist = false;
    }
    e.stopPropagation();
  }

/////////////////////////////
  $scope.getDistricts = function(){
    $scope.data.working++;

    $scope.data.districts=[];
    $scope.data.schools=[];
    $scope.data.classes=[];

    return $http.get('/api/districts/user').then(function(res){
      $scope.data.districts = res.data;
      var p=[];
      for(var i = 0; i < $scope.data.districts.length; i++){
        if($scope.data.districts[i].adminStaff == 2){
          $scope.data.districts[i].staffView = true;
          $scope.data.districts[i].staffEdit = true;
        } else if($scope.data.districts[i].adminStaff == 1){
          $scope.data.districts[i].staffView = true;
        }
        if($scope.data.districts[i].adminClasses == 2){
          $scope.data.districts[i].classView = true;
          $scope.data.districts[i].classEdit = true;
        } else if($scope.data.districts[i].adminClasses == 1){
          $scope.data.districts[i].classView = true;
        }
        if($scope.data.districts[i].adminStudents == 2){
          $scope.data.districts[i].studentView = true;
          $scope.data.districts[i].studentEdit = true;
        } else if($scope.data.districts[i].adminStudents == 1){
          $scope.data.districts[i].studentView = true;
        }
        if($scope.data.districts[i].adminExams == 2){
          $scope.data.districts[i].examView = true;
          $scope.data.districts[i].examEdit = true;
        } else if($scope.data.districts[i].adminExams == 1){
          $scope.data.districts[i].examView = true;
        }
        p.push($scope.getSchools($scope.data.districts[i]));
      }
      if(p.length > 0){
        Promise.all(p).then(function(){
          if($scope.data.schools.length > 0){
            return Promise.all($scope.data.schools.map(function(s){return $scope.getClasses(s);}));
          }
          return [];
        })
        .then(function(){
          $scope.refresh(function(){$scope.data.working--;});
          return $scope.selectClass(null);
        }).then(function(){
          if($stateParams.cl && $stateParams.sa){
            var attempt;
            var classInfo = $scope.data.classes.find(function(c){return c.idClass == $stateParams.cl});
            if(classInfo){
              var url = classInfo.school.database+'/api/assignments/'+classInfo.idClass+'/x/attempts?attempt='+$stateParams.sa;
              return $http({
                method: 'GET',
                url: url,
                headers: {
                  Authorization: 'Bearer '+localStorage.getItem('studentToken'),
                }
              }).then(function(res){
                attempt = res.data.attempts[0];
                url = classInfo.school.database+'/api/assignments/'+classInfo.idClass+'/'+attempt.fkAssignment;
                return $http({
                  method: 'GET',
                  url: url,
                  headers: {
                    Authorization: 'Bearer '+localStorage.getItem('studentToken'),
                  }
                });
              }).then(function(res){
                attempt.assignment = res.data.assignments[0];
                attempt.assignment.class = classInfo;
                attempt.assignment.start = new Date(attempt.assignment.start);
                attempt.assignment.stop = new Date(attempt.assignment.stop);
                $scope.data.attempts.push(attempt);
                $scope.data.attempt = attempt;
              }).catch(function(err){
                alertify.error('An error occured loading the assignment')
                console.error(err);
              });
            }
          }
        })
      } else {
        $scope.refresh(function(){$scope.data.working--;});
      }
    }).catch(function(err){
      $scope.data.working--;
      console.error(err);
    })
  }

//////////////////////////////
  $scope.getSchools = function(district){
    return $http.get('/api/user/schools/'+district.idDistrict).then(function(res){
      var schools = res.data;
      $scope.data.schools = $scope.data.schools||[];
      for(var i = 0; i < schools.length; i++){
        if(district.adminStaff == 2){
          schools[i].staffView = true;
          schools[i].staffEdit = true;
        } else if(district.adminStaff == 1){
          schools[i].staffView = true;
        }
        if(district.adminClasses == 2){
          schools[i].classView = true;
          schools[i].classEdit = true;
        } else if(district.adminClasses == 1){
          schools[i].classView = true;
        }
        if(district.adminStudents == 2){
          schools[i].studentView = true;
          schools[i].studentEdit = true;
        } else if(district.adminStudents == 1){
          schools[i].studentView = true;
        }
        if(district.adminExams == 2){
          schools[i].examView = true;
          schools[i].examEdit = true;
        } else if(district.adminExams == 1){
          schools[i].examView = true;
        }

        if(schools[i].adminStaff == 2){
          schools[i].staffView = true;
          schools[i].staffEdit = true;
        } else if(schools[i].adminStaff == 1){
          schools[i].staffView = true;
        }
        if(schools[i].adminClasses == 2){
          schools[i].classView = true;
          schools[i].classEdit = true;
        } else if(schools[i].adminClasses == 1){
          schools[i].classView = true;
        }
        if(schools[i].adminStudents == 2){
          schools[i].studentView = true;
          schools[i].studentEdit = true;
        } else if(schools[i].adminStudents == 1){
          schools[i].studentView = true;
        }
        if(schools[i].adminExams == 2){
          schools[i].examView = true;
          schools[i].examEdit = true;
        } else if(schools[i].adminExams == 1){
          schools[i].examView = true;
        }
        schools[i].district = district;
        $scope.data.schools.push(schools[i]);
      }
    }).catch(function(err){
      console.error(err);
    })
  }

///////////////////////////////
  $scope.$watch('data.class',function(cl){
    $scope.selectClass(cl);
  });
  $scope.selectClass = function(cl){
    $scope.refresh(function(){$scope.data.working++;});
    var p;
    $scope.data.assignments = [];
    $scope.data.attempts = [];
    $scope.data.allAttempts = [];
    $scope.data.questionIndex = -1;
    $scope.data.question = null;
    if(!cl){
      if($scope.data.classes.length > 0){
        p = Promise.all($scope.data.classes.map(function(c){
          if(c.examEdit)return $scope.getAssignments(c);
          return [];
        }));
      }
    } else {
      p = $scope.getAssignments(cl);
    }

    if(p){
      return p.then(function(){
        if($scope.data.assignments.length > 0){
          return Promise.all($scope.data.assignments.map(function(a){return $scope.getAttempts(a);}));
        }
        return [];
      })
      .then(function(){
        if($scope.data.attempts)
          $scope.data.attempts.sort(function(a,b){return a.status-b.status})
        $scope.data.attemptIndex = 0;
        $scope.data.attempt = $scope.data.attempt || ($scope.data.attempts ? $scope.data.attempts[0]:null);

        $scope.refresh(function(){$scope.data.working--;});
      }).catch(function(err){
        $scope.refresh(function(){$scope.data.working--;});
        console.error(err);
      });
    } else {
      $scope.refresh(function(){$scope.data.working--;});
      return false;
    }
  }

  $scope.getClasses = function(school){
    var url = '/api/user/class/'+school.idSchool;
    if($scope.data.search.archivedClasses){
      url+='?schoolYear=all';
      if($scope.data.search.start)
        url+=('&start='+$scope.data.search.start);
      if($scope.data.search.stop)
        url+=('&stop='+$scope.data.search.stop);
    }
    return $http.get(url).then(function(res){
      $scope.data.classes = $scope.data.classes||[];
      for(var i = 0; i < res.data.length; i++){
        if(school.district.adminStaff == 2){
          res.data[i].staffView = true;
          res.data[i].staffEdit = true;
        } else if(school.district.adminStaff == 1){
          res.data[i].staffView = true;
        }
        if(school.district.adminClasses == 2){
          res.data[i].classView = true;
          res.data[i].classEdit = true;
        } else if(school.district.adminClasses == 1){
          res.data[i].classView = true;
        }
        if(school.district.adminStudents == 2){
          res.data[i].studentView = true;
          res.data[i].studentEdit = true;
        } else if(school.district.adminStudents == 1){
          res.data[i].studentView = true;
        }
        if(school.district.adminExams == 2){
          res.data[i].examView = true;
          res.data[i].examEdit = true;
        } else if(school.district.adminExams == 1){
          res.data[i].examView = true;
        }

        if(school.adminStaff == 2){
          res.data[i].staffView = true;
          res.data[i].staffEdit = true;
        } else if(school.adminStaff == 1){
          res.data[i].staffView = true;
        }
        if(school.adminClasses == 2){
          res.data[i].classView = true;
          res.data[i].classEdit = true;
        } else if(school.adminClasses == 1){
          res.data[i].classView = true;
        }
        if(school.adminStudents == 2){
          res.data[i].studentView = true;
          res.data[i].studentEdit = true;
        } else if(school.adminStudents == 1){
          res.data[i].studentView = true;
        }
        if(school.adminExams == 2){
          res.data[i].examView = true;
          res.data[i].examEdit = true;
        } else if(school.adminExams == 1){
          res.data[i].examView = true;
        }

        if(res.data[i].adminStaff == 2){
          res.data[i].staffView = true;
          res.data[i].staffEdit = true;
        } else if(res.data[i].adminStaff == 1){
          res.data[i].staffView = true;
        }
        if(res.data[i].adminClasses == 2){
          res.data[i].classView = true;
          res.data[i].classEdit = true;
        } else if(res.data[i].adminClasses == 1){
          res.data[i].classView = true;
        }
        if(res.data[i].adminStudents == 2){
          res.data[i].studentView = true;
          res.data[i].studentEdit = true;
        } else if(res.data[i].adminStudents == 1){
          res.data[i].studentView = true;
        }
        if(res.data[i].adminExams == 2){
          res.data[i].examView = true;
          res.data[i].examEdit = true;
        } else if(res.data[i].adminExams == 1){
          res.data[i].examView = true;
        }
        res.data[i].school = school;

        $scope.data.classes.push(res.data[i]);
      }
    }).catch(function(err){
      console.error(err);
    })
  }

////////////////////////////////
  $scope.getAssignments = function(classInfo){
    var url = classInfo.school.database+'/api/assignments/'+classInfo.idClass+'/list?';
    if($scope.data.search.start)
      url+=('&start='+$scope.data.search.start);
    if($scope.data.search.stop)
      url+=('&stop='+$scope.data.search.stop);
    return $http({
      method: 'GET',
      url: url,
      headers: {
        Authorization: 'Bearer '+localStorage.getItem('studentToken'),
      }
    }).then(function(res){
      $scope.data.assignments = $scope.data.assignments||[];
      for(var i = 0; i < res.data.assignments.length; i++){
        res.data.assignments[i].start = new Date(res.data.assignments[i].start);
        res.data.assignments[i].stop = new Date(res.data.assignments[i].stop);
        res.data.assignments[i].class = classInfo;
        $scope.data.assignments.push(res.data.assignments[i]);
      }
    }).catch(function(err){
      console.error(err);
    })
  }

///////////////////////////////
  $scope.$watch('data.attempt',function(attempt){
    if(attempt){
      $scope.data.attemptIndex = $scope.data.attempts.findIndex(function(a){return a.idStudentAssignment==attempt.idStudentAssignment});
      if(!$scope.data.assignment || $scope.data.assignment.idAssignment!=attempt.fkAssignment){
        $scope.data.working++;
        $scope.data.assignment = attempt.assignment;
        return $http.get('/api/:userId/exams/'+$scope.data.assignment.fkExam+'/questions')
        .then(function(res){
          $scope.data.questions = res.data.questions;
          $scope.data.passages = res.data.passages;
          $scope.linkQuestions();
          $scope.selectQuestion(0);
          $scope.data.working--;
        })
      } else {
        $scope.linkQuestions()
        $scope.selectQuestion($scope.data.questionIndex);
      }
    } else {
      $scope.data.question = null;
      $scope.data.questionIndex = 0;
    }
  })
  $scope.getAttempts = function(assignment){
    return $http({
     method: 'GET',
     url: assignment.class.school.database+'/api/assignments/'+assignment.class.idClass+'/'+assignment.idAssignment+'/attempts?status='+$scope.data.search.status,
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken'),
     }
    }).then(function(res){
      $scope.data.allAttempts = $scope.data.allAttempts||[];
      for(var i = 0; i < res.data.attempts.length; i++){
        res.data.attempts[i].assignment = assignment;
        $scope.data.allAttempts.push(res.data.attempts[i]);
      }
      $scope.data.attempts = $scope.filterAttempts();
    });
  }
  $scope.linkQuestions = function(){
    for(var i = 0; i < $scope.data.attempt.questions.length; i++){
      var content = ($scope.data.questions||[]).find(function(q){return q.idQuestion==$scope.data.attempt.questions[i].fkQuestion});
      if(content){
        $scope.data.attempt.questions[i].prompt = content.prompt;
        $scope.data.attempt.questions[i].type = content.type;
        $scope.data.attempt.questions[i].fkPassage = content.fkPassage;
        $scope.data.attempt.questions[i].responses = content.responses;
      }
      if(!$scope.data.attempt.questions[i].fkCorrect){
        var answer = $scope.data.attempt.questions[i].responses.find(function(r){return r.correct});
        if(answer)$scope.data.attempt.questions[i].fkCorrect = answer.idResponse;
      }
      if($scope.data.attempt.questions[i].fkPassage)
        $scope.data.attempt.questions[i].passage = $scope.data.passages.find(function(q){return q.idPassage==$scope.data.attempt.questions[i].fkPassage});
    }
    // $scope.data.attempt.questions.sort(function(a,b){return b.sortOrder-a.sortOrder})
  }

  $scope.$watch('data.search.status', function(a,b){
    if(b!=a)
      $scope.selectClass($scope.data.class);
  });
  $scope.$watch('data.search.archivedClasses', function(a,b){
    if(b!=a)
      $scope.getDistricts();
  });
  $scope.$watch('data.search.start', function(a,b){
    if(b!=a){
      if($scope.data.search.archivedClasses)
        $scope.getDistricts();
      else
        $scope.selectClass($scope.data.class);
    }
  });
  $scope.$watch('data.search.stop', function(a,b){
    if(b!=a){
      if($scope.data.search.archivedClasses)
        $scope.getDistricts();
      else
        $scope.selectClass($scope.data.class);
    }
  });
  $scope.$watch('data.search.name', updateFilter);
  $scope.$watch('data.search.title', updateFilter);
  $scope.$watch('data.assignmentType', updateFilter);
  function updateFilter(a,b){
    if(b!=a){
      $scope.data.attempt = null;
      $scope.data.attempts = $scope.filterAttempts();
      $scope.data.question = null;
      $scope.data.attemptIndex = 0;
      $scope.data.questionIndex = 0;
      $scope.refresh(()=>{
        $scope.data.attempt = $scope.data.attempts?$scope.data.attempts[0]:null;
      });
    }
  }

  $scope.showSearch = function(){
    $scope.data.searchBox = true;
    $scope.data.showBackdrop = true;

    // $scope.data.tmp.status = $scope.data.search.status;
    // $scope.data.tmp.searchText = $scope.data.search.text;
    // $scope.data.tmp.start = $scope.data.search.start?new Date($scope.data.search.start):undefined;
    // $scope.data.tmp.stop = $scope.data.search.start?new Date($scope.data.search.stop):undefined;
    // $scope.data.tmp.archivedClasses = $scope.data.search.archivedClasses;
  }
  $scope.submitSearch = function(){
    $scope.data.search.status = $scope.data.tmp.status;
    $scope.data.search.name = $scope.data.tmp.searchText||'';
    $scope.data.search.title = $scope.data.tmp.searchTitle?$scope.data.tmp.searchTitle.title:'';
    $scope.data.search.start = $scope.data.tmp.start?$scope.data.tmp.start.getTime():undefined;
    $scope.data.search.stop = $scope.data.tmp.stop?$scope.data.tmp.stop.getTime():undefined;
    $scope.data.search.archivedClasses = $scope.data.tmp.archivedClasses;

    console.log($scope.data.search,$scope.data.tmp);

    $scope.data.searchBox = false;
    $scope.data.showBackdrop = false;
  }

  $scope.filterAttempts = function(){
    return $scope.data.allAttempts.filter(function(attempt){
      var flag = true;

      if(!attempt.FirstName.toLowerCase().includes($scope.data.search.name.toLowerCase()) &&
        !attempt.LastName.toLowerCase().includes($scope.data.search.name.toLowerCase()))
        flag = false;
      if(!attempt.assignment.title.toLowerCase().includes($scope.data.search.title.toLowerCase()))
        flag = false;

      if(attempt.status != $scope.data.search.status)
        flag = false;
      else if($scope.data.assignmentType && attempt.assignment.type != $scope.data.assignmentType)
        flag = false;
      return flag;
    });
  }

  $scope.selectAttempt = function(index){
    $scope.data.attemptIndex = index;
    $scope.data.attempt = $scope.data.attempts[index];
    // $scope.linkQuestions();
    // $scope.selectQuestion(0);
  }
  $scope.selectQuestion = function(index){
    $scope.data.questionIndex = index;
    $scope.data.question = $scope.data.attempt.questions[index];
  }
  $scope.setGraded = function(){
    $scope.selectQuestion($scope.data.questionIndex);
    return $http({
     method: 'GET',
     url: $scope.data.attempt.assignment.class.school.database+'/api/assignments/'+$scope.data.attempt.assignment.class.idClass+'/'+$scope.data.attempt.assignment.idAssignment+'/attempt/'+$scope.data.attempt.idStudentAssignment+'/graded',
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken'),
     }
    }).then(function(){
      alertify.success('Assignment marked as graded');
      $scope.data.attempt.status = 3;
      if($scope.data.attemptIndex >=0)$scope.data.attempts.splice($scope.data.attemptIndex,1);
      $scope.selectAttempt(($scope.data.attemptIndex<$scope.data.attempts.length)?$scope.data.attemptIndex:$scope.data.attempts.length-1);
    });
  }

  $scope.newAssignment = function(){
    $scope.data.newAssignment = {
      classes: [$scope.data.class],
    }
    $scope.data.showBackdrop = true;
    $scope.data.showNewAssignment = true;
  }
  $scope.editAssignment = function(c){
    c.backup={
      title:c.title,
      start:c.start,
      stop:c.stop,
    }
    c.editing = true;
  }
  $scope.saveAssignment = function(assignment,isNew){
    var assignments;
    var p;
    if(!assignment.questions){
      p = $http.get('/api/'+$rootScope.profile.UserId+'/exams/'+(assignment.fkExam||assignment.exam.id)+'/count');
    } else {
      p = Promise.resolve(false);
    }
    p.then(function(res){
      if(res && res.data){
        assignment.questions = res.data.count;
      }
      if(isNew){
        assignment.class = assignment.classes[0];
        assignments = [];
        for(var i = 0; i < assignment.classes.length; i++){
          assignments.push({
            idAssignment:uuid.v4(),
            fkClass:assignment.classes[i].idClass,
            fkExam:assignment.exam.id,
            title:assignment.title,
            start:(assignment.start).getTime(),
            stop:(assignment.stop).getTime(),
            options:JSON.stringify(assignment.options||{}),
            questions:(assignment.questions||0),
          })
        }
      }else {
        assignments = [{
          idAssignment:assignment.idAssignment,
          fkClass:assignment.fkClass,
          fkExam:assignment.fkExam,
          title:assignment.title,
          start:(assignment.start).getTime(),
          stop:(assignment.stop).getTime(),
          options:JSON.stringify(assignment.options||{}),
          questions:assignment.questions||0,
        }]
      }
      var exists = false;
      for(var i = 0; i < $scope.data.assignments.length; i++){
        if($scope.data.assignments[i].title.toLowerCase()==assignment.title.toLowerCase() &&
        $scope.data.assignments[i].idAssignment != assignment.idAssignment){
          exists = true;
        }
      }
      if(!exists){
        return $http({
         method: 'PUT',
         url: assignment.class.school.database+'/api/assignments/'+assignment.class.idClass,
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         },
         data: assignments
        }).then(function(res){
          assignment.editing = false;
          if(isNew){
            $scope.getAssignments();
            $scope.dismiss();
            alertify.success("Assignment Added");
          } else
            alertify.success("Assignment Updated");
        }).catch(function(err){
          console.error(err);
          $scope.dismiss()
          alertify.error("an error occured updating Assignment");
        });
      } else {
        alertify.error('Duplicate assignment name');
      }
    })
  }
  $scope.deleteAssignment = function(assignment){
    bootbox.confirm("Delete "+assignment.name+" ?", function(result){
      if(result){
        $http({
         method: 'DELETE',
         url: assignment.class.school.database+'/api/assignments/'+assignment.class.idClass+'/'+assignment.idAssignment,
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         }
        }).then(function(res){
          // var index = $scope.data.classes.findIndex(function(x){return x.idClass == c.idClass;});
          // $scope.data.classes.splice(index,1);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing class');
        });
      }
    });
  }
  $scope.revertAssignment = function(assignment){
    assignment.title=assignment.backup.title;
    assignment.start=assignment.backup.start;
    assignment.stop=assignment.backup.stop;
    assignment.editing = false;
  }
  $scope.remClassLinkColumn = function(index){
    $scope.data.newAssignment.classes.splice(index,1);
  }

  $scope.showAssignmentInfo = function(assignment,refresh){
    $scope.dismiss();
    $scope.data.assignment = assignment;

    if(refresh){
      $scope.data.working++;
      $http.get('/api/attempts/'+assignment.idAssignment)
      return $http({
       method: 'GET',
       url: assignment.class.school.database+'/api/assignments/'+assignment.class.idClass+'/'+assignment.idAssignment+'/attempts',
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       }
      })
      .then(function(res){
         assignment.attempts = res.data;
         assignment.avg = 0
         for(var i = 0; i < assignment.attempts.length; i++){
           assignment.attempts[i].grade = 0;
           assignment.attempts[i].time = 0;
           for(var q = 0; q < assignment.attempts[i].questions.length; q++){
             assignment.attempts[i].grade += assignment.attempts[i].questions[q].value;
             assignment.attempts[i].time += assignment.attempts[i].questions[q].time;
           }
           assignment.maxPoints = assignment.attempts[i].questions.length;

           if(!assignment.avg){
             assignment.avg = assignment.attempts[i].grade;
           } else {
             assignment.avg = (assignment.avg + assignment.attempts[i].grade) / 2;
           }
         }
         $scope.data.working--;

         $scope.data.showBackdrop = true;
         $scope.data.showAssignmentInfo = true;
      });
    } else {
      $scope.data.showBackdrop = true;
      $scope.data.showAssignmentInfo = true;
    }
  }
  $scope.showAttemptInfo = function(attempt){
    $scope.dismiss();
    $scope.data.attempt = attempt;
    $scope.data.showBackdrop = true;
    $scope.data.showAttemptInfo = true;
  }
  $scope.getAssignmentAvg = function(question){
    var avg = 0;
    for(var i = 0; i < $scope.data.assignment.attempts.length; i++){
      var q = $scope.data.assignment.attempts[i].questions.find(function(r){return r.fkQuestion==question.fkQuestion});
      if(q){
        if(!avg){
          avg = q.value;
        } else {
          avg = (avg + q.value) / 2;
        }
      }
    }
    return avg;
  }

  $scope.setQStatus = function(question, status, value){
    if($scope.data.question == question){
      if(question.status == 0){
        $scope.refresh(function(){
          question.status = status;
        });
      } else {
        // bootbox.confirm("Update question status?", function(result){
        //   if(result){
            $scope.refresh(function(){
              question.status = status;

              if(Number.isFinite(value)){
                question.value = value;
              } else if(status == 2){
                question.value = 1;
              } else {
                question.value = 0;
              }

              $http({
                method: 'PUT',
                url: $scope.data.attempt.assignment.class.school.database+'/api/assignments/'+$scope.data.attempt.assignment.class.idClass+'/'+$scope.data.attempt.assignment.idAssignment+'/response/'+$scope.data.question.idStudentResponse,
                headers: {
                  Authorization: 'Bearer '+localStorage.getItem('studentToken'),
                },
                data: {status:question.status, value:question.value, feedback:question.feedback}
              });
            });
          // }
        // });
      }
    }
  }
  $scope.updateValue = function(question){
    question.value = parseFloat(question.value);
    if(!Number.isFinite(question.value)){
      if(status == 2){
        question.value = 1;
      } else {
        question.value = 0;
      }
    }
    if(question.value < 0){
      question.value = 0;
    }
    $scope.setQStatus(question,question.status,question.value);
  }
  $scope.updateQuestion = function(question){
    if($scope.data.feedbackTmr){
      clearTimeout($scope.data.feedbackTmr);
    }
    $scope.data.feedbackTmr = setTimeout(function () {
      $scope.updateValue(question);
    }, 1000);
  }

  $rootScope.$watch('profile',function(profile){
    if(profile){
      // var date = moment();
      // date.month('august')
      //   .year(moment().year())
      //   .startOf('month');
      // $scope.data.search.start = date.valueOf();
      // date.month('june')
      //   .year(date.year()+1)
      //   .endOf('month');
      // $scope.data.search.stop = date.valueOf();

      $scope.getDistricts();
      couch.use(profile);
      exams.populate(couch);
    }
  });

  exams.ready.then(function() {
    $scope.data.exams = exams.list;
  });
}
