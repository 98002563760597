var app = angular.module('examgen');

app.filter('spSearch', function() {
  return function(items,searchText) {
      var out = [];
      var lc = searchText.toLowerCase();

      if(items){
        for(var i = 0; i < items.length; i++){
          var flag = false;
          if(!flag && items[i].name && items[i].name.toLowerCase().includes(lc))flag = true;
          if(!flag && items[i].FirstName && items[i].FirstName.toLowerCase().includes(lc))flag = true;
          if(!flag && items[i].LastName && items[i].LastName.toLowerCase().includes(lc))flag = true;
          if(!flag && items[i].UserName && items[i].UserName.toLowerCase().includes(lc))flag = true;
          if(!flag && items[i].title && items[i].title.toLowerCase().includes(lc))flag = true;
          if(!flag && items[i].localStaffId && items[i].localStaffId.toLowerCase().includes(lc))flag = true;
          if(!flag && items[i].localStudentId && items[i].localStudentId.toLowerCase().includes(lc))flag = true;
          if(!flag && items[i].localClassId && items[i].localClassId.toLowerCase().includes(lc))flag = true;
          if(flag || !searchText)out.push(items[i]);
        }
      }
      return out;
    }
});

app.filter('spActive', function() {
  return function(items,showAll) {
      var out = [];
      if(items){
        for(var i = 0; i < items.length; i++){
          if(!items[i].hasOwnProperty('active') || showAll || items[i].active)
            out.push(items[i]);
        }
      }
      return out;
    }
});

app.filter('spDropped', function() {
  return function(items,showAll) {
      var out = [];
      if(items){
        for(var i = 0; i < items.length; i++){
          if(!items[i].hasOwnProperty('dropped') || showAll || !items[i].dropped)
            out.push(items[i]);
        }
      }
      return out;
    }
});

app.filter('notIn', function() {
  return function(items,pool) {
      var out = [];
      if(items && pool){
        for(var i = 0; i < items.length; i++){
          var flag = true;
          for(var p = 0; p < pool.length; p++){
            if(items[i].idClass){
              if(items[i].idClass == pool[p].idClass)flag = false;
            }else{
              if(items[i].fkUser && items[i].fkUser == pool[p].fkUser)flag = false;
              if(flag && items[i].idStudent && items[i].idStudent == pool[p].idStudent)flag = false;
            }
          }
          if(flag)out.push(items[i]);
        }
      }
      return out;
    }
});

app.filter('prettyMonthYear', function() {
  return function(date) {
    return moment(date).format('MM/YYYY');
  };
});

function studentManagementController($rootScope, $scope, $http, $filter, $stateParams, $timeout, $sce, flashMessages, uuid) {
  $rootScope.inStudents = true;
  $scope.$on('$destroy', function() {
    $rootScope.inStudents = false;
  });

  $scope.data = {
    states: [],
    state: null,
    districts: [],
    district: null,
    schools: [],
    school: null,
    staff: [],
    students: [],
    user: null,
    classes: [],
    class: null,
    view: '',
    searchText:'',
    assignSearch:'',
    access:['None','View','View/Edit'],
    letters:'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    adminOverride:null,
    archivedClasses: false,
    classRange: { start: null, stop: null },
    showInactiveStudents: false,
    showDroppedStudents: false,
    schoolYear: {},
    importSchoolYear: {start:Date.now(), stop:Date.now()},
    working: 0,
    filter: $filter,
  };
  $scope.refresh = function(next){
    $timeout(function() {
      $scope.$apply();
      if(next){
        next();
      }
    });
  }
  $scope.dismiss = function(){
    if ($scope.data.showPicklist) {
      $scope.data.showPicklist = false;
    } else if($scope.data.showSetSchoolYear){
      $scope.data.showSetSchoolYear = false;
      if(!$scope.data.showImport)$scope.data.showBackdrop = false;
    }else{
      $scope.data.showBackdrop = false;
      $scope.data.showStudentClasses = false;
      $scope.data.showStaffClasses = false;
      $scope.data.showClassStudents = false;
      $scope.data.showClassStaff = false;
      $scope.data.showPermissions = false;
      $scope.data.showImportSelect = false;
      $scope.data.showImport = false;
      $scope.data.showImportAccess = false;
      $scope.data.showImportResults = false;
      $scope.data.showResendInvites = false;
      $scope.data.showDeactivateStudents = false;
    }
  }
  $scope.preventDef = function(e){
    //console.log(e);
    if ($scope.data.showPicklist) {
      $scope.data.showPicklist = false;
    }

    if(e.target.classList.contains('dropdown-toggle')){
      $(e.target).dropdown('toggle');
    } else if(e.target.parentElement.classList.contains('dropdown-toggle')){
      $(e.target.parentElement).dropdown('toggle');
    } else{
      $('.dropdown-toggle').dropdown('dispose');
    }

    e.stopPropagation();
  }
  $scope.delayPicklist = ()=>{
    setTimeout(function () {
      $scope.refresh(()=>{$scope.data.showPicklist=true;});
    }, 10);
  }

  $scope.$watch('data.showPicklist',function(spl,last){
    console.log($scope.data.class);
    $scope.data.assignSearch = '';
  });
  $scope.$watch('data.adminOverride',function(override,last){
    if(override){
      $scope.getStates();
    } else if(override != null && !override){
      $scope.getDistricts();
    }
  });
  $scope.$watch('data.view',function(view,last){
    if (view == 'classes') {
      if($scope.data.school)$scope.getClasses();
    } else if (view == 'district') {
      if($scope.data.district)$scope.getStaff($scope.data.district);
    } else if (view == 'school') {
      if($scope.data.school)$scope.getStaff($scope.data.school);
    } else if (view == 'students') {
      if($scope.data.school)$scope.getStudents($scope.data.school);
    }
  });

/////////////////////////////
  $scope.$watch('data.state',function(state,last){
    $scope.data.districts = [];
    $scope.data.district = null;
    $scope.data.view = '';
    if(state){
      localStorage.setItem('smLastState', state.idState);
      $scope.getDistricts();
    }
  });
  $scope.getStates = function(){
    $scope.data.states = [];
    return $http.get('/api/state/list').then(function(res){
      $scope.data.states = res.data;
      if(!$scope.data.state){
        setTimeout(function () {
          var smLastState = localStorage.getItem('smLastState');
          $scope.refresh(function(){
            if(smLastState){
              $scope.data.state = $scope.data.states.find(function(s){return s.idState==smLastState});
            } else $scope.data.district = null;
            if(!$scope.data.state){
              $scope.data.state = $scope.data.states[0];
            }
          });
        }, 100);
      }
    }).catch(function(err){
      console.error(err);
    });
  }
  $scope.editState = function(){
    bootbox.prompt("State Name:",'Cancel','Ok',function(result){
      if(result){
        $scope.data.state.name = result;
        return $http.put('/api/state',[$scope.data.state]).then(function(res){

        }).catch(function(err){
          console.error(err);
          alertify.error('Error updating state');
        });
      }
    }, $scope.data.state.name);
  }
  $scope.addState = function(){
    bootbox.prompt("State Name:", function(result){
      if(result){
        var x = {
          idState:uuid.v4(),
          name:result||'New State',
        }
        return $http.put('/api/state',[x]).then(function(res){
          $scope.data.state = x;
          $scope.data.states.push(x);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding state');
        });
      }
    });
  }
  $scope.deleteState = function(){
    bootbox.confirm("Delete "+$scope.data.state.name+" ?", function(result){
      if(result){
        $http.delete('/api/state/'+$scope.data.state.idState).then(function(res){
          var index = $scope.data.states.findIndex(function(x){return x.idState == $scope.data.state.idState;});
          $scope.data.states.splice(index,1);
          $scope.data.state = $scope.data.states[0];
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing state');
        });
      }
    });
  }


  $scope.$watch('data.district',function(district,last){
    $scope.data.schools = [];
    $scope.data.school = null;
    $scope.data.view = '';
    if(district){
      localStorage.setItem('smLastDistrict', district.idDistrict);
      //$scope.getStaff(district);
      $scope.getSchools(district)
    }
  });
  $scope.getDistricts = function(){
    $scope.data.districts = [];
    // $scope.data.schools = [];
    // $scope.data.school = null;
    return $http.get($scope.data.adminOverride?'/api/district/'+$scope.data.state.idState:'/api/districts/user').then(function(res){
      $scope.data.districts = res.data;
      var p = []
      for(var i = 0; i < $scope.data.districts.length; i++){
        if($scope.data.districts[i].adminStaff == 2 || $scope.data.adminOverride){
          $scope.data.districts[i].staffView = true;
          $scope.data.districts[i].staffEdit = true;
        } else if($scope.data.districts[i].adminStaff == 1){
          $scope.data.districts[i].staffView = true;
        }
        if($scope.data.districts[i].adminClasses == 2 || $scope.data.adminOverride){
          $scope.data.districts[i].classView = true;
          $scope.data.districts[i].classEdit = true;
        } else if($scope.data.districts[i].adminClasses == 1){
          $scope.data.districts[i].classView = true;
        }
        if($scope.data.districts[i].adminStudents == 2 || $scope.data.adminOverride){
          $scope.data.districts[i].studentView = true;
          $scope.data.districts[i].studentEdit = true;
        } else if($scope.data.districts[i].adminStudents == 1){
          $scope.data.districts[i].studentView = true;
        }
        if($scope.data.districts[i].adminExams == 2 || $scope.data.adminOverride){
          $scope.data.districts[i].examView = true;
          $scope.data.districts[i].examEdit = true;
        } else if($scope.data.districts[i].adminExams == 1){
          $scope.data.districts[i].examView = true;
        }
        // p.push($scope.getSchools($scope.data.districts[i]));
      }
      // if(p.length > 0){
      //   Promise.all(p).then(function(res){
      //     if(true || $scope.data.schools.length == 1){
      //       $scope.refresh(function(){
      //         $scope.data.school = $scope.data.schools[0];
      //       })
      //     }
      //   })
      // }
      if($scope.data.districts.length > 0){
        setTimeout(function () {
          var smLastDistrict = localStorage.getItem('smLastDistrict');
          $scope.refresh(function(){
            if(smLastDistrict){
              $scope.data.district = $scope.data.districts.find(function(s){return s.idDistrict==smLastDistrict});
            } else $scope.data.district = null;
            if(!$scope.data.district){
              $scope.data.district = $scope.data.districts[0];
            }
          });
        }, 100);
      }
    }).catch(function(err){
      console.error(err);
    })
  }
  $scope.editDistrict = function(){
    bootbox.prompt("District Name:",'Cancel','Ok',function(result){
      if(result){
        $scope.data.district.name = result
        return $http.put('/api/district',[$scope.data.district]).then(function(res){
        }).catch(function(err){
          console.error(err);
          alertify.error('Error updating district');
        });
      }
    }, $scope.data.district.name);
  }
  $scope.addDistrict = function(){
    bootbox.prompt("District Name:", function(result){
      if(result){
        var x = {
          idDistrict:uuid.v4(),
          name: result||'New District',
          fkState:$scope.data.state.idState,
        }
        return $http.put('/api/district',[x]).then(function(res){
          $scope.data.district = x;
          $scope.data.districts.push(x);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding district');
        });
      }
    });
  }
  $scope.deleteDistrict = function(){
    bootbox.confirm("Delete "+$scope.data.district.name+" ?", function(result){
      if(result){
        $http.delete('/api/district/'+$scope.data.district.idDistrict).then(function(res){
          var index = $scope.data.districts.findIndex(function(x){return x.idDistrict == $scope.data.district.idDistrict;});
          $scope.data.districts.splice(index,1);
          $scope.data.district = $scope.data.districts[0];
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing district');
        });
      }
    });
  }

//////////////////////////////
  $scope.$watch('data.school',function(school,last){
    $scope.data.classes = [];
    $scope.data.class = null;
    $scope.data.view = '';
    if(school){
      localStorage.setItem('smLastSchool', school.idSchool);

      //$scope.data.district = $scope.data.districts.find(function(d){return d.idDistrict==school.schoolDistrict});
      $scope.data.school.students = [];
      //$scope.getClasses();
      //$scope.getStaff(school);
      //$scope.getStudents(school);
      $scope.refresh(()=>{$scope.data.view = 'classes';});
    }
  });
  $scope.getSchools = function(district){
    return $http.get($scope.data.adminOverride?'/api/school/'+district.idDistrict:'/api/user/schools/'+district.idDistrict).then(function(res){
      var schools = res.data;
      for(var i = 0; i < schools.length; i++){
        if(district.adminStaff == 2 || $scope.data.adminOverride){
          schools[i].staffView = true;
          schools[i].staffEdit = true;
        } else if(district.adminStaff == 1){
          schools[i].staffView = true;
        }
        if(district.adminClasses == 2 || $scope.data.adminOverride){
          schools[i].classView = true;
          schools[i].classEdit = true;
        } else if(district.adminClasses == 1){
          schools[i].classView = true;
        }
        if(district.adminStudents == 2 || $scope.data.adminOverride){
          schools[i].studentView = true;
          schools[i].studentEdit = true;
        } else if(district.adminStudents == 1){
          schools[i].studentView = true;
        }
        if(district.adminExams == 2 || $scope.data.adminOverride){
          schools[i].examView = true;
          schools[i].examEdit = true;
        } else if(district.adminExams == 1){
          schools[i].examView = true;
        }

        if(schools[i].adminStaff == 2 || $scope.data.adminOverride){
          schools[i].staffView = true;
          schools[i].staffEdit = true;
        } else if(schools[i].adminStaff == 1){
          schools[i].staffView = true;
        }
        if(schools[i].adminClasses == 2 || $scope.data.adminOverride){
          schools[i].classView = true;
          schools[i].classEdit = true;
        } else if(schools[i].adminClasses == 1){
          schools[i].classView = true;
        }
        if(schools[i].adminStudents == 2 || $scope.data.adminOverride){
          schools[i].studentView = true;
          schools[i].studentEdit = true;
        } else if(schools[i].adminStudents == 1){
          schools[i].studentView = true;
        }
        if(schools[i].adminExams == 2 || $scope.data.adminOverride){
          schools[i].examView = true;
          schools[i].examEdit = true;
        } else if(schools[i].adminExams == 1){
          schools[i].examView = true;
        }
        schools[i].districtName = district.name;
        $scope.data.schools.push(schools[i]);
      }
      $scope.data.schools.sort(function(a,b){
        return a.districtName.localeCompare(b.districtName);
      });
      if($scope.data.schools.length > 0){
        setTimeout(function () {
          var smLastSchool = localStorage.getItem('smLastSchool');
          $scope.refresh(function(){
            if(smLastSchool){
              $scope.data.school = $scope.data.schools.find(function(s){return s.idSchool==smLastSchool});
            } else $scope.data.school = null;
            if(!$scope.data.school){
              $scope.data.school = $scope.data.schools[0];
            }
          });
        }, 100);
      }
    }).catch(function(err){
      console.error(err);
    })
  }
  $scope.editSchool = function(){
    var form = $('<div style="padding:10px;"></div>');

    var str ='<table><tr><td style="width=1px;">Name:</td><td><input id="name_txt" type="text" value="'+$scope.data.school.name+'"/></td></tr>'+
    '<tr><td>School id:</td><td><input id="id_txt" type="text" value="'+($scope.data.school.localSchoolId||'')+'"/></td></tr>'+
    '<tr><td>Code:</td><td><input id="code_txt" type="text" value="' + ($scope.data.school.schoolCode || '') + '"/></td></tr>' +
    '<tr><td>Database:</td><td><input id="db_txt" type="text" value="' + ($scope.data.school.database || '') + '"/></td></tr>' +
    '<tr><td>Live:</td><td><input id="live_cb" type="checkbox" ' + ($scope.data.school.live? 'checked':'') +'/></td></tr></table>';

    form.append(str);

    var dlg = bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        $scope.data.school.name = form.find('#name_txt').val();
        $scope.data.school.localSchoolId = form.find('#id_txt').val();
        $scope.data.school.schoolCode = form.find('#code_txt').val();
        $scope.data.school.database = form.find('#db_txt').val();
        $scope.data.school.live = form.find('#live_cb').prop('checked')?1:0;
        return $http.put('/api/school',[$scope.data.school]).then(function(res){
          //$scope.data.school.students.push(x);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error editing school');
        });
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn',
    }],{
      // prompts need a few extra options
      'header'  : 'Edit School',
    });
  }
  $scope.addSchool = function(){
    var form = $('<div style="padding:10px;"></div>');

    var str ='<table><tr><td style="width=1px;">Name:</td><td><input id="name_txt" type="text"/></td></tr>'+
    '<tr><td>School id:</td><td><input id="id_txt" type="text"/></td></tr>'+
    '<tr><td>Code:</td><td><input id="code_txt" type="text"/></td></tr>'+
    '<tr><td>Database:</td><td><input id="db_txt" type="text"/></td></tr></table>';

    form.append(str);

    var dlg = bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        var x = {
          idSchool:uuid.v4(),
          name:form.find('#name_txt').val()||'New School',
          localSchoolId: form.find('#id_txt').val(),
          schoolCode: form.find('#code_txt').val(),
          fkDistrict: $scope.data.district.idDistrict,
          database: form.find('#db_txt').val()||'student_test',
        }
        return $http.put('/api/school',[x]).then(function(res){
          $scope.data.school = x;
          $scope.data.schools.push(x);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding school');
        });
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn',
    }],{
      // prompts need a few extra options
      'header'  : 'Add School',
    });
  }
  $scope.deleteSchool = function(){
    bootbox.confirm("Delete "+$scope.data.school.name+" ?", function(result){
      if(result){
        $http.delete('/api/school/'+$scope.data.school.idSchool).then(function(res){
          var index = $scope.data.schools.findIndex(function(x){return x.idSchool == $scope.data.school.idSchool;});
          $scope.data.schools.splice(index,1);
          $scope.data.school = $scope.data.schools[0];
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing school');
        });
      }
    });
  }

//////////////////////////
  // $scope.$watch('data.archivedClasses',function(classes){
  //   if($scope.data.school)$scope.getClasses();
  // })

  $scope.getClasses = function(){
    var p;
    if($scope.data.adminOverride)
      p = $http.get('/api/class/'+$scope.data.school.idSchool+
        ($scope.data.archivedClasses ? `?start=${$scope.data.classRange.start}&stop=${$scope.data.classRange.stop}` : ''));

    else
      p = $http.get('/api/user/class/'+$scope.data.school.idSchool+
        ($scope.data.archivedClasses ? `?start=${$scope.data.classRange.start}&stop=${$scope.data.classRange.stop}` : ''));

    return p
    .then(function(res){
      $scope.data.classes = res.data;
      for(var i = 0; i < $scope.data.classes.length; i++){
        if($scope.data.district.adminStaff == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].staffView = true;
          $scope.data.classes[i].staffEdit = true;
        } else if($scope.data.district.adminStaff == 1){
          $scope.data.classes[i].staffView = true;
        }
        if($scope.data.district.adminClasses == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].classView = true;
          $scope.data.classes[i].classEdit = true;
        } else if($scope.data.district.adminClasses == 1){
          $scope.data.classes[i].classView = true;
        }
        if($scope.data.district.adminStudents == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].studentView = true;
          $scope.data.classes[i].studentEdit = true;
        } else if($scope.data.district.adminStudents == 1){
          $scope.data.classes[i].studentView = true;
        }
        if($scope.data.district.adminExams == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].examView = true;
          $scope.data.classes[i].examEdit = true;
        } else if($scope.data.district.adminExams == 1){
          $scope.data.classes[i].examView = true;
        }

        if($scope.data.school.adminStaff == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].staffView = true;
          $scope.data.classes[i].staffEdit = true;
        } else if($scope.data.school.adminStaff == 1){
          $scope.data.classes[i].staffView = true;
        }
        if($scope.data.school.adminClasses == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].classView = true;
          $scope.data.classes[i].classEdit = true;
        } else if($scope.data.school.adminClasses == 1){
          $scope.data.classes[i].classView = true;
        }
        if($scope.data.school.adminStudents == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].studentView = true;
          $scope.data.classes[i].studentEdit = true;
        } else if($scope.data.school.adminStudents == 1){
          $scope.data.classes[i].studentView = true;
        }
        if($scope.data.school.adminExams == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].examView = true;
          $scope.data.classes[i].examEdit = true;
        } else if($scope.data.school.adminExams == 1){
          $scope.data.classes[i].examView = true;
        }

        if($scope.data.classes[i].adminStaff == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].staffView = true;
          $scope.data.classes[i].staffEdit = true;
        } else if($scope.data.classes[i].adminStaff == 1){
          $scope.data.classes[i].staffView = true;
        }
        if($scope.data.classes[i].adminClasses == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].classView = true;
          $scope.data.classes[i].classEdit = true;
        } else if($scope.data.classes[i].adminClasses == 1){
          $scope.data.classes[i].classView = true;
        }
        if($scope.data.classes[i].adminStudents == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].studentView = true;
          $scope.data.classes[i].studentEdit = true;
        } else if($scope.data.classes[i].adminStudents == 1){
          $scope.data.classes[i].studentView = true;
        }
        if($scope.data.classes[i].adminExams == 2 || $scope.data.adminOverride){
          $scope.data.classes[i].examView = true;
          $scope.data.classes[i].examEdit = true;
        } else if($scope.data.classes[i].adminExams == 1){
          $scope.data.classes[i].examView = true;
        }
        // $scope.getStaff($scope.data.classes[i]);
        // $scope.getStudents($scope.data.classes[i]);
      }
    }).catch(function(err){
      console.error(err);
    })
  }
  $scope.addClass = function(){
    var form = $('<div style="padding:10px;"></div>');

    var str ='<table><tr><td style="width=1px;">Class Name:</td><td><input id="classname_txt" type="text"/></td></tr>'+
    '<tr><td>Class Period:</td><td><input id="classext_txt" type="text"/></td></tr>'+
    '<tr><td>Class ID:</td><td><input id="classid_txt" type="text"/></td></tr></table>';

    form.append(str);

    var dlg = bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        var exists = false;
        var badId = false;
        var x = {
          idClass:uuid.v4(),
          name:form.find('#classname_txt').val(),
          period:form.find('#classext_txt').val(),
          localClassId:form.find('#classid_txt').val(),
          fkSchool:$scope.data.school.idSchool,
        }

        var date = moment('1990-1-1')
          .month('july')
          .year(moment().year()+((moment().month()>5)?0:-1))
          .startOf('month');
        x.start = date.valueOf();
        date.month('june')
          .year(date.year()+1)
          .endOf('month');
        x.stop = date.valueOf();

        for(var i = 0; i < $scope.data.classes.length; i++){
          if($scope.data.classes[i].localClassId && $scope.data.classes[i].localClassId.toLowerCase()==x.localClassId.toLowerCase()){
            exists = true;
          }
        }
        badId = !x.localClassId;

        if(!exists && !badId){
          return $http.put('/api/class',[x]).then(function(res){
            $scope.data.class = x;
            $scope.data.class.students = [];
            $scope.data.class.staff = [];

            x.classView = true;
            x.classEdit = true;
            x.examView = true;
            x.examEdit = true;
            x.staffView = true;
            x.staffEdit = true;
            x.studentView = true;
            x.studentEdit = true;

            $scope.data.classes.push(x);
            if(!$scope.data.adminOverride)
              $scope.assignStaff(x,$rootScope.profile)
          }).catch(function(err){
            console.error(err);
            alertify.error('Error adding class');
          });
        } else {
          if(badId) alertify.error('Must provide a class ID');
          if(exists) alertify.error('Duplicate class ID');
        }
        //
        // for(var i = 0; i < $scope.data.school.students.length; i++){
        //   if($scope.data.school.students[i].username == x.username)
        //     badLogin = true;
        //   if($scope.data.school.students[i].idStudent == x.idStudent)
        //     badId = true;
        // }
        //
        // if(badLogin||badId){
        //   if(badId)alertify.error('Duplicate Student ID');
        //   if(badLogin)alertify.error('Duplicate student username');
        // } else {
        //   return $http({
        //    method: 'PUT',
        //    url: $scope.data.school.database+'/api/students/'+$scope.data.school.idSchool,
        //    headers: {
        //      Authorization: 'Bearer '+localStorage.getItem('studentToken'),
        //    },
        //    data: [x]
        //   }).then(function(res){
        //     $scope.data.school.students.push(x);
        //   }).catch(function(err){
        //     console.error(err);
        //     alertify.error('Error adding student');
        //   });
        // }
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn',
    }],{
      // prompts need a few extra options
      'header'  : 'New Class',
    });
  }

  $scope.editClass = function(c){
    c.backup={
      name:c.name,
      period:c.period,
      localClassId:c.localClassId,
      classCode:c.classCode,
    }
    c.editing = true;
  }
  $scope.saveClass = function(c){
    var exists = false;
    for(var i = 0; i < $scope.data.classes.length; i++){
      if((
        (($scope.data.classes[i].localClassId||'').toLowerCase()==(c.localClassId||'').toLowerCase() && $scope.data.classes[i].classCode)
        ||
        (($scope.data.classes[i].classCode||'').toLowerCase()==(c.classCode||'').toLowerCase() && $scope.data.classes[i].classCode)
      ) &&
      ($scope.data.classes[i].idClass||'').toLowerCase()!=(c.idClass||'').toLowerCase()){
        exists = true;
      }
    }
    if(!exists){
      return $http.put('/api/class',c).then(function(res){
        c.editing = false;
        alertify.success("Class Updated");
      }).catch(function(err){
        console.error(err);
      });
    } else {
      alertify.error('Duplicate class name/code');
    }
  }
  $scope.deleteClass = function(c){
    bootbox.confirm("Delete "+c.name+" ?", function(result){
      if(result){
        $http.delete('/api/class/'+c.idClass).then(function(res){
          var index = $scope.data.classes.findIndex(function(x){return x.idClass == c.idClass;});
          $scope.data.classes.splice(index,1);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing class');
        });
      }
    });
  }
  $scope.revertClass = function(c){
    c.name=c.backup.name,
    c.period=c.backup.period,
    c.localClassId=c.backup.localClassId,
    c.editing = false;
  }

  $scope.getClassStudents = function(c){
    $scope.getStudents(c)
    .then(function(){
      $scope.data.class = c;
      $scope.data.showBackdrop = true;
      $scope.data.showClassStudents = true;
    });
  }
  $scope.getClassStaff = function(c){
    $scope.getStaff(c)
    .then(function(){
      return $scope.getStaff($scope.data.school);
    }).then(function(){
      $scope.data.class = c;
      $scope.data.showBackdrop = true;
      $scope.data.showClassStaff = true;
    });
  }
  $scope.updateClassStaff = function(c){
    for(var i= 0; i < c.staff.length; i++){
      if(c.staff[i].dirty){
        c.staff[i].dirty = false;
        $scope.saveStaff(c.staff[i],true);
      }
    }
    $scope.dismiss();
  }

  $scope.setClassDates = function(){
    $scope.setSchoolYear($scope.data.classRange, true, function(){
      if ($scope.data.school) $scope.getClasses();
    });
  }
/////////////////////////

  $scope.getStaff = function(query){
    query.staff = [];
    var querystr ='';
    if(query.idUser){
      querystr += '?idUser='+query.idUser;
    }
    else if(query.idClass){
      querystr += '?idClass='+query.idClass;
    }
    else if(query.idSchool){
      querystr += '?idSchool='+query.idSchool;
    }
    else if(query.idDistrict){
      querystr += '?idDistrict='+query.idDistrict;
    }
    return $http.get('/api/staff/'+querystr).then(function(res){
      query.staff = res.data;
      return query;
    }).catch(function(err){
      console.error(err);
    })
  }
  $scope.addStaff = function(parent){
    bootbox.prompt("User Email", function(result){
      if(result){
        var exists = false;
        for(var i = 0; i < parent.staff.length; i++){
          if(parent.staff[i].Email == result || parent.staff[i].invitedEmail == result)
            exists = true;
        }
        if(exists){
          alertify.error('user already assigned');
        } else {
          var x = {
            idStaff:uuid.v4(),
            email:result,
            adminStudents:0,
            adminStaff:0,
            adminClasses:0,
            adminExams:0,
            title:"",
          }

          if(parent.idClass){
            x.fkClass=parent.idClass;
          }
          else if(parent.idSchool){
            x.fkSchool=parent.idSchool;
          }
          else if(parent.idDistrict){
            x.fkDistrict=parent.idDistrict;
          }


          return $http.put('/api/staff/email',[x]).then(function(res){
            $scope.data.user = res.data[0];
            parent.staff = parent.staff || [];
            parent.staff = parent.staff.concat(res.data);
            var notify = {
              new:{
                emails:[],
                location:parent.name,
              },
              old:{
                emails:[],
                location:parent.name,
              },
            };
            if(res.data[0].fkUser){
              notify.old.emails.push(res.data[0].Email);
            } else {
              notify.new.emails.push(res.data[0].invitedEmail);
            }
            $http.put('/api/staff/invite',notify);
          }).catch(function(err){
            console.error(err);
            alertify.error('Error adding Staff');
          });
        }
      }
    });
  }
  $scope.assignStaff = function(parent,staff){
    if(staff){
      var x = {
        idStaff:uuid.v4(),
        email:staff.Email||staff.invitedEmail,
        adminStudents:2,
        adminStaff:2,
        adminClasses:2,
        adminExams:2,
        localStaffId:staff.localStaffId,
        title:"",
      }

      if(parent.idClass){
        x.fkClass=parent.idClass;
      }
      else if(parent.idSchool){
        x.fkSchool=parent.idSchool;
      }
      else if(parent.idDistrict){
        x.fkDistrict=parent.idDistrict;
      }

      return $http.put('/api/staff/email',[x]).then(function(res){
        $scope.data.user = res.data[0];
        parent.staff = parent.staff || [];
        parent.staff = parent.staff.concat(res.data)
        var notify = {
          new:{
            emails:[],
            location:parent.name,
          },
          old:{
            emails:[],
            location:parent.name,
          },
        };
        if(res.data[0].fkUser){
          notify.old.emails.push(res.data[0].Email);
        } else {
          notify.new.emails.push(res.data[0].invitedEmail);
        }
        $http.put('/api/staff/invite',notify);
      }).catch(function(err){
        console.error(err);
        alertify.error('Error adding Staff');
      });
    }
  }

  $scope.resendInvite = function(user,parent){
    var notify = {
      new:{
        emails:[],
        location:parent.name,
      },
      old:{
        emails:[],
        location:parent.name,
      },
    };
    if(user.fkUser){
      notify.old.emails.push(user.Email);
    } else {
      notify.new.emails.push(user.invitedEmail);
    }
    $http.put('/api/staff/invite',notify);
    alertify.success('invite resent')
  }

  $scope.editStaff = function(staff){
    staff.backup={
      title:staff.title,
      adminExams:staff.adminExams,
      adminStaff:staff.adminStaff,
      adminClasses:staff.adminClasses,
      adminStudents:staff.adminStudents,
      localStaffId:staff.localStaffId,
    }
    staff.editing = true;
  }
  $scope.saveStaff = function(staff,suppress){
    $http.put('/api/staff',[staff])
    .then(function(res){
      staff.editing = false;
      if(!suppress)alertify.success("Staff member updated");
    }).catch(function(err){
      console.error(err);
    });
  }
  $scope.deleteStaff = function(parent,staff){
    bootbox.confirm("Remove "+(staff.LastName ?(staff.LastName+", "+staff.FirstName):staff.invitedEmail)+" ?", function(result){
      if(result){
        $http.delete('/api/staff/'+staff.idStaff)
        .then(function(res){
          var index = parent.staff.findIndex(function(x){return x.idStaff == staff.idStaff;});
          parent.staff.splice(index,1);
          if(parent.hasOwnProperty('localSchoolId')){
            alertify.success("Teacher removed");
            return new Promise(function(resolve, reject) {
              bootbox.dialog("Remove teacher from classes?", [
                {
                  label: 'Do not remove from classes',
                  class: 'btn pull-left',
                  callback: function () {
                    resolve(false);
                  },
                },
                {
                  label: 'All Classes',
                  class: 'btn btn-primary',
                  callback: function () {
                    bootbox.confirm("Are you sure you want to remove ALL record of " + (staff.LastName ? (staff.LastName + ", " + staff.FirstName) : staff.invitedEmail) + " ?", 
                    function (result) {
                      if(result){
                        $http.delete('/api/staff/classes?scope=all&school='+parent.idSchool+'&'+
                        (staff.fkUser?('user='+staff.fkUser):('email='+staff.invitedEmail)))
                        .then(function(res){resolve(res)})
                        .catch(function(err){reject(err)});
                      } else {
                        resolve(false);
                      }
                    });
                  },
                },
                {
                  label: 'Only Active Classes',
                  class: 'btn btn-primary',
                  callback: function () {
                    $http.delete('/api/staff/classes?scope=active&school='+parent.idSchool+'&'+
                    (staff.fkUser?('user='+staff.fkUser):('email='+staff.invitedEmail)))
                    .then(function(res){resolve(res)})
                    .catch(function(err){reject(err)});
                  },
                },
              ]);
            });
          } else if(parent.hasOwnProperty('localClassId')){
            alertify.success("Teacher removed");
          } else {
            alertify.success("Staff member removed");
          }
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing staff');
        });
      }
    });
  }
  $scope.revertStaff = function(staff){
    staff.title=staff.backup.title,
    staff.adminExams=staff.backup.adminExams,
    staff.adminStaff=staff.backup.adminStaff,
    staff.adminClasses=staff.backup.adminClasses,
    staff.adminStudents=staff.backup.adminStudents,
    staff.localStaffId=staff.backup.localStaffId,
    staff.editing = false;
  }
  $scope.editAccess = function(staff){
    $scope.data.staff = staff;
    $scope.data.showBackdrop = true;
    $scope.data.showPermissions = true;
  }

  $scope.getStaffClasses = function(staff){
    $scope.data.staff = staff;
    $scope.data.staffClasses = [];
    return $http.get('/api/staff/classes?'+(staff.fkUser?`user=${staff.fkUser}`:`email=${staff.invitedEmail}`)).then(function(res){
      $scope.data.staffClasses = res.data.classes;
      $scope.data.showBackdrop = true;
      $scope.data.showStaffClasses = true;
    }).catch(function(err){
      console.error(err);
    });
  }
  $scope.dismissAccess = function(){
    $scope.data.staff.adminExams=$scope.data.staff.backup.adminExams,
    $scope.data.staff.adminStaff=$scope.data.staff.backup.adminStaff,
    $scope.data.staff.adminClasses=$scope.data.staff.backup.adminClasses,
    $scope.data.staff.adminStudents=$scope.data.staff.backup.adminStudents,
    $scope.dismiss();
  }
  $scope.updateAccess = function(){
    $scope.data.staff.backup.adminExams=$scope.data.staff.adminExams,
    $scope.data.staff.backup.adminStaff=$scope.data.staff.adminStaff,
    $scope.data.staff.backup.adminClasses=$scope.data.staff.adminClasses,
    $scope.data.staff.backup.adminStudents=$scope.data.staff.adminStudents,

    $http.put('/api/staff',[{
      idStaff: $scope.data.staff.idStaff,
      title: $scope.data.staff.backup.title,
      localStaffId: $scope.data.staff.backup.localStaffId,
      invitedEmail: $scope.data.staff.invitedEmail,
      fkUser: $scope.data.staff.fkUser,
      adminExams: $scope.data.staff.adminExams,
      adminStaff: $scope.data.staff.adminStaff,
      adminClasses: $scope.data.staff.adminClasses,
      adminStudents: $scope.data.staff.adminStudents,
      fkDistrict: $scope.data.staff.fkDistrict,
      fkSchool: $scope.data.staff.fkSchool,
      fkClass: $scope.data.staff.fkClass,
    }])
    .then(function(res){
      alertify.success("Staff member updated");
    }).catch(function(err){
      console.error(err);
    });

    $scope.dismiss();
  }

///////////////////////////
  $scope.getStudents = function(target){
    var querystr ='';
    if(target.idClass){
      querystr += '?all=true&idClass='+target.idClass;
    }
    else if(target.idSchool){
      querystr += '?idSchool='+target.idSchool;
    }

    return $http({
     method: 'GET',
     url: $scope.data.school.database+'/api/students/'+querystr,
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken'),
     }
    }).then(function(res){
      if(target.idClass){
        target.students = [];
        for(let i = 0; i < res.data.students.length; i++){
          if(res.data.students[i].fkClass && res.data.students[i].archived != 1)
            target.students.push(res.data.students[i]);
        }
        target.otherStudents = res.data.students;
      } else
        target.students = res.data.students;
      return target;
    }).catch(function(err){
      console.error(err);
    })
  }
  $scope.addStudent = function(){
    var form = $('<div style="padding:10px;"></div>');

    var str ='<table><tr><td style="width=1px;">First Name:</td><td><input id="fname_txt" type="text"/></td></tr>'+
    '<tr><td>Last Name:</td><td><input id="lname_txt" type="text"/></td></tr>'+
    '<tr><td>ID:</td><td><input id="id_txt" type="text"/></td></tr>'+
    '<tr><td>Username:</td><td><input id="login_txt" type="text"/></td></tr></table>';

    form.append(str);

    var dlg = bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        var badLogin = false;
        var badId = false;

        var x = {
          idStudent:uuid.v4(),
          FirstName:form.find('#fname_txt').val(),
          LastName:form.find('#lname_txt').val(),
          localStudentId:form.find('#id_txt').val(),
          username:form.find('#login_txt').val(),
          fkSchool: $scope.data.school.idSchool,
        }

        for(var i = 0; i < $scope.data.school.students.length; i++){
          if($scope.data.school.students[i].username == x.username)
            badLogin = true;
          if($scope.data.school.students[i].idStudent == x.idStudent)
            badId = true;
        }

        if(badLogin||badId){
          if(badId)alertify.error('Duplicate Student ID');
          if(badLogin)alertify.error('Duplicate student username');
        } else {
          return $http({
           method: 'PUT',
           url: $scope.data.school.database+'/api/students/'+$scope.data.school.idSchool,
           headers: {
             Authorization: 'Bearer '+localStorage.getItem('studentToken'),
           },
           data: [x]
          }).then(function(res){
            var emails = [{
                school:$scope.data.school.name,
                schoolcode:$scope.data.school.schoolCode,
                email:x.username,
                district:$scope.data.district.name,
                state:$scope.data.state?$scope.data.state.name:$scope.data.district.stateName,
              }];
            $http.post('/api/student/added',{students:emails})
            $scope.data.school.students.push(x);
          }).catch(function(err){
            console.error(err);
            alertify.error('Error adding student');
          });
        }
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn-primary',
    }],{
      // prompts need a few extra options
      'header'  : 'New Student',
    });
  }

  $scope.editStudent = function(student){
    student.backup={
      LastName:student.LastName,
      FirstName:student.FirstName,
      username:student.username,
      localStudentId:student.localStudentId,
    }
    student.editing = true;
  }
  $scope.deleteStudent = function(student){
    bootbox.confirm("Delete "+student.LastName+", "+student.FirstName+" ? This will also remove all historical records for this student.", function(result){
      if(result){
        $http({
         method: 'DELETE',
         url: $scope.data.school.database+'/api/students/'+student.idStudent,
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         }
        }).then(function(res){
          var index = $scope.data.school.students.findIndex(function(x){return x.idStudent == student.idStudent;});
          $scope.data.school.students.splice(index,1);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing student');
        });
      }
    });
  }
  $scope.saveStudent = function(student){
    var flag = true;
    for(var i = 0; i < $scope.data.school.students.length; i++){
      if(student.idStudent != $scope.data.school.students[i].idStudent &&
      (student.username == $scope.data.school.students[i].username || student.localStudentId == $scope.data.school.students[i].localStudentId)){
        flag = false;
      }
    }

    if(flag){
      return $http({
       method: 'PUT',
       url: $scope.data.school.database+'/api/students/'+$scope.data.school.idSchool,
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       },
       data: [student]
      }).then(function(res){
        alertify.success("Student Updated");
        student.editing = false;
      }).catch(function(err){
        alertify.error("Error updating Student");
        console.error(err);
      });
    } else {
      alertify.error("Duplicate Student credentials");
    }
  }
  $scope.revertStudent = function(student){
    student.LastName=student.backup.LastName,
    student.FirstName=student.backup.FirstName,
    student.username=student.backup.username,
    student.localStudentId=student.backup.localStudentId,
    student.editing = false;
  }

  $scope.clearPassword = function(student){
    return $http({
     method: 'PUT',
     url: $scope.data.school.database+'/api/students/password',
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken'),
     },
     data: {idStudent:student.idStudent}
    }).then(function(res){
      alertify.success('Student password cleared');
    }).catch(function(err){
      console.error(err);
    });
  }

  $scope.isInactive = function(student){
    return (student && student.hasOwnProperty('active') && !student.active);
  }
  $scope.showDeactivateStudents = function(){
    $scope.data.showBackdrop = true;
    $scope.data.showDeactivateStudents = true;
    $scope.data.studentIdString = '';
  }
  $scope.activateStudents = function(students){
    if(students.length > 0){
      return $http({
       method: 'PUT',
       url: $scope.data.school.database+'/api/students/activate',
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       },
       data: {students:students.map(s=>s.idStudent)},
      }).then(function(res){
        students.forEach(s=>s.active = 1);
        alertify.success("Students Activated");
      }).catch(function(err){
        alertify.error("Error Activating Students");
        console.error(err);
      });
    } else {
      alertify.error('Please add at least 1 student to activate');
    }
  }
  $scope.deactivateStudents = function(students){
    if(students.length > 0){
      return $http({
       method: 'PUT',
       url: $scope.data.school.database+'/api/students/deactivate',
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       },
       data: {students:students.map(s=>s.idStudent)},
      }).then(function(res){
        students.forEach(s=>s.active = 0);
        alertify.success("Students deactivated");
      }).catch(function(err){
        alertify.error("Error deactivating Students");
        console.error(err);
      });
    } else {
      alertify.error('Please add at least 1 student to deactivate');
    }
  }
  $scope.bulkDeactivateStudents = function(idString){
    var ids = idString.replace(/,/g,'\n').split('\n');
    var pool = $scope.data.school.students;
    var students = [];

    ids = ids||[];

    for(var i = 0; i < ids.length; i++){
      ids[i] = ids[i].trim().toLowerCase();
    }

    for(var i = 0; i < pool.length; i++){
      var student = ids.find(function(s){
        return pool[i].localStudentId.toLowerCase() == s || pool[i].username.toLowerCase() == s;
      });
      if(student) students.push(pool[i]);
    }
    $scope.deactivateStudents(students);
  }

  $scope.showResendInvites = function(){
    $scope.data.showBackdrop = true;
    $scope.data.showResendInvites = true;
    $scope.data.studentIdString = '';
  }
  $scope.bulkStudentInvites = function(idString){
    var ids = idString.replace(/,/g,'\n').split('\n');
    for(var i = 0; i < ids.length; i++){
      ids[i] = ids[i].trim().toLowerCase();
    }
    $scope.resendStudentInvite(ids);
  }
  $scope.resendStudentInvite = function(ids, all, pool){
    var emails = [];
    var p;
    ids = ids||[];
    pool = pool || $scope.data.school.students;

    for(var i = 0; i < pool.length; i++){
      var student = ids.find(function(s){
        return pool[i].localStudentId.toLowerCase() == s || pool[i].username.toLowerCase() == s;
      });
      if(student || all) emails.push({
        school:$scope.data.school.name,
        schoolcode:$scope.data.school.schoolCode,
        email:pool[i].username,
        district:$scope.data.district.name,
        state:$scope.data.state?$scope.data.state.name:$scope.data.district.stateName,
      });
    }

    if(emails.length > 0)
      p = $http.post('/api/student/added',{students:emails});
    else
      p = Promise.resolve(null);

    p.then(function(){
      if(emails.length > 0)
        alertify.success('Instructions Sent');
      else
        alertify.error('No students found');
    }).catch(function(){
      alertify.error('An error occured');
    });
  }

  $scope.isDropped = function(student){
    return (student && student.hasOwnProperty('dropped') && student.dropped);
  }
  $scope.getStudentsClasses = function(student){
    $scope.data.student = student;
    $scope.data.studentClasses = [];
    return $http({
     method: 'GET',
     url: $scope.data.school.database+'/api/students/'+$scope.data.student.idStudent+'/class',
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken'),
     }
    }).then(function(res){
      $scope.data.studentClasses = res.data.classes;
      $scope.data.showBackdrop = true;
      $scope.data.showStudentClasses = true;
    }).catch(function(err){
      console.error(err);
    });
  }
  $scope.assignStudent = function(addStudent){
    if($scope.data.class){
      return $http({
       method: 'PUT',
       url: $scope.data.school.database+'/api/students/classes',
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       },
       data: [{idStudent:addStudent.idStudent,idClass:$scope.data.class.idClass}]
      }).then(function(res){
        var student = $scope.data.class.students.find((s)=>s.idStudent == addStudent.idStudent);
        if(student)
          student.dropped = 0;
        else
          $scope.data.class.students.push(addStudent);
      }).catch(function(err){
        console.error(err);
      });
    }
  }
  $scope.unassignStudent = function(remStudent){
    bootbox.confirm("Delete "+remStudent.LastName+", "+remStudent.FirstName+" from this class?", function(result){
      if(result && $scope.data.class){
        $http({
         method: 'DELETE',
         url: $scope.data.school.database+'/api/students/'+remStudent.idStudent+'/class/'+$scope.data.class.idClass,
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         }
        }).then(function(res){
          var index = $scope.data.class.students.findIndex(function(s){return s.idStudent==remStudent.idStudent})
          if(index>=0)  $scope.data.class.students.splice(index,1);
          alertify.success('Student removed');
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing student');
        });
      }
    });
  }
  $scope.dropStudents = function(students){
    if(students.length > 0){
      return $http({
       method: 'PUT',
       url: $scope.data.school.database+'/api/students/class/'+$scope.data.class.idClass+'/drop',
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       },
       data: {students:students.map(s=>s.idStudent)},
      }).then(function(res){
        students.forEach(s=>s.dropped = 1);
        alertify.success('Students Dropped');
      }).catch(function(err){
        alertify.error('Error dropping Students');
        console.error(err);
      });
    } else {
      alertify.error('Please add at least 1 student to drop');
    }
  }


///////////////////////////
  $scope.filterStudents = function(pool,filter){
    var filtered = [];

    for(var p = 0; p < pool.length; p++){
      var flag = true
      for(var f = 0; f < filter.length && flag; f++){
        if(pool[p].idStudent == filter[f].idStudent)
          flag = false;
      }
      if(flag)
        filtered.push(pool[p]);
    }

    return filtered;
  }
  $scope.filterStaff = function(pool,filter){
    var filtered = [];

    for(var p = 0; p < pool.length; p++){
      var flag = true;
      for(var f = 0; f < filter.length && flag; f++){
        if(pool[p].fkUser && filter[f].fkUser && pool[p].fkUser == filter[f].fkUser)
          flag = false;
      }
      if(flag)
        filtered.push(pool[p]);
    }

    return filtered;
  }

  $scope.getLetter = function(i){
    var str = '';
    if(i >= 26)
      str += $scope.data.letters[i/26];
    str += $scope.data.letters[i%26];
    return str;
  }

  $scope.findClass = function(localId,start,stop){
    var c = null;
    for(var i = 0; i < $scope.data.classes.length && !c; i++){
      if($scope.data.classes[i].localClassId == localId && ((!start && !stop)||
        (start <= $scope.data.classes[i].stop && start >= $scope.data.classes[i].start) ||
        (stop >= $scope.data.classes[i].start && stop <= $scope.data.classes[i].stop) ||
        (stop && start <= $scope.data.classes[i].start && stop >= $scope.data.classes[i].stop)
      )){
        c = $scope.data.classes[i];
      }
    }
    return c;
  }
  $scope.findClassInPool = function(localId,pool){
    var c = null;
    for(var i = 0; i < pool.length && !c; i++){
      if(pool[i].localClassId == localId){
        c = pool[i];
      }
    }
    return c;
  }

  $scope.findStudent = function (localId, login, pool) {
    return $scope.findStudentByID(localId, pool) || $scope.findStudentByLogin(login, pool);
  }
  $scope.findStudentByID = function (localId, pool) {
    var c = null;
    for(var i =0; i < pool.length && !c; i++){
      if(pool[i].localStudentId == localId){
        c = pool[i];
      }
    }
    return c;
  }
  $scope.findStudentByLogin = function (login, pool) {
    var c = null;
    for (var i = 0; i < pool.length && !c; i++) {
      if (pool[i].username == login) {
        c = pool[i];
      }
    }
    return c;
  }

  $scope.findStudentClass = function(idStudent,idClass,pool){
    var c = null;
    for(var i =0; i < pool.length && !c; i++){
      if(pool[i].idStudent == idStudent && pool[i].idClass == idClass){
        c = pool[i];
      }
    }
    return c;
  }

  $scope.findStaff = function (id, email, idTarget, pool) {
    return $scope.findStaffByID(id, idTarget, pool) || $scope.findStaffByLogin(email, idTarget, pool)
  }
  $scope.findStaffByID = function(id,idTarget,pool){
    var c = null;
    for(var i =0; i < pool.length && !c; i++){
      if((pool[i].localStaffId||'').toLowerCase() == id.toLowerCase() &&
      (pool[i].fkSchool == idTarget || pool[i].fkDistrict == idTarget || pool[i].fkClass == idTarget))
      {
        c = pool[i];
      }
    }
    return c;
  }
  $scope.findStaffByLogin = function(email,idTarget,pool){
    var c = null;
    email = email.toLowerCase();
    for(var i =0; i < pool.length && !c; i++){
      if(((pool[i].Email||'').toLowerCase() == email || (pool[i].invitedEmail||'').toLowerCase() == email || (pool[i].email||'').toLowerCase() == email) &&
      (pool[i].fkSchool == idTarget || pool[i].fkDistrict == idTarget || pool[i].fkClass == idTarget))
      {
        c = pool[i];
      }
    }
    return c;
  }

  $scope.getClassCompositID = function(importValues){
    //importValues[$scope.data.importColumns[1].index];
    let str = importValues[$scope.data.importColumns[1].index];
    if ($scope.data.importColumns[3].index >= 0)
      str += '-' + importValues[$scope.data.importColumns[3].index];
    if ($scope.data.importColumns[4].index >= 0)
      str += '-' +importValues[$scope.data.importColumns[4].index];

    return str;
  }

  $scope.import = function(target){
    var input = document.createElement('input');
    input.setAttribute("type", "file");
    input.onchange = function (e) {
      var file = input.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var lines = reader.result.split('\n').filter(function(l){return l.length > 0});
        $scope.data.importData = [];
        $scope.data.importIndex = 1;
        $scope.data.importBatch = 500;
        $scope.data.importFetch = {
          staff:false,
          teachers:false,
          classes:false,
          students:false,
        }
        $scope.data.importObjects = [];
        $scope.data.importInvalid = [];
        $scope.data.importedObjects = [];
        $scope.data.imported = {
          classes:0,
          staff:0,
          teachers:0,
          students:0,
          studentClasses:0,
          teacherClasses:0,
        };
        for(var i = 0; i < lines.length; i++){
          lines[i] = lines[i].split(',');

          for(var e = 0; e < lines[i].length; e++){
            lines[i][e] = lines[i][e].trim();
          }

          if(lines[0].length < lines[i].length){
            $scope.data.importInvalid.push(lines[i].splice(0,0,'Possible comma in data'));
          } else {
            $scope.data.importData.push(lines[i]);
          }
        }
        $scope.data.importColumns;
        $scope.data.importMessage = 'Please select which columns to use:';
        $scope.data.importClassLinkColumns = null;
        $scope.data.importStaffLinkColumns = null;
        $scope.data.importStudentLinkColumns = null;

        if(target == 'district'){
          $scope.data.importColumns = ['Email *','Title','Staff ID *'];
          $scope.data.importTitle = 'District Staff';
        } else if (target == 'school'){
          $scope.data.importColumns = ['Email *','Title','Teacher ID *'];
          $scope.data.importTitle = 'Teachers';
          $scope.data.importClassLinkColumns = {start:-1,stop:-1};
        } else if(target == 'students'){
          $scope.data.importColumns = ['Last name','First name','Student Username *','Student ID *'];
          $scope.data.importTitle = 'students';
          $scope.data.importClassLinkColumns = {start:-1,stop:-1};
        } else if(target == 'classes'){
          $scope.data.importColumns = ['Class Name *', 'Class ID *', 'Class Period', 'Composite ID Part 1', 'Composite ID Part 2'];
          $scope.data.importTitle = 'classes';
          $scope.data.importStaffLinkColumns = {start:-1,stop:-1};
          $scope.data.importStudentLinkColumns = {start:-1,stop:-1};
        }

        var date = moment('1990-1-1')
          .month('july')
          .year(moment().year() + ((moment().month() > 5) ? 0 : -1))
          .startOf('month');
        $scope.data.importSchoolYear.start = date.valueOf();
        date.month('june')
          .year(date.year()+1)
          .endOf('month');
        $scope.data.importSchoolYear.stop = date.valueOf();

        $scope.data.importColumns = $scope.data.importColumns.map(function(name){
          return {
            required:name.endsWith('*'),
            name:name,
            index:-1
          }
        });

        $scope.dismiss();
        $scope.refresh(function(){
          $scope.data.showBackdrop = true;
          $scope.data.showImport = true;
        });
      };
      if (file) {
        reader.readAsText(file);
      }
    };
    input.click();
  }
  $scope.startImport = function(target){
    var flag = true;
    for(var i = 0; i < $scope.data.importColumns.length ;i++){
      if($scope.data.importColumns[i].required && $scope.data.importColumns[i].index == -1){
        flag = false;
        alertify.error($scope.data.importColumns[i].name.replace('*','')+'is required');
      }
    }

    if (flag) {
      $scope.data.working++;
      $scope.formatImport(target);
    }
  }
  $scope.formatImport = function(target){
    $scope.dismiss();
    $scope.data.importMessage = 'Please select access level for imported staff members:';
    $scope.data.importValidateStaff = [];
    $scope.data.importValidateStudents = [];
    $scope.data.importObjects = [];

    if($scope.data.importClassLinkColumns){
      $scope.data.importClassLinkColumns.start = parseInt($scope.data.importClassLinkColumns.start);
      $scope.data.importClassLinkColumns.stop = parseInt($scope.data.importClassLinkColumns.stop);
    }
    if($scope.data.importStaffLinkColumns){
      $scope.data.importStaffLinkColumns.start = parseInt($scope.data.importStaffLinkColumns.start);
      $scope.data.importStaffLinkColumns.stop = parseInt($scope.data.importStaffLinkColumns.stop);
    }
    if($scope.data.importStudentLinkColumns){
      $scope.data.importStudentLinkColumns.start = parseInt($scope.data.importStudentLinkColumns.start);
      $scope.data.importStudentLinkColumns.stop = parseInt($scope.data.importStudentLinkColumns.stop);
    }

    var p = [];
    if (!$scope.data.importFetch.staff && target == 'district') {
      $scope.data.importFetch.staff = true;
      p.push($scope.getStaff($scope.data.district));
    }
    if (!$scope.data.importFetch.teachers && (target == 'school' || target == 'classStaff')) {
      $scope.data.importFetch.teachers = true;
      p.push($scope.getStaff($scope.data.school));
    }
    if (!$scope.data.importFetch.students && (target == 'students' || target == 'classStudents' || target == 'studentClasses')) {
      $scope.data.importFetch.students = true;
      p.push($scope.getStudents($scope.data.school));
    }
    if (!$scope.data.importFetch.classes && target == 'classes'){
      $scope.data.importFetch.classes = true
      p.push($scope.getClasses());
    }

    Promise.all(p).then(function(){
      var p2 = [];
      var fetchedPools = []

      for (var i = $scope.data.importIndex; i < $scope.data.importIndex + $scope.data.importBatch && i < $scope.data.importData.length; i++) {
        if (target == 'district') {
          $scope.data.importValidateStaff.push([
            $scope.data.importData[i][$scope.data.importColumns[0].index] || '',
            $scope.data.importData[i][$scope.data.importColumns[1].index] || '',
            $scope.data.importData[i][$scope.data.importColumns[2].index] || '',
          ]);
        } else if (target == 'school') {
          $scope.data.importValidateStaff.push([
            $scope.data.importData[i][$scope.data.importColumns[0].index] || '',
            $scope.data.importData[i][$scope.data.importColumns[1].index] || '',
            $scope.data.importData[i][$scope.data.importColumns[2].index] || '',
          ]);
        } else if (target == 'students') {
          var s;
          if ($scope.findStudent($scope.data.importData[i][$scope.data.importColumns[3].index],
            $scope.data.importData[i][$scope.data.importColumns[2].index],
            $scope.data.importedObjects)) {
            // console.log('dupe import');
          } else if (s = $scope.findStudent($scope.data.importData[i][$scope.data.importColumns[3].index],
            $scope.data.importData[i][$scope.data.importColumns[2].index],
            $scope.data.school.students)) {
            if($scope.isInactive(s)){
              $scope.data.importObjects.push({
                idStudent: s.idStudent,
                FirstName: s.FirstName,
                LastName: s.LastName,
                localStudentId: s.localStudentId,
                username: s.username,
                fkSchool: s.fkSchool,
              });
            }
            else
            $scope.data.importInvalid.push([
              'Existing student',
              $scope.data.importData[i][$scope.data.importColumns[0].index],
              $scope.data.importData[i][$scope.data.importColumns[1].index],
              $scope.data.importData[i][$scope.data.importColumns[2].index],
              $scope.data.importData[i][$scope.data.importColumns[3].index],
            ]);
          } else if (!($scope.findStudent($scope.data.importData[i][$scope.data.importColumns[3].index],
            $scope.data.importData[i][$scope.data.importColumns[2].index],
            $scope.data.importObjects))) {
            $scope.data.importObjects.push({
              idStudent: uuid.v4(),
              FirstName: $scope.data.importData[i][$scope.data.importColumns[1].index] || '',
              LastName: $scope.data.importData[i][$scope.data.importColumns[0].index] || '',
              localStudentId: $scope.data.importData[i][$scope.data.importColumns[3].index] || '',
              username: $scope.data.importData[i][$scope.data.importColumns[2].index] || '',
              fkSchool: $scope.data.school.idSchool,
            });
          }
        } else if (target == 'classStudents') {
          for (var l = 0; l < 1 + ($scope.data.importStudentLinkColumns.stop - $scope.data.importStudentLinkColumns.start); l++) {
            if ($scope.data.importData[i][$scope.data.importStudentLinkColumns.start + l]) {
              $scope.data.importValidateStudents.push([
                $scope.data.importData[i][$scope.data.importColumns[1].index] || '',
                $scope.data.importData[i][$scope.data.importStudentLinkColumns.start + l] || '',
              ]);
            }
          }
        } else if (target == 'studentClasses') {
          for (var l = 0; l < 1 + ($scope.data.importClassLinkColumns.stop - $scope.data.importClassLinkColumns.start); l++) {
            if ($scope.data.importData[i][$scope.data.importClassLinkColumns.start + l]) {
              $scope.data.importValidateStudents.push([
                $scope.data.importData[i][$scope.data.importClassLinkColumns.start + l] || '',
                $scope.data.importData[i][$scope.data.importColumns[3].index] || ''
              ]);
            }
          }
        } else if (target == 'classStaff') {
          for (var l = 0; l < 1 + ($scope.data.importStaffLinkColumns.stop - $scope.data.importStaffLinkColumns.start); l++) {
            if ($scope.data.importData[i][$scope.data.importStaffLinkColumns.start + l]) {
              var s = $scope.findStaff($scope.data.importData[i][$scope.data.importStaffLinkColumns.start + l],
                $scope.data.importData[i][$scope.data.importStaffLinkColumns.start + l],
                $scope.data.school.idSchool,
                $scope.data.school.staff);
              if (!s) {
                $scope.data.importInvalid.push([
                  'No matching Staff',
                  $scope.data.importData[i][$scope.data.importColumns[1].index],
                  $scope.data.importData[i][$scope.data.importStaffLinkColumns.start + l]
                ])
              } else {
                $scope.data.importValidateStaff.push([
                  s.Email || s.invitedEmail,
                  s.title,
                  s.localStaffId,
                  $scope.data.importData[i][$scope.data.importColumns[1].index] || '',
                ]);
              }
            }
          }
        } else if (target == 'staffClasses') {
          for (var l = 0; l < 1 + ($scope.data.importClassLinkColumns.stop - $scope.data.importClassLinkColumns.start); l++) {
            if ($scope.data.importData[i][$scope.data.importClassLinkColumns.start + l]) {
              $scope.data.importValidateStaff.push([
                $scope.data.importData[i][$scope.data.importColumns[0].index] || '',
                $scope.data.importData[i][$scope.data.importColumns[1].index] || '',
                $scope.data.importData[i][$scope.data.importColumns[2].index] || '',
                $scope.data.importData[i][$scope.data.importClassLinkColumns.start + l] || ''
              ]);
            }
          }
        } else if (target == 'classes') {
          if ($scope.findClassInPool($scope.getClassCompositID($scope.data.importData[i]), $scope.data.importedObjects)) {
            // console.log('dupe import');
          } else if ($scope.findClass($scope.getClassCompositID($scope.data.importData[i]),
            $scope.data.importSchoolYear.start, $scope.data.importSchoolYear.stop)) {
            $scope.data.importInvalid.push([
              'Existing Class',
              $scope.data.importData[i][$scope.data.importColumns[0].index],
              $scope.getClassCompositID($scope.data.importData[i]),
            ])
          } else if (!$scope.findClassInPool($scope.getClassCompositID($scope.data.importData[i]), $scope.data.importObjects)) {
            $scope.data.importObjects.push({
              idClass: uuid.v4(),
              name: ($scope.data.importData[i][$scope.data.importColumns[0].index] || ''),
              period: ($scope.data.importData[i][$scope.data.importColumns[2].index] || ''),
              localClassId: $scope.getClassCompositID($scope.data.importData[i]) || '',
              fkSchool: $scope.data.school.idSchool,
              start: $scope.data.importSchoolYear.start,
              stop: $scope.data.importSchoolYear.stop,
            })
          }
        }
      }

      $scope.data.importIndex += $scope.data.importBatch;

      if ($scope.data.importValidateStaff.length > 0 && (target == 'classStaff' || target == 'staffClasses')) {
        p2.push(...$scope.data.importValidateStaff.map(function(item){
          var pool = $scope.findClass(item[3], $scope.data.importSchoolYear.start, $scope.data.importSchoolYear.stop);
          if (pool && fetchedPools.findIndex((c) => c.idClass == pool.idClass) < 0){
            fetchedPools.push(pool);
            return $scope.getStaff(pool);
          } else
            return Promise.resolve();
        }));
      }
      if ($scope.data.importValidateStudents.length > 0) {
        p2.push(...$scope.data.importValidateStudents.map(function (item) {
          var pool = $scope.findClass(item[0], $scope.data.importSchoolYear.start, $scope.data.importSchoolYear.stop);
          if (pool && fetchedPools.findIndex((c) => c.idClass == pool.idClass) < 0) {
            fetchedPools.push(pool);
            return $scope.getStudents(pool);
          } else
            return Promise.resolve();
        }));
      }

      return Promise.all(p2);
    }).then(function () {

      if ($scope.data.importValidateStaff.length > 0) {
        for (var i = 0; i < $scope.data.importValidateStaff.length; i++) {
          var pool = $scope.data.district;
          var targetId = $scope.data.district.idDistrict;
          if (target == 'school') {
            pool = $scope.data.school;
            targetId = $scope.data.school.idSchool;
          }
          if (target == 'classStaff' || target == 'staffClasses') {
            pool = $scope.findClass($scope.data.importValidateStaff[i][3],
              $scope.data.importSchoolYear.start, $scope.data.importSchoolYear.stop);
            targetId = pool ? pool.idClass : null;
          }
          if (!pool) {
            $scope.data.importInvalid.push([
              'No class found',
              $scope.data.importValidateStaff[i][0],
              $scope.data.importValidateStaff[i][1],
              $scope.data.importValidateStaff[i][2],
              $scope.data.importValidateStaff[i][3] || '',
            ])
          } else if ($scope.findStaff($scope.data.importValidateStaff[i][2], $scope.data.importValidateStaff[i][0],
            targetId, $scope.data.importedObjects)) {
            //   console.log([
            //     'dupe import',
            //     $scope.data.importValidateStaff[i][0],
            //     $scope.data.importValidateStaff[i][1],
            //     $scope.data.importValidateStaff[i][2],
            //     $scope.data.importValidateStaff[i][3] || ''
            //   ]);
          } else if ($scope.findStaff($scope.data.importValidateStaff[i][2], $scope.data.importValidateStaff[i][0],
            targetId, pool.staff)) {
            $scope.data.importInvalid.push([
              'Existing staff member',
              $scope.data.importValidateStaff[i][0],
              $scope.data.importValidateStaff[i][1],
              $scope.data.importValidateStaff[i][2],
              $scope.data.importValidateStaff[i][3] || '',
            ])
          } else if (!($scope.findStaff($scope.data.importValidateStaff[i][2], $scope.data.importValidateStaff[i][0],
            targetId, $scope.data.importObjects))) {
            $scope.data.importObjects.push({
              idStaff: uuid.v4(),
              email: $scope.data.importValidateStaff[i][0],
              adminStudents: (target == 'classStaff' || target == 'staffClasses') ? 2 : 0,
              adminStaff: (target == 'classStaff' || target == 'staffClasses') ? 2 : 0,
              adminClasses: (target == 'classStaff' || target == 'staffClasses') ? 2 : 0,
              adminExams: (target == 'classStaff' || target == 'staffClasses') ? 2 : 0,
              fkSchool: (target == 'school') ? $scope.data.school.idSchool : null,
              fkDistrict: (target == 'district') ? $scope.data.district.idDistrict : null,
              fkClass: (target == 'classStaff' || target == 'staffClasses') ? pool.idClass : null,
              title: $scope.data.importValidateStaff[i][1],
              localStaffId: $scope.data.importValidateStaff[i][2],
            })
          } 
          //else {
          //   console.log([
          //     'dupe import',
          //     $scope.data.importValidateStaff[i][0],
          //     $scope.data.importValidateStaff[i][1],
          //     $scope.data.importValidateStaff[i][2],
          //     $scope.data.importValidateStaff[i][3] || ''
          //   ]);
          // }
        }
      }

      if ($scope.data.importValidateStudents.length > 0) {
        for (var i = 0; i < $scope.data.importValidateStudents.length; i++) {
          var c = $scope.findClass($scope.data.importValidateStudents[i][0],
                                    $scope.data.importSchoolYear.start,
                                    $scope.data.importSchoolYear.stop);
          var s = $scope.findStudent($scope.data.importValidateStudents[i][1],
                                      $scope.data.importValidateStudents[i][1],
                                      $scope.data.school.students);
          if (!c) {
            $scope.data.importInvalid.push([
              'No matching Class found',
              $scope.data.importValidateStudents[i][0],
              $scope.data.importValidateStudents[i][1],
            ])
          } else if (!s) {
            $scope.data.importInvalid.push([
              'No matching student found',
              $scope.data.importValidateStudents[i][0],
              $scope.data.importValidateStudents[i][1],
            ])
          } else if ($scope.findStudentClass(s.idStudent, c.idClass, $scope.data.importedObjects)) {
            // console.log([
            //   'dupe import',
            //   $scope.data.importValidateStudents[i][0],
            //   $scope.data.importValidateStudents[i][1],
            // ]);
          } else if ($scope.findStudent($scope.data.importValidateStudents[i][1],
                                        $scope.data.importValidateStudents[i][1],
                                        c.students)) {
            $scope.data.importInvalid.push([
              'Existing student in class',
              $scope.data.importValidateStudents[i][0],
              $scope.data.importValidateStudents[i][1],
            ])
          } else if (!$scope.findStudentClass(s.idStudent, c.idClass, $scope.data.importObjects)) {
            $scope.data.importObjects.push({
              idStudent: s.idStudent,
              idClass: c.idClass,
            })
          } 
          // else {
          //   console.log([
          //     'dupe import',
          //     $scope.data.importValidateStudents[i][0],
          //     $scope.data.importValidateStudents[i][1],
          //   ]);
          // }
        }
      }

      // if($scope.data.importObjects.length > 0 && (target == 'district'||target == 'school')){
      //   $scope.refresh(function(){
      //     $scope.data.showBackdrop = true;
      //     $scope.data.showImportAccess = true;
      //   });
      // } else {
      $scope.data.working--;

      $scope.finishImport(target);
      // }
    })


    // if($scope.data.importObjects.length > 0 && (target == 'district'||target == 'school')){
    //   $scope.refresh(function(){
    //     $scope.data.showBackdrop = true;
    //     $scope.data.showImportAccess = true;
    //   });
    // } else {
    // }
  }
  $scope.finishImport = function(target){
    function next() {
      console.log(target, $scope.data.importIndex);
      if ($scope.data.importIndex < $scope.data.importData.length) {
        setTimeout(() => $scope.formatImport($scope.data.importTarget), 100);
      } else if(target == 'school' && $scope.data.importClassLinkColumns.start >=0 ){
        $scope.data.importIndex = 1;
        $scope.data.importTarget = 'staffClasses';
        setTimeout(() => $scope.formatImport($scope.data.importTarget), 100);
      } else if (target == 'students' && $scope.data.importClassLinkColumns.start >= 0) {
        $scope.data.importIndex = 1;
        $scope.data.importTarget = 'studentClasses';
        setTimeout(() => $scope.formatImport($scope.data.importTarget), 100);
      } else if (target == 'classes' && $scope.data.importStudentLinkColumns.start >= 0) {
        $scope.data.importIndex = 1;
        $scope.data.importTarget = 'classStudents';
        setTimeout(() => $scope.formatImport($scope.data.importTarget), 100);
      } else if ((target == 'classes' || target == 'classStudents') && $scope.data.importStaffLinkColumns.start >= 0) {
        $scope.data.importIndex = 1;
        $scope.data.importTarget = 'classStaff';
        setTimeout(() => $scope.formatImport($scope.data.importTarget), 100);
      } else {
        var results = [];
        if ($scope.data.imported.classes > 0)
          results.push('Imported ' + $scope.data.imported.classes + ' classes');
        if ($scope.data.imported.staff > 0)
          results.push('Imported ' + $scope.data.imported.staff + ' district staff members');
        if ($scope.data.imported.teachers > 0)
          results.push('Imported ' + $scope.data.imported.teachers + ' teachers');
        if ($scope.data.imported.students > 0)
          results.push('Imported ' + $scope.data.imported.students + ' students');
        if ($scope.data.imported.studentClasses > 0)
          results.push('Linked students and classes');
        if ($scope.data.imported.teacherClasses > 0)
          results.push('Linked teachers and classes');

        $scope.data.imported = results;

        $scope.data.working--;
        $scope.getClasses();
        $scope.refresh(function(){
          $scope.data.showBackdrop = true;
          $scope.data.showImportResults = true;
        });
      }
    }
    $scope.dismiss();
    $scope.data.working++;
    if($scope.data.importObjects.length > 0){
      var p;
      if(target == 'district'){
        p = $http.put('/api/staff/email',$scope.data.importObjects).then(function(res){
          $scope.data.district.staff.push(...res.data);
          $scope.data.importedObjects.push(...res.data);
          $scope.data.imported.staff += res.data.length;
          var notify = {
            new:{
              emails:[],
              location:$scope.data.district.name,
            },
            old:{
              emails:[],
              location:$scope.data.district.name,
            },
          };
          for(var i = 0; i < res.data.length; i++){
            if(res.data[i].fkUser){
              notify.old.emails.push(res.data[i].Email);
            } else {
              notify.new.emails.push(res.data[i].invitedEmail);
            }
          }
          $http.put('/api/staff/invite',notify);
          next();
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding Staff');
        });
      } else if(target == 'school'){
        p = $http.put('/api/staff/email',$scope.data.importObjects).then(function(res){
          $scope.data.school.staff.push(...res.data);
          $scope.data.importedObjects.push(...res.data);
          $scope.data.imported.teachers += res.data.length;
          var notify = {
            new:{
              emails:[],
              location:$scope.data.school.name,
            },
            old:{
              emails:[],
              location:$scope.data.school.name,
            },
          };
          for(var i = 0; i < res.data.length; i++){
            if(res.data[i].fkUser){
              notify.old.emails.push(res.data[i].Email);
            } else {
              notify.new.emails.push(res.data[i].invitedEmail);
            }
          }
          $http.put('/api/staff/invite',notify);
          next();
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding Staff');
        });
      } else if(target == 'students'){
        p = $http({
         method: 'PUT',
         url: $scope.data.school.database+'/api/students/'+$scope.data.school.idSchool,
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         },
         data: $scope.data.importObjects
        }).then(function(res){
          var emails = $scope.data.importObjects.map(function(student){
            return {
              school:$scope.data.school.name,
              schoolcode:$scope.data.school.schoolCode,
              email:student.username,
              district:$scope.data.district.name,
              state:$scope.data.state?$scope.data.state.name:$scope.data.district.stateName,
            }
          });
          $http.post('/api/student/added',{students:emails})
          $scope.getStudents($scope.data.school);
          $scope.data.importedObjects.push(...$scope.data.importObjects);
          $scope.data.imported.students += $scope.data.importObjects.length;
          next();
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding student');
        });
      } else if(target == 'classStudents'||target == 'studentClasses'){
        p = $http({
         method: 'PUT',
         url: $scope.data.school.database+'/api/students/classes',
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         },
         data: $scope.data.importObjects
        }).then(function(res){
          $scope.data.imported.studentClasses++;
          $scope.data.importedObjects.push(...$scope.data.importObjects);
          next();
        }).catch(function(err){
          console.error(err);
        });
      } else if(target == 'classStaff'||target == 'staffClasses'){
        p = $http.put('/api/staff/email', $scope.data.importObjects).then(function (res) {
          $scope.data.importedObjects.push(...$scope.data.importObjects);
          $scope.data.imported.teacherClasses++;
          next();
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding Staff');
        });
      } else if(target == 'classes'){
        p = $http.put('/api/class',$scope.data.importObjects).then(function(res){
          for(var i = 0; i < $scope.data.importObjects.length; i++){
            $scope.data.importObjects[i].students=[];
            $scope.data.importObjects[i].staff=[];
          }
          $scope.data.classes.push(...$scope.data.importObjects);
          $scope.data.importedObjects.push(...$scope.data.importObjects);
          $scope.data.imported.classes += $scope.data.importObjects.length;
          next();
        }).catch(function(err){
          console.error(err);
          alertify.error('Error adding Class');
        });
      }
    } else {
      next();
    }
  }

///////////////////////////////

  $scope.copyCode = function(text){
    var el = document.createElement('input');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    if(text && document.execCommand('copy')){
      alertify.success('Code copied to clipboard');
      document.body.removeChild(el);
    } else {
      alertify.error('Error copying school code');
    }
  }

  $scope.setSchoolYear = function(object, classFilter, next){
    $scope.data.showSetSchoolYear = true;
    $scope.data.showBackdrop = true
    $scope.data.schoolYear.edit = object;
    $scope.data.schoolYear.next = next;
    $scope.data.schoolYear.classFilter = classFilter;

    var date = moment(object.start);
    $scope.data.schoolYear.startMonth = date.month()+1;
    $scope.data.schoolYear.startYear = date.year();

    date = moment(object.stop);
    $scope.data.schoolYear.stopMonth = date.month()+1;
    $scope.data.schoolYear.stopYear = date.year();

  }

  $scope.setDates = function(){
    $scope.dismiss();

    var date = moment('1990-1-1');
    date.month($scope.data.schoolYear.startMonth-1)
      .year($scope.data.schoolYear.startYear)
      .startOf('month');
    $scope.data.schoolYear.edit.start = date.valueOf();

    date.month($scope.data.schoolYear.stopMonth-1)
      .year($scope.data.schoolYear.stopYear)
      .endOf('month');
    $scope.data.schoolYear.edit.stop = date.valueOf();

    if ($scope.data.schoolYear.next) $scope.data.schoolYear.next();
  }

  $rootScope.$watch('profile',function(profile){
    if(profile){
      $scope.profile = profile;
      $scope.getDistricts();

      if(profile.toolsAccess)$scope.data.adminOverride = true;
    }
  });



/////////////////////////////////////////////////

  $scope.exportStaff= function(staff, name){
    let labels = ['Staff ID', 'Last Name', 'First Name', 'Email', 'Title', 'Exam Access', 'Staff Access', 'Class Access', 'Student Access'];
    let keys = ['localStaffId', 'LastName', 'FirstName', 'Email', 'title', 'adminExams', 'adminStaff', 'adminClasses', 'adminStudents'];
    let accessLevel = ['None', 'View', 'View/Edit'];

    let csv='';
    for(let i = 0; i < keys.length; i++){
      csv+= labels[i]+',';
    }
    csv+='\n';

    for(let a = 0; a < staff.length; a++){
      for (let b = 0; b < keys.length; b++) {
        if(keys[b] === 'Email')
          csv += ((staff[a].Email || staff[a].invitedEmail) || '') + ',';
        else if( keys[b].startsWith('admin'))
          csv += (accessLevel[staff[a][keys[b]]] || '') + ',';
        else
          csv += (staff[a][keys[b]]||'') + ',';
      }
      csv += '\n';
    }

    var hiddenElement = document.createElement('a');

    var blob = new Blob([csv], { type: "attachment/csv" })
    var url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = name +' Staff Export.csv';
    hiddenElement.click();
  }

  $scope.exportStudents = function (students, name) {
    let labels = ['Student ID', 'Last Name', 'First Name', 'Login'];
    let keys = ['localStudentId', 'LastName', 'FirstName', 'username'];

    let csv = '';
    for (let i = 0; i < keys.length; i++) {
      csv += labels[i] + ',';
    }
    csv += '\n';

    for (let a = 0; a < students.length; a++) {
      for (let b = 0; b < keys.length; b++) {
        csv += (students[a][keys[b]] || '') + ',';
      }
      csv += '\n';
    }

    var hiddenElement = document.createElement('a');

    var blob = new Blob([csv], { type: "attachment/csv" })
    var url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = name + ' Student Export.csv';
    hiddenElement.click();
  }

  $scope.exportClasses = function (classes, name) {
    let labels = ['Class ID', 'Name', 'Period', 'Access Code', 'Start', 'Stop'];
    let keys = ['localClassId', 'name', 'period', 'classCode', 'start', 'stop'];

    let csv = '';
    for (let i = 0; i < keys.length; i++) {
      csv += labels[i] + ',';
    }
    csv += '\n';

    for (let a = 0; a < classes.length; a++) {
      for (let b = 0; b < keys.length; b++) {
        if (keys[b] === 'start' || keys[b] === 'stop')
          csv += (classes[a][keys[b]] ? moment(classes[a][keys[b]]).format('MM/YYYY') :'') + ',';
        else
          csv += (classes[a][keys[b]] || '') + ',';
      }
      csv += '\n';
    }

    var hiddenElement = document.createElement('a');

    var blob = new Blob([csv], { type: "attachment/csv" })
    var url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = name + ' Class Export.csv';
    hiddenElement.click();
  }

  $scope.exportAllStudents = function(classes){
    var fetch = [];
    for(let i = 0; i < classes.length; i++){
      fetch.push($scope.getStudents(classes[i]));
      // $scope.getStudents(classes[i])
      //   .then(function(res){
      //     console.log(res);
      //     $scope.exportStudents(res.students, res.name);    
      //   });      
    }
    Promise.all(fetch)
      .then((results) => {
        let labels = ['Student ID', 'Last Name', 'First Name', 'Login', 'Class Name', 'Class ID'];
        let keys = ['localStudentId', 'LastName', 'FirstName', 'username'];

        let csv = '';
        for (let i = 0; i < labels.length; i++) {
          csv += labels[i] + ',';
        }

        csv += '\n';
        results.forEach((classInfo) => {

          for (let a = 0; a < classInfo.students.length; a++) {
            for (let b = 0; b < keys.length; b++) {
              csv += (classInfo.students[a][keys[b]] || '') + ',';
            }
            csv += (classInfo.name || '') + ',';
            csv += (classInfo.localClassId || '') + ',';
            csv += '\n';
          }        
        })

        var hiddenElement = document.createElement('a');

        var blob = new Blob([csv], { type: "attachment/csv" })
        var url = URL.createObjectURL(blob);
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'All Class Student Export.csv';
        hiddenElement.click();
      });
  };
  $scope.exportAllStaff= function (classes) {
    var fetch = [];
    for (let i = 0; i < classes.length; i++) {
      fetch.push($scope.getStaff(classes[i]));
      // $scope.getStaff(classes[i])
      //   .then(function (res) {
      //     console.log(res);
      //     $scope.exportStaff(res.staff, res.name);
      //   });
    }

    Promise.all(fetch)
      .then((results)=>{
        let labels = ['Staff ID', 'Last Name', 'First Name', 'Email', 'Title', 'Exam Access', 'Staff Access', 'Class Access', 'Student Access', 'Class Name', 'Class ID'];
        let keys = ['localStaffId', 'LastName', 'FirstName', 'Email', 'title', 'adminExams', 'adminStaff', 'adminClasses', 'adminStudents'];
        let accessLevel = ['None', 'View', 'View/Edit'];

        let csv = '';
        for (let i = 0; i < labels.length; i++) {
          csv += labels[i] + ',';
        }
        csv += '\n';
        results.forEach((classInfo) => {
          for (let a = 0; a < classInfo.staff.length; a++) {
            for (let b = 0; b < keys.length; b++) {
              if (keys[b] === 'Email')
                csv += ((classInfo.staff[a].Email || classInfo.staff[a].invitedEmail) || '') + ',';
              else if (keys[b].startsWith('admin'))
                csv += (accessLevel[classInfo.staff[a][keys[b]]] || '') + ',';
              else
                csv += (classInfo.staff[a][keys[b]] || '') + ',';
            }
            csv += (classInfo.name || '') + ',';
            csv += (classInfo.localClassId || '') + ',';
            csv += '\n';
          }
        });

        var hiddenElement = document.createElement('a');

        var blob = new Blob([csv], { type: "attachment/csv" })
        var url = URL.createObjectURL(blob);
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'All Class Staff Export.csv';
        hiddenElement.click();
    });
  }

  var date = moment('1990-1-1')
    .month('july')
    .year(moment().year() + ((moment().month() > 5) ? 0 : -1))
    .startOf('month');
  $scope.data.classRange.start = date.valueOf();
  date.month('june')
    .year(date.year() + 1)
    .endOf('month');
  $scope.data.classRange.stop = date.valueOf();
}
