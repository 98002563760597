angular.module('examgen.directive')
.directive('qbtree', function() {
  return {
    restrict: 'E',
    scope:{
      dbs:'=dbs',
      import:'=import',
      inhouse:'=inhouse',
      personal:'=personal',
    },
    templateUrl: '/app/partials/directives/qbtree.html',
    controller: 'qbtreeCtrl',
  };
})
.directive('itemInfo',function(){
  return {
    restrict: 'E',
    scope: false,
    templateUrl: 'itemInfo',
  }
})
.controller('qbtreeCtrl',function($rootScope, $scope, $window, $element, $attrs, $http, $sce, $timeout, $sce, $compile, $templateCache, $state, uuid) {

  $scope.log = function(e){
    console.log($scope,e);
  }
  $scope.getLabel = function(db){
    if(db && db.name){
      if($scope.inhouse){
        return db.name + ' ('+db.version+')' + (db.archive == 0 ? ' *active*':'')
      } else {
        return db.name;
      }
    } else {
      return '';
    }
  }

  $scope.qbv = {
    selectedDB:'',
    selectedDBName:'',
    questionbank:undefined,
    questionbankString:'',
    questionPool:[],
    passagePool:[],
    tocPassages:[],
    selectedItem:undefined,
    selectedNode:undefined,
    selectedItems:[],
    selectedNodes:[],
    action:'',
    loading:0,
    librec: undefined,
    librecTimer: undefined,
    nodes:[
      {
        id:'chapters',
      },
      {
        id:'standards',
      },
    ],
    sr: {
      inQB:false,
      questions:true,
      passages:true,
      onlyHTML: false,
      ignoreHTML: true,
      chapter:null,
      section:null,
      topic:null,
      part:null,
    },
    passageTranslations: [],
    questionTranslations: [],
    responseTranslations: [],
  }

  $rootScope.questionIsDirty = $rootScope.questionIsDirty || function(p){ return false;}
  $rootScope.editorIsDirty = function(p){
    if( p == 'clear'){
      $scope.qbv.questionbank = ''
    }

    if($scope.qbv.questionbank && $scope.qbv.questionbank.length > 0 && !$scope.import){
      var hash = JSON.stringify($scope.qbv.questionbank,function(key, value){
        return (key == 'parent' || key.startsWith('$$') || key == 'data') ? null : value;
      });
      return !angular.equals(JSON.parse($scope.qbv.librec),JSON.parse(hash));
    } else {
      return false;
    }
  };

  $scope.backup = function(fresh) {
    if($scope.qbv.questionbank && !$scope.import){
      if($scope.qbv.librecTimer){
        $timeout.cancel($scope.qbv.librecTimer);
        $scope.qbv.librecTimer = undefined;
      }
      var newlibrec = JSON.stringify($scope.qbv.questionbank,function(key, value){
        return (key == 'parent' || key == 'nestedSubPart' || key.startsWith('$$') || key == 'data') ? null : value;
      });
      if($scope.qbv.librec && !fresh){
        if(!angular.equals(JSON.parse(newlibrec),JSON.parse($scope.qbv.librec))){
          $http.put('/api/'+$rootScope.profile.UserId+'/librec/'+'?userDB='+(!$scope.personal ? false : true),{
            fkUser:$rootScope.profile.UserId,
            fkProduct: $scope.qbv.questionbank.idProduct,
            Data: JSON.stringify($scope.qbv.questionbank,function(key, value){
              return (key == 'parent' || key == 'nestedSubPart' || key.startsWith('$$')) ? null : value;
            }),
          }).then(function(res){

          }).catch(function(err){
            $http.put('/api/log/add',{
              action:'backup',
              tags:'error',
              meta:JSON.stringify({
                profile: $rootScope.profile,
                error: JSON.stringify(err,Object.getOwnPropertyNames(err),2),
                product: $scope.qbv.questionbank.name,
              },null,2),
            });
          });
        }
      }
      $scope.qbv.librec = newlibrec;
      $scope.qbv.librecTimer = $timeout($scope.backup,1000);
    }
  }

  $scope.refresh = function(next){
    $timeout(function() {
      $scope.$apply();
      if(next){
        next();
      }
    });
  }

  $scope.cloneObj = function(src,dst,deep){
    for(k in src){
      if(typeof(src[k]) != 'object' && typeof(src[k]) != 'function'){
        dst[k] = src[k];
      } else if(Array.isArray(src[k])){
        dst[k] = [];
        $scope.cloneObj(src[k],dst[k]);
      }
    }
  }

  function onResize(){
    // var divs = document.getElementsByClassName('qtDiv');
    // for(var i = 0; i < divs.length; i++){
    //   h = window.innerHeight - ($(divs[i]).offset().top+35);
    //   divs[i].style.height = h + 'px';
    //   var header = divs[i].getElementsByClassName('qtDiv_header');
    //   var tree = divs[i].getElementsByClassName('qtDiv_tree');
    //   if(tree.length > 0 && header.length > 0){
    //     tree[0].style.height = (h - header[0].offsetHeight - 10)+'px';
    //   }
    // }

    $scope.refresh();
  };

  angular.element($window).bind('resize', onResize);

  $scope.$watch(
    function () {
      var x = document.getElementsByClassName('qtDiv_header');
      if(x.length > 0){
        return [].slice.call(x).map(function(w){return w.offsetHeight + w.offsetWidth}).join(',');
      }
      return '';
    },
    function (value) {
      onResize();
    }
  );

  onResize();

  var haltedStateRedirect;

  $scope.$on('$destroy', $rootScope.$on('$stateChangeStart', function(event, state, params) {
    if ($rootScope.questionIsDirty() || $rootScope.editorIsDirty()){
      haltedStateRedirect = {state: state, params: params};
      event.preventDefault();
      $('#exitSavePrompt').modal('show');
    }
  }));

  function redirect() {
    console.log('redirecting to', haltedStateRedirect);
    if (haltedStateRedirect) {
      $state.go(haltedStateRedirect.state.name, haltedStateRedirect.params);
    }
  }

  $scope.disregardChanges = function(closeOnly) {
    $('#exitSavePrompt').modal('hide');
    if (!closeOnly) {
      alertify.success('Changes have been discarded');
      $rootScope.exitSavePrompt = false;
      $rootScope.editorIsDirty('clear');
      $rootScope.questionIsDirty('clear');
      redirect();
    }
  };

  $scope.saveChanges = function() {
    $scope.updateDatabase(function(good){
      $('#exitSavePrompt').modal('hide');
      if(good){
        redirect();
      }
    })
  };

  $scope.confirmSelectDB = function(selectedDB){
    if (($rootScope.questionIsDirty() ||
    ($rootScope.editorIsDirty() && selectedDB != $scope.qbv.questionbank.idProduct))
    && !$scope.uneditable()) {
      bootbox.confirm("You have unsaved changes, do you want to continue?",function(ok){
        if(ok){
          $scope.qbv.selectedItem = {};
          $scope.CheckBackup(selectedDB)
        } else {
          $scope.qbv.selectedDB = $scope.qbv.questionbank.idProduct;
          $scope.refresh();
        }
      });
    } else {
      $scope.CheckBackup(selectedDB)
    }
  }

  $scope.CheckBackup = function(selectedDB){
    $scope.qbv.action = 'Loading';
    $scope.qbv.loading++;
    $http.get('/api/'+$rootScope.profile.UserId+'/librec/'+selectedDB+'?userDB='+ (!$scope.personal ? false : true)).then(function(res){
      if(res.data.length > 0){
        bootbox.confirm("You have a recovery version of this Library, do you want to load it?",'No','Yes',function(ok){
          if(ok){
            var tmp = $scope.dbs.findIndex(function(db){
              return selectedDB === db.idProduct;
            });
            if(tmp >= 0){
              var data = JSON.parse(res.data[0].Data);
              $scope.expandFormat(data);
              data = $scope.modLibrary(data,false,true);
              $scope.collapseFormat(data);

              $scope.dbs[tmp] = data;

              $scope.backup(true);
              $scope.loadDB(selectedDB);
              $scope.qbv.loading--;
              $scope.refresh();
            }
          } else {
            $http['delete']('/api/'+$rootScope.profile.UserId+'/librec/'+selectedDB+'?userDB='+(!$scope.personal ? false : true));
            $scope.loadDB(selectedDB);
            $scope.qbv.loading--;
          }
        });
      } else {
        $scope.loadDB(selectedDB);
        $scope.qbv.loading--;
      }
    });
  };

  $scope.loadDB = function(selectedDB){

    $scope.qbv.passagePool = [];
    $scope.qbv.tocPassages = [];
    $scope.qbv.questionPool = [];
    $scope.qbv.selectedDBName = '';
    $scope.qbv.selectedItem=undefined;
    $scope.qbv.selectedNode=undefined;
    $scope.qbv.selectedItems=[];
    $scope.qbv.selectedNodes=[];
    $scope.qbv.questionbank=undefined;

    if(!$scope.uneditable()){
      $rootScope.$broadcast("editorQuestionSelected", undefined);
    } else {
      $rootScope.$broadcast("previewQuestionSelected", undefined);
    }

    var tmp = $scope.dbs.find(function(db){
      return selectedDB === db.idProduct;
    });

    if (tmp){
      if($scope.inhouse){
        // $http.put('/api/log/add',{
        //   action:'loadQB',
        //   tags:'editor,inhouse',
        //   meta:JSON.stringify({
        //     profile: $rootScope.profile,
        //     product: tmp.name,
        //   },null,2),
        // });
      }

      $scope.qbv.action = 'Loading';

      $scope.qbv.loading++;

      $scope.qbv.questionbank = tmp;

      $scope.qbv.selectedDBName = $scope.qbv.questionbank.name;

      if(!$scope.uneditable()){
        $rootScope.product = $scope.qbv.questionbank.name;
      }

      if(!$scope.qbv.questionbank.new && $scope.qbv.questionbank.items.length == 0){
        $scope.qbv.questionbank.items = [];
        $scope.qbv.questionbank.standards = [];

        $scope.qbv.loading++;
        $http.get('/api/standardlist/'+selectedDB+'?userDB='+($scope.personal ? true : false)).then(function(res) {
          $scope.qbv.loading--;
          var standardIDs = [];
          $scope.qbv.questionbank.standards = res.data.map(function(item){
            standardIDs.push(item.idStandard);
            return {
              id: item.idStandard,
              name: item.name,
              description: item.description,
              fk: item.fkProduct,
              sortOrder: item.sortOrder,
              fkStandardDefinition: item.fkStandardDefinition,
              items: [],
              ids: [],
              isStandard: true,
            }
          });

          if(standardIDs.length > 0){
            $scope.qbv.loading++;
            $http.put('/api/standard/list?json=true&userDB='+($scope.personal ? true : false),standardIDs).then(function(res){
              $scope.qbv.loading--;
              for(var i = 0;i < res.data.items.length; i++){
                var standard = $scope.qbv.questionbank.standards.find(function(s){ return s.id == res.data.items[i].fkStandard});
                if(standard){
                  standard.ids.push(res.data.items[i].fkQuestion);
                }
              }
            });
          }
        });

        var topicList = [];
        var partsList = [];
        var subpartsList = [];
        var questionLoad = [];
        var passageLoad = [];

        $scope.qbv.loading += 5;

        $http.get('/api/chapters/'+selectedDB+'?userDB='+($scope.personal ? true : false)).then(function(res) {
          $scope.qbv.loading--;
          var chapterIDs = [];
          $scope.qbv.questionbank.items = res.data.map(function(item){
            chapterIDs.push(item.idChapter);
            return {
              name: item.name,
              id: item.idChapter,
              fk: item.fkProduct,
              meta: item.meta,
              items: [],
              sortOrder: item.sortOrder,
              created: item.created,
              modified: item.modified,
              parent: $scope.qbv.questionbank,
            }
          });


          if(chapterIDs.length){
            return $http.put('/api/topics/list'+'?userDB='+($scope.personal ? true : false),chapterIDs);
          } else {
            return {data:[]};
          }
        })
        .then(function(topics){
          $scope.qbv.loading--;

          var topicIDs = [];

          for(var t = 0; t < topics.data.length; t++){
            topicIDs.push(topics.data[t].idTopic);
            var item = {
              name: topics.data[t].name,
              id: topics.data[t].idTopic,
              fk: topics.data[t].fkChapters,
              meta: topics.data[t].meta,
              items: [],
              sortOrder: topics.data[t].sortOrder,
              parent: null,
              created: topics.data[t].created,
              modified: topics.data[t].modified,
            };
            topicList.push(item);
            var chapter = $scope.qbv.questionbank.items.find(function(c){return c.id == item.fk});
            if(chapter){
              item.parent = chapter;
              chapter.items.push(item);
            }
          }

          if(topicIDs.length > 0){
            return $http.put('/api/sections/list'+'?userDB='+($scope.personal ? true : false),topicIDs);
          } else {
            return {data:[]};
          }
        })
        .then(function(parts){
          $scope.qbv.loading--;

          var partIDs = [];

          for(var p = 0; p < parts.data.length; p++){
            partIDs.push(parts.data[p].idPart);
            var item = {
              name: parts.data[p].name,
              id: parts.data[p].idPart,
              fk: parts.data[p].fkTopic,
              meta: parts.data[p].meta,
              items: [],
              sortOrder: parts.data[p].sortOrder,
              parent: null,
              created: parts.data[p].created,
              modified: parts.data[p].modified,
            };
            partsList.push(item);
            var topic = topicList.find(function(c){return c.id == item.fk});
            if(topic){
              item.parent = topic;
              topic.items.push(item);
            }
          }

          if(partIDs.length > 0){
            return $http.put('/api/subsections/list'+'?userDB='+($scope.personal ? true : false),partIDs);
          } else {
            return {data:[]};
          }
        })
        .then(function(subparts){
          $scope.qbv.loading--;

          var subpartIDs = [];

          for(var p = 0; p < subparts.data.length; p++){
            var item = {
              name: subparts.data[p].name,
              id: subparts.data[p].idSubPart,
              fk: subparts.data[p].fkPart,
              fkSubPart: subparts.data[p].fkSubPart,
              meta: subparts.data[p].meta,
              items: [],
              sortOrder: subparts.data[p].sortOrder,
              parent: null,
              created: subparts.data[p].created,
              modified: subparts.data[p].modified,
            };
            subpartIDs.push(item.id);
            subpartsList.push(item);
            var parent = partsList.find(function(c){return c.id == item.fk});
            if(parent){
              item.parent = parent;
              parent.items.push(item);
            }
          }

          if(subpartIDs.length > 0){
            return $http.put('/api/items/list'+'?userDB='+($scope.personal ? true : false),{subpartIds:subpartIDs});
          } else {
            return {data:[]};
          }
        })
        .then(function(questions){
          $scope.qbv.loading--;
          for(var t = 0; t < questions.data.length; t++){
            var item = questions.data[t];
            var parent = subpartsList.find(function(c){return c.id == item.fkSubPart});
            if(parent){
              parent.items.push(item);
            }
          }

          for(var sp = 0; sp < subpartsList.length; sp++){
            var subpart = subpartsList[sp];
            for(var q = 0; q < subpart.items.length; q++){
              subpart.items[q].parent = subpart;

              var index;

              if(!subpart.items[q].isPassage){
                subpart.items[q].isQuestion = true;

                var index = $scope.qbv.questionPool.findIndex(function(p){
                  return p.idQuestion == subpart.items[q].fkQuestion
                });

                if(index < 0){
                  var newQuestion = {
                    idQuestion : subpart.items[q].fkQuestion,
                    loaded : false,
                  };
                  $scope.qbv.questionPool.push(newQuestion);
                  subpart.items[q].data = newQuestion;
                  //questionLoad.push(subpart.items[q].fkQuestion);
                } else {
                  subpart.items[q].data = $scope.qbv.questionPool[index];
                }

                if(!subpart.items[q].origin){
                  subpart.items[q].origin = $scope.qbv.questionbank.shortCode +' '+
                  subpart.items[q].typeId + ' ' +
                  (subpart.parent.parent.parent.sortOrder+1) + '-' + (subpart.items[q].name);
                }

                $scope.qbv.questionbank.standards.forEach(function(standard){
                  if(standard.ids){
                    if(standard.ids.findIndex(function(id){
                      return subpart.items[q].idSubPartQuestions == id;
                    }) >= 0){
                      standard.items.push(subpart.items[q]);
                    }
                  }
                });

                if(subpart.items[q].fkPassage){
                  var passageIndex = subpart.items.findIndex(function(p){
                    return p.id == subpart.items[q].fkProductPassage && p.isPassage;
                  });

                  if(passageIndex >= 0){
                    subpart.items[passageIndex].items.push(subpart.items[q]);
                    subpart.items[q].parent = subpart.items[passageIndex];
                    subpart.items.splice(q,1);
                    q--;
                  } else {
                    var passage = {
                      id: subpart.items[q].fkProductPassage,
                      poolId: subpart.items[q].fkPassage,
                      name: subpart.items[q].passagename,
                      created: subpart.items[q].created,
                      modified: subpart.items[q].modified,
                      isPassage:true,
                      items: [subpart.items[q]],
                    };
                    subpart.items[q].parent = passage;
                    subpart.items.splice(q,1,passage);
                    if(!$scope.uneditable())$scope.qbv.tocPassages.push(passage);
                    q--;
                  }

                }
              } else if(subpart.items[q].isPassage){
                index = $scope.qbv.passagePool.findIndex(function(p){
                  return p.idPassage == subpart.items[q].poolId;
                });

                if(index < 0){
                  var newPassage = {
                    idPassage : subpart.items[q].poolId,
                    loaded : false,
                  };
                  $scope.qbv.passagePool.push(newPassage);
                  subpart.items[q].data = newPassage;
                  //passageLoad.push(subpart.items[q].poolId);
                } else {
                  subpart.items[q].data = $scope.qbv.passagePool[index];
                }
              }
            }
          }

          if(questionLoad.length > 0){
            return $http.put('/api/question/list?json=true'+'&userDB='+($scope.personal ? true : false),{questionIds:questionLoad})
          } else {
            return {data:[]};
          }
        })
        .then(function(questions){

          questions.data.forEach(function(q){
            var scrub = function(text){
              var tmp = text;

              var bits = tmp.split(/<\/?math>/g);
              var res = '';
              var inmath = false;
              for(var i =0; i < bits.length; i++){
              	if(inmath){
                    res += bits[i].replace(/<\/?span[^>]*>/g,'');
                    res += '</math>'
                    inmath = false;
                  }else{
                    res += bits[i];
                    if(i < bits.length - 1){
                      res += '<math>'
                    }
                    inmath = true;
                  }
              }

              return res;
            }

            q.prompt = scrub(q.prompt);
            for(var i = 0; i < q.responses.length; i++){
              q.responses[i].answer = scrub(q.responses[i].answer);
            }

            var ques = $scope.qbv.questionPool.find(function(item){
              return item.idQuestion == q.itemCode;
            });

            if(ques){
              ques.loaded = true;
              angular.extend(ques,q);
            }
          });

          if(passageLoad.length > 0){
            return $http.put('/api/passage/list?json=true'+'&userDB='+($scope.personal ? true : false),{passageIds:passageLoad});
          } else {
            return {data:[]};
          }
        })
        .then(function(passages){

          passages.data.forEach(function(p){
            var pass = $scope.qbv.passagePool.find(function(item){
              return item.idPassage == p.idPassage;
            });

            if(pass){
              angular.extend(pass,p);
            }
          });
        })
        .catch(function(err){
          $scope.qbv.loading = 0;
          $http.put('/api/log/add',{
            action:'backup',
            tags:'error',
            meta:JSON.stringify({
              profile: $rootScope.profile,
              error: JSON.stringify(err,Object.getOwnPropertyNames(err),2),
              product: $scope.qbv.questionbank.name,
            },null,2),
          });
          alertify.error('An error occured while loading the Library')
        });
      }

      $scope.qbv.loading--;
    }
  }

  $scope.countItems = function(questionbank){
    function addCount(container, item){
      container.questionCounter[item.typeId]++;
      container.questionCounter.total++;
      if (item.name < container.questionCounter.start) container.questionCounter.start = item.name;
      if (item.name > container.questionCounter.stop) container.questionCounter.stop = item.name;
    }
    function mergeCount(parent, child){
      parent.questionCounter.TF += child.questionCounter.TF;
      parent.questionCounter.MC += child.questionCounter.MC;
      parent.questionCounter.ER += child.questionCounter.ER;
      parent.questionCounter.SR += child.questionCounter.SR;
      parent.questionCounter.total += child.questionCounter.total;
      parent.questionCounter.CB += child.questionCounter.CB;
      if (child.questionCounter.start < parent.questionCounter.start) parent.questionCounter.start = child.questionCounter.start;
      if (child.questionCounter.stop > parent.questionCounter.stop) parent.questionCounter.stop = child.questionCounter.stop;
    }
    questionbank.questionCounter = {
      TF:0,
      MC:0,
      ER:0,
      SR:0,
      total:0,
      CB:0,
    }
    for(var c = 0; c < questionbank.items.length; c++){
      var chapter = questionbank.items[c];
      chapter.questionCounter = {
        TF:0,
        MC:0,
        ER:0,
        SR: 0,
        total: 0,
        CB: 0,
      }
      for (var t = 0; t < chapter.items.length; t++){
        var topic = chapter.items[t];
        topic.questionCounter = {
          TF:0,
          MC:0,
          ER:0,
          SR: 0,
          total: 0,
          CB: 0,
          start:99999,
          stop:-1,
        }
        for(var p = 0; p < topic.items.length; p++){
          var part = topic.items[p];
          part.questionCounter = {
            TF:0,
            MC:0,
            ER:0,
            SR: 0,
            total: 0,
            CB: 0,
            start:99999999,
            stop:-1,
          }
          for(var s = 0; s < part.items.length; s++){
            var section = part.items[s];
            section.questionCounter = {
              TF:0,
              MC:0,
              ER:0,
              SR: 0,
              total: 0,
              CB: 0,
              start:999999,
              stop:-1,
            }
            
            for(var i = 0; i < section.items.length; i++){
              if (section.items[i].items){
                var passage = section.items[i];
                section.questionCounter.CB++;

                for (var q = 0; q < passage.items.length; q++){
                  addCount(section, passage.items[q]);
                }
              } else {
                addCount(section, section.items[i]);
              }
            } 
            mergeCount(part, section);
          }
          mergeCount(topic, part);
        }
        mergeCount(chapter, topic);
      }
      mergeCount(questionbank, chapter);
    }
  }

  $scope.loadSingleItem = function(item){
    function scrubText(text){
      var tmp = text;

      var bits = tmp.split(/<\/?math>/g);
      var res = '';
      var inmath = false;
      for(var i =0; i < bits.length; i++){
        if(inmath){
            res += bits[i].replace(/<\/?span[^>]*>/g,'');
            res += '</math>'
            inmath = false;
          }else{
            res += bits[i];
            if(i < bits.length - 1){
              res += '<math>'
            }
            inmath = true;
          }
      }

      return res;
    }
    function loadQuestion(){
      if(item.data.loaded){
        return Promise.resolve(item);
      } else {
        return $http.put('/api/question/list?json=true'+'&userDB='+($scope.personal ? true : false),
          {questionIds:[item.data.idQuestion]})
        .then(function(questions){
          var q = questions.data[0]
          q.prompt = scrubText(q.prompt);
          for(var i = 0; i < q.responses.length; i++){
            q.responses[i].answer = scrubText(q.responses[i].answer);
          }
          item.data.loaded = true;
          angular.extend(item.data,q);
          return Promise.resolve(item);
        });
      }
    }
    if((item.isQuestion)){
      if(item.parent.isPassage){
        if(item.parent.data.loaded){
          return loadQuestion();
        } else {
          return $http.put('/api/passage/list?json=true'+'&userDB='+($scope.personal ? true : false),
          {passageIds:[item.parent.data.idPassage]})
          .then(function(passages){
            item.parent.data.loaded = true;
            angular.extend(item.parent.data,passages.data[0]);
            return loadQuestion();
          });
        }
      } else {
        return loadQuestion();
      }
    } else if (item.isPassage){
      if(item.data.loaded){
        return Promise.resolve(item);
      } else {
        return $http.put('/api/passage/list?json=true'+'&userDB='+($scope.personal ? true : false),
        {passageIds:[item.data.idPassage]}).then(function(passages){
          item.data.loaded = true;
          angular.extend(item.data,passages.data[0]);
          return Promise.resolve(item);
        });
      }
    } else {
      return Promise.resolve(item);
    }
  }


  $scope.getRootQB = function(item){
    var source = item;
    while (source.parent) {
      source = source.parent
    }
    return source;
  }

  $scope.accept = function(sourceNode, destNodes, destIndex) {
    var returnflag = false;
    var nodrop = false;
    var isQuestion = false;
    var isStandard = false;
    var isStandardQuestion = false;
    var sameDB = false;

    if(destNodes.$treeScope){
      nodrop = destNodes.$treeScope.nodropEnabled;
      sameDB = destNodes.$treeScope.$element.attr('data-selected-db') == sourceNode.$treeScope.$element.attr('data-selected-db')
    } else if(destNodes.nodesScope) {
      nodrop = destNodes.nodesScope.$treeScope.nodropEnabled;
      sameDB = destNodes.nodesScope.$treeScope.$element.attr('data-selected-db') == sourceNode.$treeScope.$element.attr('data-selected-db')
    }

    if(sourceNode.$modelValue){
      isQuestion = sourceNode.$modelValue.isQuestion;
    } else if(sourceNode.nodeScope){
      isQuestion = sourceNode.nodeScope.$modelValue.isQuestion;
    }

    if(sourceNode.$modelValue){
      isStandard = sourceNode.$modelValue.isStandard;
    }
    if(sourceNode.$parentNodeScope){
      isStandardQuestion = sourceNode.$parentNodeScope.$modelValue.isStandard;
    }

    if(!nodrop){
      if(destNodes.$nodeScope){
        if(!destNodes.$nodeScope.collapsed){
          if(destNodes.$nodeScope.$modelValue.isStandard && sameDB){
            returnflag = true;
          } else if (destNodes.$element.attr('standard') !='true'){
            returnflag = true;
          }
        }
      }
      else if (destNodes.nodesScope){
        if (destNodes.nodesScope.$nodeScope){
          if(!destNodes.nodesScope.$nodeScope.collapsed){
            if(destNodes.nodesScope.$nodeScope.$modelValue.isStandard && sameDB){
              returnflag = true;
            } else if (destNodes.nodesScope.$element.attr('standard') !='true'){
              returnflag = true;
            }
          }
        }
      }
      else if (!destNodes.nodesScope && !destNodes.$nodeScope && destNodes.$type == "uiTreeNodes"){
        if(destNodes.$modelValue.isStandard && sameDB){
          returnflag = true;
        } else if (destNodes.$element.attr('standard')!='true'){
          returnflag = true;
        } else if(destNodes.$element.attr('standard')=='true' && sameDB){
          returnflag = true;
        }
        // else if (sourceNode.$modelValue.isStandard && destNodes.$element.attr('standard') =='true'){
        //   returnflag = true;
        // }
      }
      else if (isQuestion){
        if(destNodes.$element.attr('standard')=='true' && sourceNode.$modelValue.isStandard){
          returnflag = true;
        } else if (destNodes.$element.attr('standard') !='true'){
          returnflag = true;
        }
      }

      if(isStandard || isStandardQuestion){
        if(destNodes.$element.attr('standard')!='true'){
          returnflag = false
        }
      }
    }
    else {
      returnflag = false;
    }
    return returnflag;
  }

  $scope.treeOptions = {
    dragStart: function(e){
      $scope.qbv.selectedNode = e.source.nodeScope;
      $scope.qbv.selectedItem = e.source.nodeScope.$modelValue;
    },
    accept: $scope.accept,
    beforeDrop: function(e){
      var returnFlag = false;

      if(e.dest.nodesScope.$nodeScope){
        if(!(e.dest.nodesScope.$nodeScope.$modelValue.isPassage && e.source.nodeScope.$modelValue.isPassage)){
          returnFlag = true;
        } else {
          returnFlag = false;
        }
      } else {
        returnFlag = true;
      }

      if(returnFlag){
        if(e.dest.nodesScope != e.source.nodesScope){
          var flag = !$scope.isIn(e.dest.nodesScope.$modelValue,e.source.nodeScope.$modelValue);

          if (flag){
            var clone = e.source.cloneModel != undefined;
            var src = e.source.cloneModel || e.source.nodeScope.$modelValue;
            var dest = e.dest.nodesScope;
            // if (e.dest.nodesScope.$element.attr('standard')=='true'){
            //   var data = $scope.qbv.selectedItem;
            //   if()
            //   e.dest.nodesScope.$modelValue.splice(e.dest.index,0,data);
            //   returnFlag = false;
            // } else {
            //   returnFlag = true;
            // }
            if (e.dest.nodesScope.$element.attr('standard')=='true'){
              var tmpIndex = e.dest.index;

              var loop = function(item){
                if(!item.isQuestion){
                  for(var i = 0; i < item.items.length;i++){
                    loop(item.items[i]);
                  }
                } else {
                  if(!$scope.isIn(e.dest.nodesScope.$modelValue,item)){
                    e.dest.nodesScope.$modelValue.splice(tmpIndex,0,item);
                    tmpIndex++;
                  }
                }
                return true;
              }
              loop(src);

              returnFlag = false;
            } else {
              returnFlag = true;
            }
          } else {
            alertify.error('Duplicate Item');
            returnFlag = false;
          }
        } else if(!e.dest.nodesScope.$treeScope.nodropEnabled){
          returnFlag = true;
        } else {
          returnFlag = false;
        }
      }

      if(returnFlag){
        var clone = e.source.cloneModel != undefined;
        var src = e.source.cloneModel || e.source.nodeScope.$modelValue;
        var dest = e.dest.nodesScope;
        if(dest.$nodeScope){
          dest = dest.$nodeScope.$modelValue;
        } else {
          dest = $scope.qbv.questionbank;
        }


        if($scope.qbv.selectedNodes.length > 0){
          var tmpIndex = e.dest.index;
          for(var i = 0; i < $scope.qbv.selectedNodes.length; i++){
            var item;

            if(clone){
              item = angular.copy($scope.qbv.selectedItems[i]);
            } else {
              item = $scope.qbv.selectedItems[i];
            }

            $scope.loadSingleItem(item).then(function(resolved){
              if($scope.import && !$scope.inhouse){
                resolved.data._UPDATE = true;
                var newid = uuid.v4();
                if(resolved.isQuestion){
                  resolved.fkQuestion = newid;
                  resolved.idQuestion = newid;
                  resolved.data.idQuestion = newid;
                  resolved.data.itemCode = newid;

                  if(resolved.parent.isPassage){
                    resolved.fkPassage = resolved.parent.fkPassage;
                    resolved.data.passageId = resolved.fkPassage;
                  }
                  if(resolved.data.responses){
                    for(var i = 0; i < resolved.data.responses.length; i++){
                      resolved.data.responses[i].itemCode = newid;
                      resolved.data.responses[i].idResponse = uuid.v4();
                    }
                  }

                } else if(resolved.isPassage){
                  resolved.fkPassage = newid;
                  resolved.poolId = newid;
                  resolved.data.idPassage = newid;
                }
              }
            });

            if($scope.accept($scope.qbv.selectedNodes[i], e.dest, null)){
              if(!(dest.isPassage && item.isPassage)){
                if(!($scope.isIn(dest.items,item) && item.parent != dest)){
                  if(dest.isStandard){
                    dest.items.splice(tmpIndex,0,item);
                    tmpIndex++;
                  } else {
                    $scope.updatePassageRefOnDrop(item,dest,function(ok){
                      if(ok){
                        item.parent = dest;
                        if(!clone){
                          var index = $scope.qbv.selectedNodes[i].index();
                          $scope.qbv.selectedNodes[i].$parentNodesScope.$modelValue.splice(index,1);
                        }
                        dest.items.splice(tmpIndex,0,item);
                        tmpIndex++;
                      }
                    });
                  }
                } else {
                  alertify.error('Duplicate Item');
                }
              }
            }
          }

          if($scope.isIn($scope.qbv.selectedItems,e.source.nodeScope.$modelValue)){
            returnFlag = false;
          }

          $scope.qbv.selectedNodes = [];
          $scope.qbv.selectedItems = [];
        } else {
          $scope.updatePassageRefOnDrop(src,dest,function(ok){
            if(ok){
              src.parent = dest;
            } else {
              dest.items.splice(e.dest.index,1);
              src.parent.items.splice(e.source.index,0,src);
              returnFlag = false;
            }
          });
        }
        $scope.qbv.selectedNode = undefined;
        $scope.qbv.selectedItem = undefined;
      }

      $timeout(()=>{
        $scope.cleanIndexs($scope.getRootQB(e.dest.nodesScope.$modelValue[0]));
      },10);
      

      return returnFlag;
    },
    dropped: function(e){
      if(!$scope.inhouse && e.dest.nodesScope.$treeScope.$id != e.source.nodesScope.$treeScope.$id){
        var src = e.source.cloneModel;
        function rec(item){
          if(item.isQuestion || item.isPassage){
            item.data._UPDATE = true;
            var newid = uuid.v4();
            if(item.isQuestion){
              item.fkQuestion = newid;
              item.idQuestion = newid;
              item.data.idQuestion = newid;
              item.data.itemCode = newid;

              if(item.parent.isPassage){
                item.fkPassage = item.parent.fkPassage;
                item.data.passageId = item.fkPassage;
              }
              if(item.data.responses){
                for(var i = 0; i < item.data.responses.length; i++){
                  item.data.responses[i].itemCode = newid;
                  item.data.responses[i].idResponse = uuid.v4();
                }
              }

            } else if(item.isPassage){
              item.fkPassage = newid;
              item.poolId = newid;
              item.data.idPassage = newid;
            }
          }
          if(item.items){
            for(var i = 0; i < item.items.length; i++){
              rec(item.items[i]);
            }
          }
        }
        rec(src);
      }
      $scope.cleanIndexs($scope.getRootQB(e.dest.nodesScope.$modelValue[0]));
    },
    removed: function(e){
      $scope.cleanIndexs();
    }
  }

  $scope.updatePassageRefOnDrop = function(src,dest,next){
    var saveNewQuestion =  function(data){
      data.itemCode = uuid.v4();
      data.created = Date.now()/1000;
      data._UPDATE = true;

      for(var i = 0; i < data.responses.length; i++){
        data.responses[i] = angular.copy(data.responses[i]);

        data.responses[i].itemCode = data.itemCode;
        data.responses[i].idResponse = uuid.v4();
        data.responses[i].created = Date.now()/1000;
      }

      src.idQuestion = data.itemCode;
      src.fkQuestion = data.itemCode;
      src.data.idQuestion = data.itemCode;

      src.data = data;
    }

    if(dest.isPassage && src.isQuestion && src.fkProductPassage != dest.id){
      var str = 'This passage does not match the questions original passage:<br><br>' + src.origin;
      bootbox.dialog(str, [{
        label : 'Update Question',
        class : 'btn',
        callback: function() {
          src.fkProductPassage = dest.id;
          src.fkPassage = dest.data.idPassage;
          src.data.passageId = dest.data.idPassage;
          src.data._UPDATE = true;
          next(true);
        }
      }, {
        label : 'Save as New Question',
        class : 'btn',
        callback: function() {
          src.fkProductPassage = dest.id;
          src.fkPassage = dest.data.idPassage;

          var data = angular.copy(src.data);
          data.passageId = dest.data.idPassage;

          saveNewQuestion(data);

          next(true);
        }
      },{
        label : 'Cancel',
        class : 'btn',
        callback: function() {
          next(false);
        }
      }]);

      // src.fkProductPassage = dest.id;
      // src.fkPassage = dest.data.idPassage;
      // src.data.passageId = dest.data.idPassage;
      //
      // $http.put('/api/question', src.data).then(function(res){
      //   alertify.success('Question Added to Passage');
      // });
    } else if(!dest.isPassage && src.isQuestion && src.fkProductPassage){
      var str = 'This question was originally assigned to a passage:<br><br>' + src.origin;
      bootbox.dialog(str, [{
        label : 'Update Question',
        class : 'btn',
        callback: function() {
          delete src.fkProductPassage;
          delete src.fkPassage;
          delete src.data.passageId;
          src.data._UPDATE = true;
          next(true);
        }
      }, {
        label : 'Save as New Question',
        class : 'btn',
        callback: function() {
          delete src.fkProductPassage;
          delete src.fkPassage;

          var data = angular.copy(src.data);
          delete data.passageId;

          saveNewQuestion(data);

          next(true);
        }
      },{
        label : 'Cancel',
        class : 'btn',
        callback: function() {
          next(false);
        }
      }]);

      // delete src.fkProductPassage;
      // delete src.fkPassage;
      // delete src.data.passageId;
      // $http.put('/api/question', src.data).then(function(res){
      //   alertify.success('Question Removed from Passage');
      // });
    } else {
      next(true);
    }
  }


  $scope.toggle = function (scope) {
    scope.toggle();
  };

  $scope.selectItem = function(e,item){
    var f = function(){
      var old = $scope.qbv.selectedItem;
      if(item.$nodeScope){
        if(item.$nodeScope.$modelValue.isQuestion || item.$nodeScope.$modelValue.isPassage){
          if(!(e.shiftKey || e.ctrlKey || e.altKey) &&
          $scope.qbv.selectedNode != item.$nodeScope &&
          !$scope.isIn($scope.qbv.selectedItems,item.$nodeScope.$modelValue)){
            $scope.qbv.selectedItems = [];
            $scope.qbv.selectedNodes = [];
          } else {
            if (e.ctrlKey || e.altKey) {
              $scope.addRemove($scope.qbv.selectedNodes,item.$nodeScope);
              $scope.addRemove($scope.qbv.selectedItems,item.$nodeScope.$modelValue);
            } else if (e.shiftKey) {
              if($scope.qbv.selectedItem.parent == item.$nodeScope.$modelValue.parent){
                var a = $scope.qbv.selectedNode.index();
                var b = item.$nodeScope.index();
                var data = item.$nodeScope.$parentNodesScope.childNodes();
                if(a > b){
                  for(var i = a; i >= b ; i--){
                    $scope.pushNew($scope.qbv.selectedNodes,data[i]);
                    $scope.pushNew($scope.qbv.selectedItems,data[i].$modelValue);
                  }
                } else {
                  for(var i = a; i <= b ; i++){
                    $scope.pushNew($scope.qbv.selectedNodes,data[i]);
                    $scope.pushNew($scope.qbv.selectedItems,data[i].$modelValue);
                  }
                }
              } else {
                  $scope.pushNew($scope.qbv.selectedNodes,item.$nodeScope);
                  $scope.pushNew($scope.qbv.selectedItems,item.$nodeScope.$modelValue);
              }
            }
          }
        } else {
          $scope.qbv.selectedItems = [];
          $scope.qbv.selectedNodes = [];
        }
        $scope.qbv.selectedNode = item.$nodeScope;
        $scope.qbv.selectedItem = item.$nodeScope.$modelValue;
      } else {
        $scope.qbv.selectedItem = item;
      }
      if(old === $scope.qbv.selectedItem)$scope.selectedItemChanged($scope.qbv.selectedItem);
    }

    if ($rootScope.questionIsDirty() && item.$nodeScope.$modelValue != $scope.qbv.selectedItem){
      bootbox.confirm("You have unsaved changes, do you want to continue?",function(ok){
        if(ok){
          f();
        }
      });
    } else {
      f();
    }
  }


  $scope.selectedStyle = function(item){
    var objstyle = {};
    var flag = false;

    if($scope.qbv.selectedItem){
      if (item.isQuestion){
        if($scope.qbv.selectedItem.idSubPartQuestions == item.idSubPartQuestions){
          flag = true;
        }
      } else if($scope.qbv.selectedItem.id == item.id){
        flag = true;
      }
    }

    objstyle.border = flag ? 'ridge 4px #0088cc': 'none 0 #000000';
    objstyle.padding = flag ? '0px 2px':'4px 6px';

    flag = false;

    for(var i = 0; i < $scope.qbv.selectedItems.length && !flag; i++ ){
      if (item.isQuestion){
        if($scope.qbv.selectedItems[i].idSubPartQuestions == item.idSubPartQuestions){
          flag = true;
        }
      } else if($scope.qbv.selectedItems[i].id == item.id){
        flag = true;
      }
    }

    // if(item.isPassage){
    //   objstyle.width = '-webkit-calc(100% - 125px)'
    // }
    //
    // if(item.isStandard){
    //   objstyle.width = '-webkit-calc(100% - 80px)'
    // }

    objstyle['background-color'] = flag ? '#d0f5fd' : 'transparent';

    return objstyle;
  }

  $scope.getQuestionCount = function(item){

    var str = '';
    if(item.questionCounter.start && item.questionCounter.stop){
      str += '<strong>Question Range:</strong>'+item.questionCounter.start+' - '+item.questionCounter.stop+'<br><br>';
    }
    str += '<strong>Question Count: </strong>'+(item.questionCounter.TF+item.questionCounter.MC+item.questionCounter.ER+item.questionCounter.SR)+'<br>';
    str += '&nbsp;&nbsp;<strong>MC: </strong>'+item.questionCounter.MC+'<br>';
    str += '&nbsp;&nbsp;<strong>TF: </strong>'+item.questionCounter.TF+'<br>';
    str += '&nbsp;&nbsp;<strong>ER: </strong>'+item.questionCounter.ER+'<br>';
    str += '&nbsp;&nbsp;<strong>SR: </strong>'+item.questionCounter.SR+'<br>';

    bootbox.alert(str);
  };

  $scope.getQuestionUsageData = function(item){
    var source = item;
    var chapter;
    while (source.parent) {
      chapter = source;
      source = source.parent
    }

    return $http.get('/api/search/usage/question/' + item.idQuestion + '?userDB=' + ($scope.personal ? true : false))
      .then(function (res) {
      var products = [];
      var results = [];
      if(source && source.version){
        products.push({
          name: '<b>' +source.name + '</b>(' + source.version + ')',
          chapters: ['Chapter ' + (1 + chapter.sortOrder) + ' #' + item.name],
          standards: [],
          passages: [],
        });
      }

      res.data.forEach(function (item) {
        var pname = '<b>'+item.productName + '</b>(' + item.version + ')';
        var cname = 'Chapter ' + (1 + item.chapterNumber) + ' #' + item.name;
        var ptmp = {
          name: pname,
          chapters: [],
          standards: [],
          passages: [],
        };
        var product = products.find(function (p) { return p.name == pname });
        if (!product) {
          products.push(ptmp);
          product = ptmp;
        }

        if (product.chapters.indexOf(cname) < 0) {
          product.chapters.push(cname);
        }
        if (product.passages.indexOf(item.passageName) < 0 && item.passageName != null) {
          product.passages.push(item.passageName);
        }
        if (product.standards.indexOf(item.standardName) < 0 && item.standardName != null) {
          product.standards.push(item.standardName);
        }
      });
      products.forEach(function (item) {
        var str = '';

        str = item.name;
        if (item.chapters.length > 0) {
          str += '<br>&nbsp;&nbsp;&nbsp;&nbsp;';
          str += item.chapters.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
        }
        if (item.passages.length > 0) {
          str += '<br>&nbsp;&nbsp;Passages:<br>&nbsp;&nbsp;&nbsp;&nbsp;';
          str += item.passages.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
        }
        if (item.standards.length > 0) {
          str += '<br>&nbsp;&nbsp;Standards:<br>&nbsp;&nbsp;&nbsp;&nbsp;';
          str += item.standards.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
        }

        results.push(str);
      })
      return results;
    });
  }
  $scope.getQuestionUsage = function(item){
    $scope.getQuestionUsageData(item).then(function (results){
      bootbox.alert('<strong>Question Usage:</strong><br><br>' + results.join('<br><br>'));
    });
  };

  $scope.getPassageUsageData = function(item){
    return $http.get('/api/search/usage/passage/'+item.poolId+'?userDB='+($scope.personal ? true : false)).then(function(res) {
      var products = [];
      var results = [];
      res.data.forEach(function(item){
        var pname = item.productName + '('+item.version+')';
        var cname = 'Chapter '+(1+item.chapterNumber);
        var ptmp = {
          name: pname,
          chapters: [],
        };
        var product = products.find(function(p){return p.name == pname});
        if(!product){
          products.push(ptmp);
          product = ptmp;
        }

        if(product.chapters.indexOf(cname) < 0){
          product.chapters.push(cname);
        }
      });
      products.forEach(function(item){
        var str = '';

        str = item.name;
        if(item.chapters.length > 0){
          str += '<br>&nbsp;&nbsp;&nbsp;&nbsp;';
          str += item.chapters.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
        }

        results.push(str);
      });
      return results;
    });
  }
  $scope.getPassageUsage = function (item) {
    $scope.getPassageUsageData(item).then(function(results){
      bootbox.alert('<strong>Passage Usage:</strong><br><br>'+results.join('<br>'));
    });
  };


  $scope.$watch('dbs',function(dbs,old){
    if(dbs != null && old == null && !$scope.inhouse){
      if(!($scope.qbv.selectedDB.length > 0)){
        $scope.refresh(function(){
          if(dbs.length > 0){
            $scope.qbv.selectedDB = dbs[0].idProduct;
          } else if(!$scope.import){
            $scope.addDatabase();
          }
        });
      }
    }
  })

  $scope.$watch('qbv.loading',function(loading){
    if(loading == 0){
      if($scope.qbv.questionbank){
        $scope.cleanIndexs();
        $scope.expandFormat($scope.qbv.questionbank);
        $scope.countItems($scope.qbv.questionbank);
        $scope.collapseFormat($scope.qbv.questionbank);
        if(!$scope.import){
          $scope.backup(true);
        }
        $('#qbname').val($scope.qbv.questionbank.name)

        var selfirst = function(scope){
          if(scope){
            if(scope.collapsed != undefined){
              scope.collapsed = false;
            }
            var items = scope.childNodes();
            if(items){
              if(items.length > 0){
                selfirst(scope.childNodes()[0]);
              } else {
                $scope.qbv.selectedNode = scope;
                $scope.qbv.selectedItem = scope.$modelValue;
              }
            } else {
              $scope.qbv.selectedNode = scope;
              $scope.qbv.selectedItem = scope.$modelValue;
            }
          }
        }
        $scope.refresh(function() {
          if(!$scope.inhouse){
            var elms = $('.qtDiv_tree.ng-scope.angular-ui-tree');
            for(var i = 0; i < elms.length; i++){
              if(elms[i].attributes['data-import-tree'].value != "true"){
                selfirst(angular.element(elms[i]).scope().$nodesScope);
              }
            }
          }
        });
      }
    }
  })

  $scope.$watch('qbv.selectedDB',function(selectedDB){
    if(selectedDB){
      if(selectedDB.length > 0){
        if($scope.qbv.questionbank){
          if(selectedDB != $scope.qbv.questionbank.idProduct){
            if($scope.qbv.librecTimer){
              $timeout.cancel($scope.qbv.librecTimer);
              $scope.qbv.librecTimer = undefined;
            }
            $scope.confirmSelectDB(selectedDB);
          }
        } else {
          if($scope.qbv.librecTimer){
            $timeout.cancel($scope.qbv.librecTimer);
            $scope.qbv.librecTimer = undefined;
          }
          if($scope.uneditable()){
            $scope.loadDB(selectedDB);
          } else {
            $scope.CheckBackup(selectedDB);
          }
        }
      } else {
        $scope.loadDB('none');
      }
    }
  });

  $scope.selectedItemChanged = function(selectedItem){
    if(selectedItem){
      if(selectedItem.isQuestion || selectedItem.isPassage){
        $scope.loadSingleItem(selectedItem).then(function(){
          if(!$scope.uneditable()){
            $rootScope.$broadcast("editorQuestionSelected", selectedItem);
          } else {
            $rootScope.$broadcast("previewQuestionSelected", selectedItem);
          }
        })
      } else {
        if(!$scope.uneditable()){
          $rootScope.$broadcast("editorQuestionSelected", undefined);
        } else {
          $rootScope.$broadcast("previewQuestionSelected", undefined);
        }
      }
    }
  };

  $scope.$watch('qbv.selectedItem',$scope.selectedItemChanged);

  $scope.$on('editorAddQuestion', function(event, item) {
    var target = $scope.qbv.selectedItem;
    if(!$scope.uneditable() && target){
      if(target.isQuestion){
        target = target.parent;
      }
      if(target.items && !target.isStandard){
        if(target.isPassage){
          item.passageId = target.data.idPassage;
        }

        var newQuestion = {
          idQuestion:item.itemCode,
          fkQuestion:item.itemCode,
          origin:'New Question',
          idSubPartQuestions:uuid.v4(),
          isQuestion:true,
          name:'',
          parent: target,
          data: item,
        };

        item._UPDATE = true;

        if(target.isPassage){
          newQuestion.fkProductPassage = target.id;
          newQuestion.fkPassage = target.data.idPassage;
        }

        target.items.push(newQuestion);

        $scope.qbv.selectedItem = newQuestion;

        $scope.cleanIndexs();
      }
    }
  });

  $scope.$on('editorAddPassage', function(event, item) {
    var target = $scope.qbv.selectedItem;
    if(!$scope.uneditable() && target){
      if(target.isQuestion){
        target = target.parent;
      }
      if(target.isPassage){
        target = target.parent;
      }
      if(target.items && !target.isStandard){

        var passage = {
          origin:'New Passage',
          id:uuid.v4(),
          isPassage:true,
          name:'',
          poolId: item.idPassage,
          parent: target,
          data: item,
          items: [],
        };

        target.items.push(passage);

        passage.data._UPDATE = true;

        $scope.qbv.selectedItem = passage;

        // $http.put('/api/passage', item).then(function(res){
        //   alertify.success('Passage Added');
        //
        // }).catch(function(err){
        //   console.error('Passage Added Failed',err);
        //   alertify.error('Passage Added Failed');
        // });
      }
    }
  });

  $scope.$on('updateQuestionID', function(event, item) {
    if(!$scope.uneditable() && $scope.qbv.selectedItem.isQuestion){
      if(item.idSubPartQuestions == $scope.qbv.selectedItem.idSubPartQuestions){
        $scope.qbv.selectedItem.idQuestion = item.idQuestion;
        $scope.qbv.selectedItem.fkQuestion = item.fkQuestion;
      }
    }
  });

  $scope.$on('loadAllForPassage', function(event, item) {
    $scope.qbv.working = true;

    var ids = [];
    for(var i = 0; i < $scope.qbv.tocPassages.length; i++){
      if($scope.qbv.tocPassages[i].data.idPassage==item.idPassage){
        for(var q = 0; q < $scope.qbv.tocPassages[i].items.length; q++){
          if(!$scope.qbv.tocPassages[i].items[q].data.loaded){
            ids.push($scope.qbv.tocPassages[i].items[q].data.idQuestion);
          }
        }
      }
    }
    if(ids.length > 0){
      $http.put('/api/question/list?json=true'+'&userDB='+($scope.personal ? true : false),{questionIds:ids})
      .then(function(questions){
        questions.data.forEach(function(q){
          var scrub = function(text){
            var tmp = text;

            var bits = tmp.split(/<\/?math>/g);
            var res = '';
            var inmath = false;
            for(var i =0; i < bits.length; i++){
              if(inmath){
                  res += bits[i].replace(/<\/?span[^>]*>/g,'');
                  res += '</math>'
                  inmath = false;
                }else{
                  res += bits[i];
                  if(i < bits.length - 1){
                    res += '<math>'
                  }
                  inmath = true;
                }
            }

            return res;
          }

          q.prompt = scrub(q.prompt);
          for(var i = 0; i < q.responses.length; i++){
            q.responses[i].answer = scrub(q.responses[i].answer);
          }

          var ques = $scope.qbv.questionPool.find(function(item){
            return item.idQuestion == q.itemCode;
          });

          if(ques){
            ques.loaded = true;
            angular.extend(ques,q);
          }
        });
        $scope.qbv.working = false;
      })
    } else {
      $scope.qbv.working = false;
    }
  });



  $scope.isIn = function(array,item,removeIfFound){
    var flag = false;

    if(item && array){
      for(var i = 0; i < array.length; i++){
        if(array[i]==item){
          if(removeIfFound){
            array.splice(i,1);
            i--;
          }
          flag = true
        }
        if(item.id){
          if(array[i].id==item.id){
            if(removeIfFound){
              array.splice(i,1);
              i--;
            }
            flag = true;
          }
        }
        if(item.idSubPartQuestions){
          if(array[i].idSubPartQuestions==item.idSubPartQuestions){
            if(removeIfFound){
              array.splice(i,1);
              i--;
            }
            flag = true;
          }
        }
      }
    }

    return flag;
  }

  $scope.pushNew = function(array,item){
    var flag = $scope.isIn(array,item);

    if(!flag){
      array.push(item);
    }
    return flag;
  }

  $scope.addRemove = function(array,item){
    var flag = $scope.isIn(array,item,true);

    if(!flag){
      array.push(item);
    }

    return flag;
  }


  $scope.addItem = function(item){
    if(item){
      if(item.isQuestion){
        item = item.parent;
      }
      if(item.isPassage){
        item = item.parent;
      }

      if(item.items && !item.isStandard){
        item.items.push({
            name: 'New Item',
            id: uuid.v4(),
            fk: item.id,
            items: [],
            meta: {},
            sortOrder: item.length,
          }
        );
      } else if(item.id == 'chapters'){
        $scope.qbv.questionbank.items.push({
            name: 'New Item',
            id: uuid.v4(),
            fk: item.id,
            items: [],
            meta: {},
            sortOrder: item.length,
          }
        );
      } else if(item.id == 'standards'){
        $scope.qbv.questionbank.standards.push({
            name: 'New Standard',
            id: uuid.v4(),
            fk: $scope.qbv.questionbank.idProduct,
            items: [],
            sortOrder: $scope.qbv.questionbank.standards.length,
            isStandard: true,
            description: '',
          }
        );
      }
    }
  }

  $scope.removeItem = function(item){
    var node = item.$parent.$nodeScope;
    parent = node.$parent.$nodeScope;
    var str = 'Are you sure you want to delete '+node.$parent.item.name+' and all of the items it containes?';
    if(node.$parent.item.isQuestion){
      str = 'Are you sure you want to delete question '+node.$parent.item.origin+'?';
    }
    bootbox.confirm(str,function(ok){
      if(ok){
        if(node.depth() > 1){
          parent.item.items.splice(node.index(),1);
        } else if(node.$parent.item.isStandard){
          $scope.qbv.questionbank.standards.splice(node.index(),1);
        } else {
          $scope.qbv.questionbank.items.splice(node.index(),1);
        }
        $scope.refresh();
      }
    });
  }

  $scope.copyItem = function(item){
    if(item){
      if(item.isQuestion && !item.parent.isStandard){
        var newitem = angular.copy(item);
        newitem.idSubPartQuestions = uuid.v4();
        item.parent.items.push(angular.copy(item));
        $scope.refresh();
      }
    }
  }


  $scope.addToStandardDialog = function(standard){
    var form = $('#addToStandard')[0].cloneNode(true);

    bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        var ci = form.querySelector("#chapterSel").selectedIndex;
        if(ci >= 0){
          $scope.addToStandard(standard,$scope.qbv.questionbank.items[ci],form.querySelector("#startIndex").value,form.querySelector("#stopIndex").value);
        }
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn-primary',
    }],{
      // prompts need a few extra options
      "header"  : 'Add questions to '+standard.name,
    });
  }
  $scope.addToStandard = function(standard,chapter,start,stop){
    $scope.expandFormat($scope.qbv.questionbank);

    var index = 1;

    function addItem(question){
      if(index >= start && index <= stop){
        if(!$scope.isIn(standard.items,question,false)){
          standard.items.push(question)
        }
      }
      index++;
    };

    for(var t = 0; t < chapter.items.length; t++){
      var topic = chapter.items[t];
      for(var s = 0; s < topic.items.length; s++){
        var section = topic.items[s];
        for(var p = 0; p < section.items.length; p++){
          var part = section.items[p];
          for(var i = 0; i < part.items.length; i++){
            var item = part.items[i];
            if(item.isPassage){
              for(var si = 0; si < item.items.length; si++){
                if(item.items[si].isQuestion) {
                  addItem(item.items[si]);
                }
              }
            } else if(item.isQuestion) {
              addItem(item);
            }
          }
        }
      }
    }

    $scope.collapseFormat($scope.qbv.questionbank);
    $scope.cleanIndexs();
  }

  $scope.loadStandardFile = function (ele) {
    if(ele.files.length > 0){
      var reader = new FileReader();
      reader.onload = function () {
        if(reader.result.length > 0){
          bootbox.confirm('File Loaded, Do you want to Import Standard Names and Discriptions?<br>* this tool will only create new Standards and or update the Discription and Order',
          function(ok){
            if(ok){
              $scope.importStandards(reader.result);
            }
          })
        }
        ele.value = null;
      }
      reader.readAsText(ele.files[0]);
    }
  }
  $scope.loadStandardItemsFile = function (ele) {
    if(ele.files.length > 0){
      var reader = new FileReader();
      reader.onload = function () {
        if(reader.result.length > 0){
          bootbox.confirm('File Loaded, Do you want to Import Standard Questions?<br>* this tool will remove any questions from all referenced Standards before importing questions.',
          function(ok){
            if(ok){
              $scope.importStandardItems(reader.result);
            }
          })
        }
        ele.value = null;
      }
      reader.readAsText(ele.files[0]);
    }
  }
  $scope.importStandards = function(fileStr){
    alertify.success('Loading standards');
    var lines = fileStr.split('\n');
    for(var i = 0; i < lines.length; i++){
      lines[i] = lines[i].replace('\r','').split('\t');
      if(lines[i].length > 1){
        var std = $scope.qbv.questionbank.standards.find(function(item){return item.name == lines[i][0]});
        if(std){
          std.description = lines[i][1];
          std.sortOrder = i
        } else {
          $scope.qbv.questionbank.standards.push({
            id: uuid.v4(),
            name: lines[i][0],
            description: lines[i][1],
            fk: $scope.qbv.questionbank.idProduct,
            sortOrder: i,
            items: [],
            ids: [],
            isStandard: true,
          })
        }
      }
    }
    $scope.qbv.questionbank.standards.sort(function(a,b){return a.sortOrder-b.sortOrder})
    alertify.success('Standard import complete');
    $scope.refresh();
  }
  $scope.importStandardItems = function(fileStr){
    alertify.success('Loading standards');
    var lines = fileStr.split('\n');
    for(var i = 0; i < lines.length; i++){
      lines[i] = lines[i].replace('\r','').split('\t');

      if(lines[i].length > 3){
        for(var s = 3; s < lines[i].length; s++){
          var std = $scope.qbv.questionbank.standards.find(function(item){return item.name == lines[i][s]})
          if(std && lines[i][s].length > 0){
            std.items = [];
          }
        }
      }
    }
    for(var i = 0; i < lines.length; i++){
      console.log(lines[i]);
      if(lines[i].length > 3){
        var chapter = $scope.qbv.questionbank.items[parseInt(lines[i][0])-1];
        var start = parseInt(lines[i][1]);
        var stop = parseInt(lines[i][2]);

        for(var s = 3; s < lines[i].length; s++){
          var std = $scope.qbv.questionbank.standards.find(function(item){return item.name == lines[i][s]})
          if(std){
            $scope.addToStandard(std,chapter,start,stop);
          }
        }
      }
    }
    alertify.success('Standard import complete');
    $scope.refresh();
  }

  $scope.getAllStandards = function(){
    $http.get('/api/standardCategory/').then(function(res){
      $scope.qbv.standardCategories = res.data.map(function(cat){
        cat.created = new Date(cat.created*1000);
        cat.modified = new Date(cat.modified*1000);
        cat.standards = [];
        return cat;
      });
      return $http.put('/api/standardDef/list', $scope.qbv.standardCategories.map(function(cat){return cat.idStandardCategory;}));
    }).then(function(res){
      for(var s = 0; s < res.data.length; s++){
        for(var c = 0; c < $scope.qbv.standardCategories.length; c++){
          if(res.data[s].fkStandardCategory == $scope.qbv.standardCategories[c].idStandardCategory){
            res.data[s].topics = (res.data[s].topics||'').split('||');
            $scope.qbv.standardCategories[c].standards.push(res.data[s]);
          }
        }
      }
    });
  };

  $scope.updateStandardDisc = function(item){
    var form = $('<form></form>');
    var select = 'Category: <select id="stdCat">'
    select += '<option>Select Category</option>';
    for(var i = 0; i < $scope.qbv.standardCategories.length; i++){
      select += '<option value="'+i+'">'+$scope.qbv.standardCategories[i].name+'</option>';
    }
    select += '</select><br>';
    form.append(select);

    select = 'Standard: <select id="stdDef"><option>Select Definition</option></select>';
    form.append(select);

    function populate(index){
      select = form.find("#stdDef");
      select.empty()
      select.append('<option>Select Definition</option>');
      for(var i = 0; i < $scope.qbv.standardCategories[index].standards.length; i++){
        select.append('<option value="'+i+'">'+$scope.qbv.standardCategories[index].standards[i].name+'</option>');
      }
    }

    form.find("#stdCat").change(function(){
      var index = this.value;
      populate(index);
    })

    setTimeout(function () {
      if(item.fkStandardDefinition){
        for(var c = 0; c < $scope.qbv.standardCategories.length; c++){
          for(var s = 0; s < $scope.qbv.standardCategories[c].standards.length; s++){
            if($scope.qbv.standardCategories[c].standards[s].idStandardDefinition == item.fkStandardDefinition){
              populate(c);
              form.find("#stdCat").val(c);
              form.find("#stdDef").val(s);
            }
          }
        }
      }
    }, 10);

    bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        cat = form.find("#stdCat").val();
        def = form.find("#stdDef").val();
        if(cat >= 0 && def >= 0){
          item.fkStandardDefinition = $scope.qbv.standardCategories[cat].standards[def].idStandardDefinition;
          item.name = $scope.qbv.standardCategories[cat].standards[def].name;
          item.description = $scope.qbv.standardCategories[cat].standards[def].description;
        }
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn',
    }],{
      // prompts need a few extra options
      "header"  : 'Description of '+$scope.qbv.questionbank.name+' - '+item.name,
    });
  }

  $scope.selectStandardCategory = function(next,message){
    if(alertify.infoDialog()){
      alertify.infoDialog().destroy();
    }
    if($scope.qbv.questionbank.standards){
      var form = $('<form></form>');
      if(message){
        form.append('<div class="alert alert-warning">'+message+'</div>');
      }
      var select = 'Category: <select id="stdCat">'
      for(var i = 0; i < $scope.qbv.standardCategories.length; i++){
        select += '<option value="'+i+'">'+$scope.qbv.standardCategories[i].name+'</option>';
      }
      select += '</select><br>';
      form.append(select);

      bootbox.dialog(form, [{
        'label' : 'Ok',
        'class' : 'btn-primary',
        'callback': function() {
          next($scope.qbv.standardCategories[form.find("#stdCat").val()]);
        }
      }, {
        'label' : 'Cancel',
        'class' : 'btn',
      }],{
        // prompts need a few extra options
        "header"  : 'Select Standard Category',
      });
    } else {
      alertify.error('No Standards');
    }
  }
  $scope.linkStandards = function(cat){
    var count = 0;
    for(var s = 0; s < $scope.qbv.questionbank.standards.length; s++){
      for(var c = 0; c < cat.standards.length; c++){
        if(cat.standards[c].name ==  $scope.qbv.questionbank.standards[s].name){
          $scope.qbv.questionbank.standards[s].fkStandardDefinition = cat.standards[c].idStandardDefinition;
          count++
        }
      }
    }
    alertify.success('standards linked:'+count+' of '+$scope.qbv.questionbank.standards.length);
  }
  $scope.importStandardsFromDef = function(cat){
    if(cat){
      $scope.qbv.questionbank.standards = [];
      for(var s = 0; s < cat.standards.length; s++){
        var standard = {
          id: uuid.v4(),
          name: cat.standards[s].name,
          description: cat.standards[s].description,
          fk: $scope.qbv.questionbank.idProduct,
          sortOrder: cat.standards[s].sortOrder,
          fkStandardDefinition: cat.standards[s].idStandardDefinition,
          items: [],
          ids: [],
          isStandard: true,
        }
        $scope.qbv.questionbank.standards.push(standard);
      }
      alertify.success("standards imported")
      $scope.importStandardItemsFromDef(cat);
    }
  }
  $scope.importStandardItemsFromDef = function(cat){
    $scope.expandFormat($scope.qbv.questionbank);
    var count = 0;
    var report = []
    for(var s = 0; s < cat.standards.length; s++){
      var result = {
        name:cat.standards[s].name,
        status:'Populated',
        missing:[],
      }
      var standard = null;
      for(var i = 0; i < $scope.qbv.questionbank.standards.length; i++){
        if(cat.standards[s].name == $scope.qbv.questionbank.standards[i].name){
          standard = $scope.qbv.questionbank.standards[i];
          standard.items = [];
        }
      }
      if(standard){
        for(var index = 0; index < cat.standards[s].topics.length; index++){
          var good = false;
          var path = cat.standards[s].topics[index].split('/');
          for(var pc = 0; pc < $scope.qbv.questionbank.items.length; pc++){
            if(path[0]==$scope.qbv.questionbank.items[pc].name){
              var chapter = $scope.qbv.questionbank.items[pc];
              for(var pt = 0; pt < chapter.items.length; pt++){
                if(path[1]==chapter.items[pt].name){
                  var topic = chapter.items[pt];
                  for(var ps= 0; ps < topic.items.length; ps++){
                    if(path[2]==topic.items[ps].name){
                      var section = topic.items[ps];
                      for(var pp= 0; pp < section.items.length; pp++){
                        if(path[3]==section.items[pp].name){
                          var part = section.items[pp];
                          good = true;
                          for(var pi = 0; pi < part.items.length; pi++){
                            var item = part.items[pi];
                            if(item.isPassage){
                              for(var ppi = 0; ppi < item.items.length; ppi++){
                                if(item.items[ppi].isQuestion) {
                                  if(!$scope.isIn(standard.items,item.items[ppi],false)){
                                    standard.items.push(item.items[ppi]);
                                  }
                                }
                              }
                            } else if(item.isQuestion) {
                              if(!$scope.isIn(standard.items,item,false)){
                                standard.items.push(item);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if(!good){
            result.missing.push(cat.standards[s].topics[index]);
          }
        }
        if(standard.items.length <= 0){
          result.status = 'No questions';
        }
      } else {
        result.status = 'Standard Not Found';
      }
      report.push(result);
    }
    $scope.collapseFormat($scope.qbv.questionbank);
    $scope.cleanIndexs();

    var form = $('<form></form>');
    var str=('<table>');
    for(var i = 0; i < report.length; i++){
      str+=('<tr><td style="width:25%;">'+report[i].name+': </td><td>'+report[i].status+'</td></tr>');
      if(report[i].missing.length> 0)str+=('<tr><td></td><td>missing topics</td></tr>');
      for(var t= 0; t < report[i].missing.length; t++){
        str+=('<tr><td></td><td>'+report[i].missing[t]+'</td></tr>');
      }
    }
    str+=('</table>');
    form.append(str);

    bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
      }
    }],{
      // prompts need a few extra options
      "header"  : 'Results',
    });
  }

  $scope.modLibrary = function(db,copy,map){
    var questionList = {};
    var product = {};
    $scope.cloneObj(db,product);
    if(copy){
      product.archive=1;
      product.created=Date.now()/1000;
      product.idProduct=uuid.v4();
      product.modified=Date.now()/1000;
    }

    if(db.items){
      for(var c = 0; c < db.items.length; c++){
        var chapter = db.items[c];
        var newChapter = {items:[]};
        $scope.cloneObj(chapter,newChapter);
        newChapter.sortOrder = c;
        newChapter.fk = product.idProduct;
        if(copy){
          newChapter.id = uuid.v4();
          newChapter.created = Date.now()/1000;
          newChapter.modified = Date.now()/1000;
        }
        if(map){
          newChapter.parent = product;
        } else {
          newChapter.parent = null;
        }

        if(chapter.items){
          for (var t = 0; t < chapter.items.length; t++){
            var topic = chapter.items[t];
            var newTopic = {};

            $scope.cloneObj(topic,newTopic);
            newTopic.sortOrder = t;
            newTopic.fk = newChapter.id;
            if(copy){
              newTopic.id = uuid.v4();
              newTopic.created = Date.now()/1000;
              newTopic.modified = Date.now()/1000;
            }
            if(map){
              newTopic.parent = newChapter;
            } else {
              newTopic.parent = null;
            }

            chapterQuestionIndex = 0;
            if(topic.items){
              for (var p = 0; p < topic.items.length; p++){
                var part = topic.items[p];
                var newPart = {};

                $scope.cloneObj(part,newPart);
                newPart.sortOrder = p;
                newPart.fk = newTopic.id;
                if(copy){
                  newPart.id = uuid.v4();
                  newPart.created = Date.now()/1000;
                  newPart.modified = Date.now()/1000;
                }
                if(map){
                  newPart.parent = newTopic;
                } else {
                  newPart.parent = null;
                }


                if(part.items){
                  for(var sp = 0; sp < part.items.length; sp++){
                    var subpart = part.items[sp];
                    var newSubPart = {};

                    $scope.cloneObj(subpart,newSubPart);
                    newSubPart.sortOrder = sp;
                    newSubPart.fk = newPart.id;
                    if(copy){
                      newSubPart.id = uuid.v4();
                      newSubPart.created = Date.now()/1000;
                      newSubPart.modified = Date.now()/1000;
                    }
                    if(map){
                      newSubPart.parent = newPart;
                    } else {
                      newSubPart.parent = null;
                    }

                    if(subpart.items){
                      for(var e = 0;e < subpart.items.length; e++){
                        var entry = subpart.items[e];
                        var newEntry = {};
                        $scope.cloneObj(entry,newEntry);
                        newEntry.data = entry.data;
                        if(entry.isPassage){
                          newEntry.fk = product.idProduct;
                          if(copy){
                            newEntry.id = uuid.v4();
                          }
                          if(map){
                            newEntry.parent = newSubPart;
                          } else {
                            newEntry.parent = null;
                          }
                          if(entry.items){
                            for(var q = 0; q < entry.items.length; q++){
                              var question = entry.items[q];
                              var newQuestion = {};
                              if(question.isQuestion){

                                $scope.cloneObj(question,newQuestion);
                                newQuestion.sortOrder = chapterQuestionIndex+1;
                                newQuestion.name = chapterQuestionIndex+1;
                                newQuestion.fkProductPassage = newEntry.id;
                                newQuestion.idProductPassages = newEntry.id;
                                newQuestion.fkSubPart = newSubPart.id;
                                newQuestion.fkProduct = product.idProduct;
                                newQuestion.data = question.data;

                                if(copy){
                                  newQuestion.idSubPartQuestions = uuid.v4();
                                }
                                if(map){
                                  newQuestion.parent = newEntry;
                                } else {
                                  newQuestion.parent = null;
                                }
                                chapterQuestionIndex++;

                                questionList[question.idSubPartQuestions] = newQuestion;
                                // if(question.data._UPDATE){
                                //   updateQuestionPush.push(question.data);
                                //   for(var i = 0; i < question.data.responses.length; i++){
                                //     updateResponsePush.push(question.data.responses[i])
                                //   }
                                //   delete question.data._UPDATE;
                                // }
                                //var item = angular.copy(question);
                                //delete item.parent;
                                // itemPush.push({
                                //   idSubPartQuestions:question.idSubPartQuestions,
                                //   fkSubPart: question.fkSubPart,
                                //   fkQuestion: question.fkQuestion,
                                //   name: question.name,
                                //   sortOrder: question.sortOrder,
                                //   fkProductPassage:question.fkProductPassage,
                                //   origin:question.origin,
                                // });
                                newEntry.items.push(newQuestion);
                              }
                            };
                          }
                          // if(entry.data._UPDATE){
                          //   updatePassagePush.push(entry.data);
                          //   entry.data._UPDATE = false;
                          // }
                          // var item = angular.copy(entry);
                          // delete item.items;
                          // delete item.parent;
                          // passagePush.push({
                          //   idProductPassages: entry.idProductPassages,
                          //   fkProduct: entry.fkProduct,
                          //   fkPassage: entry.fkPassage,
                          //   name: entry.name,
                          // });
                          newSubPart.items.push(newEntry);

                        } else if(entry.isQuestion){
                          newEntry.fkProduct = product.idProduct;
                          newEntry.fkSubPart = newSubPart.id;
                          newEntry.sortOrder = chapterQuestionIndex+1;
                          newEntry.name = chapterQuestionIndex+1;

                          if(copy){
                            newEntry.idSubPartQuestions = uuid.v4();
                          }
                          if(map){
                            newEntry.parent = newSubPart;
                          } else {
                            newEntry.parent = null;
                          }
                          chapterQuestionIndex++;

                          questionList[entry.idSubPartQuestions] = newEntry;

                          // if(entry.data._UPDATE){
                          //   updateQuestionPush.push(entry.data);
                          //   for(var i = 0; i < entry.data.responses.length; i++){
                          //     updateResponsePush.push(entry.data.responses[i])
                          //   }
                          //   entry.data._UPDATE = false;
                          // }
                          // var item = angular.copy(entry);
                          // delete item.parent;
                          // itemPush.push({
                          //   idSubPartQuestions:entry.idSubPartQuestions,
                          //   fkSubPart:entry.fkSubPart,
                          //   fkQuestion:entry.fkQuestion,
                          //   name:entry.name,
                          //   sortOrder:entry.sortOrder,
                          //   fkProductPassage:entry.fkProductPassage,
                          //   origin:entry.origin,
                          // });
                          newSubPart.items.push(newEntry);
                        }
                      };
                    }
                    // var item = angular.copy(subpart);
                    // delete item.items;
                    // delete item.parent;
                    // subpartsPush.push({
                    //   idSubPart:subpart.idSubPart,
                    //   fkPart:subpart.fkPart,
                    //   name:subpart.name,
                    //   created:subpart.created,
                    //   modified:subpart.modified,
                    //   sortOrder:subpart.sortOrder,
                    // });
                    newPart.items.push(newSubPart);
                  };
                }
                // var item = angular.copy(part);
                // delete item.items;
                // delete item.parent;
                // partsPush.push({
                //   idPart:part.idPart,
                //   fkTopic:part.fkTopic,
                //   name:part.name,
                //   created:part.created,
                //   modified:part.modified,
                //   sortOrder:part.sortOrder,
                // });
                newTopic.items.push(newPart);
              };
            }
            // var item = angular.copy(topic);
            // delete item.parent;
            // delete item.items;
            // topicsPush.push({
            //   idTopic:topic.idTopic,
            //   fkChapters:topic.fkChapters,
            //   name:topic.name,
            //   created:topic.created,
            //   modified:topic.modified,
            //   sortOrder:topic.sortOrder,
            // });
            newChapter.items.push(newTopic);
          };
        }
        // chaptersPush.push({
        //   idChapter:chapter.idChapter,
        //   fkProduct:chapter.fkProduct,
        //   name:chapter.name,
        //   created:chapter.created,
        //   modified:chapter.modified,
        //   sortOrder:chapter.sortOrder,
        // });
        product.items.push(newChapter);
      };
    }

    if(db.standards){
      //product.standards.forEach(function(standard,index){
      for(var s = 0; s < db.standards.length; s++){
        var standard = db.standards[s];
        var newStandard = {};
        $scope.cloneObj(standard,newStandard);
        newStandard.sortOrder = s;
        newStandard.fk = product.idProduct;
        if(copy){
          newStandard.id = uuid.v4();
        }
        if(map){
          newStandard.parent = product;
        } else {
          newStandard.parent = null;
        }

        if(standard.items){
          for(var sq = 0; sq < standard.items.length; sq++){
            newStandard.items.push(questionList[standard.items[sq].idSubPartQuestions]);
            // standardItemPush.push({
            //   fkStandard: standard.idStandard,
            //   fkQuestion: standard.items[sq].idSubPartQuestions,
            // });
          }
        }
        // var item = angular.copy(standard)
        // delete item.items;
        // standardsPush.push({
        //   idStandard:standard.idStandard,
        //   fkProduct:standard.fkProduct,
        //   name:standard.name,
        //   description:standard.description,
        //   created:standard.created,
        //   modified:standard.modified,
        //   sortOrder:standard.sortOrder,
        // })
        product.standards.push(newStandard);
      };
    }

    return product;
  }

  $scope.$on('newLibrary', function() {
    if(!$scope.import){
      $scope.addDatabase()
    }
  })

  $scope.$on('saveLibrary', function() {
    if(!$scope.import){
      $scope.updateDatabase()
    }
  });

  $scope.$on('openSelectedLibrary', function(event, item) {
    if(!$scope.import){
      $scope.qbv.selectedDB = item;
    }
  });


  $scope.deleteDatabase = function(){
    alertify.infoDialog().destroy();
    var str = 'Are you sure you want to delete '+$scope.qbv.questionbank.name+
    ' v'+$scope.qbv.questionbank.version+'?';
    bootbox.confirm(str,function(ok){
      if(ok){
        $http.delete('/api/database/'+$scope.qbv.questionbank.idProduct+'?userDB='+($scope.personal ? true : false)).then(function(res) {
          var i = $scope.dbs.findIndex(function(item){
            return item.idProduct == $scope.qbv.questionbank.idProduct;
          });
          $scope.dbs.splice(i,1);
          $scope.qbv.selectedDB = '';
          $scope.qbv.questionbank = null;
          $timeout(function() {
            $scope.$apply();
            $scope.refresh();
          });
        });
      }
    });
  }

  $scope.addDatabase = function(){
    bootbox.prompt('New Library name:',function(value){
      if(value){
        var dbid = uuid.v4();
        var newQB = {
          idProduct:dbid,
          version:'1.00',
          archive:1,
          created:Date.now()/1000,
          modified:Date.now()/1000,
          sortOrder:0,
          name:value,
          new:true,
          items:[{
            id: uuid.v4(),
            fk: dbid,
            created:Date.now()/1000,
            name:'New Chapter',
            items:[],
          }],
          standards:[],
          owner: $rootScope.profile.UserId,
        };
        $scope.dbs.push(newQB);
        $scope.refresh(function(){
          $scope.qbv.selectedDB = newQB.idProduct;
        });
      }
    });
  }

  $scope.copyDatabase = function(){
    bootbox.prompt('New version of '+$scope.qbv.questionbank.name+' :',
    'Cancel','OK',
    function(value){
      if(value){
        alertify.infoDialog().destroy();
        var newQB = angular.copy($scope.qbv.questionbank);//jQuery.extend(true, {}, $scope.qbv.questionbank);//JSON.parse(JSON.stringify($scope.qbv.questionbank));
        newQB.owner = $rootScope.profile.UserId;
        newQB.idProduct = uuid.v4();
        newQB.new = true;
        newQB.version = value;
        newQB.archive = 1;
        $scope.dbs.push(newQB);
        $scope.refresh(function(){
          $scope.qbv.selectedDB = newQB.idProduct;

          function wait(next){
            setTimeout(function () {
              if($scope.qbv.loading > 0){
                wait(next);
              } else {
                next();
              }
            }, 1000);
          }

          wait(function(){
            $scope.updateDatabase(function(){
              $scope.refreshDatabase();
            });
          })
        });
      }
    }, $scope.qbv.questionbank.version);
  }

  $scope.translate = function(){
    function work(){
      bootbox.prompt('New language for '+$scope.qbv.questionbank.name+' :',
      'Cancel','OK',
      function(value){
        if(value){
          alertify.infoDialog().destroy();
          $scope.createTranslation(value)
        }
      }, 'Spanish');
    }

    bootbox.confirm('Shuffle answers before copying?',
    'No','Yes',
    function(value){
      if(value){
        $scope.shuffleAnswers(function(){
          $scope.updateDatabase(work);
        })
      }else{
        work();
      }
    });
  }

  $scope.createTranslation = function(lang){
    var newQB = angular.copy($scope.qbv.questionbank);//jQuery.extend(true, {}, $scope.qbv.questionbank);//JSON.parse(JSON.stringify($scope.qbv.questionbank));
    newQB.owner = $rootScope.profile.UserId;
    newQB.idProduct = uuid.v4();
    newQB.new = true;
    newQB.archive = 1;
    newQB.name += ` (${lang})`;
    $scope.dbs.push(newQB);
    $scope.qbv.passageTranslations = [];
    $scope.qbv.questionTranslations = [];
    $scope.qbv.responseTranslations = [];

    $scope.refresh(function(){
      $scope.qbv.selectedDB = newQB.idProduct;

      function wait(next){
        setTimeout(function () {
          if($scope.qbv.loading > 0){
            wait(next);
          } else {
            next();
          }
        }, 1000);
      }

      function updateRefs(){
        var p;
        var questionLoad = []
        for(var i = 0; i < $scope.qbv.questionPool.length; i++){
          if(!$scope.qbv.questionPool[i].loaded)
            questionLoad.push($scope.qbv.questionPool[i].idQuestion);
        }
        if(questionLoad.length > 0){
          p = $http.put('/api/question/list?json=true'+'&userDB='+($scope.personal ? true : false),{questionIds:questionLoad})
        } else {
          p = Promise.resolve({data:[]});
        }
        p.then(function(questions){
          questions.data.forEach(function(q){
            var scrub = function(text){
              var tmp = text;

              var bits = tmp.split(/<\/?math>/g);
              var res = '';
              var inmath = false;
              for(var i =0; i < bits.length; i++){
                if(inmath){
                    res += bits[i].replace(/<\/?span[^>]*>/g,'');
                    res += '</math>'
                    inmath = false;
                  }else{
                    res += bits[i];
                    if(i < bits.length - 1){
                      res += '<math>'
                    }
                    inmath = true;
                  }
              }

              return res;
            }

            q.prompt = scrub(q.prompt);
            for(var i = 0; i < q.responses.length; i++){
              q.responses[i].answer = scrub(q.responses[i].answer);
            }

            var ques = $scope.qbv.questionPool.find(function(item){
              return item.idQuestion == q.itemCode;
            });

            if(ques){
              ques.loaded = true;
              angular.extend(ques,q);
            }

          });

          var passageLoad = []
          for(var i = 0; i < $scope.qbv.passagePool.length; i++){
            if(!$scope.qbv.passagePool[i].loaded)
              passageLoad.push($scope.qbv.passagePool[i].idPassage);
          }
          if(passageLoad.length > 0){
            return $http.put('/api/passage/list?json=true'+'&userDB='+($scope.personal ? true : false),{passageIds:passageLoad});
          } else {
            return Promise.resolve({data:[]});
          }
        }).then(function(passages){
          passages.data.forEach(function(p){
            var pass = $scope.qbv.passagePool.find(function(item){
              return item.idPassage == p.idPassage;
            });

            if(pass){
              angular.extend(pass,p);
            }
          });
          var oldId = null

          function processQuestion(question){
            question._UPDATE = true;
            oldId = question.itemCode || question.idQuestion;
            question.fkParent = oldId;
            question.itemCode = question.idQuestion = uuid.v4();
            $scope.qbv.questionTranslations.push({
              src: oldId,
              lang: lang,
              translation: question.itemCode,
            });

            for(var i = 0; i < question.responses.length; i++){
              oldId = question.responses[i].idResponse;
              question.responses[i].idResponse = uuid.v4();
              question.responses[i].itemCode = question.idQuestion;
              $scope.qbv.responseTranslations.push({
                src: oldId,
                lang: lang,
                translation: question.responses[i].idResponse,
              });
            }
          }


          for(var p = 0; p < $scope.qbv.passagePool.length;p++){
            $scope.qbv.passagePool[p]._UPDATE = true;
            oldId = $scope.qbv.passagePool[p].idPassage
            $scope.qbv.passagePool[p].idPassage = uuid.v4();
            $scope.qbv.passageTranslations.push({
              src:oldId,
              lang:lang,
              translation:$scope.qbv.passagePool[p].idPassage,
            });
            for(var q = 0; q < $scope.qbv.questionPool.length;q++){
              if($scope.qbv.questionPool[q].passageId === oldId){
                $scope.qbv.questionPool[q].passageId = $scope.qbv.passagePool[p].idPassage;
                processQuestion($scope.qbv.questionPool[q]);
              }
            }
          }

          for(var q = 0; q < $scope.qbv.questionPool.length;q++){
            if(!$scope.qbv.questionPool[q]._UPDATE){
              processQuestion($scope.qbv.questionPool[q]);
            }
          }

          console.log(JSON.parse(JSON.stringify($scope.qbv.questionPool)));

          $scope.updateDatabase(function(){
            $scope.refreshDatabase();
            $scope.qbv.working = false;
          });
        });
      }

      $scope.qbv.working = true;
      wait(function(){
        $scope.updateDatabase(function(){
          $scope.refreshDatabase();
          wait(updateRefs);
        });
      });
    });
  }

  $scope.updateDatabase = function(next){

    if($scope.qbv.librecTimer){
      $timeout.cancel($scope.qbv.librecTimer);
      $scope.qbv.librecTimer = undefined;
    }

    $scope.qbv.action = 'Saving';
    $scope.qbv.loading = 16;
    $scope.refresh();

    $scope.expandFormat($scope.qbv.questionbank);

    var isnew = $scope.qbv.questionbank.new;
    var oldId = $scope.qbv.questionbank.idProduct;
    var product = $scope.qbv.questionbank;

    var chapterQuestionIndex = 0;
    var chaptersPush = [];
    var topicsPush = [];
    var partsPush = [];
    var subpartsPush = [];
    var passagePush = [];
    var itemPush = [];
    var standardsPush = [];
    var standardItemPush = [];
    var updateQuestionPush = [];
    var updateResponsePush = [];
    var updatePassagePush = [];

    product.idProduct = uuid.v4();
    product.modified = Date.now()/1000;

    if(product.items){

      $scope.countItems(product);
      //product.items.forEach(function(chapter,index){
      for(var c = 0; c < product.items.length; c++){
        var chapter = product.items[c];
        chapter.fkProduct = product.idProduct;
        chapter.idChapter = uuid.v4();
        chapter.modified = Date.now()/1000;
        chapter.sortOrder = c;
        // chapter.meta.questionCount = (chapter.questionCounter.TF+chapter.questionCounter.SR+chapter.questionCounter.ER+chapter.questionCounter.MC);
        if(chapter.items){
          chapterQuestionIndex = 0;
          //chapter.items.forEach(function(topic,index){
          for (var t = 0; t < chapter.items.length; t++){
            var topic = chapter.items[t];
            topic.fkChapters = chapter.idChapter;
            topic.idTopic = uuid.v4();
            topic.modified = Date.now()/1000;
            topic.sortOrder = t;
            if(!topic.meta){topic.meta = {}};
            topic.meta.questionRange = {
              stop:topic.questionCounter.stop,
              start:topic.questionCounter.start,
            };
            if(topic.items){
              //topic.items.forEach(function(part,index){
              for (var p = 0; p < topic.items.length; p++){
                var part = topic.items[p];
                part.fkTopic = topic.idTopic;
                part.idPart = uuid.v4();
                part.modified = Date.now()/1000;
                part.sortOrder = p;
                if(!part.meta){part.meta = {}};
                part.meta.questionRange = {
                  stop:part.questionCounter.stop,
                  start:part.questionCounter.start,
                };
                if(part.items){
                  //part.items.forEach(function(subpart,index){
                  for(var sp = 0; sp < part.items.length; sp++){
                    var subpart = part.items[sp];
                    subpart.fkPart = part.idPart;
                    subpart.idSubPart = uuid.v4();
                    subpart.modified = Date.now()/1000;
                    subpart.sortOrder = sp;
                    if(!subpart.meta){subpart.meta = {}};
                    subpart.meta.questionRange = {
                      stop:subpart.questionCounter.stop,
                      start:subpart.questionCounter.start,
                    };
                    if(subpart.items){
                      //subpart.items.forEach(function(entry,index){
                      for(var e = 0;e < subpart.items.length; e++){
                        var entry = subpart.items[e];
                        if(entry.isPassage){
                          entry.fkProduct = product.idProduct;
                          entry.idProductPassages = uuid.v4();
                          entry.fkPassage = entry.data.idPassage;

                          if(entry.items){
                            //entry.items.forEach(function(question){
                            for(var q = 0; q < entry.items.length; q++){
                              var question = entry.items[q];
                              if(question.isQuestion){
                                question.fkSubPart = subpart.idSubPart;
                                question.oldId = question.idSubPartQuestions;
                                question.idSubPartQuestions = uuid.v4();
                                question.modified = Date.now()/1000;
                                question.sortOrder = chapterQuestionIndex+1;
                                question.name = chapterQuestionIndex+1;
                                question.fkProductPassage = entry.idProductPassages;

                                chapterQuestionIndex++;

                                if(question.data.passageId != entry.data.idPassage && question.data.loaded){
                                  question.data.passageId = entry.data.idPassage;
                                  question.data.fkParent = question.data.idQuestion || question.data.itemCode;
                                  question.fkQuestion = question.data.itemCode = question.data.idQuestion = uuid.v4();
                                  question.data._UPDATE = true;
                                }


                                if(question.data._UPDATE ){
                                  question.fkQuestion = question.data.idQuestion || question.data.itemCode;
                                  updateQuestionPush.push(question.data);
                                  for(var i = 0; i < question.data.responses.length; i++){
                                    question.data.responses[i].itemCode = question.data.idQuestion || question.data.itemCode;
                                    updateResponsePush.push(question.data.responses[i])
                                  }
                                  question.data._UPDATE = false;
                                }

                                //var item = angular.copy(question);
                                //delete item.parent;
                                itemPush.push({
                                  idSubPartQuestions:question.idSubPartQuestions,
                                  oldId:question.oldId,
                                  fkSubPart: question.fkSubPart,
                                  fkQuestion: question.fkQuestion,
                                  name: question.name,
                                  sortOrder: question.sortOrder,
                                  fkProductPassage:question.fkProductPassage,
                                  origin:question.origin,
                                });
                              }
                            };
                          }

                          if(entry.data._UPDATE){
                            updatePassagePush.push(entry.data);
                            entry.data._UPDATE = false;
                          }

                          // var item = angular.copy(entry);
                          // delete item.items;
                          // delete item.parent;
                          passagePush.push({
                            idProductPassages: entry.idProductPassages,
                            fkProduct: entry.fkProduct,
                            fkPassage: entry.fkPassage,
                            name: entry.name,
                          });

                        } else if(entry.isQuestion){
                          entry.fkSubPart = subpart.idSubPart;
                          entry.oldId = entry.idSubPartQuestions;
                          entry.idSubPartQuestions = uuid.v4();
                          entry.modified = Date.now()/1000;
                          entry.sortOrder = chapterQuestionIndex+1;
                          entry.name = chapterQuestionIndex+1;

                          chapterQuestionIndex++;

                          if(entry.data._UPDATE){
                            entry.fkQuestion = entry.data.idQuestion || entry.data.itemCode;
                            updateQuestionPush.push(entry.data);
                            for (var i = 0; i < entry.data.responses.length; i++) {
                              entry.data.responses[i].itemCode = entry.data.idQuestion || entry.data.itemCode;
                              updateResponsePush.push(entry.data.responses[i])
                            }
                            entry.data._UPDATE = false;
                          }

                          // var item = angular.copy(entry);
                          // delete item.parent;
                          itemPush.push({
                            idSubPartQuestions:entry.idSubPartQuestions,
                            oldId:entry.oldId,
                            fkSubPart:entry.fkSubPart,
                            fkQuestion:entry.fkQuestion,
                            name:entry.name,
                            sortOrder:entry.sortOrder,
                            fkProductPassage:entry.fkProductPassage,
                            origin:entry.origin,
                          });
                        }
                      };
                    }
                    // var item = angular.copy(subpart);
                    // delete item.items;
                    // delete item.parent;
                    subpartsPush.push({
                      idSubPart:subpart.idSubPart,
                      fkPart:subpart.fkPart,
                      fkSubPart:subpart.nestedSubPart,
                      name:subpart.name,
                      meta:subpart.meta,
                      created:subpart.created,
                      modified:subpart.modified,
                      sortOrder:subpart.sortOrder,
                    });
                  };
                }
                // var item = angular.copy(part);
                // delete item.items;
                // delete item.parent;
                partsPush.push({
                  idPart:part.idPart,
                  fkTopic:part.fkTopic,
                  name:part.name,
                  meta:part.meta,
                  created:part.created,
                  modified:part.modified,
                  sortOrder:part.sortOrder,
                });
              };
            }
            // var item = angular.copy(topic);
            // delete item.parent;
            // delete item.items;
            topicsPush.push({
              idTopic:topic.idTopic,
              fkChapters:topic.fkChapters,
              name:topic.name,
              meta:topic.meta,
              created:topic.created,
              modified:topic.modified,
              sortOrder:topic.sortOrder,
            });
          };
        }
        // var item = angular.copy(chapter);
        // if(item.parent){
        //   delete item.parent;
        // }
        // delete item.items;
        chaptersPush.push({
          idChapter:chapter.idChapter,
          fkProduct:chapter.fkProduct,
          name:chapter.name,
          meta:chapter.meta,
          created:chapter.created,
          modified:chapter.modified,
          sortOrder:chapter.sortOrder,
        });
      };
    }

    if(product.standards){
      //product.standards.forEach(function(standard,index){
      for(var s = 0; s < product.standards.length; s++){
        var standard = product.standards[s];
        standard.fkProduct = product.idProduct;
        standard.idStandard = uuid.v4();
        standard.modified = Date.now()/1000;
        standard.sortOrder = s;
        if(standard.items){
          //standard.items.forEach(function(item,index){
          for(var sq = 0; sq < standard.items.length; sq++){
            var index = itemPush.findIndex(function(item){return item.oldId == standard.items[sq].idSubPartQuestions});
            if(index >= 0){
              standard.items[sq].idSubPartQuestions = itemPush[index].idSubPartQuestions;
            }
            standardItemPush.push({
              fkStandard: standard.idStandard,
              fkQuestion: standard.items[sq].idSubPartQuestions,
            });
          }
        }
        // var item = angular.copy(standard)
        // delete item.items;
        standardsPush.push({
          idStandard:standard.idStandard,
          fkProduct:standard.fkProduct,
          name:standard.name,
          description:standard.description,
          created:standard.created,
          modified:standard.modified,
          sortOrder:standard.sortOrder,
          fkStandardDefinition:standard.fkStandardDefinition,
        });
      };
    }

    var madeProduct = false;

    var data = {
      idProduct:product.idProduct || uuid.v4(),
      name:product.name,
      created:product.created || Date.now()/1000,
      modified:Date.now()/1000,
      sortOrder:product.sortOrder || 0,
      archive:product.archive || 0,
      version:product.version || '1.0',
      shortCode:product.shortCode || '',
      owner: product.owner || $rootScope.profile.UserId,
    }

    $scope.collapseFormat($scope.qbv.questionbank);

    $http.put('/api/database/info'+'?userDB='+($scope.personal ? true : false), data).then(function(res){
      $scope.qbv.loading--;
      if(isnew){
        delete product.new;
      }
      madeProduct = true;
      if(chaptersPush.length > 0){
        return $http.put('/api/chapters'+'?userDB='+($scope.personal ? true : false), chaptersPush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(topicsPush.length > 0){
        return $http.put('/api/topics'+'?userDB='+($scope.personal ? true : false), topicsPush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(partsPush.length > 0 ){
        return $http.put('/api/sections'+'?userDB='+($scope.personal ? true : false), partsPush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(subpartsPush.length > 0 ){
        for(var i = 0; i < subpartsPush.length; i++){
          if(subpartsPush[i].fkSubPart)subpartsPush[i].fkSubPart = subpartsPush[i].fkSubPart.idSubPart;
        }
        return $http.put('/api/subsections?nc='+Date.now()+'&userDB='+($scope.personal ? true : false), subpartsPush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(updatePassagePush.length > 0 ){
        return $http.put('/api/passage'+'?userDB='+($scope.personal ? true : false), updatePassagePush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(updateQuestionPush.length > 0 ){
        var batches = [];
        for (var i = 0; i < updateQuestionPush.length; i += 500){
          batches.push(updateQuestionPush.slice(i,i+500));
        }
        function a(list,i){
          if(i < list.length){
            return $http.put('/api/question'+'?userDB='+($scope.personal ? true : false), list[i]).then(function(res){
              return a(list,i+1);
            });
          }
        }
        return a(batches,0);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(updateResponsePush.length > 0 ){
        var batches = [];
        for (var i = 0; i < updateResponsePush.length; i += 500){
          batches.push(updateResponsePush.slice(i,i+500));
        }
        function a(list,i){
          if(i < list.length){
            return $http.put('/api/response'+'?userDB='+($scope.personal ? true : false), list[i]).then(function(res){
              return a(list,i+1);
            });
          }
        }
        return a(batches,0);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(passagePush.length > 0 ){
        return $http.put('/api/passagelist'+'?userDB='+($scope.personal ? true : false), passagePush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(itemPush.length > 0 ){
        var batches = [];
        for (var i = 0; i < itemPush.length; i += 1000){
          batches.push(itemPush.slice(i,i+1000));
        }
        function a(list,i){
          if(i < list.length){
            return $http.put('/api/items'+'?userDB='+($scope.personal ? true : false), list[i]).then(function(res){
              return a(list,i+1);
            });
          }
        }
        return a(batches,0);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(standardsPush.length > 0 ){
        return $http.put('/api/standardlist'+'?userDB='+($scope.personal ? true : false), standardsPush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if(standardItemPush.length > 0 ){
        return $http.put('/api/standard'+'?userDB='+($scope.personal ? true : false), standardItemPush);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if($scope.qbv.questionTranslations.length > 0 ){
        return $http.put('/api/question/translation?userDB='+($scope.personal ? true : false), $scope.qbv.questionTranslations);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if($scope.qbv.passageTranslations.length > 0 ){
        return $http.put('/api/passage/translation?userDB='+($scope.personal ? true : false), $scope.qbv.passageTranslations);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      if($scope.qbv.responseTranslations.length > 0 ){
        return $http.put('/api/response/translation?userDB='+($scope.personal ? true : false), $scope.qbv.responseTranslations);
      } else {
        return true;
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      madeProduct = false;

      if(isnew){
        $http['delete']('/api/'+$rootScope.profile.UserId+'/librec/'+product.idProduct+'?userDB='+($scope.personal ? true : false))
        return true;
      } else {
        $http['delete']('/api/'+$rootScope.profile.UserId+'/librec/'+oldId+'?userDB='+($scope.personal ? true : false))

        data = {
          idProduct:oldId,
          name:product.name,
          created:product.created || Date.now()/1000,
          modified:product.modified || Date.now()/1000,
          sortOrder:product.sortOrder || 0,
          archive:2,
          version:product.version + ' ' + Math.trunc(Date.now()/1000),
          shortCode:product.shortCode || '',
          owner: product.owner || $rootScope.profile.UserId,
        };
        return $http.put('/api/database/info'+'?userDB='+($scope.personal ? true : false), data)
        // return $http['delete']('/api/database/'+oldId+'?userDB='+($scope.personal ? true : false));
      }
    })
    .then(function(res){
      $scope.qbv.loading--;
      // $http.put('/api/log/add',{
      //   action:'saveQB',
      //   tags:'editor,success,'+($scope.inhouse?'inhouse':'enduser'),
      //   meta:JSON.stringify({
      //     profile: $rootScope.profile,
      //     product: product.name,
      //   },null,2),
      // });
      $scope.backup(true);
      alertify.success('Library Saved');
      $scope.refresh();
      if(next){next(true);}
    })
    .catch(function(err){
      $http.put('/api/log/add',{
        action:'saveQB',
        tags:'editor,error,'+($scope.inhouse?'inhouse':'enduser'),
        meta:JSON.stringify({
          profile: $rootScope.profile,
          error: JSON.stringify(err,Object.getOwnPropertyNames(err),2),
        },null,2),
      });
      alertify.error('Save Failed');
      if(madeProduct){
        $http['delete']('/api/database/'+product.idProduct+'?userDB='+($scope.personal ? true : false));
      }
      product.idProduct = oldId;
      $scope.qbv.loading = 0;
      $scope.refresh();
      if(next){next(false);}
    });
  }

  $scope.refreshDatabase = function(){
    if(alertify.infoDialog)alertify.infoDialog().destroy();

    if($scope.qbv.librecTimer){
      $timeout.cancel($scope.qbv.librecTimer);
      $scope.qbv.librecTimer = undefined;
    }

    $scope.qbv.questionbank.items = [];
    $scope.loadDB($scope.qbv.questionbank.idProduct);
  };


  $scope.cleanOrigins = function(){
    var chapterQuestionIndex = 0;
    var product = $scope.qbv.questionbank;
    var chapter;

    function next(item){
      if(item.isQuestion){
        item.name = chapterQuestionIndex+1;
        item.origin = product.shortCode +' '+
        item.typeId + ' ' +(chapter.sortOrder+1) + '-' + (item.name);
        chapterQuestionIndex++;
      } else if(item.items){
        for(var i = 0; i < item.items.length; i++){
          next(item.items[i]);
        }
      }
    }

    if(product.items){
      product.items.forEach(function(item,index){
        chapter = item;
        item.sortOrder = index;
        chapterQuestionIndex = 0;
        next(item);
      });
    }
  }

  $scope.cleanIndexs = function(qb){
    var chapterQuestionIndex = 0;
    var c;
    function next(item){
      if(item.isQuestion){
        item.name = chapterQuestionIndex+1;
        item.chapterNumber = c;
        chapterQuestionIndex++;
      } else if(item.items){
        for(var i = 0; i < item.items.length; i++){
          next(item.items[i]);
        }
      }
    }
    qb = qb || $scope.qbv.questionbank;
    for(c = 0; c < (qb.items || qb).length; c++){
      chapterQuestionIndex = 0;
      next((qb.items || qb)[c]);
    }

    if (qb.standards){
      for (var s = 0; s < qb.standards.length; s++){
        qb.standards[s].items.sort(function(a,b){
          return ((a.chapterNumber*100000)+a.name)-((b.chapterNumber*100000)+b.name);
        })
      }
    }
  }

  $scope.shuffleAnswers = function(next){
    if(alertify.infoDialog()){
      alertify.infoDialog().destroy();
    }
    var p;
    var questionLoad = []
    for(var i = 0; i < $scope.qbv.questionPool.length; i++){
      if(!$scope.qbv.questionPool[i].loaded)
        questionLoad.push($scope.qbv.questionPool[i].idQuestion);
    }
    if(questionLoad.length > 0){
      p = $http.put('/api/question/list?json=true'+'&userDB='+($scope.personal ? true : false),{questionIds:questionLoad})
    } else {
      p = Promise.resolve({data:[]});
    }
    $scope.qbv.working = true;
    p.then(function(questions){
      questions.data.forEach(function(q){
        var scrub = function(text){
          var tmp = text;

          var bits = tmp.split(/<\/?math>/g);
          var res = '';
          var inmath = false;
          for(var i =0; i < bits.length; i++){
            if(inmath){
                res += bits[i].replace(/<\/?span[^>]*>/g,'');
                res += '</math>'
                inmath = false;
              }else{
                res += bits[i];
                if(i < bits.length - 1){
                  res += '<math>'
                }
                inmath = true;
              }
          }

          return res;
        }

        q.prompt = scrub(q.prompt);
        for(var i = 0; i < q.responses.length; i++){
          q.responses[i].answer = scrub(q.responses[i].answer);
        }

        var ques = $scope.qbv.questionPool.find(function(item){
          return item.idQuestion == q.itemCode;
        });

        if(ques){
          ques.loaded = true;
          angular.extend(ques,q);
        }

      });
      var distro = [0,0,0,0]
      for(var q = 0; q < $scope.qbv.questionPool.length;q++){
        if($scope.qbv.questionPool[q].responses.length > 1){
          for(var i = 0; i < $scope.qbv.questionPool[q].responses.length; i++){
            if($scope.qbv.questionPool[q].responses[i].isCorrect &&
                !$scope.qbv.questionPool[q].responses[i].isPositionFixed){
              $scope.qbv.questionPool[q]._UPDATE = true;
              var retry = 5;
              do{
                var ni = Math.trunc(Math.random() * $scope.qbv.questionPool[q].responses.length);
                if(!$scope.qbv.questionPool[q].responses[ni].isPositionFixed){
                  var tmp = $scope.qbv.questionPool[q].responses[i];
                  $scope.qbv.questionPool[q].responses[i] = $scope.qbv.questionPool[q].responses[ni];
                  $scope.qbv.questionPool[q].responses[ni] = tmp;
                  $scope.qbv.questionPool[q].responses[i].sortOrder = i;
                  $scope.qbv.questionPool[q].responses[ni].sortOrder = ni;
                  distro[ni]++;
                  retry = 0;
                } else {
                  retry--;
                }
              } while (retry > 0);
            }
          }
        }
      }
      $scope.qbv.working = false;
      alertify.success('answers shuffled');
      if(next)next();
    })
    .catch(function(e){
      console.log(e);
      $scope.qbv.working = false;
      alertify.success('an error occured while shuffling answers');
    })
  }

  $scope.expandFormat = function(product){
    console.log('Expand');
    var noTopic = -1;
    var noPart = -1;
    var noSubpart= -1;

    if(product.items){
      for(var c = 0; c < product.items.length; c++){
        var chapter = product.items[c];
        if(chapter.items){
          noTopic = -1;
          for(var t = 0; t < chapter.items.length; t++){
            var topic = chapter.items[t];
            if(topic.isQuestion || topic.isPassage){
              if(noTopic < 0){
                noTopic = chapter.items.findIndex(function(item){
                  return item.name == '(no topic)';
                });
              }
              if(noTopic < 0){
                noTopic = chapter.items.length;
                chapter.items.push({
                  name: '(no topic)',
                  id: uuid.v4(),
                  fk: chapter.id,
                  meta:{},
                  items: [],
                  sortOrder: chapter.items.length,
                  parent: chapter,
                });
              }
              topic.parent = chapter.items[noTopic];
              chapter.items[noTopic].items.push(topic);
              chapter.items.splice(t,1);
              t--;
              noTopic--;
            } else {
              if(topic.items){
                noPart = -1
                for(var p = 0; p < topic.items.length; p++){
                  var part = topic.items[p];
                  if(part.isQuestion || part.isPassage){
                    if(noPart < 0){
                      noPart = topic.items.findIndex(function(item){
                        return item.name == '(no section)';
                      });
                    }
                    if(noPart < 0){
                      noPart = topic.items.length;
                      topic.items.push({
                        name: '(no section)',
                        id: uuid.v4(),
                        fk: topic.id,
                        meta:{},
                        items: [],
                        sortOrder: topic.items.length,
                        parent: topic,
                      });
                    }
                    part.parent = topic.items[noPart];
                    topic.items[noPart].items.push(part);
                    topic.items.splice(p,1);
                    p--;
                    noPart--;
                  } else {
                    if(part.items){
                      noSubpart= -1;
                      for(var sp = 0; sp < part.items.length; sp++){
                        var subpart = part.items[sp];
                        var extraSubparts = 1;
                        if(subpart.isQuestion || subpart.isPassage){
                          if(noSubpart < 0){
                            noSubpart = part.items.findIndex(function(item){
                              return item.name == '(no subpart)';
                            });
                          }
                          if(noSubpart < 0){
                            noSubpart = part.items.length;
                            part.items.push({
                              name: '(no subpart)',
                              id: uuid.v4(),
                              fk: part.id,
                              meta:{},
                              items: [],
                              sortOrder: part.items.length,
                              parent: part,
                            });
                          }
                          subpart.parent = part.items[noSubpart];
                          part.items[noSubpart].items.push(subpart);
                          part.items.splice(sp,1);
                          sp--;
                          noSubpart--;
                        } else {
                          subpart.parent = part;
                          for(var spi = 0; spi < subpart.items.length; spi++){
                            if(!(subpart.items[spi].isQuestion || subpart.items[spi].isPassage)){
                              subpart.items[spi].parent = part;
                              subpart.items[spi].nestedSubPart = subpart;
                              subpart.items[spi].fkSubPart = subpart.id;

                              part.items.splice(sp+extraSubparts,0,subpart.items[spi]);
                              subpart.items.splice(spi,1);

                              extraSubparts++;
                              spi--;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  $scope.collapseFormat = function(product){
    console.log('Collapse');

    var subparts = [];
    if(product.items){
      for(var c = 0; c < product.items.length; c++){
        var chapter = product.items[c];
        if(chapter.items){
          for(var t = 0; t < chapter.items.length; t++){
            var topic = chapter.items[t];
            if(topic.items){
              if(topic.name == '(no topic)' || topic.name == '(no section)' || topic.name == '(no subpart)' ){
                for(var i = topic.items.length-1; i >= 0; i--){
                  topic.items[i].parent = chapter;
                  chapter.items.splice(t+1,0,topic.items[i]);
                }
                chapter.items.splice(t,1);
                t--;
              }
              else {
                for(var p = 0; p < topic.items.length; p++){
                  var part = topic.items[p];
                  if(part.items){
                    if(part.name == '(no topic)' || part.name == '(no section)' || part.name == '(no subpart)'){
                      for(var i = part.items.length-1; i >= 0; i--){
                        part.items[i].parent = topic;
                        topic.items.splice(p+1,0,part.items[i]);
                      }
                      topic.items.splice(p,1);
                      p--;
                    }
                    else {
                      for(var sp = 0; sp < part.items.length; sp++){
                        var subpart = part.items[sp];
                        if(subpart.items){
                          if(subpart.name == '(no topic)' || subpart.name == '(no section)' || subpart.name == '(no subpart)'){
                            for(var i = subpart.items.length-1; i >= 0; i--){
                              subpart.items[i].parent = part;
                              part.items.splice(sp+1,0,subpart.items[i]);
                            }
                            part.items.splice(sp,1);
                            sp--;
                          } else {
                            subparts.push(subpart);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    for(var i = 0; i < subparts.length; i++){
      if(subparts[i].fkSubPart){
        var pindex = subparts[i].parent.items.findIndex(function(item){
          return item.id == subparts[i].id;
        });

        var index = subparts.findIndex(function(item){
          return item.id == subparts[i].fkSubPart;
        });

        if(pindex >= 0 && index >= 0 && !subparts[i].nestedSubPart){
          subparts[i].nestedSubPart = subparts[index];
        }

        if(pindex >= 0 && subparts[i].nestedSubPart){
          var fqi = subparts[i].nestedSubPart.items.findIndex(function(item){
            return item.isQuestion || item.isPassage;
          })
          subparts[i].nestedSubPart.items.splice(fqi,0,subparts[i]);
          subparts[i].parent.items.splice(pindex,1);
        }
      }
    }
  }

  $scope.addTags = function(item){
    bootbox.prompt('Add tags to all questions in '+item.name+', seperate tags with a comma.',function(value){
      if(value){
        $scope.setTags(item,value.split(','));
      }
    });
  }
  $scope.getIds = function(item,type){
    var ids = [];
    if(item.isPassage && type=='P'){
      ids.push(item.items[i].poolId);
    }

    for(var i = 0; i < item.items.length; i++){
      if(item.items[i].isQuestion && type=='Q'){
        ids.push(item.items[i].idQuestion);
      } else if(item.items[i].isPassage && type=='P'){
        ids.push(item.items[i].poolId);
      } else if(item.items[i].items){
        ids = ids.concat(ids,$scope.getIds(item.items[i],type));
      }
    }
    return ids;
  }
  $scope.setTags = function(item,tags){
    var questionIds = $scope.getIds(item,'Q');
    var passageIds = $scope.getIds(item,'P');

    $http.put('/api/question/tags?userDB='+($scope.personal ? true : false), {questionIds:questionIds, tags:tags})
    .then(function(res){
      alertify.success('tags added');
    })
    .catch(function(err){
      alertify.error('An error occured please try again later.');
    })

    $http.put('/api/passage/tags?userDB='+($scope.personal ? true : false), {passageIds:passageIds, tags:tags})
    .then(function(res){
      alertify.success('tags added');
    })
    .catch(function(err){
      alertify.error('An error occured please try again later.');
    })
  }

  $scope.libOptions = function(){
    alertify.infoDialog || alertify.dialog('infoDialog',function factory(){
      return{
        main:function(message){
          this.message = message;
        },
        setup:function(){
            return {
              options:{
                maximizable:false,
                resizable:false,
                padding:'2px',
                closableByDimmer:false,
                transition: 'slide',
              },
              buttons:[
                {
                  text: 'OK',
                  className: 'btn btn-primary',
                },
              ],
            };
        },
        prepare:function(){
          var template = $templateCache.get('infoDialog');
          this.setContent(template);
          this.setHeader('Library Information');
        },
        hooks:{
          // triggered when the dialog is shown, this is seperate from user defined onshow
          onshow: function(){
            var template = angular.element(document.querySelector('.ajs-modal'));
            $compile(template)($scope);
          },
        },
        callback:function(e){
          alertify.infoDialog().destroy();

          $scope.refresh();

          var product = $scope.qbv.questionbank;
          product.modified = Date.now()/1000;
          var data = angular.copy(product);
          delete data.items;
          delete data.standards;

          $http.put('/api/database/info'+'?userDB='+($scope.personal ? true : false), data).then(function(res){
            alertify.success('Library Information Updated');
            $scope.refresh();
          }).catch(function(err){
            $http.put('/api/log/add',{
              action:'update QB Info',
              tags:'editor,error,'+($scope.inhouse?'inhouse':'enduser'),
              meta:JSON.stringify({
                profile: $rootScope.profile,
                error: JSON.stringify(err,Object.getOwnPropertyNames(err),2),
                product: product.name,
              },null,2),
            });

            alertify.error('Library Information Update Failed');
            $scope.refresh();
          });
          //The closeEvent has the following properties
          //
          // index: The index of the button triggering the event.
          // button: The button definition object.
          // cancel: When set true, prevent the dialog from closing.
       },
    }});
    alertify.infoDialog('');
  }

  $scope.libContent = function () {
    alertify.breakdownDialog || alertify.dialog('breakdownDialog', function factory() {
      return {
        main: function (message) {
          this.message = message;
        },
        setup: function () {
          return {
            options: {
              maximizable: false,
              resizable: false,
              padding: '2px',
              closableByDimmer: false,
              transition: 'slide',
            },
            buttons: [
              {
                text: 'OK',
                className: 'btn btn-primary',
              },
            ],
          };
        },
        prepare: function () {
          var template = $templateCache.get('breakdownDialog');
          this.setContent(template);
          this.setHeader('Library Content');
        },
        hooks: {
          // triggered when the dialog is shown, this is seperate from user defined onshow
          onshow: function () {
            var template = angular.element(document.querySelector('.ajs-modal'));
            $compile(template)($scope);
          },
        },
        callback: function (e) {
          alertify.breakdownDialog().destroy();
        },
      }
    });
    alertify.breakdownDialog('');
  }

  $scope.uneditable = function(){
    var archived = false;
    if($scope.qbv.questionbank){
      archived = $scope.qbv.questionbank.archive == 0;
    }
    return $scope.import || archived;
  }

  $scope.hasSubItems = function(array){
    var flag=false;
    if(array){
      for(var i = 0; i < array.length; i++){
        if(!array.isPassage && !array.isQuestion){
          flag = true;
        }
      }
    }
    return flag;
  }

  $scope.sandrDialog = function(){
    // var form = $(document.getElementById('srform').cloneNode(true));
    var form = $($templateCache.get('srform'));


    bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        var options = {
          passages: $scope.qbv.sr.passages,
          questions: $scope.qbv.sr.questions,
          onlyHTML: $scope.qbv.sr.onlyHTML,
          ignoreHTML: $scope.qbv.sr.ignoreHTML,
          searchString: form.find('#stext').val(),
          replaceString: form.find('#rtext').val(),
        }
        if($scope.qbv.sr.inQB && $scope.qbv.selectedDB.length > 0){
          options.idProduct = $scope.qbv.selectedDB;
        }
        if(options.idProduct && $scope.qbv.sr.chapter){
          options.idChapter = $scope.qbv.sr.chapter.id;
        }
        if(options.idChapter && $scope.qbv.sr.section){
          options.idTopic = $scope.qbv.sr.section.id;
        }
        if(options.idTopic && $scope.qbv.sr.topic){
          options.idPart = $scope.qbv.sr.topic.id;
        }
        if(options.idPart && $scope.qbv.sr.part){
          options.idSubPart = $scope.qbv.sr.part.id;
        }

        $scope.sandr(options);
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn-primary',
    }],{
      // prompts need a few extra options
      "header"  : 'Search and Replace',
    });

    $scope.refresh(function(){
      setTimeout(function(){
        var template = angular.element(form);
        $compile(template)($scope);
      },100);
    })
  }

  $scope.sandr = function(options){
    var questions = [];
    var passages = [];
    var editedQuestions = [];
    var editedPassages = [];
    var editedResponses = [];
    var sstr = options.searchString;
    var sqlstr = options.searchString;
    var querystrings = [];
    var firstIndex = -1;

    sstr = sstr.replace(/[\(,\),\^,\+,\[,\],\{,\},\?,\|,\*,\\,\/]/g,'\\$&');
    sqlstr = sqlstr.replace(/[\(,\),\^,\+,\[,\],\{,\},\?,\|,\*,\\,\/]/g,'\\$&');

    function srConfirm(str,cb,header,regex){
      console.log($(str.usage));
      var form = $('<div><h4></h4><pre></pre></div>');
      if(header){
        form.find('h4').text(header);
      }
      if(str.usage){
        form.find('pre').text(str.text);
        $(str.usage).insertAfter(form.find('pre'))
      } else {
        form.find('pre').text(str);
      }
      
      if(regex){
        sstr.lastIndex = 0;
        $(form.find('pre')).markRegExp(regex);
      }

      bootbox.dialog(form, [{
        'label' : 'Replace all',
        'class' : 'btn-primary',
        'callback': function() {
          cb(true,true)
        }
      }, {
        'label' : 'Replace',
        'class' : 'btn-primary',
        'callback': function() {
          cb(true,false)
        }
      }, {
        'label' : 'Skip',
        'class' : 'btn-primary',
        'callback': function() {
          cb(false,false)
        }
      }],{
        "header"  : 'Search and Replace',
      });
    }

    function recursiveCheck(a,i,r,auto){
      function next(raaah){
        if(a == questions && r < a[i].responses.length){
          recursiveCheck(a,i,r+1,raaah || auto);
        } else if(i+1 < a.length){
          recursiveCheck(a,i+1,0,raaah || auto);
        } else {
          if(a == questions && passages.length > 0){
              recursiveCheck(passages,0,0,raaah || auto);
          } else {
            var overlay = document.createElement('div');
              overlay.style.position='fixed';
              overlay.style.top='0';
              overlay.style.left='0';
              overlay.style.right='0';
              overlay.style.bottom='0';
              overlay.style.backgroundColor='rgba(0,0,0,.1)';
            var msg = document.createElement('div');
              msg.style.position='fixed';
              msg.style.top='40%';
              msg.style.left='40%';
              msg.style.right='40%';
              msg.style.textAlign='center';
              msg.style.backgroundColor='white';
              msg.style.border='solid 1px lightgrey';
              msg.style.borderRadius='6px';
              msg.style.padding='4px';
            var info = document.createElement('p');
              info.innerHTML ='Saving';
            var spinner = document.createElement('div');
              spinner.className = 'loader';
            msg.appendChild(info);
            msg.appendChild(spinner);

            overlay.appendChild(msg);
            document.querySelector('body').appendChild(overlay);

            var promises = [];

            var meta = {
              searched:options.searchString,
              replaced:options.replaceString,
              passages:[],
              questions:[],
            };

            if(editedPassages.length > 0){
              promises.push($http.put('/api/passage',editedPassages));
              meta.passages = editedPassages.map(function(p){
                return p.idPassage;
              })
            }
            if(editedQuestions.length > 0){
              promises.push($http.put('/api/question',editedQuestions));
              meta.questions = editedQuestions.map(function(q){
                return q.idQuestion;
              })
            }
            if(editedResponses.length > 0){
              promises.push($http.put('/api/response',editedResponses));
              for(var x = 0; x < editedResponses.length; x++){
                if(meta.questions.indexOf(editedResponses[x].fkQuestion) == -1){
                  meta.questions.push(editedResponses[x].fkQuestion);
                }
              }
            }

            $http.put('/api/log/add',{
              action:'search and replace',
              tags:'',
              meta:JSON.stringify(meta,null,2),
            });

            Promise.all(promises).then(function(val){
              document.querySelector('body').removeChild(overlay);
            })
          }
        }
      }

      new Promise(function(resolve,reject){
        if(a == questions && r == 0){
          sstr.lastIndex = 0;
          if(sstr.test(a[i].prompt)){
            sstr.lastIndex = 0;
            var tmp = sstr.exec(a[i].prompt);
            var str = a[i].prompt;
            if(!auto){
              $scope.getQuestionUsageData(a[i]).then(function(usage){
                srConfirm({ text: str, usage: '<br><div><h4>Usage:</h4>' + usage.join('<br><br>') + '<div>' }, function (res, doall) {
                  if (res) {
                    a[i].prompt = a[i].prompt.replace(sstr, options.replaceString);
                    editedQuestions.push(a[i]);
                  }
                  resolve(doall);
                }, 'Question - prompt: \n' + options.searchString + '\n' + options.replaceString, sstr);
              });              
            } else {
              a[i].prompt = a[i].prompt.replace(sstr,options.replaceString);
              editedQuestions.push(a[i]);
              resolve();
            }
          } else {
            resolve();
          }
        }else if(a == questions){
          sstr.lastIndex = 0;
          if(sstr.test(a[i].responses[r-1].answer)){
            sstr.lastIndex = 0;
            var tmp = sstr.exec(a[i].responses[r-1].answer);
            var str = a[i].responses[r-1].answer;
            if(!auto){
              $scope.getQuestionUsageData(a[i]).then(function (usage) {
                srConfirm({ text: str, usage: '<br><div><b>Usage:</b><br><br>' + usage.join('<br><br>') + '<div>' }, function (res, doall) {
                  if(res){
                    a[i].responses[r-1].answer = a[i].responses[r-1].answer.replace(sstr,options.replaceString);
                    editedResponses.push(a[i].responses[r-1]);
                  }
                  resolve(doall);
                }, 'Question - answer: \n'+options.searchString+'\n'+options.replaceString, sstr);
              });
            } else {
              a[i].responses[r-1].answer = a[i].responses[r-1].answer.replace(sstr,options.replaceString);
              editedResponses.push(a[i].responses[r-1]);
              resolve();
            }
          } else {
            resolve();
          }
        } else if(a == passages){
          sstr.lastIndex = 0;
          if(sstr.test(a[i].content)){
            sstr.lastIndex = 0;
            var tmp = sstr.exec(a[i].content);
            var str = a[i].content;
            if (!auto) {
              $scope.getPassageUsageData(a[i]).then(function (usage) {
                srConfirm({ text: str, usage: '<br><div><b>Usage:</b><br><br>' + usage.join('<br><br>') + '<div>' }, function (res, doall) {
                  if(res){
                    a[i].content = a[i].content.replace(sstr,options.replaceString);
                    editedPassages.push(a[i]);
                  }
                  resolve(doall);
                }, 'Passage:\n'+options.searchString+'\n'+options.replaceString, sstr);
              });
            } else {
              a[i].content = a[i].content.replace(sstr,options.replaceString);
              editedPassages.push(a[i]);
              resolve();
            }
          } else {
            resolve();
          }
        } else {
          resolve(false);
        }
      }).then(function(res){
        next(res);
      });
    }


    // for(var i = 0; i < sqlstr.length; i++){
    //   if(sqlstr.charAt(i) === '"'){
    //     if(firstIndex >= 0){
    //       querystrings.push(sqlstr.slice(firstIndex,i));
    //       sqlstr = sqlstr.slice(0,firstIndex-1) + sqlstr.slice(i+1);
    //       firstIndex = -1;
    //       i = 0;
    //     } else {
    //       firstIndex = i+1;
    //     }
    //   }
    // }
    // if(sqlstr.length > 0){
    //   var splits = sqlstr.split(' ');
    //   for(var i = 0; i < splits.length; i++){
    //     if(splits[i].length > 0){
    //       querystrings.push(splits[i]);
    //     }
    //   }
    // }
    // sqlstr = querystrings.join('|');
    options.searchString = sqlstr;

    // querystrings = [];
    // firstIndex = -1;
    // for(var i = 0; i < sstr.length; i++){
    //   if(sstr.charAt(i) === '"'){
    //     if(firstIndex >= 0){
    //       querystrings.push(sstr.slice(firstIndex,i));
    //       sstr = sstr.slice(0,firstIndex-1) + sstr.slice(i+1);
    //       firstIndex = -1;
    //       i = 0;
    //     } else {
    //       firstIndex = i+1;
    //     }
    //   }
    // }
    // if(sstr.length > 0){
    //   var splits = sstr.split(' ');
    //   for(var i = 0; i < splits.length; i++){
    //     if(splits[i].length > 0){
    //       querystrings.push(splits[i]);
    //     }
    //   }
    // }
    // sstr = new RegExp('('+querystrings.join('|')+')','g');

    if(options.ignoreHTML){
      sstr = '(?<!<\\w+[^>]*)'+sstr;
    } else if(options.onlyHTML){
      sstr = '(?<=<\\w+[^>]*)'+sstr;
    }

    sstr = new RegExp(sstr,'g');
    var overlay = document.createElement('div');
      overlay.style.position='fixed';
      overlay.style.top='0';
      overlay.style.left='0';
      overlay.style.right='0';
      overlay.style.bottom='0';
      overlay.style.backgroundColor='rgba(0,0,0,.1)';
    var msg = document.createElement('div');
      msg.style.position='fixed';
      msg.style.top='40%';
      msg.style.left='40%';
      msg.style.right='40%';
      msg.style.textAlign='center';
      msg.style.backgroundColor='white';
      msg.style.border='solid 1px lightgrey';
      msg.style.borderRadius='6px';
      msg.style.padding='4px';
    var info = document.createElement('p');
      info.innerHTML ='Searching';
    var spinner = document.createElement('div');
      spinner.className = 'loader';
    msg.appendChild(info);
    msg.appendChild(spinner);

    overlay.appendChild(msg);
    document.querySelector('body').appendChild(overlay);

    var qp;
    if(options.questions){
      var url = options.idProduct ? '/api/search/questions' : '/api/search/questions/all';
      qp = $http.put(url+'?nc='+Date.now(),options);
    } else {
      qp = Promise.resolve({data:[]});
    }
    qp.then(function(res){
      questions = res.data.filter(function(item){
        var flag = false;
        sstr.lastIndex = 0;
        flag = sstr.test(item.prompt);
        for(var x = 0; x < item.responses.length; x++){
          sstr.lastIndex = 0;
          if(sstr.test(item.responses[x].answer)){
            flag = true;
          }
        }
        return flag;
      });
      if(options.passages){
        var url = options.idProduct ? '/api/search/passages' : '/api/search/passages/all';
        return $http.put(url+'?nc='+Date.now(),options);
      } else {
        return Promise.resolve({data:[]});
      }
    }).then(function(res){
      passages = res.data.filter(function(item){
        sstr.lastIndex = 0;
        return sstr.test(item.content);;
      });
      document.querySelector('body').removeChild(overlay);
      if((questions.length + passages.length) > 0){
        var form = $('<div><p></p></div>');
        form.find('p').text((questions.length + passages.length)+ ' Items found');
        bootbox.dialog(form, [{
          'label' : 'Replace all',
          'class' : 'btn-primary',
          'callback': function() {
            if(questions.length > 0){
              recursiveCheck(questions,0,0,true);
            } else if(passages.length > 0){
              recursiveCheck(passages,0,0,true);
            }
          }
        }, {
          'label' : 'Review',
          'class' : 'btn-primary',
          'callback': function() {
            if(questions.length > 0){
              recursiveCheck(questions,0,0,false);
            } else if(passages.length > 0){
              recursiveCheck(passages,0,0,false);
            }
          }
        }, {
          'label' : 'Cancel',
          'class' : 'btn-primary',
        }],{
          "header"  : 'Search and Replace',
        });
      } else {
        alertify.error('No items found');
      }
    }).catch(function(err){
      document.querySelector('body').removeChild(overlay);
      $http.put('/api/log/add',{
        action:'search and replace',
        tags:'error',
        meta:JSON.stringify({
          profile: $rootScope.profile,
          error: JSON.stringify(err,Object.getOwnPropertyNames(err),2),
          options: options,
        },null,2),
      });
      alertify.error('An error occured while executing Search and Replace');
    })
  }

  $scope.getAllStandards();
});
