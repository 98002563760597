angular.module('examgen.service').
/**
 * @ngdoc service
 * @name examgen.service:session
 * @description Returns the user's profile object.
 */
service('session', function($http, $q, $rootScope, $injector, $window) {
  var deferred =  $q.defer();

  var session =  {
    refresh: function() {
      deferred =  $q.defer();
      $http.get('/session').then(function(response){
        if(!response.data){
          $rootScope.loginRequired = true;        //this will open the login dlg
          deferred.reject(new Error('authentication required'));
        }else{
          if(!response.data.UserId){
            window.location.href = '/logout?origin='+$window.location.href;
          }
          else {
            deferred.resolve(response.data);
          }
        }
      });
      session.ready = deferred.promise;
      session.profile = deferred.promise;
      return session.profile;
    },

    /** returns a promise that's fullfiled when the authentication succeeds **/
    ready: deferred.promise,

    /** ready + exams.ready **/
    onLogin: function() {
      return $q.all([
        session.ready,
        $injector.get('exams').ready,
        $injector.get('couch').db
      ])
      .then(function(ret) {
        return {
          profile: ret[0],
          exams: ret[1],
          db: ret[2]
        }
      });
    },

    onProfile: function() {
      return session.onLogin().then(function(result) {
        return result.profile;
      })
    },

    getBillboardUrl: function() {
      return session.onProfile().then(function(profile) {
        var getDb = function (idx) {
          var database = profile.Databases ? profile.Databases[idx] : '';
          return (database.name||'').replace(/\s+/g, '');
        };

        var db = getDb(0);
        console.log('database is ' + db);

        if (!db) {
          return $q.reject();
        }

        // create the billboard url
        return 'http://www.examgenonline.com/' + db + '.aspx';
      });
    }
  };

  return session;
});
