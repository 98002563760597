angular.module('examgen.directive').
/**
 * @ngdoc directive
 * @name examgen.directive:tocTopic
 * @description Represents the topic in the table of contents
 */
directive('tocTopic',function(tabIndex){
  return function(scope,el,attr){
    var topic = scope.topic;

    // prevent from dragging while questions are loading
    scope.$watch('loading', function(isLoading) {
      el.attr('draggable', !isLoading);
    });

    tabIndex.topic(topic).then(function(index){
      //console.log('topic index',index);
      el.attr('tabindex',index); 
    });
  };
});