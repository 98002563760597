angular.module('examgen.service').

service('ajaxTransformer', function($http, $q, bugsnag) {
  return {
    create: function(url) {
      return function (value, headers) {
        var original = $http.defaults.transformResponse[0];
        try {
          return original(value, headers);
        } catch (e) {
          if (e instanceof SyntaxError) {
            var message = 'JSON Error: ' + url + ' (' + e.message + ')';
            bugsnag.notify(message);
            return $q.reject(e);
          }
        }
      }
    }
  };
}).
/**
 * @ngdoc service
 * @name examgen.service:lazyLoad
 * @description This service lazily loads question bank content: database, passage, standard, chapter, topic, section, item
 */
service('lazyLoad',function($http, $q, databases, bugsnag, ajaxTransformer){

  var sorter = function(key) {
    return function(a, b) {
      var ak = a[key] && a[key].toLowerCase ? a[key].toLowerCase() : false;
      var bk = b[key] && b[key].toLowerCase ? b[key].toLowerCase() : false;
      return ak < bk ? -1 : (ak > bk ? 1 : 0);
    }
  };

  var sort = function(key, data) {
    data.sort(sorter(key));
    return data;
  };

  var $get = function(url) {
    var good = function(res) {
      return res;
    };

    var bad = function(err) {
      return $q.reject(err);
    };

    // attempt to log any json errors

    return $http.get(url, {transformResponse: ajaxTransformer.create(url)}).then(good, bad);
  };

  var nocache = function(){
    return '?nc='+Date.now();
  }

  var resolveItem = function(item){
    if(item.origin){
      item.databaseName = item.origin.split('/')[0];
    } else if(item.part){
      item.databaseName = item.part.section.topic.chapter.database.databaseName;
      item.databaseId = item.part.section.topic.chapter.database.databaseId;
    } else {
      item.databaseName = '';
    }
    item.itemContent.then(function(itemContent){
      item.resolvedItemContent = itemContent;
      if(item.resolvedItemContent.responses){
        item.resolvedItemContent.responses.sort(function(a,b){return (a.sortOrder-b.sortOrder)});
      }

      //lazy-laod associated passage
      if(itemContent.passageId && !itemContent.passage){
        itemContent.passage = lazyLoad.loadPassageFromId(itemContent.passageId,item);
        itemContent.passage.then(function(passage) {
          itemContent.resolvedPassage = passage;
        });
      }

      item.resolvedStandards = null;
      var defer = $q.defer();
      item.standards = defer.promise;
      // fetch the db and then loop through all the standards
      // if this question's url is in the standards then
      // keep a list of all standards ids
      databases.all.then(function(dbs){
        //first look up his database
        return dbs.filter(function(db){
          return db.databaseName === item.databaseName;
        })[0];
      }).then(function(db) {
        if(db){
          if(db.standards){
            return db;
          } else {
            return lazyLoad.database(db);
          }
        } else {
          //console.log('db not found',item.databaseName);
          return undefined
        }
      }).then(function(db) {
        var output = [];
        if(db){
          if(db.standards){
            db.standards.then(function(standards) {
              for(var s = 0;s < standards.length; s++){
                for(var sn = 0;sn < itemContent.standardnames.length; sn++){
                  if (standards[s].standardId === itemContent.standardnames[sn].id) {
                    output.push(standards[s]);
                  }
                }
              }
            });
          }
        }

        if (itemContent.passage) {
          itemContent.passage.then(function() {
            item.loadedDefer.resolve(item);
          });
        } else {
          item.loadedDefer.resolve(item);
        }
        item.resolvedStandards = output;
        defer.resolve(output);
      });
    }, function(err) {
      item.loadedDefer.reject(err);
    });
  }

  //TODO: add error handling
  var lazyLoad = {
    database : function(database){
      if(database.chapters === null || database.refresh){
        database.chapters = $get('/api/chapters/' + database.databaseId+nocache()+'&userDB='+(database.isUserDB ? true : false)).then(function(response){
          database.refresh = false;
          database.resolvedChapters = response.data.map(function(chapter){
            return {chapterName : chapter.name, chapterNumber : chapter.sortOrder, chapterId : chapter.idChapter, isUserDB:database.isUserDB, meta:chapter.meta, topics : null, database : database};
          });
          return database.resolvedChapters
        });
      }

      if(database.passages === null || database.refresh){
        database.passages = $get('/api/passegelist/' + database.databaseId+nocache()+'&userDB='+(database.isUserDB ? true : false)).then(function(response){
          database.refresh = false;
          database.resolvedPassages = response.data.map(function(passage){
            return {passageName : passage.name, passageId : passage.idPassage, passageRefId: passage.idProductPassages, isUserDB:database.isUserDB, database : database, passageHTML : null};
          });
          return database.resolvedPassages;
        });
      }

      if(database.standards === null || database.refresh){
        database.standards = $get('/api/standardlist/' + database.databaseId+nocache()+'&userDB='+(database.isUserDB ? true : false)).then(function(response){
          database.refresh = false;
          return $q.all(response.data.map(function(standard){
            return lazyLoad.standard({standardName : standard.name, standardNumber:standard.sortOrder, standardId : standard.idStandard, description:standard.description, isUserDB:database.isUserDB, database : database, standardData : null});
          })).then(function(ret){
            var allStandardData = {};

            var p = [];
            ret.map(function(standard) {
              standard.standardData = standard.standardData.then(function(e) {
                return e;
              }, function() {
                return $q.when([]);
              });

              p.push(standard.standardData);
              standard.standardData.then(function(data) {
                if (data.items) {
                  allStandardData[standard.standardName] = angular.extend({standardName: standard.standardName, standardId: standard.idStandard}, data);
                }
              });
            });

            database.allStandardData = allStandardData;
            // wait until all the standard data have been loaded
            return $q.all(p).then(function(){
              return ret;
            });
          });
        }, function() {
          return $q.when([]);
        });
      }

      if(false){
        if (database.itemRefs === null) {
          database.itemRefs = $http.get('/databases/' + database.databaseName + '/GuidCref.json?v=' + database.version).then(function(response) {
            var ids = {},
                urls = {},
                total = 0;
            (response.data||[]).forEach(function(t){
              ++total;
              ids[t.itemID] = t.itemHref;
              urls[t.itemHref] = t.itemID;
            });
            return {ids: ids, urls: urls, total: total, failed: total < 1};
          }, function() {
            return {failed: true, ids: {}, urls: {}};
          });
        }
      }

      return $q.all(database.chapters, database.passages, database.standards/*, database.itemRefs*/).then(function(){
        return database;
      });
    },
    passage : function(passage){
      if(!passage.passageHTML){
        passage.passageHTML = $get('/api/passage/' + passage.passageId+nocache()+'&userDB='+(passage.isUserDB ? true : false)).then(function(response){
          var passageHtml = response.data;
          passage.resolvedPassageHTML = passageHtml;
          return passageHtml;
        });
      }

      return passage;
    },
    standard : function(standard){
      if(standard.standardData === null){
        standard.standardData = $http.get('/api/standard/' + standard.standardId+nocache()+'&userDB='+(standard.isUserDB ? true : false)).then(function(response){
          standard.resolvedStandardData = response.data;
          return response.data;
          //TODO: maybe fetch the items associated with the standard preemptively
        }, function(err) {
          if (err.status === 404) {
            var message = 'standards.json tried unknown standard: ';
            message += standard.standardName + ' ';
            message += '(Db: ' + standard.database.databaseName + ')';
            bugsnag.notify(message);
          }
          return $q.when([]);
        });
      }

      return standard;
    },
    chapter : function(chapter){
      if(chapter.topics === null || chapter.refresh){
        chapter.topics = $get('/api/topics/' + chapter.chapterId+nocache()+'&userDB='+(chapter.isUserDB ? true : false)).then(function(response){
          chapter.refresh = false;
          chapter.resolvedTopics = (response.data||[]).map(function(topic){
            return {topicName : topic.name, topicNumber:topic.sortOrder, topicId : topic.idTopic, isUserDB:chapter.isUserDB, meta:topic.meta, sections : null, chapter : chapter};
          });
          return chapter.resolvedTopics;
        });
      }

      return chapter.topics;
    },
    topic : function(topic){
      if(topic.sections === null || topic.refresh){
        topic.sections = $get('/api/sections/' + topic.topicId+nocache()+'&userDB='+(topic.isUserDB ? true : false)).then(function(response){
          topic.refresh = false;
          topic.resolvedSections = (response.data||[]).map(function(section){
            return {sectionName : section.name, sectionNumber:section.sortOrder, sectionId : section.idPart, isUserDB:topic.isUserDB, meta:section.meta, parts : null, topic : topic};
          });
          return topic.resolvedSections;
        });
      }

      return topic.sections;
    },
    section : function(section){
      if(section.parts === null || section.refresh){
        section.parts = $get('/api/subsections/'+section.sectionId+nocache()+'&userDB='+(section.isUserDB ? true : false)).then(function(response){
          section.refresh = false;
          section.resolvedParts = (response.data||[]).map(function(part){
            return {partName : part.name, partNumber: part.sortOrder, partId: part.idSubPart, isUserDB:section.isUserDB, meta:part.meta, items : null, section : section};
          });
          return section.resolvedParts;
        })
      }

      return section.parts;
    },
    part : function(part){
      if(part.items === null || part.refresh){
        part.items = $get('/api/items/'+part.partId+nocache()+'&userDB='+(part.isUserDB ? true : false)).then(function(response){
          part.refresh = false;
          part.resolvedItems = (response.data||[]).map(function(item){
            return {itemName : item.name || item.sortOrder+'', itemNumber: item.sortOrder, itemId: item.idQuestion, refId:item.idSubPartQuestions, userNote:item.origin, isUserDB:part.isUserDB, productPassageId:item.fkProductPassage, itemContent : null, part : part};
          });
          return part.resolvedItems;
        });
      }
      return part.items;
    },


    item : function(item){
      if(!item.itemId){
        item.itemId = item.itemID;
      }
      if(item.itemContent === null || item.itemContent === undefined) {
        item.URL = '/api/question/'+item.itemId+nocache()+'&userDB='+(item.isUserDB ? true : false);

        item.itemContent = $get(item.URL).then(function(response){
          return response.data;
        });

        if (!item.loadedDefer) {
          item.loadedDefer = $q.defer();
          item.loaded = item.loadedDefer.promise;
        }

        resolveItem(item);
      }

      return item;
    },
    lotsOfItems : function(items){
      //this function is for loading up large groups of items in a way that will not cause the browser to appear to hang
      var r = $q.defer();

      items.forEach(function(item) {
        if (!item.loadedDefer) {
          item.loadedDefer = $q.defer();
          item.loaded = item.loadedDefer.promise;
        }
      });

      async.eachLimit(items, 25,
        function(item,cb){
          lazyLoad.item(item);
          item.itemContent.then(function(itemContent){
            cb(null,itemContent);   //TODO: handle error
          }, function(err) {
            cb(err);
          });
        }, function(err) {
          if (err) {
            r.reject(err);
          } else {
            r.resolve(items);
          }
        });

      return r.promise;
    },
    itemList: function(items){

      var d = $q.defer();
      var userIDs = [], publicIDs = [];

      items.forEach(function(item) {
        if (item.loadedDefer === null || item.loadedDefer === undefined) {
          item.loadedDefer = $q.defer();
          item.loaded = item.loadedDefer.promise;
        }
        if(item.itemId === null || item.itemId === undefined){
          item.itemId = item.itemID;
        }
        if(item.itemContent === null || item.itemContent === undefined){
          item.URL = '/api/question/'+item.itemId+nocache()+'&userDB='+(item.isUserDB ? true : false);
          if(item.isUserDB){
            userIDs.push(item.itemId);
          } else {
            publicIDs.push(item.itemId);
          }
        }
      });

      if(publicIDs.length > 0 || userIDs.length > 0){
        var publicPromise;
        if(publicIDs.length > 0){
          publicPromise = $http.put('/api/question/list'+nocache()+'&userDB=false',{questionIds:publicIDs});
        } else {
          publicPromise = Promise.resolve({data:[]});
        }

        publicPromise.then(function(res){
          if(res.data.length > 0){
            for(var i = 0; i < items.length; i++){
              for(var r = 0; r < res.data.length; r++){
                //console.log(items[i].itemId+ ' = '+ res.data[r].itemCode);
                if(items[i].itemId == res.data[r].itemCode){
                  items[i].itemContent = Promise.resolve(res.data[r]);
                  resolveItem(items[i]);
                }
              }
            }
          }
          return userIDs.length > 0 ? $http.put('/api/question/list'+nocache()+'&userDB=true',{questionIds:userIDs}) : {data:[]};
        }).then(function(res){
          if(res.data.length > 0){
            for(var i = 0; i < items.length; i++){
              for(var r = 0; r < res.data.length; r++){
                //console.log(items[i].itemId+ ' = '+ res.data[r].itemCode);
                if(items[i].itemId == res.data[r].itemCode){
                  items[i].itemContent = Promise.resolve(res.data[r]);
                  resolveItem(items[i]);
                }
              }
            }
          }
          d.resolve(items);
        }).catch(function(err){
          d.reject(err);
        });
      } else {
        d.resolve(items);
      }
      return d.promise;
    },
    loadPassageFromId : function(passageId, item){
      return databases.all.then(function(dbs){
        //first look up his database
        return dbs.filter(function(db){
          return db.databaseName === item.databaseName;
        })[0];
      }).then(function(itemDb) {
        if(itemDb){
          if(itemDb.passages){
            return itemDb;
          } else {
            return lazyLoad.database(itemDb);
          }
        }
      }).then(function(itemDb){
        //from the database, find the right passage
        if(itemDb){
          return itemDb.passages.then(function(passages){
            return passages.filter(function(passage){
              return passage.passageId === passageId;
            })[0];
          });
        } else {
          return {passageName : passageId, passageId : passageId, isUserDB:item.isUserDB, database : [], passageHTML : null};
        }
      }).then(function(passage){
        //then lazy-load the passage
        if(passage){
          return lazyLoad.passage(passage);
        } else {
          console.error('failed to find passage :'+passageId);
        }
      }).catch(function(e){
        console.error('failed to find passage :'+passageId,e);
      });
    }
  };

  return lazyLoad;
});
