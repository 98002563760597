angular.module('examgen.directive').
/**
 * @ngdoc directive
 * @name examgen.directive:downloadModal
 * @description Encapuslates the modal dialog box for downloading HTML or PDF attachments
 */
directive('downloadModal',function($http){

  var o = {
    restrict : 'A',
    templateUrl : 'app/partials/modals/exams-print-download.html',
    replace : true,
    scope : {
      examToPrint : '=',
      showModal : '=',
      onDone : '=',
      onBack : '=',
      hideBackButton : '=',
      hideCancelButton : '='
    },
    link: function(scope, elm, attr, ngModel) {
      scope.showPreviewDialog = function(exam,index,isAnswerKey) {

        var my_window = window.open('', 'print_'+Date.now(),'width=1060,height=730,modal=yes');
        my_window.document.write('<!doctype html>');
        my_window.document.write('<html><head>');
        my_window.document.write('<link href="https://netdna.bootstrapcdn.com/twitter-bootstrap/2.3.2/css/bootstrap.no-responsive.no-icons.min.css" rel="stylesheet">');
        my_window.document.write('</head>');
        my_window.document.write('<body class="examPreview" onafterprint="self.close()">');//onafterprint="self.close()"
        my_window.document.write('</body></html>');
        my_window.document.close();

        $(my_window.document.querySelectorAll('body')).append(exam.html);
        $(my_window.document.querySelectorAll('img')).imagesLoaded()
        .always(function( instance ) {
          setTimeout(function () {
            if(window.navigator.userAgent.indexOf('Firefox') > -1){
              my_window.print();
            } else {
              my_window.document.execCommand('print', false, null);
            }
          }, 500);
        });
      };

      scope.previewModalBackClick = function(){
        scope.showModal = true;
        scope.showPreviewModal = false;
        scope.examToPreview = {href : ""};
      };

      scope.exportToRTF = function() {
        var iframe = $('#exam-preview-iframe')[0];
        $http({
          url : '/api/rtf',
          data : iframe.contentWindow.document.querySelector('body').innerHTML,
          method : 'PUT',
          headers : {
          "Content-Type" : "text/html"
          }
        }).then(function(val){
          if(val.data.rtf.length > 0){
            var hiddenElement = document.createElement('a');
            var blob = new Blob([val.data.rtf], {type: "text/plain"})
            var url = URL.createObjectURL(blob);
            hiddenElement.href = url;
            hiddenElement.target = '_blank';
            hiddenElement.download = 'exam_'+(moment().format('L LTS'))+'.rtf';
            hiddenElement.click();
          }
        })
      };

      scope.printPreview = function() {
        //reach into the iframe and do your thing
        var iframe = $('#exam-preview-iframe')[0];
        if(window.navigator.userAgent.indexOf('Firefox') > -1){
          iframe.contentWindow.print();
        } else {
          iframe.contentWindow.document.execCommand('print', false, null);
        }
        scope.previewModalBackClick(); //same as clicking cancel()
      };
    }
  };

  return o;
});
