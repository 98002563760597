angular.module('examgen.controller').
controller('HomeCtrl', function ($rootScope, $scope, profile, session, $state, $analytics, $rootScope, $timeout, $sanitize, $http) {

  $rootScope.inHome = true;
  $scope.$on('$destroy', function() {
    $rootScope.inHome = false;
  });


  $analytics.pageTrack('/');
  var vm = this;

  vm.newsfeed = [];
  vm.profile = profile;
  session.getBillboardUrl().then(function(url) {
    vm.billboard_url = url;
  });

  vm.createExam = function() {
    //vm.showCreateOpenModal = true;
    $rootScope.createExam();
  };
  vm.openExam = function() {
    $rootScope.$emit('openExamSwitcher');
  };
  vm.manageAssignments = function() {
    $timeout(function() {
      $state.go('manage/assignments');
    });
  };
  vm.manageStudents = function() {
    $timeout(function() {
      $state.go('manage/students');
    });
  };
  vm.gradeAssignments = function() {
    $timeout(function() {
      $state.go('grade/assignments');
    });
  };

  $http.get('/api/newsfeed').then(function(res){
    var posts = res.data;
    posts = posts.filter(function(item){ return item.timestamp < Date.now()});
    vm.newsfeed = posts;
  });
});
