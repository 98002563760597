angular.module('examgen.directive').
/**
* @ngdoc directive
* @name examgen.directive:emailEditor
* @description Encapuslates ckEditor rich text editor
*/
directive('emailEditor', function($rootScope, $timeout) {
  return {
    require: '?ngModel',
    link: function(scope, elm, attr, ngModel) {

      CKEDITOR.config.removeButtons = '';
      CKEDITOR.config.height = 400;
      CKEDITOR.config.toolbar = [
        [ 'Image','Table','HorizontalRule','Symbol','Source'],
        [ 'Link','Unlink','Anchor'],
        [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'],
        [ 'Undo', 'Redo' ],
        [ 'Find','Replace'],
        [ 'SpellChecker'],
        [ 'Bold', 'Italic', 'Underline', 'Strike',],
        [ 'Font'],
        [ 'FontSize'],
        [ 'TextColor', 'BGColor'],
        [ 'Subscript', 'Superscript',],
        [ 'RemoveFormat',],
        [ 'NumberedList','BulletedList',],
        [ 'Outdent','Indent'],
        [ 'Blockquote'],
        [ 'JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
      ];

      CKEDITOR.config.imageUploadUrl = '/api/image';

      CKEDITOR.config.autoGrow_maxHeight = 400;

      CKEDITOR.config.allowedContent = true;

      CKEDITOR.on('dialogDefinition', function(dialogDefinitionEvent) {
        var dialogDefinition = dialogDefinitionEvent.data.definition;
        dialogDefinition.dialog.on('cancel', function(cancelEvent) {
            return false;
        }, this, null, -1);
      });

      var ck = CKEDITOR.replace(elm[0]);

      if (!ngModel) return;

      ck.on('blur',function( e ){
         return false;
      });

      ck.on('pasteState', function() {
        $rootScope.$safeApply(function() {
          ngModel.$setViewValue(ck.getData());
        });
      });

      ck.on( 'fileUploadRequest', function( evt ) {
        evt.data.requestData.product = 'AdminUploads';
      });

      ngModel.$render = function(value) {
        if(ck) ck.setData(ngModel.$viewValue);
      };

      scope.$on('$destroy', function(){
        try {
          ck.destroy();
          ngModel.$render = null;
        }catch(e){
          console.error('Error destroying ckEditor');
        }
      });
    }
  };
});
