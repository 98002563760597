angular.module('examgen.directive').
directive('standardPreview', function($rootScope, $dialog, $q) {
  return {
    restrict: 'A',
    scope: {
      standardPreview: '='
    },
    link: function(scope, $element, attrs) {
      var $preview;

      // create the dropdown in the parent's element if needed and return it
      var $dropdown = function() {
        if (!$preview) {
          $preview = $element.parents('.standards-list').find('.preview');
        }

        var $dr = $preview.find('.dropdown');
        if ($dr.length) {
          return $dr;
        }

        // create it
        var $dr = $('<div class="dropdown open">' +
                    '<ul class="dropdown-menu"><li></li></ul>' +
                    '</div>');
        $preview.append($dr);
        return $dr;
      }

      $element.on('mouseover', function(ev) {
        var content = scope.standardPreview.description;

        // calculate how long the text is
        var $test = $('<span style="visibility: hidden; position: fixed; top: 0; left: 0;"></span>');
        $test.html(content);
        $('body').append($test);
        var textWidth = $test.width();
        $test.remove();

        // create the dropdown and set the position to fit nicely and set it's width
        var $dr = $dropdown();
        var availWidth = $preview.width();
        var left = $element.offset().left - $preview.offset().left;
        var top = $element.offset().top - $preview.offset().top + 25;
        $dr.css({
            top:top,
            left: left,
            'width': Math.min(textWidth, availWidth - 30) - left
          })
          .addClass('open')
          .find('li')
            .html(content);
      });

      $element.on('mouseout', function(ev) {
        $dropdown().removeClass('open');
      });
    }
  }
});
/*
             <div class="dropdown clearfix open">
                  <ul class="dropdown-menu">
                    <li>testing test test123 test<br/>test testing test2123</li>
                  </ul>
                </div>
                */
