/**
 * @ngdoc module
 * @name examgen.constructors
 *
 * @description Constructor functions for the core data structures that make up the exam tree.
 */

angular.module('examgen.constructors',[])
.service('constructors',function($q,$http,$rootScope,$location,importExport,lazyLoad,couch,session,databases,md5,uuid){

  /**
   * Root abstract class. Parent class of all objects in the composer tree.
   * @constructor
   */
  function ComposerDropTarget(parent,exam){
    this.composerParent = parent || null;
    this._exam = exam;
  }

  ComposerDropTarget.prototype = {
    /** Receives dropped items */
    receiveDroppedItems : function(items, skipInsertionPointFocus) {
      //console.log('receiving dropped item',items, this);
      return this._exam.insertItemOrComposerObject(items, this, skipInsertionPointFocus);
    },

    shuffle: function() {
      throw new Error('shuffle not implemented');
    },

    /** wrapper to call exam.removeItem(this) **/
    remove: function() {
      return this._exam.removeItem(this);
    },

    removeFromExam: function() {
      if (this.composerParent) {
        this.composerParent.removeChild(this);
      }

      this.composerParent = null;
      this._exam._treeHasMutated();

      return $q.when(this);
    },

    getChildItems: function() {
      var all = [];

      (this.items || this).forEach(function(itemOrPassage) {
        // passage, so get items
        if (itemOrPassage.items) {
          all = all.concat([itemOrPassage], itemOrPassage.items);
        } else {
          all.push(itemOrPassage);
        }
      });

      return all;
    },

    setSiblingAsInsertionPoint: function(dir) {
      var items = this._exam.getAllTreeElements();
      var idx = items.indexOf(this);
      if (idx === -1) {
        throw new Error("setSiblingAsInsertionPoint failed, couldn't find my idx");
      }

      if (items[idx + dir]) {
        this._exam.setInsertionPoint(items[idx + dir]);
        return items[idx + dir];
      }

      console.log("setSiblingAsInsertionPoint can't find anything above", idx, dir);
    },
  };

  /**
   * ComposerItem extends ComposerDropTarget. This is an individual question in the tree.
   * @constructor
   */
  function ComposerItem(parent,exam,item,id){//examItem,correctAnswerLetter,itemID,id){
    this.examItem = item.examItem;
    this.correctAnswerLetter = item.correctAnswerLetter;
    this.itemID = item.itemID;
    this.codeSort = item.codeSort;
    this.answerSort = item.answerSort;
    this.id = id || uuid.v4();
    this.isUserDB = item.examItem.isUserDB;

    // attempt to load the item ID automatically from the itemContent
    if (!item.itemID) {
      if (this.examItem.resolvedItemContent) {
        this.itemID = this.examItem.resolvedItemContent.idQuestion;

        var self = this;
        this.examItem.itemContent.then(function(item) {
          self.itemID = item.idQuestion;
        });
      }
    }

    ComposerDropTarget.apply(this,arguments);
  }

  /** Helper function to determine if an item is associated with a passage */
  function examItemHasPassage(examItem) {
    return examItem.itemContent.then(function(itemContent) {
      return !!itemContent.passageId;
    });
  }

  ComposerItem.prototype = _.extend(new ComposerDropTarget(),{

    /** Serialize to JSON */
    toJSON : function(){
      var json = {};

      // if itemID is present, fallback to path
      json.itemID = this.examItem.itemId;
      json.itemTypeId = 'Q';
      json.itemHref = this.toPath();
      json.id = this.id;
      json.correctAnswerLetter = this.correctAnswerLetter;
      json.codeSort = this.codeSort;
      json.answerSort = this.answerSort;
      json.isUserDB = this.isUserDB
      if(this.examItem.origin != null){
        json.origin = this.examItem.origin;
      } else {
        json.origin = this.examItem.part.section.topic.chapter.database.databaseName;
        json.origin += '/'+this.examItem.part.section.topic.chapter.chapterName;
        json.origin += '/'+(this.examItem.part.section.topic.chapter.chapterNumber+1);
        json.origin += '/'+this.examItem.itemName;
      }
      return json;
    },

    isTextForItemTooLarge: function (){
      return true;
    },

    /** Returns the label for an item shown by the exam tree */
    getLabel : function(){
      if(this.examItem.origin){
        var origin = this.examItem.origin.split('/');
        var chapterNumber = origin[2];
        var databaseName = origin[0];
      } else if (this.examItem.part){
        var chapterNumber = this.examItem.part.section.topic.chapter.chapterNumber + 1;
        var databaseName = this.examItem.databaseName;
      } else {
        var num = this.questionNumber ? this.questionNumber + ') ' : '';
        return num + 'QUESTION FORMAT ERROR';
      }

      var num = this.questionNumber ? this.questionNumber + ') ' : '';
      var type = this.examItem.resolvedItemContent ? this.examItem.resolvedItemContent.itemType : '';

      if (this.composerParent instanceof ComposerSection){
        if (this.composerParent.options.convertMCToShortAnswer){
          type = 'SA';
        }
      } else  if (this.composerParent.composerParent instanceof ComposerSection){
        if(this.composerParent.composerParent.options.convertMCToShortAnswer){
          type = 'SA';
        }
      }

      var name = this.examItem.itemName || this.examItem.origin.split('/')[3]

      return num + type + ' - ' + databaseName + ', Ch ' + chapterNumber + ' #' + name;
    },

    /** Returns the URL path to the item. Used for serializing the exam. */
    toPath : function(){
      //return '/databases/' + this.examItem.section.topic.chapter.database.databaseId + '/items/' + this.examItem.section.topic.chapter.chapterId + '/' + this.examItem.section.topic.topicId + '/' + this.examItem.section.sectionId + '/' + this.examItem.itemId;
      return '/api/questionPath/' + this.examItem.itemId+'?userDB='+(this.examItem.isUserDB ? true : false);
    }
  });

  /**
   * Abstract class, extends ComposerDropTarget. Adds hierarchy so we can add and remove children. Used for passages and sections.
   * @constructor
   */
  function ParentComposerDropTarget(parent,exam){
    this.items = [];

    ComposerDropTarget.apply(this,arguments);
  }

  /** Helper function to wrap an array */
  function wrapArray(arr){
    return Array.isArray(arr) ? arr : [arr];
  }

  ParentComposerDropTarget.prototype = _.extend(new ComposerDropTarget(),{
    /** Adds an item to the end of the list of children */
    append : function(items){
      items = wrapArray(items);
      this.items.push.apply(this.items,items);
    },
    /** Adds an item to the beginning of the list of children */
    prepend : function(items){
      items = wrapArray(items);
      this.items.splice.apply(this.items,[0,0].concat(items));
      items.forEach(function(item){
        item.composerParent = this;        //set the parent
      },this);
    },
    /** Adds an item to the the list of children after insertionPoint */
    insertAfter : function(insertionPoint,itemToInsert){
      var idx = this.items.indexOf(insertionPoint);
      this.items = this.items.slice(0,idx + 1).concat(
              itemToInsert,
              this.items.slice(idx + 1));

      itemToInsert.composerParent = this;        //set the parent
    },
    /** Removes the given child from the list of children */
    removeChild : function(childToRemove){
      var index = this.items.indexOf(childToRemove);
      if(index > -1){
        this.items.splice(index,1);
        this._exam._treeHasMutated();
      }
    }
  });

  /**
   * ComposerPassage extends ComposerDropTarget. This represents a passage in the exam tree.
   * @constructor
   */
  function ComposerPassage(parent,exam,passage,id){
    this.examPassage = passage.examPassage;
    this.id = id || uuid.v4()
    this.codeSort = passage.codeSort;
    this.isUserDB = passage.examPassage.isUserDB;
    ParentComposerDropTarget.apply(this,arguments);     //call super constructor
  }

  ComposerPassage.prototype = _.extend(new ParentComposerDropTarget(),{

    /** Serializes the ComposerPassage to JSON */
    toJSON : function(){
      return {
        passageHref : this.toPath(),
        passageId : this.examPassage.passageId,
        itemID: this.examPassage.passageId,
        itemTypeId : 'P',
        id: this.id,
        codeSort : (this.items[0] ? this.items[0].codeSort : []),
        isUserDB : this.isUserDB,
        origin: this.examPassage.origin || this.examPassage.database.databaseName+'/ / /'+this.examPassage.passageName,
        items : this.items.map(function(item){
          return item.toJSON();
        })
      };
    },

    /** Returns the label for an item shown by the exam tree */
    getLabel : function(){
      var name = this.examPassage.passageName || this.examPassage.origin.split('/')[3];
      var passageNumberMatch = (name).match(/Cxx(\d\d\d\d)/) || '';
      var passageNumber;

      if(passageNumberMatch){
        passageNumber = parseInt(passageNumberMatch[1],10);
      } else {
        passageNumber = '';
      }

      if(this.examPassage.origin){
        return this.examPassage.origin.split('/')[0] + ' \u2014 Passage ' + passageNumber;
      } else {
        return this.examPassage.database.databaseName + ' \u2014 Passage ' + passageNumber;
      }
    },

    /** Returns the URL path to the item. Used for serializing the exam. */
    toPath : function(){
      //FIXME: passageName includes the .html extension. This will later get refactored out, so assume it's in here.
      return '/api/passagePath/'+this.examPassage.passageId+'?userDB='+(this.examPassage.isUserDB ? true : false);
    },

    /** Removes the given child from the list of children */
    removeChild : function(childToRemove){
      //call method on superclass
      ParentComposerDropTarget.prototype.removeChild.call(this,childToRemove);

      //custom behaviour: if items is zero, then remove yourself
      if(!this.items.length){
        this.remove();
        this._exam._treeHasMutated();
      }
    },

    /** ComposerSection Remove self from exam tree */
    removeFromExam: function() {
      var deferred = $q.defer();
      var passage = this;
      var exam = passage._exam;

      $rootScope.$safeApply(function() {
        // var isLastSection = exam.sections.length === 1;
        //
        // // if deleting last section, add one
        // if (isLastSection) {
        //   exam.addSection();
        // }

        ParentComposerDropTarget.prototype.removeFromExam.call(passage);
        deferred.resolve([passage].concat(passage.getChildItems()));
      });

      return deferred.promise;
    }
  });

  function shuffle(array) {
    var counter = array.length, temp, index;
    // While there are elements in the array
    while (counter > 0) {
      // Decrease counter by 1
      counter--;
      // Pick a random index
      index = Math.floor(Math.random() * counter);
      // And swap the last element with it
      temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }

    return array;
  }


  /**
   * ComposerSection extends ComposerDropTarget. This represents a Section in the exam tree.
   * @constructor
   */
  function ComposerSection(exam,section,id){
    this.name = section.name;
    this.options = section.options || {};
    this.id = id || uuid.v4();

    if(!this.options.hasOwnProperty('noColDiv')){
      this.options.noColDiv = false;
    }

    ParentComposerDropTarget.apply(this,[exam, exam, section.name]);     //call super constructor
  }

  ComposerSection.prototype = _.extend(new ParentComposerDropTarget(),{
    /** Serializes the ComposerSection to JSON */
    toJSON : function(){
      //this is vaguely idiomatic
      //this says: wait for this.items, but return the whole section object (the surrounding object)
      /* this.items is a List<ComposerItem | ComposerPassage>*/
      return {
        id: this.id,
        name : this.name,
        options : this.options,
        items : this.items.map(function(item){
          return item.toJSON();
        })
      };
    },

    shuffle: function() {
      this.items.forEach(function(item) {
        if (item instanceof ComposerPassage) {
          item.items = shuffle(item.items);
        }
      });

      this.items = shuffle(this.items);
    },

    /** Returns true if the section contains any short answers */
    hasShortAnswers : function () {
      var any = false;
      var processItem = function (item) {
        if (any) {
          return;
        }
        if (item instanceof ComposerPassage) {
          return item.items.forEach(processItem);
        }

        if (!item.examItem.resolvedItemContent) {
          return;
        }

        var type = item.examItem.resolvedItemContent.itemType;
        if (type == 'ER' || type == 'SR') {
          return any = true;
        }

        var section = item.composerParent instanceof ComposerPassage ?
                item.composerParent.composerParent : item.composerParent;
        if (section.options.convertMCToShortAnswer && type == 'MC') {
          return any = true;
        }
      }

      this.items.forEach(processItem);
      return any;
    },

    /** Returns label used in the exam tree */
    getLabel : function(){
      return this.name;
    },

    /** ComposerSection Remove self from exam tree */
    removeFromExam: function() {
      var deferred = $q.defer();
      var section = this;
      var exam = section._exam;

      var removeSection = function() {
        $rootScope.$safeApply(function() {
          var isLastSection = exam.sections.length === 1;

          // if deleting last section, add one
          if (isLastSection) {
            exam.addSection();
          }

          ParentComposerDropTarget.prototype.removeFromExam.call(section);

          // return all the items removed, including the section
          deferred.resolve([section].concat(section.getChildItems()));
        });
      };

      // remove immediately when there are no items
      if (this.items.length === 0) {
        removeSection();
        return deferred.promise;
      }

      // custom behaviour: prompt the user to verify
      bootbox.confirm("Are you sure you want to remove this section and all its items?", function (e) {
        if (e) {
          removeSection();
        }
      });
      return deferred.promise;
    }
  });

  /**
   *  This is the top-level exam tree. Contains sections, which contains items and passages.
   */
  function Exam(kwArgs){
    this.name = kwArgs.name;
    this.options = kwArgs.options || { fontSize : 12, fontFamily : '"Times New Roman", Times, serif' };
    this.date_created = kwArgs.date_created ? new Date(kwArgs.date_created) : new Date();
    this.date_updated = kwArgs.date_updated ? new Date(kwArgs.date_updated) : new Date();
    this.checksum = kwArgs.checksum;
    this.id = kwArgs.id || uuid.v4();
    this.folder = kwArgs.folder || '';
    this.code = kwArgs.code || '';
    this.pdfs = []; //this._importAttachments(kwArgs._attachments);      //TODO: add support for loading attachments

    this.sections = [];
    this.insertionPoint = null;
    this.needsSave = false;

    //console.log('exam from server ', kwArgs);
    //console.log('exam ', this);

  }

  /**
   * load an existing exam
   */
  Exam.load = function(id){
    return couch.db.getExam(id).then(function(response){
      return Exam.fromJSON(response.data);
    },function(response){
      alertify.error('Error loading exam.');
      return null;
    });
  }

  /**
   * Creates a new exam.
   */
  Exam.create = function(name){
    //FIXME: we just need to create a JSON template, but we're creating a whole new Exam instance,
    //which never gets used later. We're just using it for its serialization functions. Might be bad design.
    var exam = new Exam({name : name, id:uuid.v4(), options : { fontSize : 12, fontFamily : '"Times New Roman", Times, serif' }});
    //console.log(exam);
    exam.addSection();      //create the first exam
    // resolve loadedExam to self since there wont be anything to load from the DB
    exam.loadedExam = $q.when(exam);
    return exam.save().then(function(){
      return exam;
    });
  };

  /**
   * Creates a new exam from a serialized exam saved on the server.
   */
  Exam.fromJSON = function(examFromServer){

    var exam = new Exam(examFromServer);

    return exam.processJSON(examFromServer);
  };

  function DbNotFound(db) {
    this.name = 'DbNotFound';
    this.message = db;
  }
  DbNotFound.prototype = new Error();
  DbNotFound.prototype.constructor = new DbNotFound;

  function getDb(path, databaseName) {
    //console.log('getDb()', path, databaseName);
    if (path) {
      // fetch the database from the path and load it from the list of databases
      databaseName = path.match(/databases\/([^\/]+)/);
      if (!databaseName) {
        throw new Error('database not matched: ' + path);
      }
      databaseName = databaseName[1];
    }

    return databases.all.then(function(resolvedDatabases){
      return resolvedDatabases.filter(function(db){
        return db.databaseName === databaseName;
      })[0];
    }).then(function(db) {
      if (!db) {
        return $q.reject(new DbNotFound(databaseName));
      }

      return db;
    });
  }

  function migrateExam(json) {
    //console.log(json);
    var defer = $q.defer(),
      questionIDs = 0;

    function fix(path) {
      return $q.when(path);

      var _defer = $q.defer();

      // fetch the database from the path and load it from the list of databases
      var databaseName = path.match(/databases\/([^\/]+)/);
      if (!databaseName) {
        throw new Error('database not matched', path);
      }

      databases.all.then(function(resolvedDatabases) {
        return resolvedDatabases.filter(function(db) {
          return db.databaseName === databaseName[1];
        })[0];
      }).then(function(db) {
        if (!db) {
          throw new DbNotFound(databaseName[1]);
        }

        // load the db item refs
        lazyLoad.database(db).then(function(){
          db.itemRefs.then(function(refs) {
            if (refs.failed) {
              return _defer.reject;
            }

            var fixedPath = path.replace(/^\/+/, '');
            var id = refs.urls[fixedPath];

            _defer.resolve(id ? id : false, id ? path : false);
          }, _defer.reject);
        }, _defer.reject);
      });
      return _defer.promise;
    }

    function fixItemId (id,userDB) {
      if (!id) {
        return $q.when(false);
      }

      return getItemIdHref(id,userDB).then(function(){
        return $q.when(true);
      }, function() {
        return $q.when(false);
      });
    }

    function fixItem(item) {
      if (item && item.itemID) {
        var p = item.itemID.split('/');
        if (p.length === 2 && p[1] === 'undefined') {
          delete item.itemID;
        }
      }
    }

    var wait = [],
      questionIds = [],
      deleted = [],
      changes = 0;

    // process all sections
    json.sections.forEach(function(section, sectionIdx) {
      section.items.forEach(function(item, itemIdx) {
        //fixItem(item);
        //console.log(item);
        // section item?
        if (item.itemHref && !item.itemID) {
          questionIds.push(item.itemHref);
          wait.push(fix(item.itemHref).then(function(id, path) {
            changes++;
            if (id) {
              item.itemID = id;
              item.itemHref = path;
            } else {
              deleted.push(item.itemHref);
              section.items.splice(itemIdx, 1);
            }
          }));
        } else if (item.itemID) {
          questionIds.push(item.itemID);
          wait.push(fixItemId(item.itemID,item.isUserDB).then(function(e, path) {
            if (!e) {
              section.items.splice(itemIdx, 1);
              deleted.push(item.itemID);
            } else {
              item.itemHref = path;
            }
          }));
        }

        if (item.passageHref) {
          item.items.forEach(function(i, idx){
            fixItem(item);
            questionIds.push(i.itemHref);
            if (i.itemHref) {
              wait.push(fix(i.itemHref).then(function(id, path){
                changes++;
                if (id) {
                  i.itemID = id;
                  i.itemHref = path;
                } else {
                  deleted.push(i.itemHref);
                  item.items.splice(idx, 1);
                }
              }));
            } else if (i.itemID) {
              questionIds.push(i.itemID);
              wait.push(fixItemId(i.itemID,i.isUserDB).then(function(e, path){
                if (!e) {
                  item.items.splice(idx, 1);
                  deleted.push(i.itemID);
                } else {
                  i.itemHref = path;
                }
              }));
            }
          });
        }
        //console.log(item);
      });
    });

    var allWait = wait.map(function(item) {
      var def = $q.defer();
      item.then(function() {
        def.resolve();
      }, function(err) {
        console.error('migration fix failed for one: ' + err);
        def.resolve();
      });

      return def;
    });

    $q.all(allWait).then(function(){
      console.info('all waited done', 'deleted:', deleted, 'changes:', changes);

      if (deleted.length > 0) {
        var questions = deleted.map(function(url) {
          return '#' + (questionIds.indexOf(url)+1);
        });

        alert('The questions ' + questions.join(', ') + ' have been deleted due to editorial reasons.');
      }

      // save any changes
      if (changes > 0 || deleted.length > 0) {
        json.date_updated = new Date();
        this.needsSave = false;

        console.log('saving exam');
        couch.db.putExam(json.id,json).then(function() {
          //console.log('exam saved');
          defer.resolve(json);
        });
      } else {
        defer.resolve(json);
      }
    });

    return defer.promise;
  }

  function getItemIdHref(itemID,userDB) {
    var id = itemID.split('/');

    return $q.when('/api/questionPath/'+itemID+'?userDB='+(userDB ? true : false));

    // return getDb(null, id[0]).then(function(db){
    //   if (!db) {
    //     return $q.reject(new Error('cant find db for itemID: ' + itemID));
    //   }
    //
    //
    //   return lazyLoad.database(db).then(function(db) {
    //     return db.itemRefs.then(function(refs) {
    //       var href = refs.ids[id[1]];
    //       if (href) {
    //         return '/' + href;
    //       } else {
    //         return $q.reject(new Error('cant find href for itemID: ' + itemID));
    //       }
    //     });
    //   });
    // });
  }

  // makes sure itemIDs are correct
  // some got saved as "Algebra 2/undefined", so this fixes that issue
  function isValidItemID(id) {
    if (!id) {
      return false;
    }

    if (id.length !== 36 || id.length !== 32) {
      return false;
    }

    id = id.split('-');
    if (id.length != 5) {
      return false;
    }

    return true;
  }

  function processMML(item,key,str){
    return $http.put('/api/convertMML',{mml:str})
    .then(function(res){
      var img = '<img src="'+res.data.png+'" style="vertical-align: middle;"></img>'
      item[key] = item[key].replace(str,img);
    });
  }

  function renderMML(items, index, finish){
    var mmlRegex = /<math>.*?<\/math>/gi;

    if(index < items.length){
      var ps = [];
      if(items[index].examItem.resolvedItemContent.resolvedPassage &&
        mmlRegex.test(items[index].examItem.resolvedItemContent.resolvedPassage.resolvedPassageHTML)){
        mmlRegex.lastIndex = 0;
        var str = mmlRegex.exec(items[index].examItem.resolvedItemContent.resolvedPassage.resolvedPassageHTML);
        ps.push(processMML(items[index].examItem.resolvedItemContent.resolvedPassage,'resolvedPassageHTML',str[0]));
      }
      if(mmlRegex.test(items[index].examItem.resolvedItemContent.prompt)){
        mmlRegex.lastIndex = 0;
        var str = mmlRegex.exec(items[index].examItem.resolvedItemContent.prompt);
        ps.push(processMML(items[index].examItem.resolvedItemContent,'prompt',str[0]));
      }
      if(mmlRegex.test(items[index].examItem.resolvedItemContent.shortAnswer||'')){
        mmlRegex.lastIndex = 0;
        var str = mmlRegex.exec(items[index].examItem.resolvedItemContent.shortAnswer);
        ps.push(processMML(items[index].examItem.resolvedItemContent,'shortAnswer',str[0]));
      }
      for(var i = 0; items[index].examItem.resolvedItemContent.responses && i < items[index].examItem.resolvedItemContent.responses.length; i++){
        if(mmlRegex.test(items[index].examItem.resolvedItemContent.responses[i].answer)){
          mmlRegex.lastIndex = 0;
          var str = mmlRegex.exec(items[index].examItem.resolvedItemContent.responses[i].answer);
          ps.push(processMML(items[index].examItem.resolvedItemContent.responses[i],'answer',str[0]));
        }
      }

      if(ps.length > 0){
        return Promise.all(ps).then(function() {
          return renderMML(items, index, finish);
        })
      }else {
        return renderMML(items, index+1, finish)
      }
    }else{
      return finish();
    }
  }

  function processImage(item,key,str){
    return new Promise(function(resolve, reject) {
      var div = document.createElement('div');
      div.innerHTML = str;

      var canvas = document.createElement("canvas");
      var img = document.createElement('img');
      img.crossOrigin="anonymous";
      img.onload = function(){
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");

        item[key] = item[key].replace(str,dataURL);
        resolve();
      };
      img.src = str.replace(/https\:\/\/s3\.amazonaws\.com\/examgen/g,'/databases');;
    });
  }

  function convertImage(items, index, finish){
    var imgRegex = /(?:<img.*src=")((?:http|\/databases)[a-zA-Z0-9\.\-\:\/\_ \(\)]+)(?:".*>)(?:<\/img>)?/i;

    if(index < items.length){
      var ps = [];
      if(items[index].examItem.resolvedItemContent.resolvedPassage &&
        imgRegex.test(items[index].examItem.resolvedItemContent.resolvedPassage.resolvedPassageHTML)){
        imgRegex.lastIndex = 0;
        var str = imgRegex.exec(items[index].examItem.resolvedItemContent.resolvedPassage.resolvedPassageHTML);
        ps.push(processImage(items[index].examItem.resolvedItemContent.resolvedPassage,'resolvedPassageHTML',str[1]));
      }
      if(imgRegex.test(items[index].examItem.resolvedItemContent.prompt)){
        imgRegex.lastIndex = 0;
        var str = imgRegex.exec(items[index].examItem.resolvedItemContent.prompt);
        ps.push(processImage(items[index].examItem.resolvedItemContent,'prompt',str[1]));
      }
      if(imgRegex.test(items[index].examItem.resolvedItemContent.shortAnswer||'')){
        imgRegex.lastIndex = 0;
        var str = imgRegex.exec(items[index].examItem.resolvedItemContent.shortAnswer);
        ps.push(processImage(items[index].examItem.resolvedItemContent,'shortAnswer',str[1]));
      }
      for(var i = 0; items[index].examItem.resolvedItemContent.responses && i < items[index].examItem.resolvedItemContent.responses.length; i++){
        if(imgRegex.test(items[index].examItem.resolvedItemContent.responses[i].answer)){
          imgRegex.lastIndex = 0;
          var str = imgRegex.exec(items[index].examItem.resolvedItemContent.responses[i].answer);
          ps.push(processImage(items[index].examItem.resolvedItemContent.responses[i],'answer',str[1]));
        }
      }

      if(ps.length > 0){
        return Promise.all(ps).then(function() {
          return convertImage(items, index, finish);
        }).catch(function(err){
          console.log(err);
        })
      }else {
        return convertImage(items, index+1, finish)
      }
    }else{
      return finish();
    }
  }

  async function generateGFormPayload(resolvedExam){
    return new Promise(async (resolve, reject) => {
      const sectionMapper = async section => {
        return new Promise(async (resolveSection, rejectSection) => {
          const itemMapper = async item => {
            return new Promise(async (resolveItem, rejectItem) => {
              if (item.itemTypeId === 'Q' || !item.itemTypeId) {
                let responses = item.responses ? item.responses.map(response => ({idResponse: response.idResponse, isCorrect: response.isCorrect, answer: item.itemType === 'MC' ? response.answerLetter : response.answer})) : [];
                let prompt = '';
                const promptEl = document.getElementById(item.id);

                promptEl.innerHTML = promptEl.innerHTML.replace(/https\:\/\/s3\.amazonaws\.com\/examgen/g,'/databases');

                try {
                  let height = promptEl.getBoundingClientRect().height;
                  // Required to account for offset when prompt is passage element
                  if (!item.itemTypeId) {
                    height += 20;
                  }
                  const dataUrl = await domtoimage.toPng(promptEl, {
                    height: height,
                    width: 660,
                  });

                  prompt = dataUrl.substr(dataUrl.indexOf(",") + 1)
                } catch (e) {
                  console.error(e);
                }


                resolveItem({id: item.id, itemTypeId: item.itemTypeId, itemType: item.itemType, prompt: prompt,responses: responses});
              } else if (item.itemTypeId === 'P') {
                let passage = '';

                const passageEl = document.getElementById(item.id);
                passageEl.innerHTML = passageEl.innerHTML.replace(/https\:\/\/s3\.amazonaws\.com\/examgen/g,'/databases');

                try {
                  const dataUrl = await domtoimage.toPng(passageEl, {
                    height: passageEl.getBoundingClientRect().height,
                    width: 660,
                  });

                  passage = dataUrl.substr(dataUrl.indexOf(",") + 1)
                } catch (e) {
                  console.error(e);
                }

                const passageItems = await Promise.all(item.items.map((passageItem) => itemMapper(passageItem)));
                resolveItem({id: item.id, itemTypeId: item.itemTypeId, prompt: passage,items: passageItems});
              }
            });
          };


          const items = await Promise.all(section.items.map((item) => itemMapper(item)));

          resolveSection({id: section.id, name: section.name, items: items});
        });
      };

      const sections = await Promise.all(resolvedExam.sections.map((section) => sectionMapper(section)));

      const formsPayload = {id:resolvedExam.id, name: resolvedExam.name, sections: sections};

      resolve(formsPayload);
    });
  }

  Exam.prototype = {
    /** Parse JSON exam saved on the server */
    processJSON: function (json) {
      // console.log('procjson',json);
      var exam = this;

      this.checksum = json.checksum;
      this.id = json.id || exam.id;
      this.options = json.options || exam.options;

      var itemLoadList = [];
      var passageLoadList = [];

      for(var s = 0; s < json.sections.length; s++){
        for(var i = 0; i < json.sections[s].items.length; i++){
          if(json.sections[s].items[i].itemTypeId == 'Q'){
            itemLoadList.push(json.sections[s].items[i]);
          } else if(json.sections[s].items[i].itemTypeId == 'P'){
            passageLoadList.push(json.sections[s].items[i]);
            for(var pi = 0; pi < json.sections[s].items[i].items.length; pi++){
              itemLoadList.push(json.sections[s].items[i].items[pi]);
            }
          }
        }
      }

      var dfd = $q.defer();
      lazyLoad.itemList(itemLoadList).then(function(items){
        var sectionPromises = json.sections.map(function(section){
          var composerSection = new ComposerSection(exam, section);
          exam.sections.push(composerSection);

          var sectionItemPromises = $q.all(section.items.map(function(item){
            // console.log(item);

            if(item.itemTypeId == 'Q'){
              //lazyLoad.item(item);

              return item.loaded.then(function(resolvedItem){
                return new ComposerItem(composerSection,exam,{
                  examItem:resolvedItem,
                  correctAnswerLetter:resolvedItem.correctAnswerLetter,
                  itemID:resolvedItem.itemID,
                  codeSort:item.codeSort,
                  answerSort:item.answerSort,
                });
              });
              //if(isValidItemID(item.itemID)) {
                //return getItemIdHref(item.itemID).then(function(href){
                  // return importExport.loadItemFromPath(href).then(function(resolvedItem){
                  //   return new ComposerItem(composerSection,exam,resolvedItem,resolvedItem.correctAnswerLetter, resolvedItem.itemID, item.id);
                  // });
                //});
              //}
            } else if(item.itemTypeId == 'P'){
              var passagePromise = lazyLoad.passage(item);
              //var passagePromise = importExport.loadPassageFromPath(item.passageHref);
              var passageItemsPromise = $q.all(item.items.map(function(item) {
                return item.loaded.then(function(resolvedItem){
                  resolvedItem.id = item.id;
                  return resolvedItem;
                });
              }));

              return $q.all([passagePromise,passageItemsPromise]).then(function(response){
                var resolvedPassage = response[0],
                  resolvedItems = response[1];
                var composerPassage = new ComposerPassage(composerSection,exam,{examPassage:resolvedPassage, codeSort:item.codeSort});

                //console.log(response);
                //FIXME: initing items directly is a bit evil
                composerPassage.items = resolvedItems.map(function(resolvedItem){
                  return new ComposerItem(composerPassage,exam,{
                    examItem:resolvedItem,
                    correctAnswerLetter:resolvedItem.correctAnswerLetter,
                    itemID:resolvedItem.itemID,
                    codeSort:resolvedItem.codeSort,
                    answerSort:resolvedItem.answerSort,
                  });
                });

                return composerPassage;
              });
            }
          }));

          sectionItemPromises.then(function(sectionItemPromises){
            //console.error('examFromServer', examFromServer, sectionItemPromises);
            composerSection.items = sectionItemPromises;
          });

          return sectionItemPromises;

        },this);


        $q.all(sectionPromises).then(function(section){
          exam.setInsertionPoint(exam.sections[0]);     //set the insertion point to the first section

          exam._treeHasMutated();
          exam.needsSave = false;   //_treeHasMutated will set needsSave to true, but in this case he doesn't need to be saved, so set him to false

          dfd.resolve(exam);
        },function(err){
          return dfd.reject(err);
        });
      });

      return dfd.promise;

      // return migrateExam(json).then(function(examFromServer) {
      //   //console.log('migrateExam done');
      //   this.options = examFromServer.options || exam.options;
      //   var sectionPromises = examFromServer.sections.map(function(section){
      //     var composerSection = new ComposerSection(exam, section.name, section.options, section.id);
      //     exam.sections.push(composerSection);
      //     //debugger;
      //     //db.getItemRef();
      //     var sectionItemPromises = $q.all(section.items.map(function(item){
      //       if(isValidItemID(item.itemID)) {
      //         return getItemIdHref(item.itemID).then(function(href){
      //           return importExport.loadItemFromPath(href).then(function(resolvedItem){
      //             return new ComposerItem(composerSection,exam,resolvedItem,resolvedItem.correctAnswerLetter, resolvedItem.itemID, item.id);
      //           });
      //         });
      //       } else if (item.itemHref) {
      //         return importExport.loadItemFromPath(item.itemHref).then(function(resolvedItem){
      //           return new ComposerItem(composerSection,exam,resolvedItem,resolvedItem.correctAnswerLetter, resolvedItem.itemID, item.id);
      //         });
      //       }
      //
      //       if(item.passageHref && item.items){
      //         //he's a passage with sub-items
      //         var passagePromise = importExport.loadPassageFromPath(item.passageHref);
      //         var passageItemsPromise = $q.all(item.items.map(function(item) {
      //             if (isValidItemID(item.itemID)) {
      //               return getItemIdHref(item.itemID).then(function(href){
      //                 return importExport.loadItemFromPath(href).then(function(resolvedItem){
      //                   resolvedItem.correctAnswerLetter = item.correctAnswerLetter;
      //                   resolvedItem.itemID = item.itemID;
      //                   resolvedItem.id = item.id;
      //                   return resolvedItem;
      //                 });
      //               });
      //             } else if (item.itemHref) {
      //               return importExport.loadItemFromPath(item.itemHref).then(function(resolvedItem){
      //                 resolvedItem.correctAnswerLetter = item.correctAnswerLetter;
      //                 resolvedItem.itemID = item.itemID;
      //                 resolvedItem.id = item.id;
      //                 return resolvedItem;
      //               });
      //             }
      //           }));
      //
      //         //console.log('item.items',item.items);
      //
      //         return $q.all([passagePromise,passageItemsPromise]).then(function(response){
      //           var resolvedPassage = response[0],
      //             resolvedItems = response[1];
      //
      //           var composerPassage = new ComposerPassage(composerSection,exam,resolvedPassage);
      //
      //           //FIXME: initing items directly is a bit evil
      //           composerPassage.items = resolvedItems.map(function(resolvedItem){
      //             return new ComposerItem(composerPassage,exam,resolvedItem,resolvedItem.correctAnswerLetter,resolvedItem.itemID,resolvedItem.id);
      //           });
      //
      //           return composerPassage;
      //         });
      //       }else{
      //         throw new Error('Malformed item in JSON from server.');
      //       }
      //     }));
      //
      //     sectionItemPromises.then(function(sectionItemPromises){
      //       //console.error('examFromServer', examFromServer, sectionItemPromises);
      //       composerSection.items = sectionItemPromises;
      //     });
      //
      //     return sectionItemPromises;
      //
      //   },this);
      //
      //   var dfd = $q.defer();
      //   $q.all(sectionPromises).
      //     then(function(section){
      //       exam.setInsertionPoint(exam.sections[0]);     //set the insertion point to the first section
      //
      //       exam._treeHasMutated();
      //       exam.needsSave = false;   //_treeHasMutated will set needsSave to true, but in this case he doesn't need to be saved, so set him to false
      //
      //       dfd.resolve(exam);
      //     },function(err){
      //       return dfd.reject(err);
      //     });
      //
      //   return dfd.promise;
      // });
    },

    /** Private helper function used to add a tabindex to exam items. This is used to allow keyboard navigation in the tree */
    _tagNodesWithPreorderIndex : function(){
      this._getPreorderFlattenedTree(true, true).forEach(function(item,i){
        item.index = i;
      });
    },

    /** Adds question numbers to all exam items. */
    _tagNodesWithQuestionNumber : function(){
      this._getPreorderFlattenedTree(false, false).forEach(function(item,i){
        item.questionNumber = i+1;
      });
    },
    /** Creates copies of the given exam */
    createCopies : function(numCopies,shuffleAnswers,shuffleQuestions){
      var copiesPromise = $q.all(_.range(1,numCopies).map(function(){
        return this._copy(shuffleAnswers,shuffleQuestions);
      }.bind(this)));

      return copiesPromise;
    },
    /**
     * Private method to facilitate copying exam
     * @private
     */
    _copy: function(shuffleAnswers, shuffleQuestions) {

      //first convert to JSON and determine whether he's a copy
      var examJson = this.toJSON();
      examJson.id = uuid.v4();

      var copyId = window.__copyID || 1;
      console.log('----- copy begin for ', copyId);
      window.__copyID = copyId + 1;

      function copyExam(examCopy) {
        function afterShuffleQuestions() {
          //transforms
          if (shuffleAnswers) {
            var items = examCopy._getAllItems();
            items.forEach(function(item){
              if(item.examItem.resolvedItemContent.responses){
                var responses = shuffle(item.examItem.resolvedItemContent.responses);
                for(var i = 0; i < responses.length; i++){
                  if(responses[i].isPositionFixed && responses[i].sortOrder != i){
                    var tmp = responses[responses[i].sortOrder];
                    responses[responses[i].sortOrder] = responses[i];
                    responses[i] = tmp;
                    i = 0;
                  }
                }
              }
            })
          }

          return examCopy.chooseCorrectAnswers(shuffleAnswers).then(function(){
            //this should do it
            return examCopy;
          })
        }

        if (!shuffleQuestions) {
          return afterShuffleQuestions();
        }

        var defer = $q.defer();

        async.eachSeries(examCopy.sections, function(section, cb) {
          section.shuffle();
          cb();
        }, function() {
          defer.resolve(afterShuffleQuestions());
        });

        return defer.promise;
      };

      return Exam.fromJSON(examJson).then(copyExam);
    },
    /** Deletes the exam locally and on the server */
    "delete" : function(){
      return couch.db.removeExam(this.id);
    },
    /** Clears HTML/PDF attachments locally and on the server */
    clearPdfAttachments : function(numCopies){
      var copiesToDelete = this.pdfs.slice(numCopies);
      this.pdfs = this.pdfs.slice(0, numCopies||0);

      //console.log('deleting copies',copiesToDelete);

      copiesToDelete.forEach(function(attachmentPair){
      ['exam','answerKey'].forEach(function(prop){
        //console.log('Generating EXAM');

        if(attachmentPair[prop] && attachmentPair[prop].href){
          return $http['delete'](attachmentPair[prop].href).then(function(response){
            //console.log('successfully deleted EXAM',attachmentPair,prop);
          }.bind(this),function(err){
            //console.log('error response from deleting  EXAM',err);
          }.bind(this))
        }else{
        return $q.when(true);
        }
      }.bind(this));
      }.bind(this));
    },
    /** Helper function to choose a name for HTML attachments */
    _chooseCanonicalAttachmentName : function(copyNumber,isAnswerKey){
      return this.name + (copyNumber ? ' - Copy ' + copyNumber : '') + (isAnswerKey ? ' - Answer Key' : '') + '.html'
    },

    recordSortOrders : function(copies){
      var items = this._getAllItems();
      var exams = [this].concat(copies||[]);
      var newitems = exams.map(function(exam){return exam._getAllItems();});
      for(var i = 0; i < items.length; i++){
        items[i].codeSort = [];
        items[i].answerSort = [];
        for(var c = 0; c < exams.length; c++){
          var index = newitems[c].findIndex(function(item){return item.itemID==items[i].itemID});
          if(index>=0){
            items[i].codeSort.push(index);
            var answerSort = [];
            if(newitems[c][index].examItem.resolvedItemContent.responses){
              var answers = newitems[c][index].examItem.resolvedItemContent.responses;
              for(var a = 0; a < answers.length; a++){
                answerSort.push(answers[a].sortOrder);
              }
            }
            items[i].answerSort.push(answerSort);
          }
        }
      }
    },
    recoverCopies : function(){
      var count = this.sections[0].items[0].codeSort.length;
      var copies = [];
      function recover(index,exam){
        return exam.then(function(copy){
          for(var s = 0; s < copy.sections.length;s++){
            copy.sections[s].items.sort(function(a,b){
              return a.codeSort[index]-b.codeSort[index];
            });
            for(var i = 0; i < copy.sections[s].items.length; i++){
              if(copy.sections[s].items[i].items){
                copy.sections[s].items[i].items.sort(function(a,b){
                  return a.codeSort[index]-b.codeSort[index];
                });
                for(var si = 0; si < copy.sections[s].items[i].items.length; si++){
                  var old = copy.sections[s].items[i].items[si].examItem.resolvedItemContent.responses;
                  if(old){
                    old.sort(function(a,b){return a.sortOrder-b.sortOrder});
                    var answers = [];
                    for(var a = 0; a < old.length; a++){
                      answers.push(old[copy.sections[s].items[i].items[si].answerSort[index][a]])
                    }
                    copy.sections[s].items[i].items[si].examItem.resolvedItemContent.responses = answers;
                  }
                }
              } else if(copy.sections[s].items[i].examItem.resolvedItemContent.responses){
                var old = copy.sections[s].items[i].examItem.resolvedItemContent.responses;
                old.sort(function(a,b){return a.sortOrder-b.sortOrder});
                var answers = [];
                for(var a = 0; a < old.length; a++){
                  answers.push(old[copy.sections[s].items[i].answerSort[index][a]])
                }
                copy.sections[s].items[i].examItem.resolvedItemContent.responses = answers;
              }
            }
          }
          return copy;
        }).then(function(copy){
          return copy.chooseCorrectAnswers().then(function(){
            return copy;
          });
        });
      }

      var p = recover(0,Promise.resolve(this));

      for(var i = 1; i < count; i++){
        copies.push(recover(i,this._copy(false,false)));
      }

      return p.then(function(){
        return Promise.all(copies);
      });
    },

    copyAnswerSort : function(fromExam){
      var items = this._getAllItems().map(function(a){return a.examItem});
      var templates = fromExam._getAllItems().map(function(a){return a.examItem});

      for(var i = 0; i < items.length; i++){
        var template = templates.find(function(t){return t.itemID == items[i].itemID});
        if(template.resolvedItemContent.responses)
          items[i].resolvedItemContent.responses = JSON.parse(JSON.stringify(template.resolvedItemContent.responses));
      }
    },

    /** Initializes the PDF data structure */
    initPdfAttachments : function(copies){

      this.clearPdfAttachments(copies.length + 1);

      //init the this.pdfs structure
      this.pdfs = ([this].concat(copies)).map(function(examCopy){
        return {
          exam : {examToGenerate : examCopy},
          answerKey : {examToGenerate : examCopy}
        };
      });
    },
    /** Creates an HTML attachment */
    createPdfAttachment : function(copyNumber,isAnswerKey,html){

      var targetProp, loadingProp;
      if(isAnswerKey) {
        targetProp = 'answerKey';
      }else{
        targetProp = 'exam';
      }

      this.pdfs[copyNumber] = this.pdfs[copyNumber] || {};
      var o = this.pdfs[copyNumber][targetProp];

      if (o) {
        o.resolvedDOM = html;
        o.html = o.resolvedDOM[0].innerHTML;      //this property will prompt the UI to show a "Preview" button
      }

      // console.log('Generating EXAM');
      //
      // var attachmentName = this._chooseCanonicalAttachmentName(copyNumber,isAnswerKey);
      //
      // //generate
      // var url = couch.db.getAttachmentUrl(this.id,attachmentName);
      // couch.db.putAttachment(this.id,attachmentName,html).then(function(response){
      //   o.href = url;
      //
      //   //console.log('this.pdfs',this.pdfs);
      // },function(err){
      //   console.log('error response from generating EXAM',err);
      //   o.error = err.data.error || 'An error occurred generating the EXAM.';
      //
      //   console.log('this.pdfs',this.pdfs);
      // });
    },
    /** Choose correct answers for the answer key according to the high-water mark algorithm */
    chooseCorrectAnswers : function(shuffle){
      //TODO: collect all items
      //apply the algorithm - no more than two correct answers in a row
      return $q.all(this.sections.map(function(section){
        //collect all item content promises from section
        var itemTuplePromises =
            section.items.map(function(item){
              if(item instanceof ComposerItem){
                return item.examItem.itemContent.then(function(itemContent){
                  return {
                    composerItem : item,
                    itemContent : itemContent
                  };
                });
              }else if(item instanceof ComposerPassage){
                return item.items.map(function(subitem){
                  return subitem.examItem.itemContent.then(function(itemContent){
                    return {
                      composerItem : subitem,
                      itemContent : itemContent
                    };
                  });
                });
              }else {
                throw new Error('Unknown item type');
              }
            }).reduce(function(a,b){
              return a.concat(b);
            },[]);

        return itemTuplePromises;
      }).reduce(function(a,b){
        return a.concat(b);     //flatten out item promises from all sections
      },[])).then(function(allItemContentTuples){
        //now we have all item contents in one big array allItemContents

        var totalChosenResponses = {
          A : 0,
          B : 0,
          C : 0,
          D : 0
        };

        var answerLetterTokens;

        function initAnswerLetterTokens(){
          answerLetterTokens = {
            A : 2,
            B : 2,
            C : 2,
            D : 2
          };
        }

        function getValidAnswerLetterTokens(){
          return Object.keys(answerLetterTokens).filter(function(answerPosition){
            return answerLetterTokens[answerPosition] > 0;
          });
        }

        function selectCorrectAnswerLetter(){

          var validAnswerLetterTokens = getValidAnswerLetterTokens();

          if(!validAnswerLetterTokens.length){
            //console.log('All tokens are zero. Re-initing tokens',answerLetterTokens);
            //re-init data structure if empty if empty
            initAnswerLetterTokens();
            validAnswerLetterTokens = getValidAnswerLetterTokens();
          }

          var selectedRandomTokenIndex = Math.floor(Math.random() * validAnswerLetterTokens.length);

          var answerLetter = validAnswerLetterTokens[selectedRandomTokenIndex];

          answerLetterTokens[answerLetter]--;    //decrement token
          //console.log('Decrementing answer letter token',answerLetterTokens[answerLetter]);
          totalChosenResponses[answerLetter]++;

          return answerLetter;
        }

        initAnswerLetterTokens();
        //console.log('Initing tokens',answerLetterTokens);

        allItemContentTuples.
          forEach(function(tuple){
            if(!tuple.itemContent.responses) return;

            var letters = "ABCDEFG".split('');
            var retry = 5;
            do{
              //console.log(tuple.itemContent.responses);
              var correctResponse = tuple.itemContent.responses.findIndex(function(response){
                return response.isCorrect;
              });

              if(correctResponse<0){
                console.error('no answer',tuple.itemContent);
              }

              //choose the position of the correct answer, from the set of available correct answers,
              //where correct can be defined as any category which has more than zero tokens available to it
              var correctAnswerLetter = //letters[correctResponse];
                tuple.itemContent.responses[correctResponse].isPositionFixed||!shuffle ?
                letters[correctResponse] :
                selectCorrectAnswerLetter();

              // console.log('Correct answer letter needs to be',correctAnswerLetter);
              var answerIndex = "ABCDEFG".indexOf(correctAnswerLetter);

              if(answerIndex >= tuple.itemContent.responses.length)answerIndex = tuple.itemContent.responses.length-1;

              if(!tuple.itemContent.responses[answerIndex].isPositionFixed){
                var tmp = tuple.itemContent.responses[answerIndex];
                tuple.itemContent.responses[answerIndex] = tuple.itemContent.responses[correctResponse];
                tuple.itemContent.responses[correctResponse] = tmp;

                for(var i = 0; i < tuple.itemContent.responses.length; i++){
                  tuple.itemContent.responses[i].answerLetter = letters[i];
                }

                if(answerIndex < 0){
                  console.error('no answer',tuple.itemContent);
                }
                tuple.composerItem.correctAnswerLetter = 'ABCDEFG'.charAt(answerIndex);
                retry = 0;
              } else {
                retry--;
              }
            } while (retry > 0);
          });
      });
    },
    /** Saved the exam to the server */
    save : function(email){
      return Promise.resolve().then(function(){
        var json = this.toJSON();
        this.previous_checksum = json.checksum;
        this.checksum = this.generateChecksum();
        // console.log('generating new checksum', this.checksum);
        return json;
      }.bind(this)).then(function(serializableExam){
        serializableExam.date_updated = new Date();
        this.needsSave = false;

        // console.log('Saving exam',serializableExam);
        return couch.db.putExam(this.id,serializableExam,email);
      }.bind(this));
    },
    /** Adds a section to the exam */
    addSection : function(){
      $http.put('/tools/sessions/action',{action:{name:'add section',metadata:JSON.stringify({
        examName:this.name,
      })}});

      var x = {name:'New Exam Section',}
      if(this.sections.length > 0){
        x.options = JSON.parse(JSON.stringify(this.sections[0].options));
        x.options.heading = '';
      }
      var newSection = new ComposerSection(this, x, uuid.v4());
      this.sections.push(newSection);
      return newSection;
    },

    removeItem: function(item) {
      return this.removeItems([item]);
    },

    removeItems: function(items) {
      if (!items || !items.length) {
        throw new Error("Exam.removeItems() needs an array with at least 1 item");
      }
      $http.put('/tools/sessions/action',{action:{name:'remove items',metadata:JSON.stringify({
        examName:this.name,
        items:items.map(function(item){
          if(item instanceof ComposerPassage){
            return {
              passage :item.getLabel(),
              items:item.items.length,
            };
          } else if(item instanceof ComposerItem){
            return {item:item.getLabel()};
          } else if(item instanceof ComposerSection){
            return {
              section :item.name,
              items:item.items.length,
            };
          }
        }),
      })}});
      //console.log('removing multiple items', items.length);
      var treeItems = this.getAllTreeElements();
      var self = this;

      return $q
        .all(items.map(function(t) {
          return t.removeFromExam();
        }))
        .then(function(removed) {
          if (self.inDragging) {
            return;
          }

          items = removed.reduce(function(a, b) {
            return wrapArray(a).concat(wrapArray(b));
          });
          var pref = self.pickNextInsertionPointCandidate(treeItems, wrapArray(items));
          self.setInsertionPoint(pref);
        });
    },

    pickNextInsertionPointCandidate: function(items, ignore) {
      // find the last item selected
      var lastItem = ignore.sort(function(a, b) {
        return items.indexOf(a) < items.indexOf(b);
      })[0];
      var lastIdx = items.indexOf(lastItem);

      // now, set the new insertion point
      // let's find the best candidate to be the next insertion point
      var pref;
      var self = this;
      var findPref = function(dir) {
        for (var i = lastIdx; i >= 0 && i < items.length; i+= dir) {
          //if (items[i] !== self && (!compareClass || items[i] instanceof compareClass)) {
          if (ignore.indexOf(items[i]) === -1
              && (!(items[i] instanceof ComposerPassage) || items[i].items.length > 0)) {
            return items[i];
          }
        }
      };

      pref = findPref(1) || findPref(-1);
      if (pref) {
        return pref;
      }
      // might have been because there's nothing in the exam, so let's look at it's tree now
      // only happens when deleting the last section
      var elements = this.getAllTreeElements();
      if (elements.length) {
        return elements[0];
      }

      // nothing found, default to error
      throw new Error("couldn't find candidate to be next insertion point");
    },

    /** Sets the insertion point to the first section */
    setDefaultInsertionPoint : function(){
      this.setInsertionPoint(this.sections[this.sections.length-1]);      //set it to first section, which will always be available
    },

    setInsertionPoint: function(point, skipFocus) {
      if (point === this.insertionPoint) {
        return;
      }

      if (!point) {
        throw new Error('insertionPoint is empty');
      }

      //console.log('setInsertionPoint', point && point.getLabel ? point.getLabel() : point, skipFocus);
      this.skipInsertionPointFocus = skipFocus;

      // if composer passage, then select the parent
      if (point instanceof ComposerPassage && point.items.length === 0) {
        this.setNextInsertionPoint(point, skipFocus);
      } else {
        this.insertionPoint = point;
      }
    },

    /** Serializes the exam to JSON */
    toJSON : function(){
      return {
        name : this.name,
        //_attachments : this._attachments,
        date_created : this.date_created,
        date_updated : this.date_updated,
        options : this.options,
        folder : this.folder,
        code : this.code,
        id : this.id,
        checksum: this.checksum,
        sections : this.sections.map(function(section){
          return section.toJSON();
        })
      };
    },
    _getPreorderFlattenedTree : function(includeSections,includePassages){
      return this.sections.map(function(section){
        return (includeSections ? [section] : []).concat(
          section.items.map(function(itemOrPassage){
            if(itemOrPassage.items){
              //he's a passage
              //return his sub-items
              return (includePassages ? [itemOrPassage] : []).concat(itemOrPassage.items);
            }else{
              //he's an item
              //return itself
              return itemOrPassage;
            }
          }).reduce(function(a,b){return a.concat(b);},[]));
      }).reduce(function(a,b){return a.concat(b);},[]);
    },
    /** Helper function to flatten the exam tree to a single array */
    _getAllItems : function(){
      return this.sections.map(function(section){
        return section.items.map(function(itemOrPassage){
          if(itemOrPassage.items){
            //he's a passage
            //return his sub-items
            return itemOrPassage.items;
          }else{
            //he's an item
            //return itself
            return itemOrPassage;
          }
        }).reduce(function(a,b){return a.concat(b);},[]);
      }).reduce(function(a,b){return a.concat(b);},[]);
    },

    /** like _getAllItems but returns sections and passages **/
    getAllTreeElements: function() {
      var all = [];
      this.sections.forEach(function(section) {
        all.push(section);

        section.items.forEach(function(itemOrPassage) {
          // passage, so get items
          if (itemOrPassage.items) {
            all = all.concat([itemOrPassage], itemOrPassage.items);
          } else {
            all.push(itemOrPassage);
          }
        });
      });

      return all;
    },


    /** sets the insertion point with the closest element in the tree (after being deleted) **/
    setNextInsertionPoint: function(item, skipFocus) {
      var allItems = this.getAllTreeElements();
      var idx = allItems.indexOf(item);

      if (idx === -1) {
        throw new Error("setInsertionPoint, couldn't find item in exam tree");
      }

      console.log('setNextInsertionPoint', item, skipFocus);

      // idx should be the ID of the element that was deleted
      var possible = allItems[idx - 1] || allItems[idx + 1];
      this.setInsertionPoint(possible || this.getAllTreeElements().pop(), skipFocus);
    },

    /** Remove a section from the exam tree */
    removeChild : function(sectionToRemove){
      //only allow delete if there is more than one section
      if(this.sections.length > 1){
        var index = this.sections.indexOf(sectionToRemove);
        if( index  > -1 ){
          this.sections.splice(index,1);
        }
      }
      this._treeHasMutated();
    },
    /** Inserts the given items in the exam tree */
    receiveDroppedItems : function(items, skipInsertionPointFocus, target) {
      this.inDragging = true;
      var self = this;

      if (!Array.isArray(items)) {
        items = [items];
      }

      if (target instanceof Exam) {
        var allElements = this.getAllTreeElements();
        target = allElements[allElements.length - 1];
      }

      if (items[0] === target) {
        console.log('dropping into itself');
        return $q.when(false);
      }

      return this.insertItemOrComposerObject(items, target, skipInsertionPointFocus)
      .then(function(inserted) {
        self.inDragging = false;

        return inserted;
      });
    },

    /** Insert a passage from the exam tree into the exam tree */
    insertComposerPassage : function(composerPassage){
      $http.put('/tools/sessions/action',{action:{name:'add items',metadata:JSON.stringify({
        examName:this.name,
        items:[{
          passage:composerPassage.getLabel(),
          items:composerPassage.items.map(function(subitem){
            return subitem.getLabel();
          }),
        }],
      })}});

      var insertAfterCommonBlock = function(composerPassageInsertionPoint){
        composerPassageInsertionPoint.composerParent.insertAfter(composerPassageInsertionPoint,composerPassage);
      };

      var ret;
      if(this.insertionPoint instanceof ComposerItem){
        ret = examItemHasPassage(this.insertionPoint.examItem).then(function(hasPassage){
          if(hasPassage){
            //we are dragging and dropping onto another common block.
            //insert him after the parent common block.
            insertAfterCommonBlock(this.insertionPoint.composerParent);
          }else{
            //we are dragging him onto an item without a common block.
            //insert him after the item.
            insertAfterCommonBlock(this.insertionPoint);
          }
        }.bind(this));
      }else if(this.insertionPoint instanceof ComposerPassage){
        //insert him after the given common block
        insertAfterCommonBlock(this.insertionPoint);
      }else if(this.insertionPoint instanceof ComposerSection){
        //insert him after the given composer section
        this.insertionPoint.prepend(composerPassage);
      } else{
        throw new Error('Insertion point is in a bad state, and has uknown type');
      }

      this._tagNodesWithPreorderIndex();
      return ret;
    },

    /** Insert a question from the grid, or an item from the exam tree */
    insertItemOrComposerObject : function(itemOrComposerObject, newInsertionPoint, skipFocus) {
      if (Array.isArray(itemOrComposerObject) && itemOrComposerObject.length === 1) {
        itemOrComposerObject = itemOrComposerObject[0];
      }

      //update insertion point if need be
      if (newInsertionPoint){
        this.setInsertionPoint(newInsertionPoint, skipFocus);
      }

      if (itemOrComposerObject instanceof ComposerDropTarget){
        //he's a single composer object
        return this.insertComposerObject(itemOrComposerObject);
      } else if (Array.isArray(itemOrComposerObject)){
        // multiple ComposerDropTargets
        if (itemOrComposerObject.length > 0 && itemOrComposerObject[0] instanceof ComposerDropTarget) {
          return this.insertComposerObjects(itemOrComposerObject);
        }else{
          //he's an array of items
          return this.insertItems(itemOrComposerObject);
        }
      }else if(itemOrComposerObject.itemContent){
        //he's a single item. wrap him in an array
        return this.insertItems([itemOrComposerObject]);
      }else{
        throw new Error('Unrecognized type.');
      }
    },

    /** Insert an array of items from the exam tree into the exam tree */
    insertComposerObjects : function(items) {
      var self = this;
      var i = 0;
      var doNext = function () {
        var composerObject = items.shift();
        if (!composerObject) {
          return $q.when(true);
        }

        console.log('Inserting', i++, composerObject.getLabel());

        var composerParent, oldIndex;
        if(composerObject.composerParent){
          composerParent = composerObject.composerParent;
          oldIndex = composerParent.items.findIndex((i)=>i.id===composerObject.id);
          composerObject.remove();        //we're moving him, so remove him from the tree
        }

        function recover(){
          if(composerParent){
            composerParent.insertAfter(composerParent.items[oldIndex-1], composerObject);
            self._treeHasMutated();
          }
        }

        if (composerObject instanceof ComposerItem) {
          console.log('adding ', composerObject)
          var newItemsPromise = self.insertItems([composerObject.examItem]);

          var lastPromise = newItemsPromise;
          return newItemsPromise.then(function(newItems){
            newItems[0].correctAnswerLetter = composerObject.correctAnswerLetter;
            return doNext();
          }).catch(recover);
          return newItemsPromise;
        } else if(composerObject instanceof ComposerPassage) {
          return $q.when(self.insertComposerPassage(composerObject)).then(doNext).catch(recover);
        } else {
          throw new Error('Unable to insert object of unknown type into composer tree.');
        }
      };

      return doNext();
    },

    /** Insert a single item from the exam tree into the exam tree */
    insertComposerObject : function(composerObject){
      var self = this;
      var composerParent, oldIndex;
      if(composerObject.composerParent){
        composerParent = composerObject.composerParent;
        oldIndex = composerParent.items.findIndex((i)=>i.id===composerObject.id);
        composerObject.remove();        //we're moving him, so remove him from the tree
      }

      function recover(){
        if(composerParent){
          composerParent.insertAfter(composerParent.items[oldIndex-1], composerObject);
          self._treeHasMutated();
        }
      }

      if(composerObject instanceof ComposerItem){
        //very simple - just unwrap the exam item and pass it along.
        var newItemsPromise = this.insertItems([composerObject.examItem]);

        //we need to copy over the composer attributes into the new item
        //FIXME: it would be better to modify insertItems to accept composer items.
        newItemsPromise.then(function(newItems){
          newItems[0].correctAnswerLetter = composerObject.correctAnswerLetter;
        }).catch(recover);
        return newItemsPromise;
      //just like D&D from the question bank.
      }else if(composerObject instanceof ComposerPassage){
        return this.insertComposerPassage(composerObject).catch(recover);
      }else{
        throw new Error('Unable to insert object of unknown type into composer tree.');
      }
    },

    hasMC : function(){
      var items = this._getAllItems();
      var flag = false;
      for(var i = 0; i < items.length; i++){
        if(items[i].examItem.resolvedItemContent.itemType == "MC"){
          flag = true;
        }
      }
      return flag;
    },

    /**
      Handles insertion of questions from grid.
      Rules for insertion are:

      if dropped item does not have a passage
        if the insertion point is a composer item whose parent is a composer passage
          to which dropped item does belong
            -> then insert item in the insertion point's composer parent, after the insertion point
          to which dropped item does not belong
            -> then insert item in the insertion point's composer parent section parent, after the insertion point's composer parent

        if the insertion point is a composer item whose parent is a section
          -> then insert item in the insertion point's section parent, after the insertion point

        if the insertion point is a passage
          to which dropped item does belong
            -> then insert (append?) item in the passage insertion point
          to which dropped item does not belong
            -> then insert item in the insertion point's section parent, after the insertion point

        if the insertion point is a section
          -> then insert (append) item to the section insertion point

      if dropped item has a passage
        if the insertion point is a composer item whose parent is a composer passage
          to which dropped item does belong
            -> then insert item in the insertion point's passage parent, after the insertion point
          to which dropped item does not belong
            -> then find the associated passage in the  insertion point's passage parent's section parent if it exists; OR create it, and append it in the section after the insertion point's passage parent
            -> finally insert the item in the new passage

        if the insertion point is a composer item whose parent is a section
          -> then find the associated passage in the section if it exists; OR create it, and append it in the section after the insertion point's passage parent
          -> finally insert the item in the new passage

        if the insertion point is a passage
          to which dropped item does belong
            -> then insert (append) item in the passage insertion point
          to which dropped item does not belong
            -> then find the associated passage in the passage's section parent if it exists; OR create it, and append it in the insertion point's parent section,  after the insertion point
            -> finally insert the item in the new passage

        if the insertion point is a section
            -> then find the associated passage in the section if it exists; OR create it, and append it in the section
            -> finally insert the item in the new passage



      return value is new ComposerItem
    */
    insertItems : function(itemsToInsert){
      $http.put('/tools/sessions/action',{action:{name:'add items',metadata:JSON.stringify({
        examName:this.name,
        items:itemsToInsert.map(function(item){
          if(item instanceof ComposerItem){
            return {item:item.getLabel()};
          } else {
            return {item:item.origin || item.databaseName+'/'+
            item.part.section.topic.chapter.chapterName+'/'+
            (item.part.section.topic.chapter.chapterNumber+1)+'/'+
            item.itemName};
          }
        })
      })}});

      var self = this;

      var allComposerItems = self._getAllItems();
      var initialInsertionPoint = self.insertionPoint;

      var allItems = allComposerItems.map(function(composerItem){
        return composerItem.examItem;
      });

      var itemsNotAlreadyInserted =
          itemsToInsert.filter(function(item){
            return allItems.findIndex(function(examItem){
              return examItem.itemId === item.itemId;
            }) === -1;
          });

       //FIXME: I'm not sure if there is a more elegant way to filter content of a list by its promise
      var itemPassageTuplePromises = _getItemTuplePromises();

      var newItemPromises = _getNewItemPromises();
      _wrapUp();

      function _getItemTuplePromises(){
        return itemsNotAlreadyInserted.map(function(item){
          return examItemHasPassage(item).then(function(hasPassage){
            return {
              item : item,
              hasPassage : hasPassage
            };
          });
        });
      }

      function _getNewItemPromises(){
        return $q.all(itemPassageTuplePromises).then(function(itemPassageTuples){

          var defer = $q.defer();

          var newItems = [];

          function insertItemAsyncLoop(){

            var itemPassageTuple = itemPassageTuples.shift();

            if(!itemPassageTuple) return defer.resolve(newItems);

            var item = itemPassageTuple.item;

            if(itemPassageTuple.hasPassage){
              // if dropped item has a passage
              var newItemToReturn = _processItemWithPassage(item);
            }else{
              // if dropped item does not have a passage
              newItemToReturn = _processItemWithoutPassage(item);
            }

            $q.when(newItemToReturn).then(function(resolvedNewItemToReturn){
              //update the insertion point
              //this is slightly evil, but it makes the algebra simpler

              // don't update insertion point if it is a section
              if (!(self.insertionPoint instanceof ComposerSection)) {
                self.setInsertionPoint(resolvedNewItemToReturn, true);
              }
              newItems.push(resolvedNewItemToReturn);

              insertItemAsyncLoop();
            });

          }

          insertItemAsyncLoop();

          return defer.promise;
        });
      }

      function _processItemWithoutPassage(item){
        var newItemToReturn;

        //if the insertion point is a composer item whose parent is a composer passage
        if(self.insertionPoint instanceof ComposerItem){
          //if the insertion point is a composer item...
          if(self.insertionPoint.composerParent instanceof ComposerPassage){
            //...whose parent is a passage
            var passageParent = self.insertionPoint.composerParent;
            var sectionGrandparent = self.insertionPoint.composerParent.composerParent

            //insert in section after the passage parent
            newItemToReturn = new ComposerItem(sectionGrandparent, self, {examItem:item});
            sectionGrandparent.insertAfter(passageParent,newItemToReturn);

          }else if(self.insertionPoint.composerParent instanceof ComposerSection){
            //...whose parent is a section
            var parentSection = self.insertionPoint.composerParent;

            //then insert him in the parent section after the current insertion point
            newItemToReturn = new ComposerItem(parentSection, self, {examItem:item});
            parentSection.insertAfter(self.insertionPoint,newItemToReturn);
          }else{
            throw new Error('Unexpected condition: Insertion point is a ComposerItem whose parent is neither a ComposerPassage nor a ComposerSection');
          }
        }else if(self.insertionPoint instanceof ComposerPassage){
          //if the insertion point is a passage
          //we don't belong to a passage, so just insert him in the section
          var passage = self.insertionPoint,
            section = self.insertionPoint.composerParent;

          newItemToReturn = new ComposerItem(section, self, {examItem:item});
          section.insertAfter(passage,newItemToReturn);

        }else if(self.insertionPoint instanceof ComposerSection){
          //if the insertion point is a section
          //then just append him to the end
          section = self.insertionPoint;

          newItemToReturn = new ComposerItem(section, self, {examItem:item});
          section.append(newItemToReturn);
        }else{
          throw new Error('Insertion point has unknown type.');
        }

        return newItemToReturn;
      }

      function _processItemWithPassage(item){
        console.log(item);
        var newItemToReturn;
        return item.itemContent.then(function(itemContent){
          return lazyLoad.loadPassageFromId(itemContent.passageId,item);
        }).then(function(passageForItem){
          //if the insertion point is a composer item whose parent is a composer passage
          if(self.insertionPoint instanceof ComposerItem){
            //if the insertion point is a composer item...
            if(self.insertionPoint.composerParent instanceof ComposerPassage){
              //...whose parent is a passage
              var passageParent = self.insertionPoint.composerParent;


              if(_itemBelongsToPassage(passageForItem,passageParent)){
                //to which dropped item does belong

                //-> then insert item in the insertion point's composer parent, after the insertion point
                newItemToReturn = new ComposerItem(passageParent, self, {examItem:item});
                passageParent.insertAfter(self.insertionPoint, newItemToReturn);
              }else{

                passageParent = self.insertionPoint.composerParent;
                var grandparentSection = passageParent.composerParent;

                //to which dropped item does not belong
                newItemToReturn = _findOrCreatePassageAndAppendItem(item, passageForItem, passageParent, grandparentSection);
               }
            }else if(self.insertionPoint.composerParent instanceof ComposerSection){
              //...whose parent is a section
              var parentSection = self.insertionPoint.composerParent;

              newItemToReturn = _findOrCreatePassageAndAppendItem(item, passageForItem, self.insertionPoint, parentSection);
            }else{
              throw new Error('Unexpected condition: Insertion point is a ComposerItem whose parent is neither a ComposerPassage nor a ComposerSection');
            }
          }else if(self.insertionPoint instanceof ComposerPassage){
            //if the insertion point is a passage

            if(_itemBelongsToPassage(passageForItem,self.insertionPoint)){
              //to which dropped item does belong
              //then append the item in the passage
              newItemToReturn = new ComposerItem(self.insertionPoint, self, {examItem:item});
              self.insertionPoint.append(newItemToReturn);
            }else{
              //to which dropped item does not belong
              //-> then find the associated passage in the passage's section parent if it exists; OR create it, and append it in the insertion point's parent section,  after the insertion point
              //-> finally insert the item in the new passage
              newItemToReturn = _findOrCreatePassageAndAppendItem(item, passageForItem, self.insertionPoint, self.insertionPoint.composerParent);
            }
          }else if(self.insertionPoint instanceof ComposerSection){
            //if the insertion point is a section
            //-> then find the associated passage in the section if it exists; OR create it, and append it in the section
            //-> finally insert the item in the new passage
            newItemToReturn = _findOrCreatePassageAndAppendItem(item, passageForItem, null, self.insertionPoint);
          }else{
            throw new Error('Insertion point has unknown type.');
          }

          return newItemToReturn;
        });
      }

      function _findOrCreatePassageAndAppendItem(item, passageForItem, passageInsertionPoint, parentSection){
        console.log(item);
        var newItemToReturn;
        var composerPassageForItem = _getComposerPassageForItem(item,passageForItem);
        if(composerPassageForItem){
          //TODO: this is common code. we can move this out
          //if the associated composer passage exists
          //insert him in the existing passage
          newItemToReturn = new ComposerItem(composerPassageForItem, self, {examItem:item});

        }else{
          //-> create new composer passage, and append it in the section after the insertion point's passage parent
          //-> finally insert the item in the new passage

          //insert after the insertion point
          composerPassageForItem = new ComposerPassage(parentSection, self, {examPassage:passageForItem,});

          if(passageInsertionPoint){
            //if we are given a parent passage, insert the new passage after it
            parentSection.insertAfter( passageInsertionPoint, composerPassageForItem);
          }else{
            //this will be null when dropping into a section
            //then we just append the new item
            parentSection.append(composerPassageForItem);
          }

          //create the new item, and append to the new passage
          newItemToReturn = new ComposerItem(composerPassageForItem, self, {examItem:item});
        }

        composerPassageForItem.append(newItemToReturn); //append him to the appropriate passage

        return newItemToReturn;
      }

      function _getComposerPassageForItem(item,passageForItem,insertionPoint){
        //-> then find the associated passage in the  insertion point's passage parent's section parent if it exists; OR create it, and append it in the section after the insertion point's passage parent
        //-> finally insert the item in the new passage
        var sectionForInsertionPoint = _getSectionForInsertionPoint();

        //get the passages from the section
        var composerPassagesForSection = sectionForInsertionPoint.items.filter(function(item){
          return item instanceof ComposerPassage;
        });

        var examPassagesForSection = composerPassagesForSection.map(function(composerPassage){
          return composerPassage.examPassage;
        });

        var indexOfPassageInSection = examPassagesForSection.findIndex(function(passage){ return passage.passageId === passageForItem.passageId });

        return composerPassagesForSection[indexOfPassageInSection];
      }

      function _itemBelongsToPassage(passageForItem,passage){
        return passage.examPassage.passageId === passageForItem.passageId;
      }

      function _getSectionForInsertionPoint(){
        var sectionForInsertionPoint;
        if(self.insertionPoint instanceof ComposerItem){
          //if the insertion point is a composer item...
          if(self.insertionPoint.composerParent instanceof ComposerSection){
            //...whose parent is a section
            sectionForInsertionPoint = self.insertionPoint.composerParent;
          }else if(self.insertionPoint.composerParent.composerParent instanceof ComposerSection){
            //...whose parent is a passage
            sectionForInsertionPoint = self.insertionPoint.composerParent.composerParent;
          }else{
            throw new Error('Unable to find section for insertion point');
          }
        }else if(self.insertionPoint instanceof ComposerPassage){
          sectionForInsertionPoint = self.insertionPoint.composerParent;
        }else if(self.insertionPoint instanceof ComposerSection){
          sectionForInsertionPoint = self.insertionPoint;
        }else{
          throw new Error('Unable to find section for insertion point');
        }
        return sectionForInsertionPoint;
      }

      function _wrapUp(){

        newItemPromises.then(function(newItems){

          //console.log( 'newItems',allItems);

          //self.insertionPoint = newItems[0];  //set the insertion point to the first inserted item

          //console.log('All insertions complete. Exam state is now',self);

          self._treeHasMutated();     //notify that tree has mutated
        });
      }

      return newItemPromises;
    },

    /** if the checksum has changed, like a new item added or something **/
    hasItemsChange: function () {
      console.log('hasItemsChange', this.checksum, this.previous_checksum);
      return false;//this.checksum !== this.previous_checksum;
    },

    generateChecksum: function() {
      var checksum = '';
      this._getAllItems().map(function(item){
        checksum += item.examItem.URL;
      });

      return md5.createHash(checksum);
    },

    /** Helper function called after content of a tree has changed in order to refresh question numbers and tab index and refresh cached flattened list of exam items. */
    _treeHasMutated : function(){
      //this is used to facilitate fast lookups
      //note that it would probably be better
      this._allExamItems = this._getAllItems().map(function(item){
        return item.examItem;
      });

      this._tagNodesWithPreorderIndex();
      this._tagNodesWithQuestionNumber();

      this.needsSave = true;
    },

    /** Returns true if given examItem is contained by the exam tree */
    containsExamItem : function(examItem){
      return this._allExamItems ? this._allExamItems.findIndex(function(item){return item.itemId === examItem.itemId}) > -1 : false;
    },

    /** Helper function used by layout to wait for all exam item promises to resolve */
    waitForAllItemsToResolve : function(){
      var allPromises = this.sections.map(function(section){
      return section.items.map(function(item){
        return item.items ?
        [item.examPassage.passageHTML].concat(item.items.map(function(item2){ return item2.examItem.itemContent})) :
        [item.examItem.itemContent];
      }).reduce(function(a,b){ return a.concat(b); },[]);   //flatten
      }).reduce(function(a,b){ return a.concat(b); },[]);     //flatten
      return $q.all(allPromises);
    },

    /** Helper function returns true to if any section contains short answers */
    hasShortAnswers : function () {
      return this.sections.map(function(section){ return section.hasShortAnswers(); })
        .filter(function(e) { return e; })
        .length > 0;
    },

    /** Rename the exam on the client and the server */
    rename : function (newName){
      var deferred = $q.defer();
      if (!newName) {
        deferred.resolve('empty');
        return deferred.promise;
      }

      var exam = this;
      couch.db.hasExam(newName).then(function(found){
        if(found){
          return deferred.resolve('duplicate')
        }else{
          couch.db.putExam(exam.id,exam).then(function(response){
            var old = exam.name;
            exam.name = newName;
            return couch.db.removeExam(exam.id);
          }).then(function(){
            deferred.resolve('ok')
          }, function(res){
            console.error('renaming exam failed', res);
            deferred.resolve('error');
          })
        }
      });

      return deferred.promise;
    },

    /* export exam to blackboard */
    exportToBB : function (){
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      var exam = this;
      return this.chooseCorrectAnswers(true)
      .then(function(){
        return new Promise(function(resolve, reject) {
          var node,subnode,text,questions,question;
          // var xmlDoc = document.implementation.createDocument(null,"POOL");
          // var root = xmlDoc.getElementsByTagName("POOL")[0];
          var ident = s4()+s4();
          var xmlDoc = document.implementation.createDocument(null,"questestinterop");
          var root = xmlDoc.getElementsByTagName("questestinterop")[0];
          var questionNodes = [];
          var questions = exam._getAllItems();
          var finish = function(){
            function processQuestions(items, index, passage){
              for(var i = 0; i < items.length; i++){
                var question = xmlDoc.createElement("item");
                question.setAttribute("ident", ident+(index<100?'-0':'-')+(index<10?'0':'')+index);
                question.setAttribute("title", "examgen export multiple choice");
                var nodeA = xmlDoc.createElement("itemmetadata");
                question.appendChild(nodeA);
                var nodeB = xmlDoc.createElement("qtimetadata");
                nodeA.appendChild(nodeB);
                nodeA = nodeB;
                nodeB = xmlDoc.createElement("qtimetadatafield");
                nodeA.appendChild(nodeB);
                nodeA = nodeB;
                nodeB = xmlDoc.createElement("fieldlabel");
                nodeB.textContent = "question_type";
                nodeA.appendChild(nodeB);
                nodeB = xmlDoc.createElement("fieldentry");

                if(items[i].examItem.resolvedItemContent.itemType == 'ER' || items[i].examItem.resolvedItemContent.itemType == 'SR'){
                  nodeB.textContent = "essay";
                  question.setAttribute("title", "examgen export essay");
                } else {
                  nodeB.textContent = "multiple_choice";
                  question.setAttribute("title", "examgen export multiple choice");
                }
                nodeA.appendChild(nodeB);

                nodeA = xmlDoc.createElement("presentation");
                question.appendChild(nodeA);
                nodeB = xmlDoc.createElement("material");
                var nodeC = xmlDoc.createElement("mattext");
                nodeC.setAttribute("texttype","text/html");
                nodeC.textContent = ((items[i].examItem.resolvedItemContent.resolvedPassage?items[i].examItem.resolvedItemContent.resolvedPassage.resolvedPassageHTML:'') + items[i].examItem.resolvedItemContent.prompt).replace(/\/databases/g,'https://'+$location.host()+'/databases');
                nodeB.appendChild(nodeC);
                nodeA.appendChild(nodeB);

                var isMC = (items[i].examItem.resolvedItemContent.itemType == "MC" || items[i].examItem.resolvedItemContent.itemType == "TF");
                var correctAnswerIndex;

                nodeB = xmlDoc.createElement(isMC?"response_lid":"response_str");
                nodeB.setAttribute("rcardinality","Single");
                nodeB.setAttribute("ident","answer"+index);
                nodeC = xmlDoc.createElement(isMC?"render_choice":"render_fib");
                nodeB.appendChild(nodeC);
                nodeA.appendChild(nodeB);
                nodeA = nodeC;

                if(isMC){
                  var answers = items[i].examItem.resolvedItemContent.responses;
                  for(var a = 0; a < answers.length; a++){
                    nodeB = xmlDoc.createElement("response_label");
                    nodeB.setAttribute("ident","choice"+(a+1));
                    nodeC = xmlDoc.createElement("material");
                    nodeB.appendChild(nodeC);
                    nodeA.appendChild(nodeB);
                    nodeB = nodeC;
                    nodeC = xmlDoc.createElement("mattext");
                    nodeC.setAttribute("texttype","text/html");
                    nodeC.textContent = answers[a].answer.replace(/\/databases/g,'https://'+$location.host()+'/databases');
                    nodeB.appendChild(nodeC);
                    if(answers[a].isCorrect){
                      correctAnswerIndex = a;
                    }
                  }
                } else {
                  nodeB = xmlDoc.createElement("response_label");
                  nodeB.setAttribute("ident","null");
                  nodeA.appendChild(nodeB);
                }

                nodeA = xmlDoc.createElement("resprocessing");
                question.appendChild(nodeA);
                nodeB = xmlDoc.createElement("outcomes");
                nodeC = xmlDoc.createElement("decvar");
                nodeC.setAttribute("varname","SCORE");
                nodeC.setAttribute("maxvalue","100");
                nodeC.setAttribute("minvalue","0");
                nodeC.setAttribute("vartype","Decimal");
                nodeB.appendChild(nodeC);
                nodeA.appendChild(nodeB);
                nodeB = xmlDoc.createElement("respcondition");
                nodeA.appendChild(nodeB);
                nodeA = nodeB;
                nodeB = xmlDoc.createElement("conditionvar");
                if(isMC){
                  nodeC = xmlDoc.createElement("varequal");
                  nodeC.setAttribute("respident","answer"+index);
                  nodeC.textContent = "choice"+(correctAnswerIndex+1);
                  nodeB.appendChild(nodeC);
                  nodeA.appendChild(nodeB);
                  nodeB = xmlDoc.createElement("setvar");
                  nodeB.setAttribute("varname","SCORE");
                  nodeB.setAttribute("action","Set");
                  nodeB.textContent = "100";
                } else {
                  nodeC = xmlDoc.createElement("other");
                  nodeB.appendChild(nodeC);
                }
                nodeA.appendChild(nodeB);
                questionNodes.push(question);
                index++;
              }
              return index;
            }

            var node = xmlDoc.createElement("assessment");
            node.setAttribute("title", exam.name);
            node.setAttribute("ident", ident);
            root.appendChild(node);
            root = node;
            var questionRoot = xmlDoc.createElement("section");
            questionRoot.setAttribute("ident", "root_section");

            var i = 1;
            i = processQuestions(questions, i, null);

            for(var q = 0; q < questionNodes.length; q++){
              questionRoot.appendChild(questionNodes[q]);
            }
            root.appendChild(questionRoot);
            //push question nodes
            var examData = '<?xml version="1.0" encoding="utf-8"?>'+new XMLSerializer().serializeToString(xmlDoc).replace(/&amp;/g,'&').replace(/&lt;/g,'<').replace(/&gt;/g,'>');

            xmlDoc = document.implementation.createDocument(null,"manifest");
            root = xmlDoc.getElementsByTagName("manifest")[0];
            root.setAttribute("identifier", ident+"-manifest");

            node = xmlDoc.createElement("organization");
            root.appendChild(node);
            node = xmlDoc.createElement("resources");
              text = xmlDoc.createElement("resource");
                text.setAttribute("type", 'imsqti_questestinterop_xmlv1p2');
                text.setAttribute("href", ident+".xml");
              node.appendChild(text);
            root.appendChild(node);

            var manifestData = '<?xml version="1.0" encoding="utf-8"?>'+new XMLSerializer().serializeToString(xmlDoc);

            var zip = new JSZip();
            zip.file("imsmanifest.xml", manifestData);
            zip.file(ident+".xml", examData);
            resolve(zip.generateAsync({type:"blob"}));
          }
          renderMML(questions,0, function(){
            return convertImage(questions,0,finish);
          });
        });
      })
      .catch(function(err){
        console.error(err);
      });
    },

    /* export exam to from node API */
    serverExport : function (target){
      var exam = this;
      return $http.get(`/api/${$rootScope.profile.UserId}/exams/${exam.id}/export`, {responseType: 'arraybuffer', params:{target}});
    },

    googleFormExport : function (gtool){
      const questionTemplate = '<div class="question"><span class="itemNumber">[[number]]</span><div class="itemContents"><div class="prompt">[[prompt]]</div>[[responses]]</div></div>';
      const responsesTemplate = '<div class="responses">[[responses]]</div>';
      const answerTemplate = '<span class="response" style="flex: 1 1 100%"><span class="responseNumber">[[number]]</span><span class="responseContents" style="flex: 1 1 100%">[[answer]]</span></span>';

      let questionNumber = 0;
      let resolveExamDialog = bootbox.dialog('<div class="text-center"><i class="icon-spinner icon-spin"></i> Resolving exam details...</div>');
      let exam = this;

      function resolveQuestion(item) {
        let questionHtml = questionTemplate.slice();
        questionHtml = questionHtml.replace('[[number]]',`${questionNumber += 1})&nbsp;&nbsp;`);
        questionHtml = questionHtml.replace('[[prompt]]',item.prompt);

        let inputWrapper;
        if (['MC', 'TF'].includes(item.itemType)) { // TODO Consolidate this to singular forEach
          let responsesHtml = responsesTemplate.slice();

          inputWrapper = document.createElement('div');
          item.responses.forEach(response => {
            let answerHtml = answerTemplate.slice();
            answerHtml = answerHtml.replace('[[number]]',`${response.answerLetter})&nbsp;&nbsp;`);
            answerHtml = answerHtml.replace('[[answer]]',response.answer);
            responsesHtml = responsesHtml.replace('[[responses]]', answerHtml+'[[responses]]');

            // inputWrapper.appendChild(labelEl);
          });

          responsesHtml = responsesHtml.replace('[[responses]]', '');
          questionHtml = questionHtml.replace('[[responses]]', responsesHtml);

        } else if (['SR', 'ER'].includes(item.itemType)) {
          // inputWrapper = document.createElement('div');
          // const inputEl = document.createElement('textarea');
          //
          // inputEl.name = item.id;
          // inputWrapper.appendChild(inputEl);
          //
          questionHtml = questionHtml.replace('[[responses]]', '');
        }

        const itemImgSrc = $(questionHtml)[0];
        itemImgSrc.style.width = '600px';
        itemImgSrc.id = item.id;
        return itemImgSrc;
      };

      $http.get(`/api/${$rootScope.profile.UserId}/exams/${exam.id}`,{params: {resolved: true, renderMML: true}} )
      .then(res=> {
        resolveExamDialog.hide();
        const resolvedExam = res.data;

        // Build preview HTML
        const previewWrapper = document.createElement('div');
        previewWrapper.classList.add('page');


        const headerEl = document.createElement('h4');
        headerEl.innerText = resolvedExam.name;
        previewWrapper.appendChild(headerEl);



        resolvedExam.sections.forEach((section, idx) =>{
          const sectionEl = document.createElement('div');

          if (res.data.sections.length > 1) {
            const sectionHeaderEl = document.createElement("h5");
            sectionHeaderEl.innerText = section.name;
            sectionEl.appendChild(sectionHeaderEl);
          }

          section.items.forEach(item => {
            const itemEl = document.createElement('div');
            itemEl.style.marginBottom = '40px';
            if (item.itemTypeId === 'Q') {
              itemEl.appendChild(resolveQuestion(item));

            } else if (item.itemTypeId === 'P'){
              const promptImgSrc = document.createElement('div');
              promptImgSrc.style.width = '600px';
              promptImgSrc.id = item.id;
              itemEl.appendChild(promptImgSrc);
              const passageItemCount = item.items.length;
              let passageInstructions;
              if (passageItemCount === 1) {
                passageInstructions = `<div>Questions ${questionNumber + 1} refers to the following:</div>`;
              } else {
                passageInstructions = `<div>Questions ${questionNumber + 1} ${passageItemCount > 2 ? 'through': 'and'} ${questionNumber + passageItemCount} refer to the following:</div>`;
              }

              promptImgSrc.innerHTML =  passageInstructions + item.passageHTML;

              item.items.forEach(subItem => {
                itemEl.appendChild(resolveQuestion(subItem));
              });
            }
            sectionEl.appendChild(itemEl);
          });

          previewWrapper.appendChild(sectionEl);
        });

        let gFormPayload;
        bootbox.dialog(previewWrapper,[{
          label: 'Export',
          class: 'btn-primary disabled',
          callback: () => {
            try {
              if (gFormPayload) {
                console.log(gFormPayload);
                bootbox.dialog('<div class="text-center"><i class="icon-spinner icon-spin"></i> Exporting Google Form...</div>');
                const scriptId = 'AKfycbzBn1G3zzmvV36A8qoDFekaNvEmqIopa_G575SaspmJlUKf8iPg9y2COf6imwirc5YJTg'
                gtool.client.script.scripts.run({
                  'scriptId': scriptId,
                  'resource': {
                    'function': 'createQuizForm',
                    'parameters': [gFormPayload]
                  }
                }).then((resp) => {
                  bootbox.hideAll();
                  alertify.success(`Google Form ${gFormPayload.name} exported successfully.`);
                  console.log(resp);
                }).catch((err)=> {
                  bootbox.hideAll();
                  alertify.error('Error occurred generating Google Form.')
                });
              } else {
                return false;
              }
            }catch (e){
              console.error(e);
            }
          }
        },{
          label: 'Cancel',
          class: 'btn-danger' ,
          callback: () => {bootbox.hideAll();}
        }],{
          header: 'Google Form Preview',
          classes: 'gform-preview'
        });
        const btnList = document.querySelectorAll('.modal-footer .btn-primary');
        const okBtn = [...btnList].find(el => el.textContent === 'Export');

        let previewAlertDialog;
        // Must wrap in timeout to prevent call stack error
        setTimeout(() => {
          previewAlertDialog = bootbox.dialog('<div class="text-center"><i class="icon-spinner icon-spin"></i> Building form...</div>');

          generateGFormPayload(resolvedExam).then(payload => {
            gFormPayload = payload;
            okBtn.classList.remove('disabled')
            previewAlertDialog.hide();
          });
        }, 500);
      })
    },

    /* export exam to google drive */
    exportToGDRIVE : function (){
      var exam = this;
      return this.chooseCorrectAnswers(true)
      .then(function(){
        return new Promise(function(resolve, reject) {
          var questions = exam._getAllItems();
          var letters = (exam.options.numericMc?'12345678':'ABCDEFGH').split('');
          var finish = function(){
            var html = '<p>'+exam.name+'</p><br><ol>';
            var key = '<p>'+exam.name+' answer key</p><br><ol>';
            for(var i = 0; i < questions.length; i++){
              html += '<li>';
              key += '<li>';
              if(questions[i].examItem.resolvedItemContent.resolvedPassage){
                html += questions[i].examItem.resolvedItemContent.resolvedPassage.resolvedPassageHTML;
              }
              html += questions[i].examItem.resolvedItemContent.prompt;
              if(questions[i].examItem.resolvedItemContent.shortAnswer){
                html += '<p>Answer:_____________________________</p>';
                key += questions[i].examItem.resolvedItemContent.shortAnswer;
              }else {
                html += '<ol type="'+(exam.options.numericMc?'1':'A')+'">';
                for(var r = 0; r < questions[i].examItem.resolvedItemContent.responses.length; r++){
                  html += '<li>'+questions[i].examItem.resolvedItemContent.responses[r].answer+'</li>';
                  if(questions[i].examItem.resolvedItemContent.responses[r].isCorrect)
                    key += letters[r];
                }
                html += '</ol>';
              }
              html += '</li>';
              key += '</li>';
            }
            html += '</ol>';
            key += '</ol>';

            resolve([html,key]);
          }
          return renderMML(questions,0, function(){
            return convertImage(questions,0,finish);
          });
        });
      })
      .catch(function(err){
        console.error(err);
      });
    },

    /* export exam to html form */
    exportToFORM : function (){
      var exam = this;
      return this.chooseCorrectAnswers(true)
      .then(function(){
        return new Promise(function(resolve, reject) {
          var questions = exam._getAllItems();
          var letters = (exam.options.numericMc?'12345678':'ABCDEFGH').split('');
          var finish = function(){
            var header ='<!doctype html><html><head>'+
              '<link href="https://netdna.bootstrapcdn.com/twitter-bootstrap/2.3.2/css/bootstrap.no-responsive.no-icons.min.css" rel="stylesheet">'+
              '<link rel="stylesheet" href="https://'+$location.host()+'/css/layout.css"><style>.page {width:8.5in;padding: .25in .5in;}.page hr {margin:[[FONT/2]] 0; border-top-color: black;}</style></head>';
            var validate = '<script>function validateForm() {var x = document.forms["exam"]["student_name"].value;if (x.length < 2) {alert("Name must be filled out");return false;}document.forms["exam"]["exam_time"].value = new Date().toLocaleString()}</script>';


            var form = header + validate + '<body class="examPreview">'+
              '<form class="page" name="exam" action="https://'+$location.host()+'/api/examform" onsubmit="return validateForm()" method="post">'+
              '<div class="pageHead"><span class="nameSpace">Name: <input type="text" name="student_name"/></span><span class="pageNum">'+exam.name+'</span></div>'+
              '<div class="pageHead"><span class="nameSpace">ID: <input type="text" name="student_id"/></span></div>'+
              '<div class="columns"><div class="left"><br>';

            var answers = header + '<body class="examPreview"><form class="page">'+
              '<div class="pageHead"><span class="nameSpace">Answer Key</span><span class="pageNum">'+exam.name+'</span></div>'
              '<div class="columns"><div class="left"><br>';

            var tmp;
            for(var i = 0; i < questions.length; i++){
              form += '<div class="question"><span class="itemNumber">'+(i+1)+'.&nbsp;&nbsp;</span><div class="itemContents"><div class="prompt">';
              if(questions[i].examItem.resolvedItemContent.resolvedPassage){
                form += questions[i].examItem.resolvedItemContent.resolvedPassage.resolvedPassageHTML;
              }
              form += questions[i].examItem.resolvedItemContent.prompt;
              form += '</div><div class="responses" style="flex-direction:column;">';
              answers += '<div class="responses">';
              if(questions[i].examItem.resolvedItemContent.shortAnswer){
                form += '<textarea name="question_'+i+'" rows="2" cols="80"></textarea>';
                answers += (i+1)+'.&nbsp;&nbsp;<p>'+questions[i].examItem.resolvedItemContent.shortAnswer+'</p>';
              }else {
                for(var r = 0; r < questions[i].examItem.resolvedItemContent.responses.length; r++){
                  form += '<span class="response"><span class="responseNumber">';
                  form +='<input type="radio" id="'+i+'-'+r+'" name="question_'+i+'" value="'+letters[r]+'"/></span><span class="responseContents">';
                  form +='<label for="'+i+'-'+r+'"><p>'+questions[i].examItem.resolvedItemContent.responses[r].answer+'</p></label>';
                  form += '</span></span>';
                  if(questions[i].examItem.resolvedItemContent.responses[r].isCorrect){
                    answers += '<span class="response"><span class="responseNumber">';
                    answers += (i+1)+'.&nbsp;&nbsp;'+letters[r]+'&nbsp;&nbsp;</span><span class="responseContents">';
                    // answers += questions[i].examItem.resolvedItemContent.responses[r].answer;
                    answers += '</span></span>';
                  }
                }
              }
              form += '</div></div></div>';
              answers += '</div></div>';
            }

            form +='<input type="hidden" name="exam_email" value="'+$rootScope.profile.Email+'">';
            form +='<input type="hidden" name="exam_name" value="'+exam.name+'">';
            form +='<input type="hidden" name="exam_length" value="'+questions.length+'">';
            form +='<input type="hidden" name="exam_letters" value="'+letters.join('')+'">';
            form +='<input type="hidden" name="exam_time" value="">';

            form += '<button class="btn btn-primary" type="submit">Submit Exam</button>';
            form += '</div></form></body></html>';
            answers += '</div></form></body></html>';

            resolve([form,answers]);
          }
          return renderMML(questions,0, function(){
            return convertImage(questions,0,finish);
          });
        });
      })
      .catch(function(err){
        console.error(err);
      });
    },
  };

  return {
    Exam : Exam,
    ComposerItem : ComposerItem,
    ComposerDropTarget : ComposerDropTarget,
    ComposerPassage : ComposerPassage,
    ComposerSection : ComposerSection
  };

});
