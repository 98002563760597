angular.module('examgen.service').
service('examPrompts', function(){
  var p = {
    prompt: function(title,cb){
      title = title || 'Enter exam name';
      bootbox.prompt(title, 'Cancel', 'OK', cb);
    },

    empty: function(title,cb){
      p.prompt(title+'<h4 class="alert alert-block">Exam name cannot be empty.</h4>',cb);
    },

    duplicate: function(title,cb){
      p.prompt('Please choose a different exam name<br><h4 class="alert alert-block">You cannot have two exams with the same name.</h4>', cb);
    },
    invalid: function(title,cb){
      p.prompt('Please choose a different exam name<br><h4 class="alert alert-block">The following symbols are not allowed: "\\ : < > / %".</h4>', cb);
    },

    handleStatus: function(title,status,cb){
      //console.log('handlePromptStatusResponse', status);
      if (status=='ok') {
        return true;
      }else if(status == 'empty'){
        p.empty(title,cb);
      }else if(status=='duplicate'){
        p.duplicate(title,cb);
      }else{
        alertify.error('There was an error, please try again.');
      }
      return false;
    }
  };

  return p;
});
