angular.module('examgen.directive').
directive('myModal',function(){
  return function(scope,el,attr){
    scope.$watch(attr.myModal,function(val){
    if(val){
      $(el).modal('show');
    }else{
      $(el).modal('hide');
    }
    });
  };
});