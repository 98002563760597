function ReportsController($rootScope, $scope, $http, $timeout) {
  var asstypes = ['', 'Homework', 'Review', 'Exam', 'Test', 'Extra Credit'];

  $rootScope.inReports = true;
  $scope.$on('$destroy', function() {
    $rootScope.inReports = false;
  });

  $scope.data = {
    profile: {},
    admins: [],
    adminsFiltered: [],
    users: [],
    usersFiltered: [],
    selectedUser: '',
    userinfo: undefined,
    selectedAdmin: '',
    date_min:null,
    date_max:null,
    schools:null,
    classes:null,
    gradebook:true,
  }
  $scope.data.date_max = new Date();
  $scope.data.date_min = new Date($scope.data.date_max)
  $scope.data.date_min.setDate($scope.data.date_max.getDate()-7);
  var date = moment('1990-1-1')
    .month('july')
    .year(moment().year() + ((moment().month() > 5) ? 0 : -1))
    .startOf('month');
  $scope.data.grade_date_min = date.toDate();
  date.month('june')
    .year(date.year()+1)
    .endOf('month');
  $scope.data.grade_date_max = date.toDate();

  $scope.refresh = function(next){
    $timeout(function() {
      $scope.$apply();
      if(next){
        next();
      }
    });
  }

  $scope.getAdmins = function(next){
    $http.get('/api/users?adminOnly=true').then(function(res) {
      for(var i = 0; i < res.data.length; i++){
        res.data[i].label = res.data[i].LastName+', '+res.data[i].FirstName+' ('+res.data[i].UserName+' : '+res.data[i].Email+' : '+' : '+res.data[i].UserId+')';
      }
      $scope.data.admins = res.data;
      $scope.data.adminsFiltered = res.data;
      $scope.refresh(next);
    });
  };

  $scope.getAPIUsage = function () {
    var date_min = new Date($scope.data.date_min).getTime();
    var date_max = new Date($scope.data.date_max).getTime();
    $http.get('/api/usage?start=' + date_min + '&stop=' + date_max).then(function (res) {
      $scope.data.APIUsage = res.data.usage;
    }).catch(function(error){
      console.error(error);
      $scope.data.APIUsage = [];
    });
  };

  $scope.getAPIUsageFor = function (user) {
    var date_min = new Date($scope.data.date_min).getTime();
    var date_max = new Date($scope.data.date_max).getTime();
    $http.get('/api/usage/'+user.fkUser+'?start=' + date_min + '&stop=' + date_max).then(function (res) {
      var csv = 'Time, URL, Query, Body\n';
      for (var i = 0; i < res.data.usage.length; i++) {
        csv += `"${moment(res.data.usage[i].time).format('MM/DD/YYYY HH:mm.ss')}",`;
        csv += `"${res.data.usage[i].url}",`;
        csv += `"${res.data.usage[i].query}",`;
        csv += `"${res.data.usage[i].body}"\n`;
      }

      var hiddenElement = document.createElement('a');

      var blob = new Blob([csv], { type: "attachment/csv" })
      var url = URL.createObjectURL(blob);
      hiddenElement.href = url;
      hiddenElement.target = '_blank';
      hiddenElement.download = moment($scope.data.date_min).format('MM_DD_YYYY') + ' ' + moment($scope.data.date_max).format('MM_DD_YYYY') + ' ' + user.Email + ' API Usage.csv';
      hiddenElement.click();
    }).catch(function (error) {
      console.error(error);
    });
  };

  $scope.getUsers = function(next){
    var request = $http.get('/api/users/admin/'+$scope.data.selectedAdmin)

    request.then(function(res) {
      for(var i = 0; i < res.data.length; i++){
        res.data[i].label = res.data[i].LastName+', '+res.data[i].FirstName+' ('+res.data[i].UserName+' : '+res.data[i].Email+' : '+' : '+res.data[i].UserId+')';
      }
      $scope.data.users = res.data;
      $scope.data.usersFiltered = res.data;
      $scope.refresh(next);
    });
  };

  $scope.findUsers = function(list, ifEmpty){
    filtered = [];
    $scope.refresh(function(){
      for(var i = 0; i < list.length; i++){
        if(list[i].label.toLowerCase().includes($scope.data.search.toLowerCase()) || ifEmpty){//scope.data.search.length <= 0){
          filtered.push(list[i])
        }
      }
    });
    return filtered;
  }

  $scope.$watch('data.selectedUser',function(selectedUser){
    if(selectedUser){
      $scope.data.userinfo = $scope.data.users.find(function(user){
        return user.UserId == selectedUser;
      });
    }
  });

  $scope.$watch('data.selectedAdmin',function(admin){
    if(admin){
      $scope.getUsers();
    }
  });

  $scope.getUsageReport = function(){
    var id = $scope.data.selectedUser;
    var sessions = undefined;
    var exams = undefined;
    var date_min = new Date($scope.data.date_min).getTime();
    var date_max = new Date($scope.data.date_max).getTime();

    var complete = function(){
      if(sessions != undefined && exams != undefined){
        var csv = 'User Info:\n';
        csv += 'ID, Account Name, First Name, Last Name, Email\n';
        csv += [
          $scope.data.userinfo.UserId,
          $scope.data.userinfo.UserName,
          $scope.data.userinfo.FirstName,
          $scope.data.userinfo.LastName,
          $scope.data.userinfo.Email
        ].join(',')+'\n';

        csv += '\nDatabases:\n';
        var databases = $scope.data.userinfo.databases || [];
        csv += databases.map(function(db){
          return db.name;
        }).join('\n')+'\n';

        csv += '\nExams:\n';
        csv += 'Name, Last Modified\n';
        exams.sort(function(a,b){return b.date_updated-a.date_updated});
        exams.forEach(function(exam){
          csv += [
            exam.name,
            moment(exam.date_updated).format('MM/DD/YYYY hh:mm:ss'),
          ].join(',')+'\n';
        });

        csv += '\nActivity:\n';
        csv += 'Login, Logoff, Time\n';
        sessions.forEach(function(session){
          var a = moment(session.login);
          var b = session.logoff != null ? moment(session.logoff) : null;
          csv += [
            a.format('MM/DD/YYYY hh:mm:ss'),
            b !=null ? b.format('MM/DD/YYYY hh:mm:ss') : '',
            b !=null ? a.from(b, true) : '',
          ].join(',')+'\n';
        });

        var hiddenElement = document.createElement('a');

        var blob = new Blob([csv], {type: "attachment/csv"})
        var url = URL.createObjectURL(blob);
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = moment($scope.data.date_min).format('MM-DD-YYYY')+' '+moment($scope.data.date_max).format('MM-DD-YYYY')+' '+$scope.data.userinfo.UserName+' Test Generator Usage.csv';
        hiddenElement.click();
      }
    };

    $http.get('/api/user/'+$scope.data.userinfo.UserName).then(function(res){
      $scope.data.userinfo.databases = res.data.Databases;
      $http.get('/tools/sessions/search?userId='+id+'&min='+(date_min)+'&max='+(date_max)).then(function(res){

        sessions = res.data.sessionLog || [];
        complete();
      }, function(err){
        console.log(err);
        sessions = [];
        complete();
      });

      $http.get('/api/'+id+'/exams?min='+(date_min/1000)+'&max='+(date_max/1000)).then(function(res){
        exams = res.data || [];
        complete();
      }, function(err){
        console.log(err);
        exams = [];
        complete();
      });
    })
  }

  $scope.getOverallUsageReport = function(){
    var date_min = new Date($scope.data.date_min).getTime();
    var date_max = new Date($scope.data.date_max).getTime();

    var overlay = document.createElement('div');
      overlay.style.position='fixed';
      overlay.style.top='0';
      overlay.style.left='0';
      overlay.style.right='0';
      overlay.style.bottom='0';
      overlay.style.backgroundColor='rgba(0,0,0,.1)';
    var msg = document.createElement('div');
      msg.style.position='fixed';
      msg.style.top='40%';
      msg.style.left='40%';
      msg.style.right='40%';
      msg.style.textAlign='center';
      msg.style.backgroundColor='white';
      msg.style.border='solid 1px lightgrey';
      msg.style.borderRadius='6px';
      msg.style.padding='4px';
    var info = document.createElement('p');
      info.innerHTML ='Generating Report';
    var spinner = document.createElement('div');
      spinner.className = 'loader';
    msg.appendChild(info);
    msg.appendChild(spinner);

    overlay.appendChild(msg);
    document.querySelector('body').appendChild(overlay);

    var loadUserData = function(i){
      return new Promise(function(resolve, reject) {
        var id = $scope.data.users[i].UserId;
        var sessions = undefined;
        var exams = undefined;
        var complete = function(){
          if(sessions != undefined && exams != undefined){
            resolve({user:$scope.data.users[i], sessions:sessions, exams:exams});
          }
        }

        $http.get('/tools/sessions/search?userId='+id+'&min='+(date_min)+'&max='+(date_max)).then(function(res){
          sessions = res.data.sessionLog || [];
          complete();
        }, function(err){
          console.log(err);
          sessions = [];
          complete();
        });

        $http.get('/api/'+id+'/exams?min='+(date_min/1000)+'&max='+(date_max/1000)).then(function(res){
          exams = res.data || [];
          complete();
        }, function(err){
          console.log(err);
          exams = [];
          complete();
        });
      });
    }

    var promises = [];

    for(var i = 0; i < $scope.data.users.length; i++){
      promises.push(loadUserData(i));
    }

    Promise.all(promises).then(function(vals){
      var csv = 'ID, Email, First Name, Last Name, Exams, Sessions, Total Time (HH:MM)\n';

      for(var i = 0; i < vals.length; i++){
        csv += [
          vals[i].user.UserId,
          vals[i].user.Email,
          vals[i].user.FirstName,
          vals[i].user.LastName,
          vals[i].exams.length,
          vals[i].sessions.length,
          '',
        ].join(',');
        var time = 0;
        for(var s = 0;s<vals[i].sessions.length;s++){
          if(vals[i].sessions[s].login && vals[i].sessions[s].logoff){
            time += vals[i].sessions[s].logoff - vals[i].sessions[s].login;
          }
        }
        var duration = moment.duration(time)
        csv += duration.asHours().toFixed(0)+':'+duration.minutes() + '\n';
      }

      var hiddenElement = document.createElement('a');
      var blob = new Blob([csv], {type: "attachment/csv"})
      var url = URL.createObjectURL(blob);
      hiddenElement.href = url;
      hiddenElement.target = '_blank';
      hiddenElement.download = moment($scope.data.date_min).format('MM-DD-YYYY')+' '+moment($scope.data.date_max).format('MM-DD-YYYY')+' Test Generator Usage.csv';
      hiddenElement.click();

      document.querySelector('body').removeChild(overlay);
    });
  }

  $scope.getSchools = function(){
    return $http.get('/api/user/schools/all')
    .then(function(res){
      $scope.data.schools = res.data;
    })
  }
  $scope.$watch('data.school',function(){
    $scope.getClasses();
  });

  $scope.getClasses = function(){
    if($scope.data.school){
      return $http.get('/api/user/class/'+$scope.data.school.idSchool+($scope.data.archivedClasses?'?schoolYear=all':''))
      .then(function(res){
        $scope.data.school.classes = res.data;
        for(var c = 0; c < $scope.data.school.classes.length; c++){
          $scope.data.school.classes[c].school = $scope.data.school;


          // for(var s = 0; s < $scope.data.schools.length; s++){
          //   if($scope.data.classes[c].fkSchool==$scope.data.schools[s].idSchool){
          //     $scope.data.schools[s].classes = $scope.data.schools[s].classes || [];
          //     $scope.data.classes[c].school = $scope.data.schools[s];
          //     $scope.data.schools[s].classes.push($scope.data.classes[c]);
          //   }
          // }
        }
        $scope.data.class = $scope.data.school.classes[0];
      })
    }
  }

  $scope.buildLMSExport = function(students,isSchool){
    var labels = {
      FirstName:'First Name',
      LastName:'Last Name',
      localStudentId:'Student ID',
      localClassId:'Class ID',
      name:'Class Name',
      idAssignment:'Assignment ID',
      title:'Assignment Name',
      type:'Assignment type',
      grade:'Grade',
      lastActive:'Submitted',
    };
    var studentkeys = ['localStudentId','FirstName','LastName'];
    var assignmentKeys = ['localClassId','name','idAssignment','type','title','grade','lastActive'];
    var allKeys = [].concat(studentkeys,assignmentKeys);

    var csv ='';
    for(var k = 0; k < allKeys.length; k++){
      csv += labels[allKeys[k]];
      if(k<allKeys.length-1){
        csv+=',';
      }
    }
    csv+='\n';
    for(var s = 0; s < students.length; s++){
      for(var a = 0; a < students[s].assignments.length; a++){
        students[s].assignments[a].type = asstypes[students[s].assignments[a].type||0];
        students[s].assignments[a].grade = ((students[s].assignments[a].value/students[s].assignments[a].questions)*100).toFixed(2);
        students[s].assignments[a].lastActive = moment(students[s].assignments[a].lastActive).format('MM/DD/YYYY h:mm a');
        for(var k = 0; k < studentkeys.length; k++){
          csv += (students[s][studentkeys[k]]||'').replace(/,/g,' ');
          csv +=',';
        }
        for(var k = 0; k < assignmentKeys.length; k++){
          csv += (students[s].assignments[a][assignmentKeys[k]]||'').replace(/,/g,' ');;
          if(k<assignmentKeys.length-1){
            csv+=',';
          }
        }
        csv+='\n';
      }
    }
    var hiddenElement = document.createElement('a');

    var blob = new Blob([csv], {type: "attachment/csv"})
    var url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = moment($scope.data.grade_date_min).format('MM-DD-YYYY')+' '+
                            moment($scope.data.grade_date_max).format('MM-DD-YYYY')+' '+
                            $scope.data.school.name+((!isSchool)?(' '+$scope.data.class.name):'')+' Grade Export.csv';
    hiddenElement.click();
  }

  $scope.buildGradeBook = function(students,isSchool){
    var labels = {
      FirstName:'First Name',
      LastName:'Last Name',
      localStudentId:'Student ID',
      localClassId:'Class ID',
      name:'Class Name',
      idAssignment:'Assignment ID',
      title:'Assignment Name',
      grade:'Grade',
      lastActive:'Submitted',
    };
    var studentkeys = ['localStudentId','LastName','FirstName'];

    var classes = [];
    for(var s = 0; s < students.length; s++){
      for (var a = 0; a < students[s].classes.length; a++) {
        var info = classes.find(function (i) { return i.idClass == students[s].classes[a] });
        if (!info){
          info = $scope.data.school.classes.find(function (i) { return i.idClass == students[s].classes[a] });
          if(info){
            classes.push({
              idClass: info.idClass,
              localClassId: info.localClassId,
              name: info.name,
              assignments: [],
              students: [students[s].localStudentId],
            });
          }
        } else {
          info.students.push(students[s].localStudentId);
        }
      }
      for(var a = 0; a < students[s].assignments.length; a++){
        var info = classes.find(function(i){return i.localClassId == students[s].assignments[a].localClassId});
        if(info){
          if(info.assignments.findIndex(function(i){return i.idAssignment == students[s].assignments[a].idAssignment})==-1){
            info.assignments.push({
              idAssignment:students[s].assignments[a].idAssignment,
              title:students[s].assignments[a].title,
              type:students[s].assignments[a].type,
            });
          }
        }
      }
    }

    var csv ='';
    for(var c = 0; c < classes.length; c++){
      csv+= classes[c].name+' ('+classes[c].localClassId+'),';
      for(var k = 0; k < studentkeys.length; k++){
        csv += labels[studentkeys[k]];
        csv += ',';
      }
      for(var a = 0; a < classes[c].assignments.length; a++){
        csv += classes[c].assignments[a].title+(classes[c].assignments[a].type?' ('+asstypes[classes[c].assignments[a].type]+')':'');
        if(a < classes[c].assignments.length-1){
          csv+=',';
        }
      }
      // csv+='\n,';
      // for(var k = 0; k < studentkeys.length; k++){
      //   csv += ',';
      // }
      // for(var a = 0; a < classes[c].assignments.length; a++){
      //   csv += asstypes[classes[c].assignments[a].type||0];
      //   if(a < classes[c].assignments.length-1){
      //     csv+=',';
      //   }
      // }
      csv+='\n,';
      for(var s = 0; s < students.length; s++){
        if(classes[c].students.indexOf(students[s].localStudentId)>-1){
          for(var k = 0; k < studentkeys.length; k++){
            csv += (students[s][studentkeys[k]]||'').replace(/,/g,' ');
            csv +=',';
          }
          for(var a = 0; a < classes[c].assignments.length; a++){
            var info = students[s].assignments.find(function(i){return i.idAssignment == classes[c].assignments[a].idAssignment})
            if(info)csv += ((info.value/info.questions)*100).toFixed(2);
            if(a<classes[c].assignments.length-1){
              csv+=',';
            }
          }
          csv+='\n,';
        }
      }
      csv+='\n';
    }
    var hiddenElement = document.createElement('a');

    var blob = new Blob([csv], {type: "attachment/csv"})
    var url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = moment($scope.data.grade_date_min).format('MM-DD-YYYY')+' '+
                              moment($scope.data.grade_date_max).format('MM-DD-YYYY')+' '+
                              $scope.data.school.name+((!isSchool)?(' '+$scope.data.class.name):'')+
                              ' Gradebook.csv';
    hiddenElement.click();
  }

  $scope.getGradeReport = function(classList,isSchool){
    var date_min = new Date($scope.data.grade_date_min).getTime();
    var date_max = new Date($scope.data.grade_date_max).getTime();
    $http({
     method: 'PUT',
     url: $scope.data.school.database+'/api/report/list?start='+(date_min)+'&stop='+(date_max),
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken')
     },
     data: classList.map((c)=>c.idClass),
    }).then(function(res){
      for(var s = 0; s < res.data.students.length; s++){
        res.data.students[s].assignments = res.data.students[s].assignments || [];
        for(var a = 0; a < res.data.students[s].assignments.length; a++){
          var cl = $scope.data.school.classes.find((c)=>c.idClass === res.data.students[s].assignments[a].fkClass)
          if(cl){
            res.data.students[s].assignments[a].localClassId = cl.localClassId;
            res.data.students[s].assignments[a].name = cl.name;
          }
        }
      }
      if($scope.data.gradebook)
        $scope.buildGradeBook(res.data.students,isSchool);
      else
        $scope.buildLMSExport(res.data.students,isSchool);
    });
  }

  $rootScope.$watch('profile',function(profile){
    if(profile){
      $scope.data.profile = profile;
      $scope.data.selectedAdmin = profile.UserId;
      $scope.getUsers();
      if(profile.toolsAccess)$scope.getAdmins();
      if(profile.toolsAccess)$scope.getAPIUsage();
      $scope.getSchools()
      .then(function(){
        $scope.data.school=$scope.data.schools[0];
      })
    }
  });

  $scope.getSchoolReport = function(groupByClass){
    var date_min = new Date($scope.data.date_min).getTime();
    var date_max = new Date($scope.data.date_max).getTime();
    $http({
     method: 'GET',
     url: $scope.data.school.database+'/api/report/usage/school/'+$scope.data.school.idSchool+'?start='+(date_min)+'&stop='+(date_max),
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken')
     },
    }).then(function(res){
      res.data.teachers = res.data.teachers.sort((a,b)=>(a.localStaffId||'').localeCompare(b.localStaffId));
      res.data.classes = res.data.classes.sort((a,b)=>(a.localClassId||'').localeCompare(b.localClassId));
      if(groupByClass)
        $scope.buildSchoolReportByClass(res.data);
      else
        $scope.buildSchoolReportByTeacher(res.data);
    });
  }

  $scope.buildSchoolReportByClass = function(data){
    var csv = "Class ID,Name,Assignments,Teachers\n";
    for (var c = 0; c < data.classes.length; c++) {
      data.classes[c].teachers = [];
      for (var t = 0; t < data.teachers.length; t++) {
        if(data.classes[c].idClass === data.teachers[t].fkClass){
          data.classes[c].teachers.push(data.teachers[t]);
        }
      }

      csv += `${data.classes[c].localClassId},${data.classes[c].name},${data.classes[c].assignments||0},${data.classes[c].teachers.length}\n`;

      // for(var i = 0; i < data.classes[c].teachers.length; i++){
      //   csv+=`,${data.classes[c].teachers[i].localStaffId},`
      //   +`${data.classes[c].teachers[i].Email||data.classes[c].teachers[i].invitedEmail},`
      //   +`${data.classes[c].teachers[i].LastName||''},`
      //   +`${data.classes[c].teachers[i].FirstName||''}\n`;
      // }
    }

    var hiddenElement = document.createElement('a');

    var blob = new Blob([csv], {type: "attachment/csv"})
    var url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = moment($scope.data.date_min).format('MM-DD-YYYY')+' '+moment($scope.data.date_max).format('MM-DD-YYYY')+' '+($scope.data.school.name)+' Classes.csv';
    hiddenElement.click();
  }

  $scope.buildSchoolReportByTeacher = function(data){
    var csv = "Teacher ID, Email, Last Name, First Name\n";
    csv += ",Class ID, Name, Assignments\n";
    var teachers = [];
    for (var t = 0; t < data.teachers.length; t++) {
      var teach = teachers.find((f)=>f.invitedEmail==data.teachers[t].invitedEmail);
      if(!teach){
        teach = data.teachers[t];
        teach.classes = [];
        teachers.push(teach);
      }
      for (var c = 0; c < data.classes.length; c++) {
        if(data.classes[c].idClass === data.teachers[t].fkClass){
          teach.classes.push(data.classes[c]);
        }
      }
    }

    for(var t = 0; t < teachers.length; t++){
      csv+=`${teachers[t].localStaffId},`
      +`${teachers[t].Email||teachers[t].invitedEmail},`
      +`${teachers[t].LastName||''},`
      +`${teachers[t].FirstName||''}\n`;


      for(var c = 0; c < teachers[t].classes.length; c++){
        csv += `,${teachers[t].classes[c].localClassId},${teachers[t].classes[c].name},${teachers[t].classes[c].assignments||0}\n`;
      }
    }

    var hiddenElement = document.createElement('a');

    var blob = new Blob([csv], {type: "attachment/csv"})
    var url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = moment($scope.data.date_min).format('MM-DD-YYYY')+' '+moment($scope.data.date_max).format('MM-DD-YYYY')+' '+($scope.data.school.name)+' Teachers.csv';
    hiddenElement.click();
  }
}
