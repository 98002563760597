function ExamsController($scope, $window, $rootScope, $http, $q, exams, constructors, session, couch, flashMessages, examPrompts, $analytics) {
  $analytics.pageTrack('/exams');

  $rootScope.guidedTour = false;

  $scope.encodeURIComponent = encodeURIComponent;

  $rootScope.menus = [ExamsController.menu];

  $scope.couch = couch;
  exams.ready.then(function() {
    onLoggedIn(couch.db, exams.list);
  });

  function onLoggedIn(db, examList) {
    console.log('onLoggedIn', examList);
    $scope.examList = examList;

    $scope.formatDate = function(exam, property) {
      console.log('exam', exam);
      return exam[property].toLocaleDateString();
    };

    $scope.printExam = function(jsonExam) {
      $q.when(jsonExam.pdfs).then(function(e) {
        if (e.length > 0) {
          $scope.loadedExam = $scope.examToPrint = jsonExam;
          $scope.showDownloadModal = true;
        } else {
          $scope.examToPrint = null;
        }
      });
    };

    $scope.doneDownloadModal = function() {
      $scope.examToPrint = null;
      $scope.showDownloadModal = false;
    };

    /** Edit the exam name */
    $scope.editExamName = function(examStub) {
      examStub.ready.then(function(resolvedExam) {
        var old = resolvedExam.name;
        var promptCb = function(newName) {
          if (newName === null) {
            return;
          }
          resolvedExam.rename(newName).then(function(status) {
            if (status === 'ok') {
              alertify.success('Renamed ' + old + ' to ' + newName);
            } else {
              examPrompts.handleStatus('Enter the exam\'s new name', status, promptCb);
            }
          });
        };
        examPrompts.prompt('Enter the exam\'s new name', promptCb);
      });
    };


    /** Delete the given exam */
    $scope.deleteExam = function(examStub) {
      bootbox.confirm('Are you sure you want to delete ' + examStub.name + '?', function(result) {
        if (result) {
          db.removeExam(examStub.id).then(function() {
            var idx =
              examList.map(function(exam) {
                return exam.name;
              }).indexOf(examStub.name);

            examList.splice(idx, 1);

            alertify.success('Deleted ' + examStub.name);
          });
        }
      })
    };

    //this stuff has to do with copying
    $scope.copyModalShouldBeOpen = false;
    $scope.shuffleAnswers = true;
    $scope.shuffleQuestions = true;
    $scope.examToCopy = null;

    /** Copy the given exam */
    $scope.copyExam = function(examStub) {
      examStub.ready.then(function(exam) {
        var cb = function(newName) {
          if (newName === null) {
            return;
          }

          if (!newName) {
            return examPrompts.empty('Choose the new exam name', cb);
          }
          couch.db.hasExam(newName).then(function(duplicate) {
            if (duplicate) {
              examPrompts.duplicate('Choose the new exam name', cb);
            } else {
              constructors.Exam.fromJSON(JSON.parse(JSON.stringify(exam))).then(function(examCopy) {
                examCopy.name = newName;
                examCopy.loadedExam = {
                  date_created: new Date(),
                  date_updated: new Date()
                };
                examCopy.ready = $q.when(examCopy);

                couch.db.putExam(examCopy.id, examCopy).then(function(response) {
                  alertify.success('Copied ' + exam.name + ' to ' + newName);
                  examList.push(examCopy);
                }, function() {
                  alertify.error('There was an error copying the exam.');
                });
              });
            }
          })
        }
        examPrompts.prompt('Choose the new exam name', cb);
      });
    };

  }
}

ExamsController.menu = {
  href: '#/exams',
  text: 'My EXAMS'
}; ///


function ExamsRedirectController($scope, exams, $q, couch, $http, $state, constructors) {
  exams.ready.then(function() {
    $state.go('exam', {id: ''});
  });

}
