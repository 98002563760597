angular.module('examgen.directive').
directive('billboardModal', function($state) {
  return {
    restrict: 'E',
    templateUrl: 'app/partials/modals/billboard.html',
    replace: true,
    scope: {
      show: '='
    },
    controller: function($scope, session, $element, $sce) {
      $scope.showModal = $scope.show;

      $scope.onClose = function() {
        $scope.showModal = false;
        $state.go('exams');
      };

      // show the billboard when the session is ready
      session.ready.then(function(profile) {
        if (!profile) {
          return;
        }

        var getDb = function (idx) {
          var database = profile.Databases ? profile.Databases[idx] : '';
          return (database.name||'').replace(/\s+/g, '');
        };

        var db = getDb(0);
        console.log('database is ' + db);

        if (!db) {
          return;
        }

        // create the billboard url
        var url = 'http://www.examgenonline.com/' + db + '.aspx';
        $scope.url = $sce.trustAsResourceUrl(url);
        $scope.showModal = true;

        if (window.ENV !== 'development') {
          //$('#billboard iframe').attr('src', 'http://www.examgenonline.com/' + db + '.aspx');
          //$rootScope.showBillboard = true;
        }
      });
    },
    link: function(scope, element, attr) {

    }
  }
});
