angular.module('examgen.directive').
directive('embeddedIframe', function($sce) {
  return {
    restrict: 'E',
    scope: {
      url: '='
    },
    template: '<iframe style="width:100%; height:100%" ng-src="{{ sceUrl }}" ng-if="sceUrl" frameborder="0"></iframe>',
    link: function($scope, $element, $attrs) {
      $element.css({width: '100%', height: '100%'});
      $scope.$watch('url', function(val) {
        $scope.sceUrl = val ? $sce.trustAsResourceUrl(val) : false;
      });
    }
  };
});