angular.module('examgen.layout').
/**
 * @ngdoc layout
 * @name examgen.directive:examLayout
 * @description Encapuslates the exam layout algorithm.
 */
directive('examLayoutNew',function($rootScope,$q,$timeout){
  return {
    template : '<div class="examPreview">',
    replace : true,
    scope : {
      exam : '=',
      $index : '=',
      preview : '=',
      totalNumberOfExamsToPrint : '='
    },
    link : function(scope,el,attr){
      var newDoc, roughDoc, finalDoc;
      var itemNumber = 0;
      var letters = 'ABCDEFGHIJK'.split('');
      var defFontSize = 12;
      var defFontFam = '"Times New Roman", Times, serif';

      var cssTemplate='<link href="/css/layout.css" rel="stylesheet">'+
      '<style>'+
      '@media print {@page {size: [[SIZE]] !important; margin: 0.01in !important;}}'+
      '.page {width:[[WIDTH]];height:[[HEIGHT]];padding: [[MARGINTOP]] [[MARGIN]];}'+
      '.page hr {margin:[[FONT/2]] 0; border-top-color: black;}'+
      '</style>';

      var sectionTemplate = '<div class="sectionHeading"></div>';
      var passageTemplate = '<div class="passage"></div>';
      var questionTemplate = '<div class="question"><span class="itemNumber"></span><div class="itemContents"><div class="prompt"></div><div class="responses"></div></div></div>';
      var answerTemplate = '<span class="response"><span class="responseNumber"></span><span class="responseContents"></span></span>';

      var pageTemplate = '<div class="page"></div>';
      var headerTemplate = '<div class="pageHead"><span class="nameSpace"></span><span class="pageNum"></span></div>';
      var nameSpaceTemplate = '<span class="blank"></span>';
      var columnsTemplate = '<div class="columns"></div>';
      var leftTemplate = '<div class="left"></div>';
      var midTemplate = '<div class="mid"><div></div></div>';
      var rightTemplate = '<div class="right"></div>';

      function cleanHtml($element){
        $element.find('[align="CENTER"]').each(function(){
          this.removeAttribute('align');
          $(this).css({'text-align':'center'});
        });

        var mathml = $element.find('math');
        mathml.attr('display','');

        //this is for IE9, which doesn't recognize the img width and height attributes
        $element.find('img').each(function(){
          $(this).css({
            width : $(this).attr('width') + 'px',
            height : $(this).attr('height') + 'px'
          });
          //this.removeAttribute('width');
          //this.removeAttribute('height');
        });

      }
      function positionCorrectAnswerResponse(item){
        var itemContents = item.examItem.resolvedItemContent;

        var correctAnswerLetter = item.correctAnswerLetter;

        if(!itemContents.responses) return;

        //place the other questions at arbitrary positions: sequentially, or at random. Doesn't matter, they are wrong answers, and will not go in the answer key.
        //correctAnswerLetter
        var indexOfGivenCorrectItem = itemContents.responses.map(function(response){
            return response.isCorrect;
        }).indexOf(true);

        var indexOfChosenAnswerLetter = itemContents.responses.map(function(response){
            return response.answerLetter;
        }).indexOf(correctAnswerLetter);

        if(indexOfGivenCorrectItem === -1 || indexOfChosenAnswerLetter === -1){
            //console.log('Error: Unable to find correct responses.');
            return;
        }

      }
      function setFont($element,options){
        $element
        .css('font-size', options.fontSize ? (options.fontSize + 'pt') : (defFontSize+'pt'))
        .css('line-height', options.fontSize ? ((options.fontSize+1) + 'pt') : ((defFontSize+1)+'pt'))
        .css('font-family', options.fontFamily ? options.fontFamily : '"Times New Roman", Times, serif');
      }
      function copyFont(a,b){
        a
        .css('font-size', b.css('font-size'))
        .css('line-height', b.css('line-height'))
        .css('font-family', b.css('font-family'));
      }

      function sectionToDom(section){
        section.options = section.options || {};
        fontOptions = scope.exam.options;

        if(section.options.heading){
          section.headingElement = $(sectionTemplate);
          section.headingElement.html(section.options.heading);
          setFont(section.headingElement,fontOptions);
        }else{
          section.headingElement = null;
        }
      }
      function passageToDom(passage,section){
        var element = $(passageTemplate);
        var body = element;
        var intro = '';
        switch(passage.items.length){
          case 0:
            return;         //empty passage
          case 1:
            intro = "<p class=\"passageHeading\"><strong>Question " + (itemNumber + 1) + " refers to the following:</strong></p>"
            break;
          case 2:
            intro = "<p class=\"passageHeading\"><strong>Questions " + (itemNumber + 1) + " and " + (itemNumber + 2) + " refer to the following:</strong></p>"
            break;
          default:
            intro = "<p class=\"passageHeading\"><strong>Questions " + (itemNumber + 1) + " through " + (itemNumber + passage.items.length) + " refer to the following:</strong></p>"
            break;
        }
        body.append($(intro));
        body.append($(passage.examPassage.resolvedPassageHTML));

        setFont(body,fontOptions);

        cleanHtml(element);

        passage.examElement = element;

        if(passage.examElement.find('math').length){    //this guard is a performance optimization - only run mathjax layout if there is some mathml content
          MathJax.Hub.Queue(["Typeset",MathJax.Hub,passage.examElement[0]]);
        }

        roughDoc.append(element);
        return element;
      }
      function questionToDom(question,section){
        itemNumber++;
        positionCorrectAnswerResponse(question);
        var element = $(questionTemplate);
        var body = element.find('.itemNumber, .itemContents');
        setFont(body,fontOptions);
        var fontSize = fontOptions.fontSize || defFontSize;
        // if Passage then fetch the parent composer, which should be a Section.
        var convertMCToShortAnswer = section.options.convertMCToShortAnswer;
        var isMC = question.examItem.resolvedItemContent.itemType == 'MC';
        var isShortAnswer = question.examItem.resolvedItemContent.itemType == 'ER' ||
                            question.examItem.resolvedItemContent.itemType == 'SR' ||
                            (isMC && convertMCToShortAnswer);

        // need to compare padding's type against a number, it could be 0
        var padding = 1;
        if (isShortAnswer && section.options.shortAnswerQuestionPadding) {
          padding = section.options.shortAnswerQuestionPadding;
        } else if ( !isShortAnswer && section.options.questionPadding){
          padding = section.options.questionPadding;
        }

        padding = parseInt(padding, 10)
        if (padding < 0 || isNaN(padding)) {
          padding = parseInt(section.options.questionPadding, 10);
        }
        // default to padding of 1
        if (padding < 0 || isNaN(padding)) {
          padding = 1;
        }
        element.css({
          paddingBottom: (padding * fontSize) + 'pt',
        });
        element.find('.itemNumber').html((section.options.answerBlanks?'____&nbsp;':'')+itemNumber + ')&nbsp;&nbsp;');

        element.find('.itemContents .prompt')
        .append(question.examItem.resolvedItemContent.prompt);

        if(question.examItem.resolvedItemContent.responses && !convertMCToShortAnswer){
          question.responseElements = question.examItem.resolvedItemContent.responses.map(function(response,i){
            var answerLetter = section.options.numericMc ? (i+1) : letters[i];
            var answerElement = $(answerTemplate);
            answerElement.find('.responseNumber').html(answerLetter + ')&nbsp;');
            answerElement.find('.responseContents').html(response.answer);
            element.find('.itemContents .responses')
            .append(answerElement);
            return answerElement;
          });
        }

        cleanHtml(element);
        question.examElement = element;

        if(question.examElement.find('math').length){    //this guard is a performance optimization - only run mathjax layout if there is some mathml content
          MathJax.Hub.Queue(["Typeset",MathJax.Hub,question.examElement[0]]);
        }

        roughDoc.append(element);
        return element;
      }

      var currentPage, pageNum = 0, currentColumnRow, currentColumn, isTwoColSection, isTwoCol;
      var bumped;
      var fontOptions, currSection;

      function addPage(){
        var $page = $(pageTemplate);
        setFont($page,fontOptions);

        var head = $(headerTemplate);
        head.css('margin-bottom',fontOptions.fontSize+'pt');
        $page.append(head);

        var last,wasTwoCol;
        if(currentPage){
          var last = currentPage.find('.columns>:not(.mid)>:last');
          var lastCol = last.parent().parent();
          var wasTwoCol = lastCol.find('.right').length > 0;

          if(last.hasClass('passage') && !last.hasClass('bumped') && !last.hasClass('diced')){
            bumped = last;
            bumped.addClass('bumped');
            bumped.remove();
            last = null;
          }
        }

        currentPage = $page;
        newDoc.append($page);
      }
      function addColumns(twoCol){
        if(isTwoCol){
          var pageSize = currentPage.height();
          var margin = (currentPage.innerHeight()-pageSize)/2;
          var headOffset = currentPage.find('.pageHead')[0].offsetHeight;
          var leftPosition,leftHeight,rightPosition,rightHeight;
          var again = false;

          do{
            again = false;
            var leftCount = currentColumnRow.find('.left').children().length;
            var rightCount = currentColumnRow.find('.right').children().length;
            var left = currentColumnRow.find('.left>:last');
            var right = currentColumnRow.find('.right>:last');

            if(left[0]){
              leftPosition = left[0].offsetTop-margin;
              leftHeight = left[0].offsetHeight;
            } else {
              leftPosition = headOffset;
              leftHeight = 0;
            }
            if(right[0]){
              rightPosition = right[0].offsetTop-margin;
              rightHeight = right[0].offsetHeight;
            } else {
              rightPosition = headOffset;
              rightHeight = 0;
            }

            var difa = Math.abs((leftPosition+leftHeight)-(rightPosition+rightHeight));
            var difb = Math.abs((leftPosition)-(rightPosition+rightHeight+leftHeight));
            if((difb < difa || rightCount == 0)
              && leftCount > 1 && rightPosition+rightHeight+leftHeight < pageSize &&
              !left.hasClass('diced') && !right.hasClass('diced')){
              left.remove();
              currentColumnRow.find('.right').prepend(left);
              again = true;
            }
          }while(again);

          if(currentColumnRow.find('.right').children().length <= 0){
            currentColumnRow.find('.right').remove();
            currentColumnRow.find('.mid').remove();
            currentColumnRow.find('.left').addClass('emptyRight');
            var questions = currentColumnRow.find('.left .question');
            for(var i = 0; i < questions.length; i++){
              var responses = $(questions[i]).find('.response').map(function(i,r){return $(r)});
              layoutResponses({responseElements:responses},$(questions[i]).find('.responses'));
            }
          }

        }
        currentColumnRow = $(columnsTemplate);
        currentColumn = $(leftTemplate)
        currentColumnRow.append(currentColumn);
        if(twoCol){
          currentColumnRow.append($(midTemplate));
          currentColumnRow.append($(rightTemplate));
          if(currSection && currSection.options.noColDiv){
            currentColumnRow.find('.mid>div').remove();
          }
        }
        currentPage.append(currentColumnRow);
        isTwoCol = twoCol;

        if(bumped){
          if(isTwoCol && bumped.hasClass('forceWide')){
            addColumns(false);
            addColumns(twoCol);
          } else {
            currentColumn.append(bumped);
            bumped = null;
          }
        }
      }
      function getNextColumn(){
        if(isTwoCol){
          if(currentColumn.hasClass('left')){
            currentColumn = currentColumnRow.find('div.right');
            return true;
          }
          return false;
        }
        return false;
      }
      function checkVerticalOverflow(item){
        var pageSize, position, height, margin, headOffset;

        function checkfit(elm){
          pageSize = currentPage.height();
          margin = (currentPage.innerHeight()-pageSize)/2;
          position = elm.offsetTop-margin;
          height = $(elm).outerHeight(true);
          headOffset = currentPage.find('.pageHead').outerHeight(true);
          return (position+height) >= pageSize;
        }
        function dicePassage(){
          var bits = item.examElement.children();
          var currentBody = $(passageTemplate);
          copyFont(currentBody,item.examElement);
          currentBody.addClass('diced');

          currentColumn.append(currentBody);
          currentBody.append(bits[0]);
          if(checkfit(currentBody[0])){
            currentBody.remove();
            addPage();
            addColumns(isTwoCol);
            currentColumn.append(currentBody);
          }

          for(var i = 0; i < bits.length; i++){
            currentBody.append(bits[i]);
            if(checkfit(currentBody[0])){
              $(bits[i]).remove();

              if(currentBody.html().length == 0){
                currentBody.remove()
              }


              var newPage = true;
              if(isTwoCol){
                if(currentColumn.hasClass('left')){
                  currentColumn = currentColumnRow.find('div.right');
                  newPage = false;
                }
              }
              if(newPage){
                addPage();
                addColumns(isTwoCol);
              }

              currentBody = $(passageTemplate);
              copyFont(currentBody,item.examElement);
              currentBody.addClass('diced');
              currentColumn.append(currentBody);
              i--;
            }
          }
          if(isTwoColSection)addColumns(isTwoColSection);
        }
        function diceQuestion(){
          var bits = item.examElement.find('.prompt').children();
          var currentBody = $(questionTemplate);
          var currentPrompt = currentBody.find('.prompt');
          var responseBits = item.examElement.find('.responses').children();
          var shouldDiceResponse = false;

          copyFont(currentBody.find('.itemNumber, .itemContents'),item.examElement.find('.itemContents'));
          currentBody.find('.itemNumber').html(item.examElement.find('.itemNumber').html());
          currentBody.addClass('diced');

          // addPage();
          // addColumns(isTwoColSection);
          currentColumn.append(currentBody);
          if(checkfit(currentBody[0])){
            currentBody.remove();
            addPage();
            addColumns(isTwoCol);
            currentColumn.append(currentBody);
          }
          var numWidth = currentBody.find('.itemNumber').width();
          var oldPb;
          for(var i = 0; i < bits.length; i++){
            currentPrompt.append(bits[i]);
            if(i == bits.length-1){
              oldPb = currentBody.css('padding-bottom')
              currentBody.css('padding-bottom', item.examElement.css('padding-bottom'));
              currentBody.find('.responses').append(shouldDiceResponse?responseBits[0]:responseBits);
            }
            if(checkfit(currentBody[0])){
              $(bits[i]).remove();
              if(i == bits.length-1){
                currentBody.css('padding-bottom',oldPb);
                shouldDiceResponse = (currentBody.find('.responses').outerHeight() >= (currentPage.height() - currentPage.find('.pageHead')[0].offsetHeight));
                currentBody.find('.responses').html('');
              }

              if(currentPrompt.html().length == 0){
                currentBody.remove()
              }

              var newPage = true;
              if(isTwoCol){
                if(currentColumn.hasClass('left')){
                  currentColumn = currentColumnRow.find('div.right');
                  newPage = false;
                }
              }
              if(newPage){
                addPage();
                addColumns(isTwoCol);
                if (item.addedPage > 15) {
                  var str = 'Error: ' + (item.questionNumber ? 'Question #' + item.questionNumber : 'Passage ' + item.examPassage.passageName) + ' will not fit on a page.';
                  str += '  Please adjust your margin size and or font size and try again.'
                  scope.exam.error = str;
                  throw str;
                } else 
                  item.addedPage++;
              }

              currentBody = $(questionTemplate);
              currentPrompt = currentBody.find('.prompt');
              copyFont(currentBody.find('.itemNumber, .itemContents'),item.examElement.find('.itemContents'));
              currentBody.find('.itemNumber').width(numWidth);
              currentBody.addClass('diced');
              currentColumn.append(currentBody);
              i--;
            }
          }

          for (var i = 1; i < responseBits.length && shouldDiceResponse; i++){
            currentBody.find('.responses').append(responseBits[i]);
            if (checkfit(currentBody[0])) {
              $(responseBits[i]).remove();

              var newPage = true;
              if (isTwoCol) {
                if (currentColumn.hasClass('left')) {
                  currentColumn = currentColumnRow.find('div.right');
                  newPage = false;
                }
              }
              
              if (newPage) {
                addPage();
                addColumns(isTwoCol);
              }

              currentBody = $(questionTemplate);
              copyFont(currentBody.find('.itemNumber, .itemContents'), item.examElement.find('.itemContents'));
              currentBody.find('.itemNumber').width(numWidth);
              currentBody.addClass('diced');
              currentColumn.append(currentBody);
              i--;
            }
          }

        }

        if(checkfit(item.examElement[0])){
          if(height < (pageSize-headOffset)){
            item.examElement.remove();
            roughDoc.append(item.examElement);
            var newPage = !getNextColumn();
            if(newPage){
              addPage();
              addColumns(isTwoColSection);
              if (item.addedPage > 15) {
                var str = 'Error: ' + (item.questionNumber ? 'Question #' + item.questionNumber : 'Passage ' + item.examPassage.passageName) + ' will not fit on a page.';
                str += '  Please adjust your margin size and or font size and try again.'
                scope.exam.error = str;
                throw str;
              } else
                item.addedPage++;
            }
            if(item.examItem){
              layoutQuestion(item);
            } else if(item.examPassage){
              layoutPassage(item);
            }
          } else if(height > (pageSize-headOffset)){
            if(!isTwoCol){
              if(canDice(item)){
                item.examElement.remove();
                if(item.examItem){
                  diceQuestion();
                } else if(item.examPassage){
                  dicePassage(item);
                }
              } else {
                var str = 'Error: '+(item.questionNumber?'Question #'+item.questionNumber:'Passage '+item.examPassage.passageName)+' will not fit on a page.';
                str += '  Please adjust your margin size and or font size and try again.'
                scope.exam.error=str;
                throw str;
              }
            } else {
              item.examElement.remove();
              roughDoc.append(item.examElement);
              item.examElement.addClass('forceWide');
              addColumns(false);
              if(item.examItem){
                layoutQuestion(item);
              } else if(item.examPassage){
                layoutPassage(item);
              }
            }
          }
          return false;
        }
        return true;
      }
      function setWide(item){
        var bits = item.examElement.find('svg, img, span[style*="white-space:pre"]');
        var setwide = item.examElement.find('.forceWide');
        var max = currentColumn.width()-item.examElement.find('.itemNumber').outerWidth();
        item.fits = true;

        for(var i = 0; i < bits.length; i++){
          //console.log(bits[i], bits[i].offsetWidth);
          if(bits[i].offsetWidth > max){
            item.fits = false;
          }
        }
        if(setwide.length > 0){
          item.fits = false;
        }

        if(!item.fits){
          item.examElement.addClass('forceWide')
        }

        if(!item.fits && isTwoCol){
          addColumns(false);
        }
      }
      function canDice(item){
        var bits = [];
        var imgs;
        if(item.examItem){
          bits = item.examElement.find('.prompt').children();
        } else if(item.examPassage){
          bits = item.examElement.children();
        }

        var maxH = currentPage.height() - currentPage.find('.pageHead')[0].offsetHeight;
        var maxW = currentPage.width();// - currentPage.find('.pageHead')[0].offsetHeight;
        var fits = true;

        for (var i = 0; i < bits.length && fits; i++){
          if ($(bits[i]).outerHeight() > maxH){
            fits = false;
          } else if ((imgs = $(bits[i]).find('img')).length > 0) {
            for (var b = 0; b < imgs.length && fits; b++){
              if ($(imgs[b]).outerWidth() > maxW)
                fits = false;
            }
          }
        }

        if (item.examItem) {
          bits = item.examElement.find('.responses').children();
          for (var i = 0; i < bits.length && fits; i++) {
            if ($(bits[i]).outerHeight() > maxH) {
              fits = false;
            } else if ((imgs = $(bits[i]).find('img')).length > 0) {
              for (var b = 0; b < imgs.length && fits; b++) {
                if ($(imgs[b]).outerWidth() > maxW)
                  fits = false;
              }
            }
          }
        }
        

        return fits;
      }

      function layoutExam(exam, wrapup){
        var numbers = roughDoc.find('.itemNumber');
        // var max = 0;
        // for(var i = 0; i < numbers.length; i++){
        //   if(numbers[i].offsetWidth > max){
        //     max = numbers[i].offsetWidth;
        //   }
        // }
        //
        // numbers.css('width',max+'px');

        async.eachSeries(exam.sections,
          function(section,callback){
            layoutSection(section);
            callback();
          },
          wrapup);


      }
      function layoutSection(section){
        // console.log('layout section');
        currSection = section;
        isTwoColSection = section.options.twoColumns;

        if (section.options.pageBreak || !currentPage) {
          addPage();
          addColumns(isTwoColSection);
        } else if((isTwoColSection && !isTwoCol) || (!isTwoColSection && isTwoCol) || section.headingElement){
          addColumns(isTwoColSection);
        }

        if(section.headingElement){
          currentColumnRow.before(section.headingElement);
        }

        async.eachSeries(section.items,
          function(item,callback){
            if(item.examElement){
              item.addedPage=0;
              if(isTwoColSection!=isTwoCol){
                addColumns(isTwoColSection);
              }
              if(item.items){
                layoutPassage(item);
                for(si = 0; si < item.items.length; si++){
                  if(isTwoColSection!=isTwoCol){
                    addColumns(isTwoColSection);
                  }
                  item.items[si].addedPage = 0;
                  layoutQuestion(item.items[si]);
                }

                if(section.options.passageHR){
                  currentColumn.append('<hr class="passage-hr">');
                }
              } else {
                layoutQuestion(item);
              }
            }
            callback();
          },
          wrapup);

        function wrapup(){
          if(section.options.sectionHR){
            currentPage.append('<hr class="section-hr">');
            addColumns(isTwoCol);
          }
        }
        //addColumns(false);
      }
      function layoutPassage(passage){
        //console.log('layout passage',passage);
        setWide(passage);

        passage.examElement.remove();
        currentColumn.append(passage.examElement);
        checkVerticalOverflow(passage);

        if(isTwoCol != isTwoColSection){
           addColumns(isTwoColSection);
        }
      }
      function layoutQuestion(question){
        //console.log('layout question',question);
        setWide(question);
        var last = currentPage.find('.columns>:not(.mid)>:last')
        if(!question.fits && last.hasClass('passage')){
          if(last.parent().parent().find('.right').length > 0){
            last.remove();
            currentColumn.append(last);
          }
        }

        question.examElement.remove();
        currentColumn.append(question.examElement);
        layoutResponses(question,question.examElement.find('.responses'));
        checkVerticalOverflow(question)

        if(!isTwoCol && isTwoColSection){
           addColumns(isTwoColSection);
        }
      };
      function layoutResponses(question,container){
        if(question.responseElements){
          var list = (question.responseElements.toArray ? question.responseElements.toArray() : question.responseElements);
          list = list.sort((a, b) => a.find('.responseNumber').html().localeCompare(b.find('.responseNumber').html()));
          for (var i = 0; i < list.length; i++) {
            container.append(list[i]);
          }

          for(var i = 0; i < question.responseElements.length; i++){
            question.responseElements[i].css('flex','').css('max-width','');
          };

          var max = 0;
          var width = Math.floor(container.width()-1);
          for(var i = 0; i < question.responseElements.length; i++){
          	if(question.responseElements[i].outerWidth() > max){
            	max = question.responseElements[i].outerWidth();
            }
          };

          var count = Math.floor(width/(max||width))
          var length = question.responseElements.length - (question.responseElements.length%2)
          if(count > length){
            count = length;
          }
          length -= count;
          while(count > length && length > 0){
          	count--;
            length++;
          }

          max=Math.floor(width/count);

          for(var i = 0; i < question.responseElements.length; i++){
          	question.responseElements[i][0].style.flex = '1 1 '+max+'px';
          	question.responseElements[i][0].style.maxWidth = max+'px';
          };

          if(width >= (max*2) && width < (max*3)){
            var l = Math.ceil(question.responseElements.length/2);
            for(var i = 0; i < l; i++){
              container.append(question.responseElements[i]);
              if((i+l) < question.responseElements.length){
                container.append(question.responseElements[i+l]);
              }
            }
          }
        }
      }

      scope.refresh = function( f ){
        $timeout(function() {
          scope.$apply();
          if(f){
            f();
          }
        });
      }

      scope.exam.waitForAllItemsToResolve()
      .then(function(){
        roughDoc = $('<div class="roughDoc examPreview"></div>');
        el.append(roughDoc);
        newDoc = $('<div class="newDoc examPreview"></div>');
        el.append(newDoc);
        finalDoc = $('<div class="finalDoc examPreview"></div>');
        el.append(finalDoc);


        if(!scope.exam.options){
          scope.exam.options = {};
        }
        if(!scope.exam.options.pageSize){
          scope.exam.options.pageSize = { label: 'Letter (8.5 x 11)', width:8.5, height:11, size:'letter portrait'};
        }
        if(!scope.exam.options.fontSize){
          scope.exam.options.fontSize = defFontSize;
        }
        if(!scope.exam.options.fontFamily){
          scope.exam.options.fontFamily = defFontFam;
        }

        pageWidth = parseFloat(scope.exam.options.pageSize.width)||8.5;
        pageHeight = parseFloat(scope.exam.options.pageSize.height)||11;
        pageMargin = scope.exam.options.margin||0.5;
        pageMarginTop = scope.exam.options.marginTop||0.25;

        var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                       navigator.userAgent &&
                       navigator.userAgent.indexOf('CriOS') == -1 &&
                       navigator.userAgent.indexOf('FxiOS') == -1;

        if(isSafari){
          pageWidth -= (pageMargin*2);
          pageHeight -= (pageMargin*2)+1;
          pageMargin = 0;
          pageMarginTop = 0;
        }

        var css = cssTemplate.replace('[[SIZE]]', scope.exam.options.pageSize.size)//width height
        .replace('[[WIDTH]]', pageWidth+'in')
        .replace('[[HEIGHT]]', pageHeight+'in')
        .replace('[[MARGIN]]', pageMargin+'in')
        .replace('[[MARGINTOP]]', pageMarginTop+'in')
        .replace('[[FONT/2]]', (scope.exam.options.fontSize/2)+'pt');

        roughDoc.append(css);
        newDoc.append(css);
        finalDoc.append(css);

        var allElements = [];
        for(var s = 0; s < scope.exam.sections.length; s++){
          scope.exam.msg = 'Loading Sections...'+(s+1);
          scope.refresh();
          sectionToDom(scope.exam.sections[s]);
          for(var i = 0; i < scope.exam.sections[s].items.length; i++){
            if(scope.exam.sections[s].items[i].items){
              allElements.push(passageToDom(scope.exam.sections[s].items[i],scope.exam.sections[s]));
              for(var si = 0; si < scope.exam.sections[s].items[i].items.length; si++){
                allElements.push(questionToDom(scope.exam.sections[s].items[i].items[si],scope.exam.sections[s]));
              }
            } else {
              allElements.push(questionToDom(scope.exam.sections[s].items[i],scope.exam.sections[s]));
            }
          }
        }
        scope.exam.msg = 'Loading Images...';
        var domElements = $(allElements.reduce(function(a,b){return $.merge(a,b);},[]));

        return $q.all([$q.when(domElements.imagesLoaded()),new Promise(function(resolve, reject) {
          console.log('loading mathml');
          MathJax.Hub.Queue(function () {
            console.log('MathJax is done');
            resolve();
          });
        })]);

      })
      .then(function(){
        scope.exam.msg = 'Preforming Exam Layout...';
        scope.refresh();
        layoutExam(scope.exam, function(){
          addColumns(false);

          scope.exam.msg = 'Preforming Exam Cleanup...';
          scope.refresh();
          $('path').each(function(){
            //console.log('removing stroke path');
            this.removeAttribute('stroke-width');
            this.setAttribute('stroke','none');
          });

          //console.log('fixing mathjax');
          // fix svg issues, this runs globaly to the whole page, not just the specific chunk of generated html
          var t = 0;
          var useTags = document.getElementsByTagName('use');
          for (var o=0; o < useTags.length; o++) {
            var useTag = useTags[o];
            var href = (useTag.getAttribute('xlink:href') || useTag.getAttribute('href') || '').substring(1);

            // fetch the MathJax path definition
            var pathDef = document.getElementById(href);
            if (!pathDef) {
              //console.log('use found issue, href not found', useTag, href);
              continue;
            }

            // create the new tag
            var cloned = pathDef.cloneNode(true);
            cloned.removeAttribute('id');

            // build the transform from the use tag, and map X Y to translate()
            var x = parseInt(useTag.getAttribute('x')) || 0;
            var y = parseInt(useTag.getAttribute('y')) || 0;
            var transform = useTag.getAttribute('transform') || '';
            var translate =  'translate(' + x + ', ' + y + ')';
            cloned.setAttribute('transform', transform + ' ' + translate);

            // now, replace use with cloned and remove the use tag
            useTag.parentNode.appendChild(cloned);
            useTag.parentNode.removeChild(useTag);
            o--;
            //console.log('deleting useTag', t++);
          }

          var columns = newDoc.find('.columns');
          for(var i = 0; i < columns.length; i++){
            if($(columns[i]).find('.left, .right').children().length == 0){
              $(columns[i]).remove();
            }
          }

          var versionText = ' V' + (scope.$index + 1);
          var pages = newDoc.find('.page');
          for(var i = 0; i < pages.length; i++){
            if($(pages[i]).find('.columns').length > 0){
              pageNum++;
              $(pages[i]).find('.pageNum').text((!scope.preview?$rootScope.documentId + versionText:'Preview') + ' - Page '+pageNum)
              if((pageNum == 1 && scope.exam.options.nameHeader == 'first') || scope.exam.options.nameHeader == 'all'){
                $(pages[i]).find('.nameSpace').text('Name:').after($(nameSpaceTemplate));
              }
            } else {
              $(pages[i]).remove();
            }
          }


          var images = [], body, pages, link, style;

          function pageToImg(index){
            if(index < pages.length){
              scope.exam.msg = 'Rendering Pages...'+(index+1)+'/'+pages.length;
              scope.refresh();
              var html = '<body style="margin:0 !important;">'+
              link+style+pages[index].outerHTML+
              '</body>';
              html = html.replace(/https\:\/\/s3\.amazonaws\.com\/examgen/g,'/databases');
              rasterizeHTML.drawHTML(html)
              .then(function(res){
                images.push(res.image);
                pageToImg(index+1);
              });
            } else {
              for(var i = 0; i < images.length; i++){
                finalDoc[0].appendChild(images[i]);
              }

              $rootScope.$broadcast('layoutComplete',{
                copyNumber : scope.$index,
                html : finalDoc,
                isAnswerKey : false
              });
            }
          }

          body = newDoc[0];
          link = body.querySelector('link').outerHTML+ '<link href="https://netdna.bootstrapcdn.com/twitter-bootstrap/2.3.2/css/bootstrap.no-responsive.no-icons.min.css" rel="stylesheet">';
          style = body.querySelector('style').outerHTML;
          pages = body.querySelectorAll('.page');
          if(scope.preview!=true){
            pageToImg(0);
          } else {
            $rootScope.$broadcast('layoutComplete',{
              copyNumber : scope.$index,
              html : newDoc,
              isAnswerKey : false
            });
          }
        });
      })
      .catch(function(err){
        scope.exam.error= scope.exam.error || 'An error occured while generating your EXAM';
        console.error(err);
      });
    }
  };
});
