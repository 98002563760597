/*

<div class="btn-group">
  <a class="btn dropdown-toggle" data-toggle="dropdown" href="#">
    {{ database.databaseName }}
    <span class="caret"></span>
  </a>
  <ul class="dropdown-menu">
    <li ng-repeat="o in databases">
      <a href="#">{{ o.databaseName }}</a>
    </li>
  </ul>
</div>

          <dropdown model="database" options="databases"></dropdown>

*/

angular.module('examgen.directive').
directive('dropdown', function() {
  return {
    restrict: 'E',
    scope: {
      model: '=model',
      options: '=options',
      selectOption: '@selectOption',
      emptyMessage: '@emptyMessage',
      loading: '=loading',
      class: '@class',
      loadingMessage: '@loadingMessage',
      display: '@display',
      tooltipsrc: '@tooltipsrc',
      displayType: '@displayType',
      vm: '=vm'
    },
    templateUrl: 'app/partials/directives/dropdown.html',
    controller: function($rootScope, $scope, $element, $attrs) {

      /*

      {{
        loading ? loadingMessage :
          (options.length ? (model ? optionName(model) : selectOption) :
            emptyMessage)
      }}

      */

      var $dropdownLabel = $element.find('.dropdown-label');
      var update = function() {
        var label;
        if ($scope.loading) {
          $scope.disabled = true;
          label = $scope.loadingMessage || 'Loading';
        } else if ($scope.options && $scope.options.length) {
          $scope.disabled = false;
          if ($scope.model) {
            label = $scope.optionName($scope.model);
          } else {
            label = $scope.selectOption;
          }
        } else {
          label = $scope.emptyMessage;
          $scope.disabled = true;
        }

        $dropdownLabel.html(label);
      };

      $scope.loadingMessage = $scope.loadingMessage || 'Loading';
      $scope.$watchCollection('[model, options, loading]', function(val) {
        $scope.active = val[0];
        update();
      });

      $scope.optionName = function(option) {
        return option ? option[$attrs.display] : '';
      };

      $scope.optionTitle = function(option) {
        return (option && $attrs.tooltipsrc) ? option[$attrs.tooltipsrc] : '';
      };

      $scope.questionRange = function(item) {
        if(item.meta && item.meta.questionRange){
          return '- Questions #'+ item.meta.questionRange.start+' - #'+item.meta.questionRange.stop;
        } else {
          return '';
        }
      };

      $scope.mouseoverItem = function(o) {
        $scope.active = o;
      };

      $scope.mouseoutItem = function() {
        $scope.active = $scope.model;
      };

      $scope.handleClick = function($event, option) {
        $event.preventDefault();
        $scope.model = option;

        if ($scope.close) {
          $scope.close();
        }
      };

      $scope.deleteItem = function($event, o) {
        $event.preventDefault();
        $rootScope.$broadcast("deleteTOC", o);
        return false;
      };
    },

    link: function(scope, element) {
      scope.selectTopicOrSectionOrStandard = function(t, $event) {
        scope.handleClick($event, t);
      };

      scope.handleDragstart = function(o, $event) {
        if (o.topic) {
          scope.vm.handleTocTopicDragstart(o.topic, $event);
        } else {
          scope.vm.handleTocSectionDragstart(o.section, $event);
        }
      };

      var options, menu, toggle;
      toggle = element.find('a.dropdown-toggle');
      toggle.bind('click', function() {
        options = element.find('ul.dropdown-menu');
        menu = element.find('.btn-group');

        if (toggle.hasClass('disabled') || !scope.options || !scope.options.length) {
          return;
        }

        options.css({
          display: 'block',
          top: '30px',
          left: '0'
        });

        var f = setInterval(function() {
          if (!menu.hasClass('open')) {
            scope.close();
            clearInterval(f);
          }
        }, 50);
      });

      scope.close = function() {
        if (options) {
          options.css('display', 'none');
        }
      };

      element.find('.btn-group')
        .attr('data-step', element.attr('data-step'))
        .attr('data-intro', element.attr('data-intro'));

      element.removeAttr('data-step');
      element.removeAttr('data-intro');
    }
  };
});
