angular.module('examgen.directive').
/**
* @ngdoc directive
* @name examgen.directive:ckEditor
* @description Encapuslates ckEditor rich text editor
*/
directive('ckEditor', function($rootScope) {
  return {
    require: '?ngModel',
    link: function(scope, elm, attr, ngModel) {
      scope.qe = {
        product: 'none'
      };

      CKEDITOR.config.height = 135;
      CKEDITOR.config.removeButtons = '';
      CKEDITOR.config.toolbar = [
        ['Bold','Italic','Underline'],
        ['Subscript', 'Superscript'],
        ['NumberedList','BulletedList']
      ];

      CKEDITOR.config.imageUploadUrl = '/api/image';

      var ck = CKEDITOR.replace(elm[0]);

      if (!ngModel) return;

      ck.on('pasteState', function() {
        $rootScope.$safeApply(function() {
          ngModel.$setViewValue(ck.getData());
        });
      });

      ngModel.$render = function(value) {
        if(ck) ck.setData(ngModel.$viewValue);
      };

      scope.$on('$destroy', function(){
        try {
          ck.destroy();
          ngModel.$render = null;
        }catch(e){
          console.error('Error destroying ckEditor');
        }
      });
    }
  };
});
