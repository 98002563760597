angular.module('examgen.directive').
directive('navigation',function(){
  return {
    restrict: 'E',
    replace: true,
    templateUrl: '/app/directives/navigation.html',
    controller: function ($scope, $element, $rootScope, $route, $state, $analytics, $window) {
      $scope.global = $rootScope;
      $scope.$window = $window;
      $rootScope.$watch('showFeedbackModal', function(val) {
        if (val === true) {
          $analytics.eventTrack('feedback modal open');
          $rootScope.feedback = "";
          $rootScope.sendingFeedback = false;
        } else if (val === false) {
          $analytics.eventTrack('feedback modal close');
        }
      });
      $scope.displayFeedbackModal = function(val) {
        $rootScope.showFeedbackModal = !!val;
      };

      // $scope.displayHelp = function(val) {
      //   $rootScope.displayHelp(val)
      // };

      $scope.newExam = function() {
        $rootScope.createExam();
      };

      $scope.saveExam = function() {
        $rootScope.$emit('saveExam');
      };

      $scope.shareExam = function() {
        $rootScope.$emit('shareExam');
      };

      $scope.exportExam = function() {
        $rootScope.$broadcast('exportExam');
      };

      $scope.printExam = function() {
        $rootScope.$emit('printExam');
      };

      $scope.newLibrary = function() {
        $rootScope.$broadcast('newLibrary');
      };

      $scope.openLibrary = function() {
        $rootScope.$emit('openLibrarySwitcher');
      };

      $scope.saveLibrary = function() {
        $rootScope.$broadcast('saveLibrary');
      };

      $scope.openExamSwitcher = function() {
        $rootScope.$emit('openExamSwitcher');
      };

      $scope.addClassByCode = function() {
        $rootScope.$emit('addClassByCode');
      }

      $scope.openSearch = function() {
        $rootScope.$emit('openSearch');
      };

      $scope.dumpLogs = function() {
        var getCircularReplacer = () => {
          var seen = new WeakSet();
          return (key, value) => {
            if (typeof value === 'object' && value !== null) {
              if (seen.has(value)) {
                return '[Circular Ref]';
              }
              seen.add(value);
            }
            return value;
          };
        };

        var csv = '';
        console.logs.forEach(e => {
          csv += `${e.time}\t\t${e.level}\t\t${JSON.stringify(e.val, getCircularReplacer())}\n`;
        });

        var hiddenElement = document.createElement('a');
        var blob = new Blob([csv], { type: "text/tab-separated-values" })
        var url = URL.createObjectURL(blob);
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'logs.tsv';
        hiddenElement.click();
      }
    }
  };
})

.directive('navTitle', function($timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attr) {
      var lwidth;
      function redraw() {
        var leftElement = element.prev();
        var rightElement = element.next();

        var start = Math.floor(leftElement.offset().left + leftElement.outerWidth());
        var right = Math.floor(rightElement.offset().left);

        var width = Math.floor(right - start) - 60;
        if (lwidth !== width) {
          lwidth = width;
          element.css({width: width, 'visibility': 'initial'})
        }
      };

      element.css('visibility', 'hidden');

      $timeout(function() {
        scope.$watch(function() {
          redraw();
          return true;
        })
      });

      $(window).bind('resize', redraw);

      scope.$on('$destroy', function() {
        console.info('$scope.$destroy');
        $(window).unbind('resize', redraw);
      });
    }
  }
});
