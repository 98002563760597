angular.module('examgen.directive').
  /**
   * @ngdoc directive
   * @name examgen.directive:composerItem
   * @description This directive provides the glue between the user interface DOM APIs and the constructor functions defined in constructors.js
   */
directive('composerItem', function($q, $rootScope, $timeout, constructors) {
  function link(scope, el, attr) {
    var type = attr.composerItem === 'exam' ? 'exam' : 'item';

    // if type is exam, then don't bind to click, focus, etc ...

    el.addClass('composerItem');

    scope.$watch('items.length + numberOfTopicsAndSections', function(startIndex) {
      //console.log('startIndex',startIndex);
      var composerItem = getNearestTarget();

      if (startIndex !== undefined) {
        el.attr('tabindex', startIndex + composerItem.index); //update tabindex of element
      }
    });

    // if insertion point is me, then focus the element
    scope.$watch('exam.insertionPoint', function(point) {
      var exam = scope.exam;
      if (getNearestTarget() === point && exam && !exam.skipInsertionPointFocus) {

        //console.log('insertion point', point && point.getLabel ? point.getLabel() : null, exam.skipInsertionPointFocus, exam);
        el.focus();
      }
    });

    function getNearestTarget() {
      return scope.treeItem || scope.itemOrPassage || scope.section || scope.loadedExam;
    }

    function stopEvent(ev) {
      ev.stopPropagation(true);
      ev.preventDefault();
    }

    function copyKeyState(ev) {
      if (ev.ctrlKey !== undefined) {
        scope.setComposerItemCtrl(ev.ctrlKey);
      }
      if (ev.shiftKey !== undefined) {
        scope.setComposerItemShift(ev.shiftKey);
      }
    }

    var mousedown = false;
    var dragging = false;
    el.bind('focus', function(ev) {
      scope.$safeApply(function() {
        copyKeyState(ev);
        //console.log('focus', getNearestTarget().getLabel());
        scope.focusAncestors(ev);
        stopEvent(ev);
        var composerItem = getNearestTarget();
        //exam.insertionPoint = composerItem;

        //update the exam insertion point
        scope.loadedExam.then(function(exam) {
          exam.setInsertionPoint(composerItem, true);
        });

        $q.when(getNearestTarget()).then(function(composerDropTarget) {
          if (composerDropTarget instanceof constructors.ComposerItem) {
            scope.setItemToDisplay(composerDropTarget.examItem);
          }
        });

        // since focus() is called first before so many events, we have to think they might be about to
        // start dragging, so if it is already focused, don't do anything, because if they will click on it,
        // click() will be called
        if ($(el).is(':focus')) {
          return;
        }
        $timeout(function() {
          if (mousedown) {
            return;
          }

          scope.handleMultiSelect(ev, getNearestTarget(), el);

        }, 100);
      });
    });

    el.bind('forceselection', function(ev) {
      scope.$safeApply(function() {
        stopEvent(ev);
        scope.forceAddMultiSelectItem(getNearestTarget());
      });
    });

    el.bind('mousedown mouseup', function(ev) {
      scope.$safeApply(function() {
        mousedown = ev.type === 'mousedown';
        copyKeyState(ev);
        ev.stopPropagation();

        if (mousedown && !ev.ctrlKey && !ev.shitfKey) {
          scope.handleMultiSelect(ev, getNearestTarget(), el);
        }
      });
    });

    if (type === 'item') {
      el.bind('click', function(ev) {
        ev.stopPropagation(true);
        scope.$safeApply(function() {
          scope.handleMultiSelect(ev, getNearestTarget(), el);
        });
      });
    }

    el.bind('keyup', function(ev) {
      if (ev.which === 17) {
        scope.setComposerItemCtrl(false);
      } else if (ev.which === 16) {
        scope.setComposerItemShift(false);
      } else if (ev.which === 9) {
        scope.setComposerItemTab(false);
      }
    });

    el.bind('keydown', function(ev) {
      if (ev.which === 192) {
        console.clear();
        stopEvent(ev);
      }
      scope.$safeApply(function() {
        switch (ev.which) {
        case 9: // tab
          scope.setComposerItemTab(true);
          break;
        case 17: // ctrl
          scope.setComposerItemCtrl(true);
          ev.stopPropagation();
          ev.preventDefault();
          break;
        case 16: // shift
          scope.setComposerItemShift(true);
          ev.stopPropagation();
          ev.preventDefault();
          break;
        case 46: // delete
        case 8: // backspace
          ev.stopPropagation();
          ev.preventDefault();

          if (getNearestTarget() instanceof constructors.ComposerSection) {
            getNearestTarget().remove();
          } else {
            scope.removeSelectedComposerItems();
          }
          break;

        // up or down
        case 38:
        case 40:
          var dir = ev.which === 38 ? -1 : 1; // 38: up, 40: down

          ev.stopPropagation();
          ev.preventDefault();

          // ensure it's added to the "multi select" list
          var assigned = getNearestTarget().setSiblingAsInsertionPoint(dir);

          // @todo does this work on IE9+?
          if (ev.shiftKey) {
            scope.handleMultiSelect(ev, assigned, el);
          } else {
            scope.setMultiSelectionItems([assigned]);
          }
          break;

        case 34: // pagedown
        case 33: // pageup
          ev.stopPropagation();
          ev.preventDefault();

          var first = ev.which === 33;
          var item = getNearestTarget();
          var items = item._exam.getAllTreeElements();
          item._exam.setInsertionPoint(items[first ? 0 : items.length - 1], true);
        break;

        case 65: // ctrl + A
          if (ev.ctrlKey) {
            ev.stopPropagation();
            ev.preventDefault();
            scope.setMultiSelectionItems(getNearestTarget()._exam.getAllTreeElements());
          }
        break;
        default:
          console.log('unknown key', ev.which);
        }

      });

    });

    //workaround for IE
    el.bind('selectstart', function() {
      dragging = true;
      //console.log('selectStart', getNearestTarget().getLabel());
      if (el[0].dragDrop) el[0].dragDrop();
      return false;
    });

    var dndCollection = []; //the purpose of this collection is described here: http://stackoverflow.com/a/10906204/366856

    el.bind('dragenter', function(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      scope.$safeApply(function() {
        //console.log('dragEnter', getNearestTarget().getLabel());
        if (el.is(':focus')) {
          scope.forceAddMultiSelectItem(getNearestTarget());
        }

        if (!dndCollection.length) {
          el.addClass('dragenter');
          //console.log('dragenter on', getNearestTarget());
        }


        if (dndCollection.indexOf(ev.target) === -1) dndCollection.push(ev.target);
      });
    });

    el.bind('dragleave', function(ev) {
      scope.$safeApply(function() {
        //console.log('dragleave', getNearestTarget().getLabel());
        dndCollection.splice(dndCollection.indexOf(ev.target), 1); //remove ev.target

        if (!dndCollection.length) {
          el.removeClass('dragenter');
        }
      });
    });

    el.bind('dragover', function(ev) {
      dragging = false;
      //console.log('dragover', getNearestTarget().getLabel());
      ev.preventDefault();
      ev.stopPropagation();
    });


    var completeDropCb; //this gets bound in the drop function

    scope.$on('completeDrop', function(something, options) {
      if (completeDropCb) { //we need to guard here because all instances of this directive have registered for this event
        completeDropCb(options);
      }
    });

    el.bind('drop', function(ev) {
      //console.log('drop');
      ev.preventDefault();
      ev.stopPropagation();
      el.removeClass('dragenter');

      scope.$safeApply(function() {
        //get the items being dragged
        var itemsBeingDragged = $rootScope.getItemsBeingDragged();

        $q.all([getNearestTarget(), scope.loadedExam, itemsBeingDragged]).then(function(vals) {
          var target = vals[0],
              exam = vals[1],
              droppedItems = vals[2];

          console.log('item drop', target, droppedItems, droppedItems.length);

          // dropping somewhere in the exam tree but in no particular item, most likely like at the end

          if ((target instanceof constructors.ComposerItem && (
            target.composerParent instanceof constructors.ComposerSection ||
            target.composerParent instanceof constructors.ComposerPassage ))||
            target instanceof constructors.ComposerPassage ||
            target instanceof constructors.ComposerSection) {
            //update the insertion point if he's a legal type
            exam.setInsertionPoint(target, true);
          } else {
            exam.setDefaultInsertionPoint();
          }

          scope.insertItemsInExam(droppedItems).then(function(inserted) {
            // cancel button pressed?
            if (!inserted) {
              return;
            }
            exam.setInsertionPoint(_.last(inserted), true);
          });
        });
      });
    });
  };


  return link;
});
