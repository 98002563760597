angular.module('examgen.directive').
directive('questionEditModal', function searchModal(exams, $compile, $window, $state, couch, $timeout, $rootScope, $http, databases, uuid, lazyLoad, session) {
  return {
    restrict: 'E',
    template: '<div id="edit-modal" ng-include="\'app/partials/modals/editQuestion.html\'"></div>',
    scope: {
      show: '=',
      showModal: '@',
    },
    controllerAs: 'ctrl',
    bindToController: true,

    controller: function($rootScope, $scope, $q, $element, $attrs) {
      var ctrl = this;

      ctrl.data = {
        question: undefined,
        dbs:[],
        firstTime:false,
      }

      databases.all.then(function(dbs) {
        ctrl.data.dbs = dbs;
      });
      $scope.refresh = function( f ){
        $timeout(function() {
          $scope.$apply();
          if(f){
            f();
          }
        });
      }

      $scope.$on('openEditQuestion', function(event, item) {
        $rootScope.$broadcast("questionEditorStatus", true);

        ctrl.data.src = item;
        var p;
        if(!item.isUserDB){
          p = $scope.initMyQuestions(item);
          item.userNote = undefined;
        } else if(item.part){
          ctrl.data.db = item.part.section.topic.chapter.database;
          p = Promise.resolve(ctrl.data.db);
        } else {
          p = Promise.resolve();
        }

        p.then(function(db){

          var part;
          if(item.isUserDB){
            part = item.part || { partId:'X'};
          } else {
            part = db.resolvedChapters[0].resolvedTopics[0].resolvedSections[0].resolvedParts[0];
          }

          ctrl.data.question = {
            idQuestion:item.itemId || item.itemCode,
            fkSubPart: part.partId || part.idSubPart,
            idSubPartQuestions: item.isUserDB ? item.refId : uuid.v4(),
            sortOrder: item.isUserDB ? item.itemNumber : part.resolvedItems.length,
            isQuestion:true,
            name:item.isUserDB ? item.itemName : part.resolvedItems.length+1,
            parent: {},
            wasUser:item.isUserDB,
          };

          if(item.displayName){
            ctrl.data.question.origin = item.userNote || (item.databaseName+' Chapter '+item.displayName.chapter+' #'+item.displayName.code);
            ctrl.data.question.displayName = item.databaseName+' Chapter '+item.displayName.chapter+' #'+item.displayName.code;
          } else if(item.origin) {
            var bits = item.origin.split('/');
            ctrl.data.question.origin = item.userNote || (bits[0]+' Chapter '+bits[2]+' #'+bits[3]);
            ctrl.data.question.displayName = bits[0]+' Chapter '+bits[2]+' #'+bits[3];
          } else {
            ctrl.data.question.origin = item.userNote ||  (item.databaseName+' Chapter '+
              (item.part.section.topic.chapter.chapterNumber+1)+' #'+item.name);
            ctrl.data.question.displayName = item.databaseName+' Chapter '+
              (item.part.section.topic.chapter.chapterNumber+1)+' #'+item.name;
          }

          if(item.resolvedItemContent.passageId){
            $scope.questionHasPassage(item.isUserDB);
          } else {
            if(item.isUserDB){
              ctrl.data.question.data = item.resolvedItemContent;
            } else {
              ctrl.data.question.data=JSON.parse(JSON.stringify(item.resolvedItemContent));
            }
          }

          if(item.resolvedItemContent.shortAnswer ){
            var response = {
              answer: item.resolvedItemContent.shortAnswer,
              answerLetter: 'A',
              idResponse: uuid.v4(),
              isCorrect: true,
              isPositionFixed: false,
              itemCode: ctrl.data.question.data.itemCode,
              sortOrder: 0
            }

            if(ctrl.data.question.data.responses && ctrl.data.question.data.responses.length > 0){
              ctrl.data.question.data.responses[0] = response;
            } else {
              ctrl.data.question.data.responses = [response];
            }
          }

          ctrl.data.newTopic = 'New Topic';
          ctrl.data.topics = [];
          if(part.section){
            part.section.topic.chapter.topics.then(function(topics){
              ctrl.data.topics = topics;
            });
            ctrl.data.question.originTopic = part.section.topic.topicId;
            ctrl.data.question.topicId = part.section.topic.topicId;
          }

          ctrl.data.question.data.prompt = ctrl.data.question.data.prompt.replace(/(?:<eq>)*(?:&#8203;)*(<math>.+<\/math>)(?:<\/eq>)*/gi,'<eq>&#8203;$1&#8203;</eq>')
          for(var i = 0; i < ctrl.data.question.data.responses.length; i++){
            ctrl.data.question.data.responses[i].answer = ctrl.data.question.data.responses[i].answer.replace(/(?:<eq>)*(?:&#8203;)*(<math>.+<\/math>)(?:<\/eq>)*/gi,'<eq>&#8203;$1&#8203;</eq>')
          }


          ctrl.showModal = true;
          $scope.refresh(function(){
            setTimeout(function () {
              $rootScope.$broadcast("editorQuestionSelected", ctrl.data.question);
            }, 100);
          });
        }).catch(function(error){
          $rootScope.$broadcast("questionEditorStatus", false);

          console.error(error);
        });
      });

      $scope.cleanIndexes= function(chapter){
        var defer = [];
        var merged = []
        chapter.topics = null
        return lazyLoad.chapter(chapter)
        .then(function(topics){
          defer = topics.map(function(t){return lazyLoad.topic(t);})
          return Promise.all(defer);
        })
        .then(function(sections){
          defer = [].concat.apply([], sections)
            .map(function(s){return lazyLoad.section(s);});
          return Promise.all(defer);
        })
        .then(function(parts){
          defer = [].concat.apply([], parts)
            .map(function(s){return lazyLoad.part(s);});
          return Promise.all(defer);
        })
        .then(function(items){
          var merged = items.concat.apply([], items);

          for(var i = 0; i < merged.length; i++){
            merged[i].itemName = i+1;
            merged[i].itemNumber = i;
            merged[i].partId = merged[i].part.partId;
            merged[i].part.refresh = true;
          }
          // for(var i = 0; i < merged.length; i++){
          //   merged[i].part = null;
          // }

          // console.log(merged);

          return $http.put('/api/items?userDB=true',merged.map(function(item){return{
            refId:item.refId,
            partId:item.partId,
            itemId:item.itemId,
            itemName:item.itemName,
            itemNumber:item.itemNumber,
            productPassageId:item.productPassageId,
            userNote:item.userNote,
          }}));
        });
      }

      $scope.questionHasPassage = function(isUserDB){
        var lzpassage;
        var data = ctrl.data.src.resolvedItemContent;
        if(ctrl.data.db){
          lzpassage = ctrl.data.db.resolvedPassages.filter(function(p){
            return p.passageName == data.resolvedPassage.passageName;
          })[0];
        }
        var pid = lzpassage ? lzpassage.passageId : uuid.v4();
        var prefid = lzpassage ? lzpassage.passageRefId : uuid.v4()
        ctrl.data.question.parent = {
          data: {
            content: data.resolvedPassage.resolvedPassageHTML,
            idPassage: pid,
            loaded: true,
          },
          isNew: lzpassage ? false:true,
          id: prefid,
          idProductPassages: prefid,
          isPassage: true,
          name: data.resolvedPassage.passageName,
          parent: {},
          poolId: pid,
          fkPassage: pid,
          fkProduct: ctrl.data.db ? ctrl.data.db.databaseId : data.resolvedPassage.database.databaseId,
        }
        if(isUserDB){
          ctrl.data.question.data = data;
        } else {
          ctrl.data.question.data = {
            idQuestion: data.itemCode,
            itemCode: data.itemCode,
            itemType: data.itemType,
            loaded: true,
            passageId: ctrl.data.question.parent.poolId,
            prompt: data.prompt,
            responses: data.responses ? JSON.parse(JSON.stringify(data.responses)) : [],
          }
        }
        ctrl.data.question.fkProductPassage = prefid
      }

      $scope.initMyQuestions = function(item){
        return Promise.resolve(ctrl.data.dbs.filter(function(db) {
          return db.databaseName === 'My Questions';
        })[0])
        .then(function(db) {
          ctrl.data.db = db;
          if(!db){
            ctrl.data.firstTime = true;

            db = {
              idProduct: uuid.v4(),
              name: 'My Questions',
              databaseName: 'My Questions',
              isUserDB: true,
              owner: $rootScope.profile.UserId,
              shortCode:'',
              created: Date.now()/1000,
              modified: Date.now()/1000,
              version: '1',
              archive: 0,
              passages:null,
              standards:null,
            };
            db.databaseId = db.idProduct;
            db.chapters = [{
              idChapter: uuid.v4(),
              fkProduct: db.idProduct,
              name: 'My Questions',
              created: Date.now()/1000,
              modified: Date.now()/1000,
              sortOrder: 0,
            }];
            db.chapters[0].topics = [{
              idTopic: uuid.v4(),
              fkChapters: db.chapters[0].idChapter,
              name:'My Questions',
              created: Date.now()/1000,
              modified: Date.now()/1000,
              sortOrder: 0,
            }];
            db.chapters[0].topics[0].sections = [{
              idPart: uuid.v4(),
              fkTopic: db.chapters[0].topics[0].idTopic,
              name:'(no section)',
              created: Date.now()/1000,
              modified: Date.now()/1000,
              sortOrder: 0,
            }];
            db.chapters[0].topics[0].sections[0].parts = [{
              idSubPart: uuid.v4(),
              fkPart: db.chapters[0].topics[0].sections[0].idPart,
              name:'(no subpart)',
              created: Date.now()/1000,
              modified: Date.now()/1000,
              sortOrder: 0,
            }];

            ctrl.data.db = db;

            var p = $q.defer();

            $http.put('/api/database/info?userDB=true', db).then(function(){
              return $http.put('/api/chapters?userDB=true', db.chapters);
            }).then(function() {
              return $http.put('/api/topics?userDB=true', db.chapters[0].topics);
            }).then(function() {
              return $http.put('/api/sections?userDB=true', db.chapters[0].topics[0].sections);
            }).then(function() {
              return $http.put('/api/subsections?userDB=true', db.chapters[0].topics[0].sections[0].parts);
            }).then(function() {
              db.chapters = null;
              return session.refresh()
            }).then(function(profile) {
              databases.fetch()
              return databases.all
            }).then(function(dbs) {
              ctrl.data.dbs = dbs;
              $rootScope.$broadcast("updateDatabaseList");
              p.resolve(db);
            })
            return p.promise;
          } else {
            return Promise.resolve(db);
          }
        })
        .then(function(db) {
          return lazyLoad.database(db).then(function(){
            return db.passages;
          }).then(function(res) {
            return db.chapters;
          }).then(function(res) {
            return lazyLoad.chapter(res[0]);
          }).then(function(res) {
            return lazyLoad.topic(res[0]);
          }).then(function(res) {
            return lazyLoad.section(res[0]);
          }).then(function(res) {
            return lazyLoad.part(res[0]);
          }).then(function(res) {
            return Promise.resolve(db);
          })
        }).catch(function(error){
          console.error(error);
        });
      }

      $scope.$on('editorModalQuestionUpdated', function() {
        ctrl.showModal = false;
        var p;
        var refreshDB = ctrl.data.question.wasUser;

        if(ctrl.data.question.data.shortAnswer && ctrl.data.question.data.responses){
          ctrl.data.question.data.shortAnswer = ctrl.data.question.data.responses[0].answer;
          ctrl.data.question.data.responses = undefined;
        }

        if(ctrl.data.question.topicId=='new' && ctrl.data.db){
          var topic = {
            idTopic: uuid.v4(),
            fkChapters: ctrl.data.db.resolvedChapters[0].chapterId,
            name:ctrl.data.newTopic,
            created: Date.now()/1000,
            modified: Date.now()/1000,
            sortOrder: ctrl.data.db.resolvedChapters[0].resolvedTopics.length,
          };
          topic.sections = [{
            idPart: uuid.v4(),
            fkTopic: topic.idTopic,
            name:'(no section)',
            created: Date.now()/1000,
            modified: Date.now()/1000,
            sortOrder: 0,
          }];
          topic.sections[0].parts = [{
            idSubPart: uuid.v4(),
            fkPart: topic.sections[0].idPart,
            name:'(no subpart)',
            created: Date.now()/1000,
            modified: Date.now()/1000,
            sortOrder: 0,
          }];

          ctrl.data.question.originTopic = topic.idTopic;
          ctrl.data.question.topicId = topic.idTopic;
          ctrl.data.question.fkSubPart = topic.sections[0].parts[0].idSubPart;
          var defer = $q.defer();

          $http.put('/api/topics?userDB=true', [topic])
          .then(function() {
            return $http.put('/api/sections?userDB=true', topic.sections);
          }).then(function() {
            return $http.put('/api/subsections?userDB=true', topic.sections[0].parts);
          }).then(function() {
            return session.refresh()
          }).then(function(profile) {
            databases.fetch()
            return databases.all
          }).then(function(dbs) {
            ctrl.data.dbs = dbs;
            if(!refreshDB)$rootScope.$broadcast("updateDatabaseList",ctrl.data.db);
            defer.resolve();
          })
          p = defer.promise;
        } else {
          p = Promise.resolve();
        }

        p.then(function(){
          if(ctrl.data.db && ctrl.data.question.topicId != ctrl.data.question.originTopic && ctrl.data.question.topicId != 'new'){
            var topic = ctrl.data.db.resolvedChapters[0].resolvedTopics.find(function(t){
              return t.topicId == ctrl.data.question.topicId;
            });

            var defer = $q.defer();

            topic.sections.then(function(sections){
              var section = sections.find(function(s){
                return s.sectionName=='(no section)';
              });

              return (section || sections[0]).parts;
            }).then(function(parts){
              var part = parts.find(function(p){
                return p.partName=='(no subpart)';
              });
              ctrl.data.question.fkSubPart = (part||part[0]).partId;
              defer.resolve();
            })

            return defer.promise;
          } else {
            return Promise.resolve();
          }
        })
        .then(function() {
          if(ctrl.data.db){
            return $http.put('/api/items?userDB=true',{
              idSubPartQuestions:ctrl.data.question.idSubPartQuestions,
              fkSubPart:ctrl.data.question.fkSubPart,
              fkQuestion:ctrl.data.question.fkQuestion,
              name:ctrl.data.question.name,
              sortOrder:ctrl.data.question.sortOrder,
              fkProductPassage:ctrl.data.question.fkProductPassage,
              origin:ctrl.data.question.origin,
            })
          } else {
            return Promise.resolve()
          }
        })
        .then(function() {
          if(ctrl.data.db){
            return $scope.cleanIndexes(ctrl.data.db.resolvedChapters[0])
          } else {
            return Promise.resolve()
          }
        })
        .then(function() {
          if(ctrl.data.db && ctrl.data.addToExam){
            var index = ctrl.data.db.resolvedChapters[0].resolvedTopics.findIndex(function(t){
              return t.topicId == ctrl.data.question.topicId;
            });
            var part = ctrl.data.db.resolvedChapters[0].resolvedTopics[index].resolvedSections[0].resolvedParts[0];
            part.items = null;

            lazyLoad.part(part);
            if(ctrl.data.question.parent.isPassage){
              ctrl.data.db.passages = null;
              lazyLoad.database(ctrl.data.db);
              return ctrl.data.db.passages;
            } else {
              return Promise.resolve();
            }
          } else if(ctrl.data.src.part && ctrl.data.addToExam){
            var part = ctrl.data.src.part
            part.items = null;
            lazyLoad.part(part);
            if(ctrl.data.question.parent.isPassage){
              ctrl.data.src.resolvedItemContent.resolvedPassage.database.passages = null;
              lazyLoad.database(ctrl.data.src.resolvedItemContent.resolvedPassage.database);
              return ctrl.data.src.resolvedItemContent.resolvedPassage.database.passages;
            } else {
              return Promise.resolve();
            }
          } else {
            Promise.resolve();
          }
        })
        .then(function() {
          if(ctrl.data.db && ctrl.data.addToExam){
            var index = ctrl.data.db.resolvedChapters[0].resolvedTopics.findIndex(function(t){
              return t.topicId == ctrl.data.question.topicId;
            });
            return ctrl.data.db.resolvedChapters[0].resolvedTopics[index].resolvedSections[0].resolvedParts[0].items;
          } else if(ctrl.data.src.part && ctrl.data.addToExam){
            return ctrl.data.src.part.items;
          } else {
            Promise.resolve();
          }
        })
        .then(function(items) {
          if(items){
            var item = items.filter(function(i){
              return i.refId == ctrl.data.question.idSubPartQuestions;
            })[0];

            if(item){
              lazyLoad.item(item);
              return item.loaded;
            } else {
              throw new Error('question not found');
            }
          } else {
            Promise.resolve();
          }
        })
        .then(function(item) {
          if(ctrl.data.addToExam && item){
            $rootScope.$broadcast("editorModalAddQuestion", item);
          }
          if(refreshDB && ctrl.data.db){
            $rootScope.$broadcast("updateDatabaseList",ctrl.data.db);
          }
          $rootScope.$broadcast("editorClean");
          $scope.refresh(function(){
            ctrl.showModal = false;
            $rootScope.$broadcast("questionEditorStatus", false);

            var str = 'Your new question has been added to the “My Questions” bank located in the question bank drop down.';
            if(ctrl.data.firstTime && !ctrl.data.src.isUserDB){
              bootbox.alert(str);
              ctrl.data.firstTime = false;
            } else if(!ctrl.data.src.isUserDB){
              alertify.success(str);
            }
          });

        })
        .catch(function(error){
          $rootScope.$broadcast("questionEditorStatus", false);

          console.error(error);
        });
      });

      $scope.deleteTopic = function(topic){
        var topics = ctrl.data.db.resolvedChapters[0].resolvedTopics;
        if(topics.length > 1){
          var topicIndex = topics.indexOf(function(t){ return t.name==topic});
          if(topicIndex > 0){
            if(topics[i].resolvedSections[0].resolvedParts[0].resolvedItems.length > 0){

            }
          }
        }
      }

      ctrl.cancel = function() {
        if(ctrl.data.question.data.shortAnswer && ctrl.data.question.data.responses){
          ctrl.data.question.data.shortAnswer = ctrl.data.question.data.responses[0].answer;
          ctrl.data.question.data.responses = undefined;
        }

        // $rootScope.$broadcast("editorQuestionSelected", undefined);
        $rootScope.$broadcast("editorClean");
        ctrl.showModal = false;
        $rootScope.$broadcast("questionEditorStatus", false);

      };

      ctrl.delete = function() {
        if(ctrl.data.question.data.shortAnswer && ctrl.data.question.data.responses){
          ctrl.data.question.data.shortAnswer = ctrl.data.question.data.responses[0].answer;
          ctrl.data.question.data.responses = undefined;
        }

        bootbox.confirm("Are You sure you want to delete "+ctrl.data.question.origin+'?',function(result) {
          if(result){
            $http.delete('/api/items/'+ctrl.data.src.refId+'?userDB=true')
            .then(function(){
              // return Promise.resolve(ctrl.data.src.part.resolveditems);
              return $scope.cleanIndexes(ctrl.data.src.part.section.topic.chapter);
            })
            .then(function(items){
              $rootScope.$broadcast("UpdateSelectedToc");
              alertify.success('Question Removed');
              $rootScope.$broadcast("editorClean");
              ctrl.showModal = false;
              $rootScope.$broadcast("questionEditorStatus", false);

            }).catch(function(error){
              console.error(error);
            });
          }
        })
      }

      ctrl.ok = function(addToExam) {
        ctrl.data.addToExam = addToExam;
        var p;
        if(ctrl.data.question.parent.isPassage && ctrl.data.question.parent.isNew){
          p = $http.put('/api/passage?userDB=true',ctrl.data.question.parent.data);
        } else {
          p = Promise.resolve();
        }
        p.then(function(){
          if(ctrl.data.question.parent.isPassage && ctrl.data.question.parent.isNew){
            return $http.put('/api/passagelist?userDB=true',ctrl.data.question.parent);
          } else {
            return Promise.resolve();
          }
        })
        .then(function(){
          $rootScope.$broadcast("editorModalSave",ctrl.data.question.wasUser);
        }).catch(function(error){
          console.error(error);
        });
      };
    }
  }
});
