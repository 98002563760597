angular.module('examgen.directive').
directive('createOrOpenExamModal', function CreateOrOpenExamModal(exams, $compile, $window, $state, couch, $timeout, $rootScope, $analytics) {
  return {
    restrict: 'E',
    template: '<div id="exam-switch-modal" ng-include="\'app/partials/modals/create-or-open-exam.html\'"></div>',
    scope: {
      show: '='
    },
    controllerAs: 'ctrl',
    bindToController: true,

    controller: function($scope, $element, $attrs) {
      var ctrl = this;

      ctrl.cancel = function() {
        ctrl.show = false;
      };

      ctrl.click = function(exam) {
        ctrl.selected = exam;
      };

      ctrl.openExam = function() {
        ctrl.show = false;
        $rootScope.$emit('openExamSwitcher');
      };

      ctrl.createExam = function() {
        ctrl.show = false;
        $rootScope.createExam();
      }

      $scope.$watch('ctrl.show', function(val) {
        if (val === true) {
          $analytics.eventTrack('modal open', {category: 'create or open exam'});
        } else if (val === false) {
          $analytics.eventTrack('modal close', {category: 'create or open exam'});
        }
      });
    }
  }
});