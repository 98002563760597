function ComposerController(
  $rootScope, $scope, $http, $q, $window, $timeout, $state, $stateParams, databases, flashMessages, lazyLoad, importExport, constructors, couch, tabIndex, exams, $route, examPrompts, $sce, $interval, $analytics, uuid) {
  $analytics.pageTrack('/composer');

  $rootScope.inComposer = true;
  $scope.$on('$destroy', function() {
    $rootScope.inComposer = false;
  });

  var storage = $window.localStorage;
  $rootScope.guidedTour = true;
  $scope.vm = $scope;
  $scope.fonts = {
    Georgia: 'Georgia, serif',
    Palatino: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
    "Times New Roman": '"Times New Roman", Times, serif',
    Arial: 'Arial, Helvetica, sans-serif',
    "Arial Black": "Gadget, sans-serif",
    "Comic Sans": '"Comic Sans MS", cursive, sans-serif',
    Impact: 'Impact, Charcoal, sans-serif',
    Lucida: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    Tahoma: 'Tahoma, Geneva, sans-serif',
    Trebuchet: '"Trebuchet MS", Helvetica, sans-serif',
    Verdana: 'Verdana, Geneva, sans-serif',
    "Courier New": '"Courier New", Courier, monospace',
    "Lucida Console": '"Lucida Console", Monaco, monospace'
  };

  $scope.pageSizes = [
    { label: 'Letter (8.5 x 11)', width:8.5, height:11, size:'letter portrait'},
    { label: 'Legal (8.5 x 14)', width:8.5, height:14, size:'legal portrait'},
    { label: 'Ledger (11 x 17)', width:11, height:17, size:'ledger portrait'},
    { label: 'A4 (8.27 × 11.69)', width:8.27, height:11.69, size:'A4 portrait'},
    { label: 'Letter (8.5 x 11) Landscape', width:11, height:8.5, size:'letter landscape'},
    { label: 'Legal (8.5 x 14) Landscape', width:14, height:8.5, size:'legal landscape'},
    { label: 'A4 (8.27 × 11.69) Landscape', width:11.69, height:8.27, size:'A4 landscape'},
  ];

  $scope.loadingChapters = true;
  $scope.loadingDatabases = true;
  $scope.loadingTopics = true;
  $scope.loadingStandards = true;
  $scope.loading = true;
  $scope.loadingTree = true;
  $scope.isPassageSmall = function(passageHTML) {
    return true;
    if (passageHTML) {
      var match = passageHTML.match(/\./g);

      if (!match) return true;

      if (match) {
        var numPeriods = match.length;
        return numPeriods < 2; //less than 2 periods means he's only on sentence long. show him
      }
    }
  };

  $scope.editQuestion = function(){
    if($scope.itemToDisplay){
      $rootScope.$broadcast("openEditQuestion", $scope.itemToDisplay);
    }
  }

  $scope.dropModalOptions = {
    open: false,
    numItemsToAddOnDrop: 0,
    shuffleItemsOnDrop: false
  };

  exams.ready.then(function() {
    onLoggedIn(couch.db, exams.list);
  });

  function loadExam(examList, id) {
    var loadedExamDefer = $q.defer();
    $scope.loadedExam = loadedExamDefer.promise;

    var promise;

    if (id.length < 1) {
      var exam = new constructors.Exam({
        name: 'New Exam',
      });
      exam.default = true;
      exam.json = {
        options: {},
        sections: [
          {name: 'New Exam Section', options: {}, items: []}
        ]
      };
      promise = exam.ready = $q.when(exam);
    } else {
      //load exam from the examList, which should already have him cached
      var idx = examList.map(function(examStub) {
        return examStub.name;
      }).indexOf(id);

      if (idx === -1) {
        alertify.error('Unable to load exam ' + id + '.');
        $state.go('home');
        return;
      }

      if(!examList[idx].ready){
        exams.load(examList[idx],couch);
      }
      promise = examList[idx].ready;
    }

    var onError = function(err) {
      alertify.error('Unable to load exam ' + id + '.');
      $state.go('exams');
    };

    if (!promise || !promise.then) {
      var kv = {};
      kv.idx = idx;
      kv.idOfExamToLoad = id;
      kv.names = examList.map(function(t) { return t.name; });
      kv.fallback = examList[idx];
      bugsnag.notify('examList[idx].ready is null!', "this shouldn't happen", {debug: kv}, "error");
      promise = $q.when(examList[idx]);
    } else {
      promise = $q.when(promise);
    }

    promise.then(function(exam) {
      // if the exam has not been processed, then do it here.
      if (exam.json) {
        return exam.processJSON(exam.json).then(function() {
          $scope.loadingTree = false;
          delete exam.json;

          loadedExamDefer.resolve(exam);
        }, function(e) {
          console.error('exam error', e);
          onError();
        });
      }
      $scope.loadingTree = false;
      loadedExamDefer.resolve(exam);
    }, onError);
  }

  var dropDialogDefer;
  function onLoggedIn(db, examList) {
    // @todo is decodeURIComponent required?
    loadExam(examList, $stateParams.id);

    $scope.openDropModalDialog = function() {
      return filterEnabledItems(itemsToInsert).
        then(function(enabledItems) {
          dropDialogDefer = $q.defer();
          //subtract from items in the exam
          //init this derived variable
          $scope.dropModalOptions.numItemsToAddOnDrop = enabledItems.length;
          $scope.dropModalOptions.totalItems = enabledItems.length;
          $scope.dropModalOptions.open = true;

          return dropDialogDefer.promise;
        });
    };

    $scope.loadedExam.then(function(exam) {
      $scope.exam = exam;
      $analytics.eventTrack('composer', {category: 'exam', 'label': exam.name});
      $rootScope.currentExam = exam;
    });

    $scope.$on('$destroy', function() {
      $rootScope.currentExam = false;
    });

    $scope.loadedExam.then(function(exam) {
      if ($rootScope.examCreated) {
        alertify.success('Exam successfully created');
        $rootScope.examCreated = false;
      }

      $scope.resolvedLoadedExam = exam; //this is necessary to integrating with some 3rd-party modules
    });

    /**
     * Add section to loaded exam
     */
    $scope.addSection = function() {
      this.loadedExam.then(function(exam) {
        exam.setInsertionPoint(exam.addSection(), true);
      });
    };

    $scope.editSection = function() {
      $scope.loadedExam.then(function(exam) {
        var ip = $scope.exam.insertionPoint;
        while (ip) {
          if (ip instanceof constructors.ComposerSection) {
            exam.setInsertionPoint(ip);
            $scope.handleExamComposerSectionDblClick();
            break;
          }

          ip = ip.composerParent;
        }
      });
    }

    databases.all.then(function(e) {
      $scope.databases = e;
    });

    $scope.$watch('exam.needsSave', function(val) {
      if (val) {
        modified();
      }
    });

    function modified(clear) {
      if (clear) {
        $rootScope.exitSavePrompt = false;
        return;
      }

      $rootScope.exitSavePrompt = 'The exam hasn\'t been saved, please save it before continuing\.';
    }

    var autoSaveInterval = $interval(function() {
      if ($scope.exam && $scope.exam.needsSave) {
        $scope.saveExam();
      }
    }, 15000);

    var haltedStateRedirect;
    $scope.$on('$destroy', $rootScope.$on('$stateChangeStart', function(event, state, params) {
      if (autoSaveInterval) {
        $interval.cancel(autoSaveInterval);
      }

      // pause redirect and prompt
      if ($rootScope.exitSavePrompt) {
        haltedStateRedirect = {state: state, params: params};
        event.preventDefault();
        $('#exitSavePrompt').modal('show');
      }
    }));

    function redirect() {
      console.log('redirecting to', haltedStateRedirect);
      if (haltedStateRedirect) {
        $state.go(haltedStateRedirect.state.name, haltedStateRedirect.params);
      }
    }

    $scope.disregardChanges = function(closeOnly) {
      $('#exitSavePrompt').modal('hide');
      if (!closeOnly) {
        alertify.success('Exam changes have been disregarded');
        $rootScope.exitSavePrompt = false;
        redirect();
      }
    };

    $scope.saveChanges = function() {
      var exitSavePrompt = $rootScope.exitSavePrompt;

      var onSave = function(a) {
        $scope.showSaveModal = false;
        $('#exitSavePrompt').modal('hide');
        if (exitSavePrompt) {
          redirect();
        }

        $rootScope.exitSavePrompt = false;
      };

      if ($scope.exam.default) {
        $scope.showSaveModal = true;
        $('#exitSavePrompt').modal('hide');
        return;
      }

      $scope.saveExam().then(onSave, function(e) {
        console.error('exam save fail', e);
        $('#exitSavePrompt').modal('hide');
      });
    };

    $scope.itemNameToItemBrowserId = function(itemName) {
      return 'browser_' + itemName;
    };

    /** Gets the item number from the item code */
    $scope.getItemNumberFromItemCode = function(item) {
      var code = (item.URL || '').match(/(\d+)\.json$/);
      return code ? parseInt(code[1], 10) : false;
    };

    /** loads the first database */
    function loadFirstDatabase(databases) {
      var last = storage.getItem('lastdb');
      var saved = databases.filter(function(e) {
        return e.databaseName === last;
      });

      $scope.database = saved.length > 0 ? saved[0] : databases[0];
    }

    /** Load all topics */
    function loadAllTopics(topics) {
      $scope.topics = topics;
      var wait = [];
      topics.forEach(function(topic) {
        var sections = lazyLoad.topic(topic);
        var promise = sections.then(function(resolvedSections) {
          topic.filteredSections = resolvedSections.filter(function(section) {
            return section.sectionName !== '(no section)';
          });
          return $q.all(resolvedSections.map(lazyLoad.section));
        }).then(function(resolvedParts){
          for(var i = 0; i < resolvedParts.length; i++){
            if(resolvedParts[i].length > 0){
              var section = resolvedParts[i][0].section;
              section.filteredParts = resolvedParts[i].filter(function(part) {
                return part.partName !== '(no subpart)';
              });
            } else {
              resolvedParts.splice(i,1); i--;
            }
          }
          return true;
        });

        wait.push(promise);
      });

      $q.all(wait).then(function() {
        $scope.loadedTopics = topics;
      });
      $scope.selectedTopicOrSectionOrStandard = topics[0];
    }

    /** Get all of the question from a given topic */
    function getAllItemsFromTopic(topic) {
      return (topic.sections||topic.topic.sections).then(function(sections) {
        //TODO: introduce a delay in here
        return $q.all(sections.map(lazyLoad.section));
      }).then(function(partsArr) {
        var allParts = [].concat.apply([], [].concat.apply([], partsArr));

        return $q.all(allParts.map(lazyLoad.part));
      }).then(function(itemsArr) {
        var allItems = [].concat.apply([], [].concat.apply([], itemsArr));
        //we use the itemcode when displaying the item, which means the item needs to be loaded.
        //So fuck it, let's load it all up.
        //allItems.forEach(lazyLoad.item);        //load up all items
        lazyLoad.itemList(allItems);
        return allItems;
      });
    }

    /** Get all the items from a given question bank section */
    function getAllItemsFromSection(section) {
      return (section.parts||section.section.parts).then(function(parts) {
        return $q.all(parts.map(lazyLoad.part));
      }).then(function(itemsArr) {
        var allItems = [].concat.apply([], [].concat.apply([], itemsArr));
        lazyLoad.itemList(allItems);
        return allItems;
      });
    }

    function getAllItemsFromPart(part) {
      return lazyLoad.part(part).then(function(items){
        lazyLoad.itemList(items);
        return items;
      });
    }

    //TODO: we may need to deep watch all items
    $scope.$watch('loadedItems', function(items) {

      var isCurrentItems = function(loadingItems){
        var flag = false;

        if($scope.loadedItems.length === loadingItems.length){
          flag = true;

          for(var i = 0; i < loadingItems.length; i++){
            if(loadingItems[i].itemId != $scope.loadedItems[i].itemId){
              flag = false;
            }
          }
        }

        return flag;
      }

      if (!(items && items.length)) {
        return;
      }

      //if there's ever a case where there are no selected items, select the first item
      if (!items.some(function(item) {
          return item.selected;
        })) {
        $scope.selectFirstEnabledItem(items);
      }

      var loading = [];
      $scope.loadingCountTotal = items.length;
      $scope.loadingCount = 0;
      var allItemContents = items.map(function(t) {
        t.loaded.then(function() {
          if(isCurrentItems(items)){
            if (loading.indexOf(t) === -1) {
              loading.push(t);
            }

            $scope.loadingCount = loading.length;
            $scope.loadingCountTotal = items.length;
          }
        });
        return t.loaded;
      });

      $q.all(allItemContents).then(function(aic) {
        if(isCurrentItems(aic)){
          $timeout(function() {
            $scope.loading = false;
          }, 100);
        }
      });
    });

    function filterEnabledItems(items) {
      return $scope.loadedExam.then(function(loadedExam) {
        return items.filter(function(item) {
          return !loadedExam.containsExamItem(item);
        });
      });
    }

    $scope.selectFirstEnabledItem = function(items) {

      if ($scope.displayStandard) {
        return false;
      }

      filterEnabledItems(items).then(function(enabledItems) {
        var firstEnabledItem = enabledItems[0];
        if (firstEnabledItem) {
          firstEnabledItem.selected = true;
          prevFocusedItem = firstEnabledItem;
        }
        updateFirstSelectedItem();
        $scope.item = firstEnabledItem;
      });
    }

    $scope.$watch('loadedExam.insertionPoint', function(insertionPoint) {
      if (!insertionPoint) {
        $scope.loadedExam.then(function(loadedExam) {
          loadedExam.setDefaultInsertionPoint();
        });
      }
    });

    $scope.$watch('selectedTopicOrSection', function(selected) {
      if (!selected) {
        return;
      }

      $scope.selectedTopicOrSectionOrStandard = selected.topic || selected.section || selected.part;
    });

    /** This watch handles the logic surrounding selection */
    $scope.$watch('selectedTopicOrSectionOrStandard', function(selected) {
      if (!selected) {
        return;
      }

      $scope.loading = true;
      $scope.loadedItems = [];
      $scope.selectedTopicOrStandard = false;
      $scope.selectedStandard = false;
      $scope.selectedTopic = false;
      $scope.selectedSection = false;
      $scope.displayStandard = false;


      ($scope.loadedTopicOptions||[]).forEach(function(e) {
        if (selected && (e.topic === selected || e.section === selected || e.part === selected)) {
          $scope.selectedTopicOrStandard = e;
        }
      });

      // section?
      if (selected.topic) {
        $scope.selectedSection = selected;
        $scope.selectedStandard = false;

        lazyLoad.section($scope.selectedSection);
        //load items for section
        $scope.items = getAllItemsFromSection(selected);
        $scope.items.then(function(items) {
          //items.forEach(lazyLoad.item);
          return lazyLoad.itemList(items);
        });
      // topic?
    } else if (selected.chapter) {
        $scope.selectedTopic = selected;
        $scope.selectedStandard = false;

        //he's a topic
        var selectedTopic = selected;
        lazyLoad.topic(selectedTopic);

        //load items for all sections
        $scope.items = getAllItemsFromTopic(selectedTopic);

      } else if (selected.section) {
        $scope.selectedPart = selected;
        $scope.selectedStandard = false;

        $scope.items = getAllItemsFromPart($scope.selectedPart);

      // standard?
      } else if (selected) {
        $scope.selectedTopicOrSection = false;
        $scope.selectedStandard = selected;
        $scope.displayStandard = true;

        $scope.loadingSelectedStandard = true;
        $scope.items = lazyLoad.standard($scope.selectedStandard).standardData.then(function(standardData) {
          $scope.loadingSelectedStandard = false;
          $scope.selectedStandard.resolvedStandardData = standardData;
          // var all = $q.all(standardData.items.map(function(e) {
          //   return importExport.loadItemFromPath(e, true);
          // }));

          return importExport.loadItemListFromPath(standardData.items)
          //lazy-load all the items by their path
          .then(function(items) {
            //lazyLoad.itemList(items);
            items = items.map(function(item) {
              //lazyLoad.item(item);
              item.standardsPromise = $q.when(selected);
              item.resolvedStandards = selected;
              item.hasStandards = true;
              return item;
            });

            return items.sort(function sortbyOrder(a, b){
              return a.itemNumber-b.itemNumber;
            });
          });
        });
      } else {
        throw new Error('selectedTopicOrSectionOrStandard set to illegal value');
      }

      $scope.loadingCount = 0;
      $scope.loadingCountTotal = 0;
      $scope.items.then(function(items) {
        $scope.loadedItems = items;
        items.forEach(function(item) {
          item.loaded.then(function(item) {
            // item.itemContent.itemType? item.section.topic.chapter.chapterNumber+1:''}}</b>
            //-{{getItemNumberFromItemCode(  item.itemContent.itemCode)}}
            // <sup class="type">{{item.itemContent.itemType}}</sup></span>
            //console.log(item);
            item.displayName = {
              chapter: item.chapterNumber ? item.chapterNumber+1 : item.part.section.topic.chapter.chapterNumber + 1,
              code: item.itemName,
              type: item.resolvedItemContent.itemType
            };
          }, function (err) {
            console.error('loading item err', err);
          });
        });
      });
    });

    $scope.$watch('database', function(database, prev) {
      if (!database) {
        return;
      }

      if (database && prev && database !== prev) {
        $analytics.eventTrack('database changed', {database: database.databaseName});
      }

      storage.setItem('lastdb', database.databaseName);
      $scope.loadingDatabases = false;

      $scope.loading = true;
      $scope.loadingTopics = true;
      $scope.loadingStandards = true;
      $scope.loadingChapters = true;
      $scope.chapters = [];
      $scope.passages = [];
      $scope.standards = [];
      $scope.selectedStandard = false;
      $scope.selectedTopicOrStandard = false;
      $scope.loadedTopicOptions = [];

      //lazy-init him
      lazyLoad.database(database).then(function(db) {
        $scope.numberOfStandards = 0;
        db.standards.then(function(e) {
          $scope.numberOfStandards = e ? e.length : 0;
          db.resolvedStandards = e;
        });

        return db.chapters;
      }).then(function(chapters) {
        // load chapter from preference
        $scope.chapter = null;
        var cached = storage.getItem('chapter');
        if (cached) {
          $scope.chapter = chapters.filter(function(c) {
            return c.chapterName === cached;
          }).shift();
        }

        // select first chapter if not coming from preferences
        if (!$scope.chapter) {
          $scope.chapter = chapters[0];
        }

        database.chapters.then(function(e) {
          $scope.chapters = e;
        });

        database.standards.then(function(e) {
          $scope.standards = e;
          if (!$scope.chapter) {
            $scope.selectedStandard = $scope.standards[0];
          }
          $scope.loadingStandards = false;
          $scope.hasStandards = e.length > 0;
        });

        database.passages.then(function(e) {
          $scope.passages = e;
        });
      });
    });


    $scope.$watch('chapter', function(chapter, prev) {
      $scope.loadingChapters = !chapter;
      $scope.loadingTopics = true;

      if (!chapter) {
        return;
      }

      if (chapter && prev && chapter !== prev && !$scope.loadingDatabases) {
        $analytics.eventTrack('chapter changed', {database: $scope.database.databaseName, chapter: chapter.chapterName});
      }

      storage.setItem('chapter', chapter.chapterName);
      $scope.topics = [];
      $scope.loadedTopics = [];
      lazyLoad.chapter(chapter).then(loadAllTopics);
    });

    $scope.$watch('loadedTopics', function(topics) {
      if (!topics || !topics.length) {
        $scope.loadedtopicOptions = [];
        $scope.loadingTopics = false;
        return;
      }

      $scope.loadingTopics = false;
      var options = [];
      topics.forEach(function(topic) {
        options.push({name: topic.topicName, isTopic: true, topic: topic});
        if(topic.filteredSections){
          topic.filteredSections.forEach(function(section) {
            options.push({name: section.sectionName, isSection: true, section: section});
            if(section.filteredParts){
              section.filteredParts.forEach(function(part){
                options.push({name: part.partName, isPart: true, part: part});
              });
            }
          });
        }
      });

      $scope.loadedTopicOptions = options;
      $scope.selectedTopicOrStandard = null;
      var topic = storage.getItem('topic'),
          section = storage.getItem('section');
          part = storage.getItem('part');
      if (topic || section) {
        var selected = options.filter(function(p) {
          return (topic && p.name === topic) || (section && p.name === section) || (part && p.name === part);
        }).shift();

        $scope.selectedTopicOrStandard = selected;
      }

      if (!$scope.selectedTopicOrStandard) {
        $scope.selectedTopicOrStandard = options[0];
      }

      if ($scope.selectedTopicOrStandard.isTopic || $scope.selectedTopicOrStandard.isSection || $scope.selectedTopicOrStandard.isPart) {
        $scope.selectedTopicOrSection = $scope.selectedTopicOrStandard;
      }
    });

    $scope.$watch('selectedTopicOrStandard', function(v, prev) {
      if (v) {
        ['topic', 'section', 'part'].map(storage.removeItem.bind(storage));

        var key = '';
        if(v.isTopic){
          key = 'topic';
        } else if(v.isSection){
          key = 'section';
        } else if(v.isPart){
          key = 'part';
        }

        if (prev && v !== prev && !$scope.loadingDatabases) {
          var kv = {database: $scope.database.databaseName, chapter: $scope.chapter.chapterName};
          kv[key] = v.name;
          $analytics.eventTrack(key + ' changed', kv);
        }

        storage.setItem(key, v.name);
        $scope.selectedTopicOrSectionOrStandard = v[key];
      }
    });

    $scope.$watch('selectedStandard', function(v, prev) {
      if (v) {
        $scope.selectedTopicOrSectionOrStandard = v;
        if (!$scope.loadingDatabases) {
          $analytics.eventTrack('standard changed', {database: $scope.database.databaseName, standard: v.standardName});
        }
      }
    });

    $scope.fixLoadedTopicName = function(o) {
      var space = String.fromCharCode(160);
      return o.isSection ? space + space + o.name : o.name;
    };

    //this performs the initial load to populate the UI
    databases.all.then(loadFirstDatabase);

    $scope.lastClickedGridOrComposerItem = null;

    //stuff for UI behaviour
    $scope.handleItemFocusEvent = function($event, item) {
      //console.log(item);
      $scope.lastSelectedGridOrComposerItem = item;

      if ($event) {
        $event.preventDefault(); //TODO:prevent focus on text on shift key
        $event.stopPropagation();
      } else {
        return regularSelectItem(item);
      }

      if ($event.ctrlKey) {
        ctrlSelectItem(item);
      } else if ($event.shiftKey) {
        shiftSelectItem(item);
      } else {
        regularSelectItem(item);
      }
    };

    //TODO: maybe refactor this into a watch
    function updateFirstSelectedItem() {
      $scope.firstSelectedItem = $scope.items.then(function(items) {
        var r = items.filter(function(item) {
          return item.selected;
        })[0];
        if (r) {
          var item = lazyLoad.item(r);
          return item;
        }
      });
    }

    /** Select an item **/
    function regularSelectItem(clickedItem) {
      // console.log(clickedItem);
      $scope.loadedExam.then(function(loadedExam) {

        //if (loadedExam.containsExamItem(clickedItem)) return; //don't allow item through if he's already in the exam

        //select the clickedItem
        //and deselect all the other items
        $scope.items.then(function(items) {
          items.forEach(function(item) {
            if (item === clickedItem) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
        });
        updateFirstSelectedItem();
      });

    }

    /** Logic to shift-click an item */
    function shiftSelectItem(clickedItem) {
      $scope.loadedExam.then(function(loadedExam) {

        if (loadedExam.containsExamItem(clickedItem)) return; //don't allow item through if he's already in the exam

        //debugger;
        //if there is a focused item, then
        //select all the items between the focused item
        //this clickedItem, and deselect everything else
        //otherwise, toggle selection of the clickedItem

        //we need to use the prevFocusedItem because the browser sends the focus event before the click event
        //TODO: make sure this is true for all browsers... that focus precedes click
        $scope.items.then(function(items) {
          if (prevFocusedItem) {
            var clickedIdx = items.indexOf(clickedItem),
              selected = items.filter(function(t) { return t.selected; }),
              startIdx, endIdx;

            var firstFocusedIdx =  items.indexOf(selected[0]),
                lastFocusedIdx = items.indexOf(selected[selected.length - 1]),
                prevFocusedIdx = items.indexOf(prevFocusedItem);

            if (clickedIdx === -1) {
              console.error('clickedIdx === -1', clickedItem, items);
              throw new Error('Unable to find clicked item in current list of items.');
            }

            items.forEach(function(item, idx) {
              if (clickedIdx < prevFocusedIdx) {
                item.selected = idx >= clickedIdx && idx <= lastFocusedIdx;
              } else {
                item.selected = idx >= firstFocusedIdx && idx <= clickedIdx;
              }
            });
          } else {
            //focus and select the clicked item and deselect everything else
            items.forEach(function(item) {
              if (item === clickedItem) {
                item.selected = true;
              } else {
                item.selected = false;
              }
            });
          }
        });

        updateFirstSelectedItem();
      });
    }

    /** Logic to ctrl-click an item */
    function ctrlSelectItem(clickedItem) {
      $scope.loadedExam.then(function(loadedExam) {

        if (loadedExam.containsExamItem(clickedItem)) return; //don't allow item through if he's already in the exam

        //toggle selection of the clicked item

        //if there is a focused item, then keep it
        //otherwise, the clicked item becomes the focused item


        clickedItem.selected = !clickedItem.selected;

        updateFirstSelectedItem();
      });
    }

    /** Logic to handle dragging an item */
    $scope.handleItemDragstart = function($event, item) {

      //drag all the items if he's selected
      //otherwise, do a regular select action on him
      if (!item.selected) {
        regularSelectItem(item);
      }

      $rootScope.getItemsBeingDragged = function() {
        return $scope.getSelectedItems().then(filterEnabledItems);
      };

      makeFirefoxDragHappy($event, this.innerHTML);
    };

    /** Fix for drag behaviour in Firefox */
    function makeFirefoxDragHappy($event, html) {
      if (!$event.originalEvent || $event.originalEvent.dataTransfer) {
        return;
      }

      if (!$event.originalEvent.dataTransfer.setData) {
        return;
      }

      //this is for Firefox to apply the "move" affect
      $event.originalEvent.dataTransfer.effectAllowed = 'move';
      $event.originalEvent.dataTransfer.setData('text/html', html); //TODO: make this *just* the RTML contents
    }

    /** Logic to handle the start of drag */
    $scope.handleComposerDropTargetDragstart = function($event, composerDropTarget) {
      $rootScope.getItemsBeingDragged = function() {
        //return $q.when(composerDropTarget);
        return $q.when($scope.multiSelectItems);
      };

      makeFirefoxDragHappy($event, '');
    };

    /** Sets the 'focus' style on the ancestors of a the target of an event */
    $scope.focusAncestors = function($event) {
      $($event.target).parents().addClass('focus');
    };

    /** Removes the 'focus' style on the ancestor of a the target of an event */
    $scope.blurAncestors = function($event) {
      $($event.target).parents().removeClass('focus');
    };

    $scope.selectTopicOrSectionOrStandard = function(topicOrSection, $event) {
      $event.stopPropagation();

      $scope.selectedTopicOrSectionOrStandard = topicOrSection;
    };

    var prevFocusedItem;

    function handleBlur($event, item) {
      item.focused = false;

      prevFocusedItem = item;
    }

    function handleFocus($event, item) {
      item.focused = true;
    }

    //TODO: right now these are not used
    $scope.itemBrowserFocus = handleFocus;
    $scope.itemBrowserBlur = handleBlur;
    $scope.itemOverviewFocus = handleFocus;
    $scope.itemOverviewBlur = handleBlur;

    var keypressBuffer = [],
      charMap = {
        "48": 0,
        "49": 1,
        "50": 2,
        "51": 3,
        "52": 4,
        "53": 5,
        "54": 6,
        "55": 7,
        "56": 8,
        "57": 9
      },
      keypressTimeout;


    var idRoot = 'overview_'; //we have to pass this around a lot, so we're making it global
    //FIXME: might be better to make this global

    /** Logic to handle keydown events */
    //$event.preventDefault();      //this would cause firefox to swallow all events.
    //only do it selectively, on a case-by-case basis
    var handleItemSelect = function($event, nextItem) {
      if (nextItem) {
        focusItem(nextItem); //focus him
        $scope.handleItemFocusEvent($event, nextItem); //then, same select logic as a click (modifier keys are respected)
      }
    };

    $scope.itemOverviewKeydown = function($event, item) {

      var doArrowCb = function(nextItem) {
        return handleItemSelect($event, nextItem);
      };

      var i = charMap[$event.which];

      if (i !== undefined) {
        handleNumericKeydown(idRoot, i);
      } else {
        switch ($event.which) {
        case 32: //spacebar
          //do regular select
          regularSelectItem(item);
          $event.preventDefault();
          break;
        case 13: //enter
          //add
          $scope.insertSelectedItems();
          $event.preventDefault();
          break;

          //these are arrow keys, clockwise, starting from the left
        case 37:
          //left arrow
          getNextItemLeft(item).then(doArrowCb);
          $event.preventDefault();
          break;
        case 38:
          //up arrow
          getNextItemUp(item).then(doArrowCb);
          $event.preventDefault();
          break;
        case 39:
          //right arrow
          getNextItemRight(item).then(doArrowCb);
          $event.preventDefault();
          break;
        case 40:
          //down arrow
          getNextItemDown(item).then(doArrowCb);
          $event.preventDefault();
          break;

        case 65: // ctrl + a
          if ($event.ctrlKey) {
            $scope.selectItem('all');
            $event.preventDefault();
          }
        break;

        default:
          break;
        }
      }
    };

    $scope.selectItem = function(which) {
      if (which === 'all') {
        $scope.loadedExam
          .then(function() {
            return $scope.items;
          })
          .then(function(items) {
            items.forEach(function(item) {
              item.selected = true;
            });
          });

        return;
      }

      if (which === 'first' || which === 'last') {
        return $scope.items.then(function(items) {
          var idx = which === 'first' ? 0 : items.length - 1;
          handleItemSelect(null, items[idx]);
        });
      }

      var func;
      if (which === 'previous') {
        func = getNextItemLeft;
      } else if (which === 'next') {
        func = getNextItemRight;
      } else {
        console.error('no idea what to select', which);
        return;
      }

      // find focused
      $scope.items.then(function(items) {
        // don't run if we didn't select one (like being at first and hitting left)
        if (items.length === 0) {
          return;
        }
        var focused = items.filter(function(e) { return e.selected; }).shift();
        if (focused) {
          func(focused).then(function(item) {
            handleItemSelect(null, item);
          });
        }
      });
    };

    /** Handle left keypress */
    function getNextItemLeft(pressedItem) {
      //focus
      return $scope.items.then(function(items) {
        var idxOfPressed = items.indexOf(pressedItem);
        if (idxOfPressed > 0) return items[idxOfPressed - 1]; //focus the preceeding item
      });
    }

    /** Handle up keypress */
    function getNextItemUp(pressedItem) {
      return $scope.items.then(function(items) {
        var numCols = getNumberOfGridColumns();
        var idxOfPressed = items.indexOf(pressedItem);
        var idxToFocus = idxOfPressed - numCols;

        if (idxToFocus >= 0) {
          return items[idxToFocus]; //focus the preceeding item
        }
      });
    }

    /** Handle right keypress */
    function getNextItemRight(pressedItem) {
      return $scope.items.then(function(items) {
        var idxOfPressed = items.indexOf(pressedItem);
        if (idxOfPressed < (items.length - 1)) return items[idxOfPressed + 1]; //focus the preceeding item
      });
    }

    /** Handle down keypress */
    function getNextItemDown(pressedItem) {
      return $scope.items.then(function(items) {
        var numCols = getNumberOfGridColumns();
        var idxOfPressed = items.indexOf(pressedItem);
        var idxToFocus = idxOfPressed + numCols;

        if (idxToFocus < items.length) {
          return items[idxToFocus]; //focus the preceeding item
        }
      });
    }

    /** Focus an item based on the itemName */
    function focusItem(itemToFocus) {
      if (!itemToFocus) {
        return;
      }
      //touch the DOM to focus the item
      //FIXME: this is dirty. Should be moved to a directive.
      var element = document.getElementById(idRoot + itemToFocus.itemName);
      if (element) {
        element.focus();
      }
    }

    /** Gets the number of columns that can fit in the grid */
    function getNumberOfGridColumns() {
      if (grids > 0) {
        return grids;
      }
      //FIXME: touching the DOM in a controller is evil. Better to move this to a service or a directive.
      var gridWidth = $('#grid').outerWidth(),
        cellWidth = $('#grid span[resizeable-item]').first().outerWidth();

      var r = Math.floor(gridWidth / cellWidth);

      //console.log('getNumberOfGridColumns', 'gridWidth', gridWidth, 'cellWidth', cellWidth, 'gridWidth/cellWidth', r);
      return r;
    }

    /** Handles number keypress to support fast navigation */
    function handleNumericKeydown(idRoot, i) {
      keypressBuffer.push(i);

      var x = parseInt(keypressBuffer.join(''), 10);

      //flush buffer after interval
      $window.clearTimeout(keypressTimeout);

      keypressTimeout = $window.setTimeout(function() {
        console.log('clearing keypress buffer after interval');
        keypressBuffer.length = 0;
      }, 1000);


      //focus x
      //pull out the item
      $scope.items.then(function(items) {
        var itemToFocus = items.filter(function(item) {
          return item.itemName === x;
        })[0];

        if (itemToFocus) {

          console.log('itemToFocus', itemToFocus);

          //touch the DOM to focus the item
          //FIXME: this is dirty. Should be moved to a directive.
          document.getElementById(idRoot + itemToFocus.itemName).focus();
          regularSelectItem(itemToFocus);
        } else {
          //clear buffer. we're done
          keypressBuffer.length = 0;
        }
      });

      //TODO: figure out how to flush the keypress buffer
    }

    /** Handle dragging a topic */
    $scope.handleTocTopicDragstart = function(topic, $event) {
      $event.stopPropagation();
      $rootScope.getItemsBeingDragged = function() {
        return getAllItemsFromTopic(topic).then(filterEnabledItems);
      };

      makeFirefoxDragHappy($event, this.innerHTML);
    };

    /** Handle dragging a section */
    $scope.handleTocSectionDragstart = function(section, $event) {
      $event.stopPropagation();
      $rootScope.getItemsBeingDragged = function() {
        return getAllItemsFromSection(section).then(filterEnabledItems);
      };
    };

    $scope.$on('$destroy', function() {
      $scope.loadedExam.then(function() {
        return $scope.items;
      }).then(function(items) {
        items.forEach(function(item) {
          item.selected = false;
        });
      });
    })

    /** Handle keydown on table of contents */
    $scope.handleTocKeyDown = function(tocTopicOrSection, $event) {

      switch ($event.which) {
        case 32: //spacebar
          //do regular select
          $event.preventDefault();
          $scope.selectTopicOrSectionOrStandard(tocTopicOrSection, $event);
          break;
          //these are arrow keys, clockwise, starting from the left
        case 38:
          //up arrow
          $scope.tabNext($event.target, -1);
          $event.preventDefault();
          break;
        case 40:
          //down arrow
          $scope.tabNext($event.target, 1);
          $event.preventDefault();
          break;
        default:
          break;
      }
    };

    /** Tab keydown */
    $scope.tabNext = function(target, step) {
      var tabindex = parseInt(target.getAttribute('tabindex'), 10);
      //FIXME: touching DOM. a bit evil
      var el = $('#exam *[tabindex=' + (tabindex + step) + ']');
      if (el.length) {
        el.focus();
        return true;
      }
    }

    $scope.numberOfTopicsAndSections = 0; //initialize

    $scope.$watch('chapter', function(chapter) {
      if (!chapter) return;

      var numberOfTopicsAndSectionsPromise = lazyLoad.chapter($scope.chapter).then(function(topics) {
        return $q.all(topics.map(lazyLoad.topic)).then(function(sectionsArr) {
          return sectionsArr.length +
            sectionsArr.reduce(function(a, b) {
              return a + b.length;
            }, 0);
        });
      });

      numberOfTopicsAndSectionsPromise.then(function(numberOfTopicsAndSections) {
        $scope.numberOfStandards = 0;
        if($scope.chapter.database.standards){
          $scope.chapter.database.standards.then(function(e) {
            $scope.numberOfStandards = e.length;
          });
        }
        $scope.numberOfTopicsAndSections = numberOfTopicsAndSections;
      });
    });

    /** Get an exam section from the tab index */
    $scope.getExamSectionTabindex = function(section, sections, $index) {
      var itemLength = $scope.$eval('items.length');

      if (itemLength === undefined) return;

      //debugger;

      return itemLength * 2 +
        $index +
        sections.slice(0, $index).reduce(function(sum, section) {
          return sum + section.items.length;
        }, 0);

    };


    $scope.getExamItemTabindex = function(section, sections, $index) {
      if (!$scope.items) return;

      return $scope.getExamSectionTabindex(section, sections, sections.indexOf(section)) + $index + 1;
    };

    // navigation actions
    $scope.$on('$destroy', $rootScope.$on('saveExam', function() {
      $scope.showSaveModal = true;
    }));

    $scope.$on('$destroy', $rootScope.$on('shareExam', function() {
      $scope.showShareModal = true;
    }));

    $scope.$on('$destroy', $rootScope.$on('printExam', function() {
      $scope.printing = true;
      $scope.showExamSettings();
    }));

    $scope.$on('$destroy', $rootScope.$on('editorModalAddQuestion', function(event, item) {
      $scope.loadedExam.then(function(loadedExam) {
        if(loadedExam.insertionPoint.examItem && $scope.itemToDisplay.itemId == loadedExam.insertionPoint.examItem.itemId && !$scope.itemToDisplay.isUserDB){
          var str = 'Do you want to replace this question in your exam?';

          bootbox.dialog(str, [
            {
             label : 'No',
             class : 'btn',
             callback: function() {
               $scope.insertItemsInExam([item]);
             }
           },{
            label : 'Yes',
            class : 'btn btn-primary',
            callback: function() {
              loadedExam.removeItem(loadedExam.insertionPoint).then(function(){
                $scope.insertItemsInExam([item]);
              })
            }
          },]);
          // bootbox.confirm(str,function(ok){
          //   var p;
          //   if(ok){
          //      p = loadedExam.removeItem(loadedExam.insertionPoint);
          //   } else {
          //     p = Promise.resolve();
          //   }
          //   p.then(function(){
          //     $scope.insertItemsInExam([item]);
          //   })
          // })
        } else {
          $scope.insertItemsInExam([item]);
        }
      })
    }));

    $scope.$on('$destroy', $rootScope.$on('updateDatabaseList', function(event, db) {
      var tmp = $scope.selectedTopicOrSectionOrStandard;
      databases.all.then(function(e) {
        $scope.databases = e;
      });
    }));

    $scope.$on('$destroy', $rootScope.$on('updateUserDB', function(event, db) {
      databases.all.then(function(e) {
        $scope.databases = e;

        var tmp = $scope.selectedTopicOrSectionOrStandard;
        var database = $scope.databases.find(function(d){return db.databaseId == d.databaseId});
        if(tmp && database){
          $scope.database = undefined;
          $scope.selectedTopicOrSectionOrStandard = undefined;
          $scope.chapter = undefined;


          $timeout(function () {
            loadFirstDatabase($scope.databases);

          //   $scope.database = database;
          //   // if (tmp.topicNumber && tmp.chapter.database.databaseId == database.databaseId) {
          //   //   lazyLoad.database(database).then(function(resolvedDB){
          //   //     return resolvedDB.chapters;
          //   //   }).then(function(resolvedChapters){
          //   //     $scope.chapter = resolvedChapters[tmp.chapter.chapterNumber];
          //   //     return lazyLoad.chapter($scope.chapter);
          //   //   }).then(function(resolvedTopics){
          //   //     $scope.selectedTopicOrSectionOrStandard = resolvedTopics[tmp.topicNumber];
          //   //   });
          //   // }
          }, 10);
        }
      });
    }));

    $scope.$on('$destroy', $rootScope.$on('UpdateSelectedToc', function() {
      var tmp = $scope.selectedTopicOrSectionOrStandard;
      $scope.selectedTopicOrSectionOrStandard = undefined;
      if (tmp.topic) {
        tmp.parts = null;
      } else if (tmp.chapter) {
        tmp.sections = null;
      } else if (tmp.section) {
        tmp.items = null;
      }

      $timeout(function() {
        $scope.selectedTopicOrSectionOrStandard = tmp;
      },2);
    }));
    $scope.$on('$destroy', $rootScope.$on('questionEditorStatus', function(event,status) {
      $scope.questionEditorStatus = status;
    }));

    $scope.$on('$destroy', $rootScope.$on('deleteTOC', function(event, item) {

      if(item.topicNumber > 0){
        var str = 'Do you want to delete this topic? <br>(any questions will be moved to "My Questions")';
        bootbox.dialog(str, [
          {
           label : 'No',
           class : 'btn',
           callback: function() {

           }
         },{
          label : 'Yes',
          class : 'btn btn-primary',
          callback: function() {
            var p;
            var firstPart;

            lazyLoad.topic(item.chapter.resolvedTopics[0])
            .then(function(sections){
              return lazyLoad.section(sections[0]);
            })
            .then(function(parts){
              firstPart = parts[0];
              return lazyLoad.part(parts[0]);
            })
            .then(function(items){
              return lazyLoad.topic(item)
            })
            .then(function(sections){
              return lazyLoad.section(sections[0]);
            })
            .then(function(parts){
              return lazyLoad.part(parts[0]);
            })
            .then(function(items){
              if(items.length > 0){
                var questions = item.resolvedSections[0].resolvedParts[0].resolvedItems.map(function(q){
                  return {
                    refId:uuid.v4(),
                    partId: firstPart.partId,
                    itemId: q.itemId,
                    itemName: q.itemName,
                    itemNumber: q.itemNumber,
                    productPassageId: q.productPassageId,
                    userNote: q.userNote,
                  }
                });

                return $http.put('/api/items?userDB=true',questions);
              } else {
                return Promise.resolve(true);
              }
            }).then(function(res){
              return $http['delete']('/api/topics/'+item.topicId+'?userDB=true');
            })
            .then(function(res){
              var database = $scope.database;
              database.refresh = true;
              $scope.database = null;
              return lazyLoad.database(database);
            })
            .then(function(db){
              $timeout(function() {
                $scope.$apply();
                $scope.database = db;
              });
            }).catch(function(err){
              console.log(err);
            });
          }
        },]);
      }
    }));

    $scope.shareExam = function(email) {
      var resolvedExam;
      return $http.get('/api/user/validate/'+email)
      .then(function(res){
        if(res.data){
          return $scope.loadedExam
          .then(function(exam) {
            return exam.createCopies(2, false, false)
          })
          .then(function(examCopies) {
            var exam = examCopies[0];
            exam.name = exam.name+' ('+$rootScope.profile.Email+')';
            var items = exam._getAllItems();
            items.forEach(function(item){
              if(item.examItem.origin && item.examItem.isUserDB){
                var bits = item.examItem.origin.split('/');
                if(bits[0]=='My Questions'){
                  bits[0] = $rootScope.profile.FirstName+"'s Questions"
                }
                item.examItem.origin = bits.join('/');
              }
            })
            return exam.save(email)
          })
          .then(function(res) {
            alertify.success('Exam Shared');
            $scope.showShareModal = false;
          }).catch(function(error){
            console.error(error);
            alertify.error('Error sharing exam');
          })
        } else {
          return $http.get('/api/exams/invite/'+email)
          .then(function(res) {
            alertify.success('Exam Shared');
            $scope.showShareModal = false;
          }).catch(function(error){
            console.error(error);
            alertify.error('Error sharing exam');
          })
        }
      })
    };

    /** Save the exam */
    $scope.saveExam = function() {
      $scope.savingChanges = true;
      var resolvedExam;
      return $scope.loadedExam.then(function(exam) {
        resolvedExam = exam;
        return exam.save().then(function(res) {
          if (exam.hasItemsChange()) {
            exam.clearPdfAttachments();
          }
          exam.code = null

          modified(true);
          $scope.showSaveModal = false;
          $scope.savingChanges = false;
          exam.needsSave = false;
          return res;
        });
      }).then(function(couchResponse) {
        if (couchResponse.status === 200) {
          alertify.success('Exam successfully saved');
          // $http.put('/tools/sessions/action',{action:{name:'saved exam',metadata:JSON.stringify({
          //   examName:resolvedExam.name,
          // })}});
          return $q.when(true);
        } else {
          $scope.savingChanges = false;
          alertify.error('Error saving exam');
          return $q.reject();
        }
      }).catch(function(err){
        console.log(err);
        $scope.savingChanges = false;
        alertify.error('Error saving exam');
        return $q.reject();
      });
    };

    $scope.testExamName = function(name,next){
      if('\\:<>/%'.split('').some(function(x){return name.includes(x);})){
        alertify.error('Please choose a different exam name<br><h4 class="alert alert-block">The following symbols are not allowed:<br> "\\ : < > / %".</h4>');
      } else {
        return couch.db.hasExam(name).then(function(duplicate) {
          if (duplicate && duplicate.id != $scope.resolvedLoadedExam.id) {
            $scope.savingChanges = false;
            alertify.error('An Exam already exists with this name');
          } else {
            return next(name);
          }
        });
      }
    }

    $scope.saveOrCopyExam = function(name) {
      $scope.testExamName(name,$scope.copyExam);
    };

    $scope.renameExam = function(newName){
      $scope.savingChanges = true;
      return $scope.testExamName(newName, function(){
        return $scope.loadedExam.then(function(exam) {
          oldName = exam.name;
          exam.name = newName;
          couch.db.putExam(exam.id, exam).then(function(response) {
            exam.ready = $q.when(exam);
            alertify.success('Saved exam as ' + newName);
            $scope.savingChanges = false;
            $scope.showRenameModal = false;
            $scope.$broadcast('examSaved');
            $timeout(function() {
              $state.go('exam', {id: newName});
            }, 150);
          });
        });
      });
    }

    /** Copy the loaded exam */
    $scope.copyExam = function(newName) {
      $scope.showSaveModal = true;
      return $scope.loadedExam.then(function(exam) {
        return exam.createCopies(2, false, false).then(function(examCopies) {
          var examCopy = examCopies[0];

          examCopy.name = newName;
          examCopy.date_created = new Date();
          examCopy.date_updated = new Date();
          examCopy.loadedExam = examCopy;

          couch.db.putExam(examCopy.id, examCopy).then(function(response) {
            examCopy.ready = $q.when(examCopy);
            examList.push(examCopy);
            alertify.success('Saved exam as ' + newName);
            $scope.savingChanges = false;
            $scope.showSaveModal = false;
            $scope.$broadcast('examSaved');


            $timeout(function() {
              if ($rootScope.exitSavePrompt) {
                $rootScope.exitSavePrompt = false;
                redirect();
              } else {
                $state.go('exam', {id: newName});
              }
            }, 150);
          }, function() {
            alertify.error('There was an error copying the exam.');
          });
        });
      });
    };

    // @todo is this needed?
    $scope.$on('$stateChangeSuccess', function(event) {
      if ($rootScope.persistRoute) {
        $route.current = $rootScope.persistRoute;
        $rootScope.persistRoute = null;
      }
    });

    /** Get a list of selected items */
    $scope.getSelectedItems = function() {
      if (!$scope.items) {
        return $q.when([]);
      }

      return $scope.items.then(function(items) {
        return items.filter(function(item) {
          return item.selected;
        });
      });
    };

    /** Inser selected items in the loaded exam */
    $scope.insertSelectedItems = function($event) {
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }

      return $scope.getSelectedItems().then(function(items) {
        // adding 0 items
        if (!items.length) {
          return $q.when([]);
        }

        return $scope
          .insertItemsInExam($scope.getSelectedItems())
          .then(function(inserted) {
            // set last item as the focused one
            var last = _.last(inserted);
            $scope.exam.setInsertionPoint(last, true);
            inserted.forEach(function(item) {
              item.examItem.selected = true;
            });

            return inserted;
          });
        });
    };

    $scope.handleInsertButton = function($event) {
      $event.stopPropagation();
      $event.preventDefault();

      var last;
      $scope.getSelectedItems()
        .then(function(selectedItems) {
          last = _.last(selectedItems);
          return $scope.insertSelectedItems();
        })
        .then(function() {
          if (last) {
            handleItemSelect(null, last);
          }
        });
    }

    var itemsToInsert;

    /** Insert given items in the exam */
    $scope.insertItemsInExam = function(items) {
      $scope.multiSelectItems = [];
      itemsToInsert = items; //save this for when the user clicks the completeDrop button

      modified();

      return $q.when(itemsToInsert).then(function(resolvedItemsToInsert) {
        itemsToInsert = resolvedItemsToInsert;

        if (resolvedItemsToInsert.length > 1) {
          //if we're dropping more then one item, and we haven't disabled the dialog, then show the dialog
          return $scope.openDropModalDialog(); //show the modal and await the results
        } else {
          //just call him directly, and assume no shuffle
          return $scope.completeDrop();
        }
      });
    };

    $scope.cancelCompleteDrop = function() {
      $scope.dropModalOptions.open = false
      if (dropDialogDefer) {
        dropDialogDefer.resolve(false);
      }
    };

    /** Handle dropping an item in the exam */
    $scope.completeDrop = function() {
      modified();

      var options = $scope.dropModalOptions;

      //this is bound and exposed on the outside. Connected to an $on register.
      //we bind to $on in directive scope so that it is only done once; not once per drop.

      var shuffled = _.shuffle(itemsToInsert);

      //these things should never be triggered if we're dropping a ComposerItem,
      //because numItemsToAddOnDrop should always be reset to 0.
      if (options && options.numItemsToAddOnDrop) {
        shuffled = shuffled.slice(0, options.numItemsToAddOnDrop);
      }

      // default behavior, so sort them by their order
      if (options && !options.shuffleItemsOnDrop) {
        shuffled.sort(function(a, b) {
          return itemsToInsert.indexOf(a) - itemsToInsert.indexOf(b);
        });
      }

      itemsToInsert = shuffled;

      return $scope.loadedExam.then(function(loadedExam) {
        var promise = loadedExam.receiveDroppedItems(itemsToInsert, true);

        //reset the modal
        options.open = false;
        options.numItemsToAddOnDrop = 0;
        options.shuffleItemsOnDrop = false;

        if (Array.isArray(itemsToInsert)) {
          itemsToInsert.forEach(function(item) {
            item.selected = false;
          });
        }

        return promise;

        return promise.then(function(inserted) {
          //var last = _.last(loadedExam._getAllItems());
          //loadedExam.setInsertionPoint(last, true);
          return inserted;
        });
      }).then(function(inserted) {
        if (dropDialogDefer) {
          dropDialogDefer.resolve(inserted);
        }

        return inserted;
      });
    };


    $scope.removeSection = function() {
      modified();
      $window.alert('TODO');
    };

    $scope.itemMouseover = function($event, item) {
      $scope.mouseoverItem = item;
    };

    $scope.itemMouseout = function($event, item) {
      if ($scope.mouseoverItem === item) $scope.mouseoverItem = null;
    };

    $scope.hidePrintModal = function() {
      $scope.printing = false;
    };

    /** Show the print modal dialog */
    $scope.openPrintModal = function(ignoreExamSettings) {
      if (!ignoreExamSettings) {
        $scope.examSettingsOnly = false;
      }
      $scope.loadedExam.then(function(loadedExam) {
        if (!loadedExam.needsSave && loadedExam.pdfs.length) {
          //show the download modal and hide the print modal
          $scope.showDownloadModal = true;
          $scope.numCopies = loadedExam.pdfs.length || 1;
        } else {
          //save the exam, then show the download modal and hide the print modal
          loadedExam.save();
          $scope.showDownloadModal = false;
          $scope.showExamSettings();
        }
      });
    };

    $scope.doneDownloadModal = function() {
      $scope.showDownloadModal = false;
      $scope.exam.error='';
    };

    $scope.backDownloadModal = function() {
      $scope.showDownloadModal = false;
      $scope.showExamSettings();
      $scope.exam.error='';
    };

    $scope.numCopies = 1; //init the number of copies to default

    var isAnswerKeysTurnToPrint = false;

    $scope.showPrintModal = false;
    $scope.shuffleAnswers = false;
    $scope.shuffleQuestions = false;

    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    function generateDocumentId() {
      return (s4() + s4()).toUpperCase();
    }

    /** Generate HTML attachments */
    $scope.generatePdfs = function(preview) {
      $scope.isPrintPreview = preview;
      $scope.showPrintModal = false;
      if(!$scope.isPrintPreview){
        $scope.showDownloadModal = true;
      } else {
        setTimeout(function () {
          $('#examPreviewLoading').modal('show');
        }, 10);
      }
      if($scope.oldExamCode && !$scope.isPrintPreview){
        couch.db.getOldExam($scope.oldExamCode).then(function(response){
          $scope.oldExam = constructors.Exam.fromJSON(response.data);
          $scope.oldExam.then(function(loadedExam){
            $rootScope.documentId = loadedExam.code;
            $scope.resolvedOldExam = loadedExam;
            loadedExam.recoverCopies()
            .then(function(copies) {

              loadedExam.initPdfAttachments(copies);
              console.log('inited loaded exam attachments', copies);

              isAnswerKeysTurnToPrint = false;
              $scope.examCopiesToPrint = [];
              $scope.examPreviewToPrint = [];
              $scope.answerKeyCopiesToPrint = [];
              $timeout(printNextExamOrAnswerKey, 100); //timeout here to give the ng-repeat time to detect the changes on the scope
            });
          });
        }).catch(function(err){
          alertify.error('Exam Not Found');
          $scope.showDownloadModal = false;
          $scope.showExamSettings();
        })
      } else {
        $scope.loadedExam.then(function(loadedExam) {
          $rootScope.documentId = generateDocumentId();
          loadedExam.code = $rootScope.documentId;
          loadedExam.chooseCorrectAnswers(true).then(function(){
            //populate exams to print
            return loadedExam.createCopies($scope.isPrintPreview?1:loadedExam.options.numCopies, loadedExam.options.shuffleAnswers, loadedExam.options.shuffleQuestions)
          })
          .then(function(copies) {

            if(!loadedExam.options.shuffleAnswers){
              for(var i = 0; i < copies.length; i++){
                copies[i].copyAnswerSort(loadedExam);
              }
            }

            loadedExam.recordSortOrders(copies);

            loadedExam.initPdfAttachments(copies);
            console.log('inited loaded exam attachments', copies);

            //init variable state
            isAnswerKeysTurnToPrint = false;
            $scope.examCopiesToPrint = [];
            $scope.examPreviewToPrint = [];
            $scope.answerKeyCopiesToPrint = [];
            $timeout(printNextExamOrAnswerKey, 100); //timeout here to give the ng-repeat time to detect the changes on the scope
          });
        });
      }
    };

    /** Print an exam or an anaswer key */
    function printNextExamOrAnswerKey() {
      ($scope.oldExam || $scope.loadedExam).then(function(loadedExam) {
        var printList;
        if(!$scope.isPrintPreview){
          printList = isAnswerKeysTurnToPrint ? $scope.answerKeyCopiesToPrint : $scope.examCopiesToPrint;
        } else {
          printList = $scope.examPreviewToPrint;
        }
        if (printList.length < loadedExam.pdfs.length) {
          console.log('Printing next exam', printList.length, isAnswerKeysTurnToPrint);
          printList.push(loadedExam.pdfs[printList.length].exam.examToGenerate);
          isAnswerKeysTurnToPrint = !isAnswerKeysTurnToPrint; //toggle
        }
      });
    }

    $scope.$on('layoutComplete', function(s, layoutArgs) {
      ($scope.oldExam || $scope.loadedExam).then(function(loadedExam) {
        if(!$scope.isPrintPreview){
          console.log('Creating next EXAM attachment');
          loadedExam.createPdfAttachment(layoutArgs.copyNumber, layoutArgs.isAnswerKey, layoutArgs.html);
          printNextExamOrAnswerKey();
        } else {
          $scope.hideExamPreview();

          var my_window = window.open('', 'print_'+Date.now(),'width=1060,height=730,modal=yes');
          my_window.document.write('<!doctype html>');
          my_window.document.write('<html><head>');
          my_window.document.write('<link href="https://netdna.bootstrapcdn.com/twitter-bootstrap/2.3.2/css/bootstrap.no-responsive.no-icons.min.css" rel="stylesheet">');
          my_window.document.write('</head>');
          my_window.document.write('<body class="examPreview" onafterprint="self.close()">');//onafterprint="self.close()"
          my_window.document.write('</body></html>');
          my_window.document.close();

          $(my_window.document.querySelectorAll('body')).append(layoutArgs.html);
        }
      });
    });

    $scope.setItemToDisplay = function(i) {
      $scope.itemToDisplay = i;
    };

    $scope.$watch('mouseoverComposerDropTarget || firstSelectedItem', function(val) {
      $q.when(val).then(function(itemToDisplay) {
        if (!itemToDisplay) {
          return;
        }

        $scope.itemToDisplayHasStandards = false;
        $q.when(itemToDisplay.loaded).then(function() {
          $q.when(itemToDisplay.standards).then(function(standards) {
            itemToDisplay.resolvedStandards = standards;
            $scope.itemToDisplayHasStandards = standards.length > 0;
          });
        });

        if (itemToDisplay && itemToDisplay.then) {
          itemToDisplay.then(function(item) {
            $scope.itemToDisplay = item;
          });
        } else {
          $scope.itemToDisplay = itemToDisplay;
        }
      });
    });

    $scope.$watch('itemToDisplay', function(item) {
      $scope.displayStandard = false;
      $scope.correctAnswer = [];
      $scope.passageHtml = null;
      if (!item) {
        return;
      }
      item.itemContent.then(function(itemContent) {
        // find the correct answer
        var type = itemContent.itemType;
        if (type === 'MC' || type === 'TF') {
          if (itemContent.responses) {
            var correct = (itemContent.responses || []).filter(function (r) { return r.isCorrect; });
            if (correct) {
              $scope.correctAnswer = correct.map(function(answer){return {
                answer: answer.answer,
                letter: answer.answerLetter
              }});
            }
          } else {
            console.error('MC or TF question have no responses', itemContent);
          }

        } else if (type === 'SR' || type === 'ER') {
          if (itemContent.shortAnswer || itemContent.extendedResponseAnswer) {
            $scope.correctAnswer = [{
              answer: itemContent.shortAnswer || itemContent.extendedResponseAnswer
            }];
          } else {
            console.error('SR question has no shortAnswer or extendedResponseAnswer', itemContent);
          }
        } else {
          console.error('unknown question type', itemContent.itemType, itemContent);
        }

        // if ($scope.correctAnswer) {
        //   $scope.correctAnswer.answer = $sce.trustAsHtml($scope.correctAnswer.answer);
        // }


        if (!itemContent.passage) {
          $timeout(function() {
            $scope.$apply();
          });

          return;
        }

        itemContent.passage.then(function(passage) {
          passage.passageHTML.then(function(html) {
            $scope.passageHtml = html;
            $timeout(function() {
              $scope.$apply();
            });
          });
        });


      });
    });

    $scope.setModalShouldBeOpen = function(modalShouldBeOpen) {
      $scope.modalShouldBeOpen = modalShouldBeOpen;
    };

    /** Handle double-click on an exam composer section */
    $scope.handleExamComposerSectionDblClick = function(ev) {
      if (ev) {
        ev.preventDefault();
        ev.stopPropagation();
      }

      $scope.loadedExam.then(function(exam) {
        $scope.tmpInsertionPoint = {
          name: exam.insertionPoint.name,
          options: $.extend({}, exam.insertionPoint.options)
        };
        $('#sectionSettings').modal('show');
      });
    };

    $scope.hideSectionSettings = function(save) {
      $('#sectionSettings').modal('hide');
      if (!save) {
        $scope.printing = false;
        $scope.tmpInsertionPoint = null;
        return;
      }
      $scope.loadedExam.then(function(exam) {
        exam.needsSave = true;
        modified();
        // $http.put('/tools/sessions/action',{action:{name:'saved section settings',metadata:JSON.stringify({
        //   sectionName:$scope.tmpInsertionPoint.name,
        //   oldSettings:exam.insertionPoint.options,
        //   newSettings:$scope.tmpInsertionPoint.options
        // })}});
        exam.insertionPoint.name = $scope.tmpInsertionPoint.name;
        exam.insertionPoint.options = $scope.tmpInsertionPoint.options;
        $scope.tmpInsertionPoint = null;
      });
    }

    $scope.$on('$destroy', $rootScope.$on('exportExam', function() {
      var exam;
      $scope.loadedExam.then(function(res) {
        exam = res;
        if(exam._getAllItems().length > 100){
          bootbox.alert("Your exam exceeds the 100 question limit for export, please reduce the length of your exam and try again.")
        } else {

          let str =
            `<div>Please select the service to export to:</div>
            <select name="service" id="exportSvcSelector">
              <option>-- Select Service --</option>
              <option value="qti_12">Canvas</option>
              <option value="qti_20_latex">Schoology</option>
              <option value="bb">Blackboard</option>
              <option value="gform">Google Form</option>
              <option value="gdoc">Google Doc</option>
              <option value="htmlForm">HTML Form</option>
              <option value="qti_12">QTI 1.2</option>
              <option value="qti_20">QTI 2.0</option>
              <option value="qti_20_latex">QTI 2.0 (LaTeX Math)</option>
              <option value="qti_21">QTI 2.1</option>
            </select>
            <i class="icon-info-sign" onclick="showExportDetail()"></i>`;

          bootbox.dialog(str, [
            {
              label: 'Cancel',
              class: 'btn pull-left',
            },
            {
              label: 'Export',
              class: 'btn btn-primary',
              callback: function () {
                const select = document.getElementById('exportSvcSelector');
                switch (select.value) {
                  case 'qti_12':
                    alertify.success(
                      'Packaging exam, your download will start shortly'
                    );
                    exam
                      .serverExport('qti_1_2')
                      .then(function (content) {
                        var hiddenElement = document.createElement('a');
                        var blob = new Blob([content.data], {
                          type: 'application/binary',
                        });
                        var url = URL.createObjectURL(blob);
                        hiddenElement.href = url;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = exam.name + '.zip';
                        hiddenElement.click();
                      })
                      .catch(function (err) {
                        console.log(err);
                        alertify.error(
                          `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                        );
                      });
                    break;
                  case 'qti_20':
                    alertify.success(
                      'Packaging exam, your download will start shortly'
                    );
                    console.log(exam);
                    exam
                      .serverExport('qti_2_0')
                      .then(function (content) {
                        var hiddenElement = document.createElement('a');
                        var blob = new Blob([content.data], {
                          type: 'application/binary',
                        });
                        var url = URL.createObjectURL(blob);
                        hiddenElement.href = url;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = exam.name + '.zip';
                        hiddenElement.click();
                      })
                      .catch(function (err) {
                        alertify.error(
                          `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                        );
                        console.log(err);
                      });
                    break;
                  case 'qti_20_latex':
                    alertify.success(
                      'Packaging exam, your download will start shortly'
                    );
                    console.log(exam);
                    exam
                      .serverExport('qti_2_0_latex')
                      .then(function (content) {
                        var hiddenElement = document.createElement('a');
                        var blob = new Blob([content.data], {
                          type: 'application/binary',
                        });
                        var url = URL.createObjectURL(blob);
                        hiddenElement.href = url;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = exam.name + '.zip';
                        hiddenElement.click();
                      })
                      .catch(function (err) {
                        console.log(err);
                        alertify.error(
                          `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                        );
                      });
                    break;
                  case 'qti_21':
                    alertify.success(
                      'Packaging exam, your download will start shortly'
                    );
                    console.log(exam);
                    exam
                      .serverExport('qti_2_1')
                      .then(function (content) {
                        var hiddenElement = document.createElement('a');
                        var blob = new Blob([content.data], {
                          type: 'application/binary',
                        });
                        var url = URL.createObjectURL(blob);
                        hiddenElement.href = url;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = exam.name + '.zip';
                        hiddenElement.click();
                      })
                      .catch(function (err) {
                        console.error(err);
                        alertify.error(
                          `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                        );
                      });
                    break;
                  case 'bb':
                    alertify.success(
                      'Formating exam, your download will start shortly'
                    );
                    exam.exportToBB().then(function (content) {
                      var hiddenElement = document.createElement('a');
                      var blob = new Blob([content], {
                        type: 'application/zip',
                      });
                      var url = URL.createObjectURL(blob);
                      hiddenElement.href = url;
                      hiddenElement.target = '_blank';
                      hiddenElement.download = exam.name + '.zip';
                      hiddenElement.click();
                    }).catch(function (err) {
                      console.error(err);
                      alertify.error(
                        `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                      );
                    });
                    break;
                  case 'gform':
                    $rootScope.googleSignin().then(function(res){
                      gtool = res;
                      exam.googleFormExport(gtool);
                    }).catch(function(err){
                      if(err != 'cancel'){
                      console.error(err);
                        alertify.error(
                          `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                        );
                      }
                    });
                    break;
                  case 'gdoc':
                    var gtool;
                    var docId;
                    var docs;
                    if($rootScope.profile.options.usedGoogle){
                      go()
                    } else {
                      var str = '<h4>We have added a Google Export tool. We are awaiting Google Verification. In the interim you can still use the tool and need to allow our app to save files to your Google account. To do this follow the instructions below.</h4>'+
                      '<ul><li><b>Select the Google account you wish to use:</b></li></ul><img src="/img/gdhelp4.png"></img><br>'+
                      '<li><b>Next click the Advance option:</b></li></ul><img src="/img/gdhelp2.png"></img><br>'+
                      '<li><b>Select Allow:</b></li></ul><img src="/img/gdhelp3.png" style="padding-left:10px;"></img><br>'+
                      '<li><b>Click the Allow button to save your exam to your Google Drive</b></li></ul><img src="/img/gdhelp1.jpg"></img><br>';

                      bootbox.dialog(str, [{
                        label : 'Don\'t show again',
                        class : 'btn',
                        callback: function() {
                          $rootScope.profile.options.usedGoogle = true;
                          $http.put('/api/user/info',$rootScope.profile);
                          go();
                        }
                      },{
                        label : 'Ok',
                        class : 'btn btn-primary',
                        callback: function() {
                          go();
                        }
                      }]);
                    }

                    function go(){
                      $rootScope.googleSignin().then(function(res) {
                        gtool = res;
                        alertify.success('Creating Document...');
                        return exam.exportToGDRIVE()
                      }).then(function(res) {
                        docs = res;
                        return gtool.client.docs.documents.create({
                          resource: {
                            title:exam.name,
                          }
                        });
                      }).then(function(res) {
                        alertify.success('Uploading Document...');
                        docId = res.result.documentId;
                        return new Promise(function(resolve, reject) {
                          var data = new Blob([docs[0]], {type: 'text/html'});
                          var metadata = {
                              'name': exam.name,
                          };

                          var accessToken = gapi.auth.getToken().access_token; // Here gapi is used for retrieving the access token.
                          var form = new FormData();
                          form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
                          form.append('file', data);

                          var xhr = new XMLHttpRequest();
                          xhr.open('PATCH', 'https://www.googleapis.com/upload/drive/v3/files/'+docId+'/?key=AIzaSyBPs_0t-tWnkIPqIAFYN9GjBMG3_cvs5xw&uploadType=multipart');
                          xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                          xhr.responseType = 'json';
                          xhr.onload = () => {
                            //alertify.success('Upload Complete');
                            resolve(xhr.response); // Retrieve uploaded file ID.
                          };
                          xhr.send(form);
                        });
                      }).then(function(res) {
                        return gtool.client.docs.documents.create({
                          resource: {
                            title:exam.name+' answer key',
                          }
                        });
                      }).then(function(res) {
                        alertify.success('Uploading Document...');
                        docId = res.result.documentId;
                        return new Promise(function(resolve, reject) {
                          var data = new Blob([docs[1]], {type: 'text/html'});
                          var metadata = {
                              'name': exam.name+' answer key',
                          };

                          var accessToken = gapi.auth.getToken().access_token; // Here gapi is used for retrieving the access token.
                          var form = new FormData();
                          form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
                          form.append('file', data);

                          var xhr = new XMLHttpRequest();
                          xhr.open('PATCH', 'https://www.googleapis.com/upload/drive/v3/files/'+docId+'/?key=AIzaSyBPs_0t-tWnkIPqIAFYN9GjBMG3_cvs5xw&uploadType=multipart');
                          xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                          xhr.responseType = 'json';
                          xhr.onload = () => {
                            alertify.success('Upload Complete');
                            resolve(xhr.response); // Retrieve uploaded file ID.
                          };
                          xhr.send(form);
                        });
                      })
                      .catch(function(err){
                        if(err != 'cancel'){
                          alertify.error(
                            `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                          );
                          console.log(err);
                        }
                      })
                    }

                    break;
                  case 'htmlForm':
                    alertify.success(
                      'Formating exam, your download will start shortly'
                    );
                    exam.exportToFORM().then(function (content) {
                      var hiddenElement = document.createElement('a');
                      var blob = new Blob([content[0]], { type: 'text/html' });
                      var url = URL.createObjectURL(blob);
                      hiddenElement.href = url;
                      hiddenElement.target = '_blank';
                      hiddenElement.download = exam.name + '.html';
                      hiddenElement.click();

                      var blob = new Blob([content[1]], { type: 'text/html' });
                      var url = URL.createObjectURL(blob);
                      hiddenElement.href = url;
                      hiddenElement.target = '_blank';
                      hiddenElement.download = exam.name + '_AnswerKey.html';
                      hiddenElement.click();
                    }).catch(function (err) {
                      console.error(err);
                      alertify.error(
                        `An error occurred processing your exam, please contact us with the exam name and export format and we will do our best to resolve this issue`
                      );
                    });
                    break;
                  default:
                    break;
                }
              },
            },
          ]);
        }
      });
    }));

    $scope.$watch('tmpExamOptions.numCopies', function(num) {
      if(num < 2){
        $scope.tmpExamOptions.shuffleQuestions = false;
        $scope.tmpExamOptions.shuffleAnswers = false;
      }
    });
    /** Show the exam settings dialog */
    $scope.showExamSettings = function() {
      $scope.showPrintModal = false;
      $scope.loadedExam.then(function(exam) {
        $scope.oldExamCode = '';
        $scope.oldExam = null;
        $scope.resolvedOldExam = null;
        $scope.tmpExamOptions = $.extend({}, exam.options);
        $scope.tmpExamOptions.numCopies = $scope.tmpExamOptions.numCopies || 1;
        $scope.tmpExamOptions.pageSize = $scope.tmpExamOptions.pageSize || $scope.pageSizes[0];
        $scope.tmpExamOptions.fontSize = $scope.tmpExamOptions.fontSize || 12;
        $scope.tmpExamOptions.margin = $scope.tmpExamOptions.margin || .5;
        $scope.tmpExamOptions.nameHeader = $scope.tmpExamOptions.nameHeader || 'first';
        $scope.tmpExamOptions.fontFamily = $scope.tmpExamOptions.fontFamily || $scope.fonts["Times New Roman"];
        $scope.tmpExamOptions.hasMC = exam.hasMC();
        if($scope.tmpExamOptions.numCopies > 1){
          $scope.tmpExamOptions.shuffleQuestions = $scope.tmpExamOptions.shuffleQuestions || false;
          if($scope.tmpExamOptions.hasMC){
            $scope.tmpExamOptions.shuffleAnswers = $scope.tmpExamOptions.shuffleAnswers || false;
          } else {
            $scope.tmpExamOptions.shuffleAnswers = false;
          }
        } else {
          $scope.tmpExamOptions.shuffleQuestions = false;
          $scope.tmpExamOptions.shuffleAnswers = false;
        }
        $('#examSettings').modal('show');
      });
    };

    $scope.showExamSettingsOnly = $scope.showExamSettings;

    /** Hide the exam settings dialog */
    $scope.hideExamSettings = function(save) {
      $scope.loadedExam.then(function(exam) {
        $('#examSettings').modal('hide');
        if (!save) {
          $scope.tmpExamOptions = null;
          return;
        }

        exam.needsSave = true;
        modified();
        exam.options = $scope.tmpExamOptions;
        exam.sections.forEach(function(section) {
          ['twoColumns'].forEach(function(key) {
            if (section.options[key + 'Custom'] === undefined) {
              section.options[key] = exam.options[key];
            }
          });
        });

        $scope.tmpExamOptions = null;
      });

    }

    $scope.hideExamPreview = function() {
      $('#examPreviewLoading').modal('hide');
      $scope.isPrintPreview = false;
    }

    $scope.multiSelectItems = [];
    $scope.lastEvent = null;
    $scope.isCtrl = false;
    $scope.isShift = false;
    $scope.isTab = false;
    $scope.lastFocusedElement;
    $scope.setComposerItemCtrl = function(e) {
      $scope.isCtrl = e;
    }

    $scope.setComposerItemShift = function(e) {
      $scope.isShift = e;
    }

    $scope.setComposerItemTab = function(e) {
      $scope.isTab = e;
    }

    /** Handle multi-select in the exam tree */
    $scope.handleMultiSelect = function(ev, item, el) {
      var lastEvent = $scope.lastEvent;
      $scope.lastEvent = ev;

      var fix = function() {
        if ((ev.type == 'click' || ev.type == 'focus')) {
          $scope.lastFocusedElement = el;
        }
      };

      var same = lastEvent && ev.currentTarget == lastEvent.currentTarget;
      // detect focus and click duplicate event conflict
      if ((same && (lastEvent.type == 'click' || lastEvent.type == 'focus'))) {
        fix();
        return;
      }

      if ($scope.isShift && !$scope.isTab) {
        var toIndex = parseInt($(ev.target).closest('li').attr('tabindex'));
        var fromIndex = $($scope.lastFocusedElement).attr('tabindex') || 0;
        $('#exam').find('li[tabindex]').map(function(el) {
          var index = parseInt(this.getAttribute('tabindex'));
          if (index >= Math.min(fromIndex, toIndex) && index <= Math.max(fromIndex, toIndex)) {
            $(this).trigger('forceselection');
          }
        });
        fix();
        return;
      }

      fix();
      //console.log('handleMultiSelect', item);

      // only 1 item then
      if (!$scope.isCtrl) {
        if (item instanceof constructors.ComposerSection) {
          $scope.multiSelectItems = [];
        } else {
          $scope.multiSelectItems = [item];
        }
        return;
      }

      var index = $scope.multiSelectItems.indexOf(item);
      // if not in stack, add it
      if (index === -1) {
        $scope.forceAddMultiSelectItem(item);
      } else {
        // delete it from
        delete $scope.multiSelectItems[index];
      }
    };

    $scope.hasMultiSelectItems = function() {
      return $scope.multiSelectItems.length > 0;
    };

    $scope.hasMultiSelectItem = function(item) {
      return $scope.multiSelectItems.indexOf(item) != -1;
    };

    $scope.forceAddMultiSelectItem = function(item) {
      if (item instanceof constructors.ComposerSection) {
        return;
      }
      var index = $scope.multiSelectItems.indexOf(item);
      if (index === -1) {
        $scope.multiSelectItems.push(item);
      }
    };

    $scope.setMultiSelectionItems = function(items) {
      // clear the multiselect list
      while ($scope.multiSelectItems.length) {
        $scope.multiSelectItems.pop();
      }

      items.forEach(function(t) {
        $scope.multiSelectItems.push(t);
      });
    };

    $scope.removeSelectedComposerItems = function() {
      modified();

      $scope.exam.removeItems($scope.multiSelectItems && $scope.multiSelectItems.length > 0 ? $scope.multiSelectItems : [$scope.exam.insertionPoint]);
    };

    $scope.$on("loadSearchResults", function(event, searchResults) {
      var product = {
       chapters:null,
       databaseId:"0000000000000000000",
       databaseName:"Search Results",
       itemRefs:null,
       ldatabaseName:"search results",
       passages:null,
       standards:Promise.resolve([]),
      }

      if(searchResults[0].standards){
        product.standards = $q.all(searchResults[0].standards.map(function(standard){
          return lazyLoad.standard({standardName : standard.name, standardNumber:standard.sortOrder, standardId : standard.idStandard, description:standard.description, isUserDB:false, database : product, standardData : null});
        })).then(function(ret){
          var allStandardData = {};

          var p = [];
          ret.map(function(standard) {
            standard.standardData = standard.standardData.then(function(e) {
              return e;
            }, function() {
              return $q.when([]);
            });

            p.push(standard.standardData);
            standard.standardData.then(function(data) {
              if (data.items) {
                allStandardData[standard.standardName] = angular.extend({standardName: standard.standardName, standardId: standard.idStandard}, data);
              }
            });
          });

          product.allStandardData = allStandardData;
          // wait until all the standard data have been loaded
          return $q.all(p).then(function(){
            for(var a = 0; a < ret.length; a++){
              for(var b = a+1; b < ret.length; b++){
                if(ret[a].standardName == ret[b].standardName){
                  var items = ret[a].resolvedStandardData.items.concat(ret[b].resolvedStandardData.items);
                  ret[a].resolvedStandardData.items = items;
                  ret[a].standardData = Promise.resolve(ret[a].resolvedStandardData);
                  ret.splice(b,1);
                  b--;
                }
              }
            }
            return ret;
          });
        });
      }

      for(var i = 0; i < searchResults.length && !product.passages; i++){
        if(searchResults[i].passages){
          product.passages = Promise.resolve(searchResults[i].passages.map(function(pass){
            var p = {passageName : pass.passageName, passageId : pass.idPassage, database : {} , passageHTML : null};
            databases.all.then(function(dbs){
              //first look up his database
              p.database = dbs.filter(function(db){
                return db.databaseName === pass.productName;
              })[0]
            })
            return p;
          }));
        }
      }

      var chapters = [];
      for(var p = 0; p < searchResults.length; p++){
        for(var c = 0; c < searchResults[p].chapters.length; c++){
          searchResults[p].chapters[c].database = searchResults[p];
          for(var t = 0; t < searchResults[p].chapters[c].topics.length; t++){
            searchResults[p].chapters[c].topics[t].chapter = searchResults[p].chapters[c];
            for(var s = 0; s < searchResults[p].chapters[c].topics[t].sections.length; s++){
              searchResults[p].chapters[c].topics[t].sections[s].topic = searchResults[p].chapters[c].topics[t];
              for(var sp = 0; sp < searchResults[p].chapters[c].topics[t].sections[s].parts.length; sp++){
                searchResults[p].chapters[c].topics[t].sections[s].parts[sp].section = searchResults[p].chapters[c].topics[t].sections[s];
                if(searchResults[p].chapters[c].topics[t].sections[s].parts[sp].items){
                  var items = searchResults[p].chapters[c].topics[t].sections[s].parts[sp].items.map(function(item){
                     var q = {
                      itemName : item.name || item.sortOrder+'',
                      itemNumber: item.sortOrder,
                      itemId: item.idQuestion,
                      itemContent : null,
                      isUserDB: item.isUserDB,
                      part : searchResults[p].chapters[c].topics[t].sections[s].parts[sp],
                      origin: item.origin || searchResults[p].name+'/'+searchResults[p].chapters[c].chapterName+'/'+searchResults[p].chapters[c].chapterNumber+'/'+item.name,
                    };

                    if(item.chapterNumber){
                      q.chapterNumber = item.chapterNumber;
                    }
                    return q;
                  });
                  searchResults[p].chapters[c].topics[t].sections[s].parts[sp].items = Promise.resolve(items);
                }
              }
              searchResults[p].chapters[c].topics[t].sections[s].parts=Promise.resolve(searchResults[p].chapters[c].topics[t].sections[s].parts);
            }
            searchResults[p].chapters[c].topics[t].sections=Promise.resolve(searchResults[p].chapters[c].topics[t].sections);
          }
          searchResults[p].chapters[c].topics = Promise.resolve(searchResults[p].chapters[c].topics)
          chapters.push(searchResults[p].chapters[c]);
        }
      }

      product.chapters = Promise.resolve(chapters);

      var index = $scope.databases.findIndex(function(db){
        return db.databaseName=="Search Results";
      });
      if(index >= 0){
        $scope.databases[index]=product;
      } else {
        $scope.databases.push(product);
      }

      $scope.database = product;
    });
  }
}
