angular.module('examgen.service').
  /**
   * @ngdoc service
   * @name examgen.service:exams
   * @description Return the list of exams
   */
service('exams', ['constructors', '$q', '$http', function(constructors, $q, $http) {
  var defer = $q.defer();

  var o = {
    importAttachmentsList: function(examName, attachmentsList) {

      var attachments = attachmentsList.map(function(attachment) {
        return {
          name: attachment.Key.split('/').pop(),
          href: attachment.Key
        };
      });

      var r = [];
      var re = new RegExp(examName + '( - Copy ([0-9])+)?( - Answer Key)?.html');
      attachments.forEach(function(attachment) {
        var match = attachment.name.match(re);
        if (match) {
          var count = parseInt(match[2], 10) || 0;
          var pdf = r[count] = r[count] || {};
          var prop = match[3] ? 'answerKey' : 'exam';
          pdf[prop] = {
            href: '/' + attachment.href
          };
        }
      });
      //console.log('pdfs',r);
      return r;
    },

    load: function(exam,couch){
      var examDefer = $q.defer();
      exam.ready = examDefer.promise;
      exam.loadedExam = {};
      couch.db.getExam(exam.id).then(function(response) {
        exam.loadedExam.name = exam.name;
        exam.loadedExam.date_created = new Date(response.data.date_created);
        exam.loadedExam.date_updated = new Date(response.data.date_updated);
        exam.loadedExam.id = response.data.id;
        //the attachments is just a property that gets populated lazily, rather than a promise
        exam.loadedExam.pdfs = [];
        couch.db.listAttachments(exam.id).then(function(response) {
          var attachmentsList = response.data;
          //console.log('attachmentsList ',attachmentsList );
          exam.loadedExam.pdfRaw = attachmentsList;
          exam.loadedExam.pdfs = o.importAttachmentsList(exam.name, attachmentsList);
        });

        exam.json = response.data;
        examDefer.resolve(exam);
      });
    },

    ready: defer.promise,

    populate: function(couch) {
      //TODO: handle error cases
      //populate incrementally
      couch.db.listExams().then(function(response) {
        var examList = [];
        try {
          examList = response.data.map(function(examStub) {
            var exam = new constructors.Exam({
              name: examStub.name,
              id: examStub.id,
              date_created: new Date(examStub.date_created),
              date_updated: new Date(examStub.date_updated),
              folder: examStub.folder,
            });
            return exam;
          }).filter(function(e) {
            return !!e.name;
          });
        } catch (e) {
          $http.put('/api/error/add',{
            action:'exams populate',
            extra:response.data,
            error:e,
            tags:'error',
          });
          examList = [];
        }

        o.list = examList;
        defer.resolve(o);

        //populate the other properties incrementally
        // examList.forEach(function(exam) {
        //   var examDefer = $q.defer();
        //   exam.ready = examDefer.promise;
        //   exam.loadedExam = {};
        //   couch.db.getExam(exam.id).then(function(response) {
        //     exam.loadedExam.name = exam.name;
        //     exam.loadedExam.date_created = new Date(response.data.date_created);
        //     exam.loadedExam.date_updated = new Date(response.data.date_updated);
        //     exam.loadedExam.id = response.data.id;
        //     //the attachments is just a property that gets populated lazily, rather than a promise
        //     exam.loadedExam.pdfs = [];
        //     // couch.db.listAttachments(exam.id).then(function(response) {
        //     //   var attachmentsList = response.data;
        //     //   //console.log('attachmentsList ',attachmentsList );
        //     //   exam.loadedExam.pdfRaw = attachmentsList;
        //     //   exam.loadedExam.pdfs = o.importAttachmentsList(exam.name, attachmentsList);
        //     // });
        //
        //     exam.json = response.data;
        //     examDefer.resolve(exam);
        //   });
        // });
      });
    }
  };
  return o;
}]);
