function ComposerQuestionAreaCtrl($scope, $element, $attrs, localStorageService) {
  var vm = this;
  var composerCtrl = $scope.composerCtrl;
  composerCtrl.questionArea = vm;

  vm.setInitialSize = function() {
    var height = parseInt(localStorageService.get('composerQuestionAreaHeight'), 10);
    console.log('composerQuestionAreaHeight', height);
    composerCtrl.container.setQuestionGridHeight(height);
  };
}

angular
  .module('examgen.directive')
  .directive('composerQuestionArea', function() {
    return {
      restrict: 'A',
      bindToController: {
        composerCtrl: '='
      },
      controllerAs: 'questionArea',
      controller: ComposerQuestionAreaCtrl,

      link: function(scope, element, attrs, controller) {
        controller.setInitialSize();
      }
    }
  })