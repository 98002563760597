angular.module('examgen.layout').
/**
 * @ngdoc layout
 * @name examgen.directive:examLayout
 * @description Encapuslates the exam layout algorithm.
 */
directive('examLayout',function($rootScope,$q){
  return {
    template : '<div class="examPreview">',
    replace : true,
    scope : {
      exam : '=',
      $index : '=',
      totalNumberOfExamsToPrint : '='
    },
    link : function(scope,el,attr){
      var debug = false;

      var copyNumber = scope.$index;

      var cachedMarginHeight;

      function questionHasOverflowedVertically(currentBody){
          return currentBody.height() > cachedMarginHeight;
      }

      var layouts = {
        2 : [
          [
            [1, 2]
          ],
          [
            [1],
            [2]
          ]
        ],
        3 : [
          [
            [1, 2, 3]
          ],
          [
            [1, 2],
            [3]
          ],
          [
            [1],
            [2],
            [3]
          ]
        ],
        4 : [
          [
            [1, 2, 3, 4]
          ],
          [
            [1, 2],
            [3, 4]
          ],
          [
            [1],
            [2],
            [3],
            [4]
          ]
        ],
        5 : [
          [
            [1, 2, 3, 4, 5]
          ],
          [
            [1, 2, 3],
            [4, 5]
          ],
          [
            [1, 2],
            [3, 4],
            [5]
          ],
          [
            [1],
            [2],
            [3],
            [4],
            [5]
          ]
        ]
      };

      var ITEM_NUMBER_COLUMN_WIDTH = 36;

      function layoutResponses(itemOrPassage, currentQuestionsContainer){
        //choose an optimal layout for responses
        if(itemOrPassage.responseElements){
          if(debug) console.log('layout responseElements',itemOrPassage.responseElements);
          var columnWidth = currentQuestionsContainer.width() - ITEM_NUMBER_COLUMN_WIDTH;
          var possibleLayouts = layouts[itemOrPassage.responseElements.length];
          var layoutsThatFit = possibleLayouts.filter(function(layoutRows){
            if(debug) console.log('layoutRows',layoutRows);
            var everyRowFitsInCurrentColumn =
                layoutRows.every(function(row){
                  var rowWidth =
                      row.map(function(elementIndex){
                        //TODO: cache the width so that we're not constantly pinging him
                        //we add 2 px for table cell padding
                        return itemOrPassage.responseElements[elementIndex - 1].width() + 3;
                      }).reduce(function(a,b){
                        return a + b;
                      },0);

                  if(debug) console.log('row',row,'rowWidth',rowWidth,'columnWidth',columnWidth);

                  return rowWidth < columnWidth;
                });
            return everyRowFitsInCurrentColumn;
          });

          var optimalResponseLayout = layoutsThatFit.shift() || possibleLayouts[possibleLayouts.length - 1];
          if(debug) console.log('optimalResponseLayout',optimalResponseLayout);

          //convert our optimal layout to DOM
          var table = $('<div></div>');
          optimalResponseLayout.forEach(function(row){
            var tr = $('<div class="flex even"></div>');
            row.forEach(function(elementIndex){
              var td = $('<div></div>');

              var responseElement = itemOrPassage.responseElements[elementIndex - 1];
              responseElement.remove();
              td.append(responseElement);
              tr.append(td);
            });

            table.append(tr);
          });

          itemOrPassage.examElement.find('.td.itemContents').append(table);
        }
      }

      // converts a string and returns an array of words
      function stringWords(text) {
        return text
          .split(/(\b[^\s]+\b)/g)
          .map(function(word) {
            return word.trim();
          })
          .filter(function(word) {
            return word.length > 1;
          })
      }

      function render(exam,renderCb){
          //console.log('Rendering items');

          var currentBody, currentColumnRow, currentColumn, isTwoCol;

          function createPage(overrideIsTwoCol){
            var $page = $('<div class="page"><div class="margin"><table class="body"></table></div></div>');
            el.append($page);   //append him to the root element
            cachedMarginHeight = $page.find('.margin').height();      //we cache the margin height for performance reasons
            currentBody = $page.find('.body');
            createColumnRow(overrideIsTwoCol);   //automatically create a column row for the page
          }

          function createColumnRow(overrideIsTwoCol){
            var twoCol = overrideIsTwoCol === undefined ? isTwoCol : overrideIsTwoCol;
            if(twoCol){
              currentColumnRow = $('<tr class="columnRow">' +
                                    '<td class="col1"><div></div></td>' +
                                    '<td class="col2"><div></div></td>' +
                                    '</tr>');
              currentColumn = currentColumnRow.find('.col1>div');
            }else{
              currentColumnRow = $('<tr class="columnRow">' +
                                    '<td class="col0" colspan="2"><div></div></td>' +
                                    '</tr>');
              currentColumn = currentColumnRow.find('.col0>div');
            }

            currentBody.append(currentColumnRow);
          }

          async.eachSeries(exam.sections,function(section,sectionCb){

            var previousSectionWasTwoCol = isTwoCol;

            // fetch from global settings or default to section settings
            isTwoCol = section.options.twoColumns;

            function isIn(env){
              return currentColumn.parent().hasClass(env);
            }

            var processItemOrPassage;

            //we create a new page if the previous section contained a page break
            //or, we're the first section
            //or this section has a heading element. I'm not sure if the heading element requirement is the correct one
            if (section.options.pageBreak || !currentBody || section.headingElement) {
              createPage();
            }

            if(section.headingElement) currentBody.prepend(section.headingElement);


            if(isTwoCol){

              if(!previousSectionWasTwoCol){
                //last section was single-column, so we start a new column row
                console.error('prev was to col');
                createColumnRow();
              }

              //flow into the first column until full

              //flow into the next column, and then repeat from the next page
              processItemOrPassage = function(itemOrPassage,i){
                var isPassage = !!itemOrPassage.examPassage;
                var passage;
                if (isPassage) {
                  passage = itemOrPassage;
                } else if (itemOrPassage.composerParent && itemOrPassage.composerParent.examPassage) {
                  passage = itemOrPassage.composerParent;
                }

                function handleCol2Overflow(){
                  //we're already on column 2.
                  //create a new page, and new col1
                  createPage();

                  //remove the overflowing unit and re-append him
                  itemOrPassage.examElement.remove();
                  currentColumn.append(itemOrPassage.examElement);
                }

                // if this is a passage and we're in col0, we need to clean the dom back to a two col
                if (isPassage && isIn('col0')) {
                  createColumnRow(true);
                }

                // insert item into exam dom
                layoutResponses(itemOrPassage,currentColumn);
                itemOrPassage.examElement.remove();

                currentColumn.append(itemOrPassage.examElement);

                var imageWidths =
                        itemOrPassage.examElement.find('img').
                            map(function(i,img){return img.width || parseInt($(img).attr('width'),10);}).
                            toArray();

                var maxImageWidth = Math.max.apply(Math,imageWidths);

                itemOrPassage.examElement.find('img')
                .each(function(i,img){
                  $(img).attr('height','');
                  img.style.height = '';
                })

                // handle image overflow
                if (maxImageWidth > currentColumn.width()) {
                  createColumnRow(false);
                  if (passage) {
                    passage.wide = true;
                  }

                // passage has more than 200 words?
                } else if (isPassage && stringWords(itemOrPassage.examElement.text()).length >= 200) {
                  createColumnRow(false);
                  if (passage) {
                    passage.wide = true;
                  }

                // question and it has more than 200 words?
                } else if (!isPassage && stringWords(itemOrPassage.examElement.find('.prompt').text()).length > 200) {
                  createColumnRow(false);
                  currentColumn.append(itemOrPassage.examElement);
                }

                var isWidePassage = passage && passage.wide;

                if (isPassage && isWidePassage) {
                  itemOrPassage.examElement.remove();
                  currentColumn.append(itemOrPassage.examElement);
                }

                //did we go out of bounds?
                if(questionHasOverflowedVertically(currentBody)){
                  if(isIn('col1')){
                    //start column 2
                    currentColumn = currentColumnRow.find('.col2>div');

                    //remove the overflowing unit and re-append him
                    itemOrPassage.examElement.remove();
                    currentColumn.append(itemOrPassage.examElement);

                    //we may fall into the second case
                    if(questionHasOverflowedVertically(currentBody)){
                      handleCol2Overflow();
                    }
                  }else if(isIn('col2')){
                    handleCol2Overflow();
                  }else if(isIn('col0')){
                    itemOrPassage.examElement.remove();
                    createPage(false);
                    currentColumn.append(itemOrPassage.examElement);
                  }
                }

                // if it's one column and not a wide passage, then clean the dom
                if (isIn('col0') && !isWidePassage) {
                  //end the temporary single-column environment by creating a new multicolumn environment
                  createColumnRow(true);
                }
              };

            }else{
                //single-column layout.

                //make one big column
                if(previousSectionWasTwoCol){
                  //last section was two-column, so we start a new column row
                  createColumnRow();
                }

                //flow into the next column, and then repeat from the next page
                processItemOrPassage = function(itemOrPassage,i){
                    if(!itemOrPassage.examElement) return;

                    layoutResponses(itemOrPassage,currentColumn);
                    itemOrPassage.examElement.remove();

                    //custom layout logic
                    currentColumn.append(itemOrPassage.examElement);

                    //did we go out of bounds?
                    if(questionHasOverflowedVertically(currentBody)){
                        if(isIn('col0')){
                            //create a new page and a new single-width column
                            createPage();

                            itemOrPassage.examElement.remove();
                            currentColumn.append(itemOrPassage.examElement);
                        }
                    }
                };
            }

            //kick him off
            async.eachSeries(section.items,function(itemOrPassage,itemCb){
                if(itemOrPassage.items){
                    [itemOrPassage].concat(itemOrPassage.items).forEach(processItemOrPassage);
                    currentColumn.append($('<div class="end-section"><hr></div>'));
                }else{
                    processItemOrPassage(itemOrPassage);
                }
                window.nextTick(itemCb);    //introduce asynchronicity here so that we avoid long-running script dialogue
            },sectionCb);
        },renderCb);
      }

      function positionCorrectAnswerResponse(item){

          var itemContents = item.examItem.resolvedItemContent;

          var correctAnswerLetter = item.correctAnswerLetter;

          if(!itemContents.responses) return;

          //place the other questions at arbitrary positions: sequentially, or at random. Doesn't matter, they are wrong answers, and will not go in the answer key.
          //correctAnswerLetter
          var indexOfGivenCorrectItem = itemContents.responses.map(function(response){
              return response.isCorrect;
          }).indexOf(true);

          var indexOfChosenAnswerLetter = itemContents.responses.map(function(response){
              return response.answerLetter;
          }).indexOf(correctAnswerLetter);

          if(indexOfGivenCorrectItem === -1 || indexOfChosenAnswerLetter === -1){
              //console.log('Error: Unable to find correct responses.');
              return;
          }

          if(indexOfGivenCorrectItem !== indexOfChosenAnswerLetter){
              //swap the entries in this weird data structure
              var givenCorrectResponse = itemContents.responses[indexOfGivenCorrectItem];
              var chosenCorrectResponse = itemContents.responses[indexOfChosenAnswerLetter];

              if(debug) console.log(
                  'Moving correct answer from',
                  givenCorrectResponse.answerLetter,
                  'to',
                  chosenCorrectResponse.answerLetter);

              var tmpAnswer = givenCorrectResponse.answer;    //this is the true right answer

              //move him to the position of the chosen letter
              givenCorrectResponse.isCorrect = false;
              givenCorrectResponse.answer = chosenCorrectResponse.answer;

              chosenCorrectResponse.isCorrect = true;
              chosenCorrectResponse.answer = tmpAnswer;

              //console.log('Responses is now',JSON.stringify(itemContents.responses));
          }
      }


      var itemNumber = 0;

      var letterItemNames = ['A','B','C','D','E'];

      function processItem(item){
          var section = item.composerParent.examPassage ? item.composerParent.composerParent : item.composerParent;
          itemNumber++;

          //do a bit of transformation
          positionCorrectAnswerResponse(item);


          //initialize DOM
          var tr = $('<div class="question flex"><div class="itemNumber td">' + itemNumber + ')&nbsp;</div><div class="itemContents td"></div></div>');
          if (section.options.answerBlanks) {
            tr.find('.itemNumber').prepend('____&nbsp;');
          }

          // calcualte the padding, either from Exam or Section setting.
          var fontSize = section.options.fontSize || 12;
          // if Passage then fetch the parent composer, which should be a Section.
          var convertMCToShortAnswer = section.options.convertMCToShortAnswer;
          var isMC = item.examItem.resolvedItemContent.itemType == 'MC';
          var isShortAnswer = item.examItem.resolvedItemContent.itemType == 'ER' ||
                              item.examItem.resolvedItemContent.itemType == 'SR' ||
                              (isMC && convertMCToShortAnswer);

          // need to compare padding's type against a number, it could be 0
          var padding;
          if (isMC && section.options.multipleChoiceQuestionPadding) {
            padding = section.options.multipleChoiceQuestionPadding;
          } else {
            padding = isShortAnswer ? section.options.shortAnswerQuestionPadding : undefined;
          }

          // try parsing the padding or default to questionPadding
          padding = parseInt(padding, 10)
          if (padding < 0 || isNaN(padding)) {
            padding = parseInt(section.options.questionPadding, 10);
          }
          // default to padding of 1
          if (padding < 0 || isNaN(padding)) {
            padding = 1;
          }

          // apply the css to each td with the corresponding padding
          tr.find('.td').
          css({
            paddingBottom: padding * fontSize + 'pt'
          }).
          css('font-size',
              section.options && section.options.fontSize ?
                section.options.fontSize + 'pt' : '12pt').
          css('font-family',
              section.options && section.options.fontFamily ?
                section.options.fontFamily : '"Times New Roman", Times, serif');

          var td = tr.find('.td.itemContents');

          td.append($('<div class="prompt">' + item.examItem.resolvedItemContent.prompt + '</div>'));

          /*console.log(section.name,
            item.getLabel(),
            isShortAnswer ? 'short' : 'not short',
            padding,
            fontSize,
            (padding || 1) * fontSize + 'pt',
            item._exam.options.shortAnswerQuestionPadding,
            section.options.shortAnswerQuestionPadding
          );*/
          // if convertMCToShortAnswer is enabled, don't render the MC questions
          //console.log('CONVERT? ', item.getLabel(), !!item.composerParent.examPassage, convertMCToShortAnswer);
          if(item.examItem.resolvedItemContent.responses){
              item.responseElements = item.examItem.resolvedItemContent.responses.map(function(response,i){
                  if (convertMCToShortAnswer) {
                    return $('<span></span>');
                  }
                  //The main thing here is to avoid putting any display:block formatting tags inside of our response div
                  //the reason for this is that display:block will take the maximum width. We want to query it to find the
                  //minimum width, as with display:inline.
                  //Our response container has display:inline-block so that it can contain multiple display:inline elements
                  var answerLabel = section.options.numericMc ? (i+1) : letterItemNames[i];
                  var x = $('<span class="response flex"><div>' + answerLabel  + ')&nbsp;</div></span>');
                  if(response.answer.match(/<FONT>/) || response.answer.match(/<P>/)){
                    //this is the old form: <rtml> root element, and then content inside of <P> and <FONT> elements
                    x.append($(response.answer).find('font, p').contents());
                  }else{
                    //this is the new form: only text content
                    var y = $('<div>' + response.answer + '</div>');
                    x.append(y);
                  }
                  td.append(x);
                  return x;
              });
          }

          cleanHtml(tr);
          item.examElement = tr;

          if(item.examElement.find('math').length){    //this guard is a performance optimization - only run mathjax layout if there is some mathml content
            MathJax.Hub.Queue(["Typeset",MathJax.Hub,item.examElement[0]]);
          }

          //add him to DOM so that MathJax can render him
          el.append(tr);
      }

      function examToDom(exam){
          var allElements = [];

          exam.sections.forEach(function(section){

            if(section.options && section.options.heading){
              section.headingElement = $('<div>' + section.options.heading + '</div>');

              section.headingElement.find('p').
              css('font-size',
                  section.options && section.options.fontSize ?
                    section.options.fontSize + 'pt' : '12pt').
              css('font-family',
                  section.options && section.options.fontFamily ?
                    section.options.fontFamily : '"Times New Roman", Times, serif');
            }else{
              section.headingElement = null;    //could be left over from last time, so clear it
            }

            section.items.forEach(function(itemOrPassage){
              var isPassage = itemOrPassage.items;
              if(isPassage){
                var passage = itemOrPassage;
                var element = $('<div class="passage tr"><div class="td" colspan="2"></div></div>');
                element.find('.tr').
                css('font-size',
                    section.options && section.options.fontSize ?
                      section.options.fontSize + 'pt' : '12pt').
                css('font-family',
                    section.options && section.options.fontFamily ?
                      section.options.fontFamily : '"Times New Roman", Times, serif');

                var td = element.find('.td');

                //intro text
                var intro;
                switch(passage.items.length){
                  case 0:
                    return;         //empty passage
                  case 1:
                    intro = "<p class=\"passageHeading\"><strong>Question " + (itemNumber + 1) + " refers to the following:</strong></p>"
                    break;
                  case 2:
                    intro = "<p class=\"passageHeading\"><strong>Questions " + (itemNumber + 1) + " and " + (itemNumber + 2) + " refer to the following:</strong></p>"
                    break;
                  default:
                    intro = "<p class=\"passageHeading\"><strong>Questions " + (itemNumber + 1) + " through " + (itemNumber + passage.items.length) + " refer to the following:</strong></p>"
                    break;
                }

                td.append($(intro));
                td.append($(passage.examPassage.resolvedPassageHTML));

                passage.examElement = element;

                if(passage.examElement.find('math').length){    //this guard is a performance optimization - only run mathjax layout if there is some mathml content
                  MathJax.Hub.Queue(["Typeset",MathJax.Hub,passage.examElement[0]]);
                }

                passage.items.forEach(processItem);

                allElements.push(element);

              }else{
                processItem(itemOrPassage);

                allElements.push(itemOrPassage.examElement);
                if(itemOrPassage.responseElements) allElements.push.apply(allElements,itemOrPassage.responseElements);
              }
            });
          });

          //return a promise that indicates DOM is ready
          //currently, this just means that images are loaded
          var domElements = $(allElements.reduce(function(a,b){return $.merge(a,b);},[]));
          var imagesLoadedPromise = $q.when(domElements.imagesLoaded());
          imagesLoadedPromise.then(function(){
            console.log('images loaded');
          }).catch(function(err){
            exam.error = 'An error occured while loading the images in your EXAM';
            console.error(err);
          });
          return imagesLoadedPromise;
      }

      function performLayout(exam){
        console.log(el);
        el.
          css('font-size',
              exam.sections[0].options && exam.sections[0].options.fontSize ?
                exam.sections[0].options.fontSize + 'pt' : '12pt').
          css('font-family',
              exam.sections[0].options && exam.sections[0].options.fontFamily ?
                exam.sections[0].options.fontFamily : '"Times New Roman", Times, serif');

        var imagesLoadedPromise = examToDom(exam);

        var mathJaxDeferred = $q.defer();

        MathJax.Hub.Queue(function () {
          console.log('MathJax is done');
          mathJaxDeferred.resolve();
        });

        //wait for images to load
        $q.all([mathJaxDeferred.promise,imagesLoadedPromise]).then(function() {
          //flow in text
          render(exam,renderComplete);
        });

        function renderComplete(){
          //console.log('render complete');
          //add page numbers
          el.find('.page').each(function(i, page){
              var versionText;
              if(scope.totalNumberOfExamsToPrint === 1){
                  versionText = '';      //empty. we have no versions, so we have no version code
              }else{
                  versionText = ' v' + (copyNumber + 1);
              }

              $(page).append( $('<span>').addClass('pageNumber').text($rootScope.documentId + versionText + ' - Page ' + (i + 1)));
          });

          //add heading
          el.find('.page:first').append(
            $('<div class="nameHeading"><div class="name">Name:&nbsp;' +
                  '<span class="blank">' +
                    '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                    '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                  '</span>' +
                  '</div></div>'));

          //This is a workaround for PhantomJS's unusual behaviour
          //where it assumes a default stroke-width of 1.
          //We have to explicitly specify stroke : none
          $('path').each(function(){
            //console.log('removing stroke path');
            this.removeAttribute('stroke-width');
            this.setAttribute('stroke','none');
          });


          //console.log('fixing mathjax');
          // fix svg issues, this runs globaly to the whole page, not just the specific chunk of generated html
          var t = 0;
          var useTags = document.getElementsByTagName('use');
          for (var o=0; o < useTags.length; o++) {
            var useTag = useTags[o];
            var href = (useTag.getAttribute('xlink:href') || useTag.getAttribute('href') || '').substring(1);

            // fetch the MathJax path definition
            var pathDef = document.getElementById(href);
            if (!pathDef) {
              //console.log('use found issue, href not found', useTag, href);
              continue;
            }

            // create the new tag
            var cloned = pathDef.cloneNode(true);
            cloned.removeAttribute('id');

            // build the transform from the use tag, and map X Y to translate()
            var x = parseInt(useTag.getAttribute('x')) || 0;
            var y = parseInt(useTag.getAttribute('y')) || 0;
            var transform = useTag.getAttribute('transform') || '';
            var translate =  'translate(' + x + ', ' + y + ')';
            cloned.setAttribute('transform', transform + ' ' + translate);

            // now, replace use with cloned and remove the use tag
            useTag.parentNode.appendChild(cloned);
            useTag.parentNode.removeChild(useTag);
            o--;
            //console.log('deleting useTag', t++);
          }

          var outputHTML = el[0].outerHTML;
          //call out
          $rootScope.$broadcast('layoutComplete',{
            copyNumber : copyNumber,
            html : outputHTML,
            isAnswerKey : false
          });
        }
      }

      //TODO: break this out into shared code.
      function cleanHtml($element){
        //transform DOM content so that it renders nicer:
            //filter out font tags
            //remove newlines and tags.
        //this seems to work well
        var fontTags = $element.find('font, i');
        fontTags.each(function(tag){
          var $t = $(this);
          var $parent = $t.parent();

          if($parent.html()){
            //console.log('this',this);
            //console.log('html1',$parent.html());

            $t.replaceWith($t.contents());

            //console.log('html2',$parent.html());

            var newHtml = $parent.html().replace(/\n|\t/g,'');
            $parent.html(newHtml);

            //console.log('html3',$parent.html());
          }
        })

        //remove legazy non-standard RTML code
        var rtml = $element.find('rtml');
        rtml.each(function(i,rtmlElement){
          var $rtmlElement = $(rtmlElement);
          var textformat = $rtmlElement.find('textformat');
          var divElement = $('<div>');
          $rtmlElement.parent()[0].replaceChild(divElement[0],rtmlElement);

          var textformatContents = textformat.contents();
          textformatContents.remove();
          divElement.append(textformatContents);
        });

        $element.find('[align="CENTER"]').each(function(){
          this.removeAttribute('align');
          $(this).css({'text-align':'center'});
        });

        var mathml = $element.find('math');
        mathml.attr('display','');

        //this is for IE9, which doesn't recognize the img width and height attributes
        $element.find('img').each(function(){
          $(this).css({
            width : $(this).attr('width') + 'px',
            height : $(this).attr('height') + 'px'
          });
          //this.removeAttribute('width');
          //this.removeAttribute('height');
        });

      }

      scope.exam.waitForAllItemsToResolve().then(function(){
        try{
          performLayout(scope.exam);
        } catch(e){
          scope.exam.error = 'An error occured while generating your EXAM';
          console.error(e);
        }
      }).catch(function(err){
        scope.exam.error='An error occured while generating the layout for your EXAM';
        console.error(err);
      });
    }
  };
});
