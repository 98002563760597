angular.module('examgen.service').
/**
 * @ngdoc service
 * @name examgen.service:databases
 * @description Returns the list of databases available from the user profile
 */
service('databases',function($http, session, $q) {
  var defer = $q.defer();
  var databases = {
    all: defer.promise,
    fetch : function() {
      databases.all = session.profile.then(function(profile) {
        var fixed = profile.Databases.map(function(dbName) {
          return {
            databaseName: dbName.name,
            ldatabaseName: dbName.name.toLowerCase(),
            databaseId: dbName.id,
            isUserDB: dbName.user,
            version: dbName.version || 'x.x.x',
            chapters: null,
            passages: null,
            standards: null,
            itemRefs: null
          };
        }).filter(function(t) {
          return !!t;
        });

        defer.resolve(fixed);
        return fixed;
      }, function() {
        alertify.error("Error retrieving the user's session");
      });
    }
  };

  return databases;
});
