var app = angular.module('examgen');

app.filter('emFilterAttempts', function() {
  return function(items,pool) {
      var out = [];
      if(items && pool){
        for(var i = 0; i < items.length; i++){
          var flag = false;
          flag = pool.find((p)=>p.idStudent == items[i])
          if(flag)out.push(items[i]);
        }
      }
      return out;
    }
});

function examManagementController($rootScope, $scope, $http, $stateParams, $timeout, $sce, flashMessages, uuid, couch, exams) {

  $rootScope.inAssignments = true;
  $scope.$on('$destroy', function() {
    $rootScope.inAssignments = false;
  });

  $scope.data = {
    working: 0,
    districts: null,
    district: null,
    schools: null,
    school: null,
    classes: null,
    class: null,
    searchText:'',
    statusText:['Not started','Inprogress','Submitted','Graded','Incomplete'],
    archivedClasses: false,
    classRange: { start: null, stop: null },
    schoolYear: {},
    assignmentTypes:[ {id:0,name:'Uncategorized'}, {id:1,name:'Homework'}, {id:2,name:'Review'},
      {id:3,name:'Exam'}, {id:4,name:'Test'}, {id:5,name:'Extra Credit'}],
  };
  $scope.Date = Date;
  $scope.refresh = function(next){
    $timeout(function() {
      $scope.$apply();
      if(next){
        next();
      }
    });
  }
  $scope.dismiss = function(){
    if ($scope.data.showPicklist) {
      $scope.data.showPicklist = false;
    }if ($scope.data.showOverrides) {
      $scope.data.showOverrides = false;
      if(!$scope.data.showNewAssignment)
        $scope.data.showBackdrop = false;
    } else {
      $scope.data.showBackdrop = false;
      $scope.data.showNewAssignment = false;
      $scope.data.showAssignmentInfo = false;
      $scope.data.showAssignmentReview = false;
      $scope.data.showAttemptInfo = false;
    }
  }
  $scope.preventDef = function(e){
    if ($scope.data.showPicklist) {
      $scope.data.showPicklist = false;
    }
    e.stopPropagation();
  }

/////////////////////////////
  $scope.getDistricts = function(){
    $scope.data.districts = [];
    $scope.data.schools = null;
    $scope.data.school = null;
    return $http.get('/api/districts/user').then(function(res){
      $scope.data.districts = res.data;
      var p=[];
      for(var i = 0; i < $scope.data.districts.length; i++){
        if($scope.data.districts[i].adminStaff == 2){
          $scope.data.districts[i].staffView = true;
          $scope.data.districts[i].staffEdit = true;
        } else if($scope.data.districts[i].adminStaff == 1){
          $scope.data.districts[i].staffView = true;
        }
        if($scope.data.districts[i].adminClasses == 2){
          $scope.data.districts[i].classView = true;
          $scope.data.districts[i].classEdit = true;
        } else if($scope.data.districts[i].adminClasses == 1){
          $scope.data.districts[i].classView = true;
        }
        if($scope.data.districts[i].adminStudents == 2){
          $scope.data.districts[i].studentView = true;
          $scope.data.districts[i].studentEdit = true;
        } else if($scope.data.districts[i].adminStudents == 1){
          $scope.data.districts[i].studentView = true;
        }
        if($scope.data.districts[i].adminExams == 2){
          $scope.data.districts[i].examView = true;
          $scope.data.districts[i].examEdit = true;
        } else if($scope.data.districts[i].adminExams == 1){
          $scope.data.districts[i].examView = true;
        }
        p.push($scope.getSchools($scope.data.districts[i]));
      }
      if(p.length > 0){
        Promise.all(p).then(function(res){
          if($scope.data.schools.length > 0){
            setTimeout(function () {
              var lastSchool = localStorage.getItem('lastSchool');
              $scope.refresh(function(){
                if(lastSchool){
                  $scope.data.school = $scope.data.schools.find(function(s){return s.idSchool==lastSchool});
                }else $scope.data.school = null
                if(!$scope.data.school){
                  $scope.data.school = $scope.data.schools[0];
                }
              });
            }, 100);
          }
        })
      }
    }).catch(function(err){
      console.error(err);
    })
  }

//////////////////////////////
  $scope.$watch('data.school',function(school,last){
    $scope.data.classes = null;
    $scope.data.class = null;
    if(school){
      localStorage.setItem('lastSchool', school.idSchool);
      $scope.data.district = $scope.data.districts.find(function(d){return d.idDistrict==school.schoolDistrict});
      $scope.getClasses();
    }
  });
  $scope.getSchools = function(district){
    return $http.get('/api/user/schools/'+district.idDistrict).then(function(res){
      var schools = res.data;
      for(var i = 0; i < schools.length; i++){
        if(district.adminStaff == 2){
          schools[i].staffView = true;
          schools[i].staffEdit = true;
        } else if(district.adminStaff == 1){
          schools[i].staffView = true;
        }
        if(district.adminClasses == 2){
          schools[i].classView = true;
          schools[i].classEdit = true;
        } else if(district.adminClasses == 1){
          schools[i].classView = true;
        }
        if(district.adminStudents == 2){
          schools[i].studentView = true;
          schools[i].studentEdit = true;
        } else if(district.adminStudents == 1){
          schools[i].studentView = true;
        }
        if(district.adminExams == 2){
          schools[i].examView = true;
          schools[i].examEdit = true;
        } else if(district.adminExams == 1){
          schools[i].examView = true;
        }

        if(schools[i].adminStaff == 2){
          schools[i].staffView = true;
          schools[i].staffEdit = true;
        } else if(schools[i].adminStaff == 1){
          schools[i].staffView = true;
        }
        if(schools[i].adminClasses == 2){
          schools[i].classView = true;
          schools[i].classEdit = true;
        } else if(schools[i].adminClasses == 1){
          schools[i].classView = true;
        }
        if(schools[i].adminStudents == 2){
          schools[i].studentView = true;
          schools[i].studentEdit = true;
        } else if(schools[i].adminStudents == 1){
          schools[i].studentView = true;
        }
        if(schools[i].adminExams == 2){
          schools[i].examView = true;
          schools[i].examEdit = true;
        } else if(schools[i].adminExams == 1){
          schools[i].examView = true;
        }
        schools[i].districtName = district.name;
        if(!$scope.data.schools) $scope.data.schools = [];
        $scope.data.schools.push(schools[i]);
      }
      $scope.data.schools.sort(function(a,b){
        return a.districtName.localeCompare(b.districtName);
      })
    }).catch(function(err){
      console.error(err);
    })
  }

///////////////////////////////
  $scope.$watch('data.class',function(cinfo,last){
    $scope.data.assignments = [];
    $scope.data.assignment = null;
    if(cinfo){
      localStorage.setItem('lastClass', cinfo.idClass);
      $scope.getAssignments();
      return $http({
       method: 'GET',
       url: $scope.data.school.database+'/api/students/?idClass='+cinfo.idClass,
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       }
      })
      .then(function(res){
        //cinfo.allStudents = res.data.students.filter((s)=>s.fkClass);
        cinfo.students = res.data.students.filter((s)=> {
          return s.fkClass &&
            (!s.hasOwnProperty('active') || s.active) &&
            (!s.hasOwnProperty('dropped') || !s.dropped)
        });
      });
    }
  });
  // $scope.$watch('data.archivedClasses',function(classes){
  //   if($scope.data.school)$scope.getClasses();
  // })
  $scope.getClasses = function(){
    $scope.data.classes = null;
    $scope.data.class = null;
    return $http.get('/api/user/class/'+$scope.data.school.idSchool+
      ($scope.data.archivedClasses ? `?start=${$scope.data.classRange.start}&stop=${$scope.data.classRange.stop}` : ''))
    .then(function(res){
      $scope.data.classes = res.data;
      for(var i = 0; i < $scope.data.classes.length; i++){
        if($scope.data.district.adminStaff == 2){
          $scope.data.classes[i].staffView = true;
          $scope.data.classes[i].staffEdit = true;
        } else if($scope.data.district.adminStaff == 1){
          $scope.data.classes[i].staffView = true;
        }
        if($scope.data.district.adminClasses == 2){
          $scope.data.classes[i].classView = true;
          $scope.data.classes[i].classEdit = true;
        } else if($scope.data.district.adminClasses == 1){
          $scope.data.classes[i].classView = true;
        }
        if($scope.data.district.adminStudents == 2){
          $scope.data.classes[i].studentView = true;
          $scope.data.classes[i].studentEdit = true;
        } else if($scope.data.district.adminStudents == 1){
          $scope.data.classes[i].studentView = true;
        }
        if($scope.data.district.adminExams == 2){
          $scope.data.classes[i].examView = true;
          $scope.data.classes[i].examEdit = true;
        } else if($scope.data.district.adminExams == 1){
          $scope.data.classes[i].examView = true;
        }

        if($scope.data.school.adminStaff == 2){
          $scope.data.classes[i].staffView = true;
          $scope.data.classes[i].staffEdit = true;
        } else if($scope.data.school.adminStaff == 1){
          $scope.data.classes[i].staffView = true;
        }
        if($scope.data.school.adminClasses == 2){
          $scope.data.classes[i].classView = true;
          $scope.data.classes[i].classEdit = true;
        } else if($scope.data.school.adminClasses == 1){
          $scope.data.classes[i].classView = true;
        }
        if($scope.data.school.adminStudents == 2){
          $scope.data.classes[i].studentView = true;
          $scope.data.classes[i].studentEdit = true;
        } else if($scope.data.school.adminStudents == 1){
          $scope.data.classes[i].studentView = true;
        }
        if($scope.data.school.adminExams == 2){
          $scope.data.classes[i].examView = true;
          $scope.data.classes[i].examEdit = true;
        } else if($scope.data.school.adminExams == 1){
          $scope.data.classes[i].examView = true;
        }

        if($scope.data.classes[i].adminStaff == 2){
          $scope.data.classes[i].staffView = true;
          $scope.data.classes[i].staffEdit = true;
        } else if($scope.data.classes[i].adminStaff == 1){
          $scope.data.classes[i].staffView = true;
        }
        if($scope.data.classes[i].adminClasses == 2){
          $scope.data.classes[i].classView = true;
          $scope.data.classes[i].classEdit = true;
        } else if($scope.data.classes[i].adminClasses == 1){
          $scope.data.classes[i].classView = true;
        }
        if($scope.data.classes[i].adminStudents == 2){
          $scope.data.classes[i].studentView = true;
          $scope.data.classes[i].studentEdit = true;
        } else if($scope.data.classes[i].adminStudents == 1){
          $scope.data.classes[i].studentView = true;
        }
        if($scope.data.classes[i].adminExams == 2){
          $scope.data.classes[i].examView = true;
          $scope.data.classes[i].examEdit = true;
        } else if($scope.data.classes[i].adminExams == 1){
          $scope.data.classes[i].examView = true;
        }
      }
      if($scope.data.classes.length > 0){
        setTimeout(function(){
          var lastClass = localStorage.getItem('lastClass');
          $scope.refresh(function(){
            if(lastClass){
              $scope.data.class = $scope.data.classes.find(function(s){return s.idClass==lastClass});
            }else{
              $scope.data.class = $scope.data.classes[0];
            }
          });
        },100)
      }
    }).catch(function(err){
      console.error(err);
    })
  }

////////////////////////////////
  $scope.$watch('data.addClass',function(addClass,last){
    if(addClass){
      $scope.data.newAssignment.classes.push(addClass);
      $scope.data.addClass = '';
    }
  });
  $scope.getAssignments = function(){
    $scope.data.assignments = [];
    return $http({
     method: 'GET',
     url: $scope.data.school.database+'/api/assignments/'+$scope.data.class.idClass+'/list',
     headers: {
       Authorization: 'Bearer '+localStorage.getItem('studentToken'),
     }
    }).then(function(res){
      $scope.data.assignments = res.data.assignments;
      for (var i = 0; i < $scope.data.assignments.length; i++) {
        $scope.data.assignments[i].attempts = $scope.data.assignments[i].attempts || [];
        $scope.data.assignments[i].submitted = $scope.data.assignments[i].submitted || [];
        $scope.data.assignments[i].graded = $scope.data.assignments[i].graded || [];
        $scope.data.assignments[i].start = new Date($scope.data.assignments[i].start)
        $scope.data.assignments[i].stop = new Date($scope.data.assignments[i].stop)
      }
    }).catch(function(err){
      console.error(err);
    })
  }
  $scope.newAssignment = function(){
    var now = new Date();
    now.setHours(8,0,0,0);
    var tomorrow = new Date(now.getTime());
    tomorrow.setDate(tomorrow.getDate()+1);
    $scope.data.newAssignment = {
      classes: [$scope.data.class],
      type:0,
      start: now,
      stop: tomorrow,
    }
    $scope.data.showBackdrop = true;
    $scope.data.showNewAssignment = true;
  }

  $scope.editAssignment = function(c){
    c.backup={
      title:c.title,
      start:c.start,
      stop:c.stop,
      type:c.type,
    }
    c.type = ""+c.type;
    c.editing = true;
  }

  $scope.processOverrides = function(c,a){
    var overrides = [];
    for(var s = 0; s < c.students.length; s++){
      overrides.push({
        fkAssignment : a.idAssignment,
        fkStudent : c.students[s].idStudent,
        available : c.students[s].hasOwnProperty('available')?c.students[s].available:1,
        overrideStart : c.students[s].overrideStart,
        overrideStop : c.students[s].overrideStop,
        overrideOptions : c.students[s].overrideOptions?JSON.stringify(c.students[s].overrideOptions):'',
      });
    }
    return overrides;
  }
  $scope.processOverridesList = function(assignment, assignments){
    var overrides = [];
    for(var i = 0; i < assignments.length; i++){
      var c = assignment.classes?(assignment.classes.find(function(c){return c.idClass=assignments[i].fkClass})):$scope.data.class;
      if(c){
        overrides.push(...$scope.processOverrides(c,assignments[i]));
      }
    }
    return overrides;
  }
  $scope.saveOverrides = function(overrides){
    if(overrides && overrides.length > 0){
      return $http({
       method: 'PUT',
       url: $scope.data.school.database+'/api/assignments/'+$scope.data.class.idClass+'/overrides',
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       },
       data: overrides,
      });
    }
    return Promise.resolve(false);
  }

  $scope.saveAssignment = function(assignment,isNew){
    var assignments;
    var p;
    if(!assignment.questions){
      p = $http.get('/api/'+$rootScope.profile.UserId+'/exams/'+(assignment.fkExam||assignment.exam.id)+'/count');
    } else {
      p = Promise.resolve(false);
    }
    p.then(function(res){
      if(res && res.data){
        assignment.questions = res.data.count;
      }
      if(isNew){
        assignments = [];
        for(var i = 0; i < assignment.classes.length; i++){
          assignments.push({
            idAssignment:uuid.v4(),
            fkClass:assignment.classes[i].idClass,
            fkExam:assignment.exam.id,
            title:assignment.title,
            type:assignment.type,
            start:(assignment.start).getTime(),
            stop:(assignment.stop).getTime(),
            options:JSON.stringify(assignment.options||{}),
            questions:(assignment.questions||0),
          })
        }
      }else {
        assignments = [{
          idAssignment:assignment.idAssignment,
          fkClass:assignment.fkClass,
          fkExam:assignment.fkExam,
          title:assignment.title,
          type:assignment.type,
          start:(assignment.start).getTime(),
          stop:(assignment.stop).getTime(),
          options:JSON.stringify(assignment.options||{}),
          questions:assignment.questions||0,
        }]
      }
      var exists = false;
      for(var i = 0; i < $scope.data.assignments.length; i++){
        if($scope.data.assignments[i].title.toLowerCase()==assignment.title.toLowerCase() &&
        $scope.data.assignments[i].idAssignment != assignment.idAssignment){
          exists = true;
        }
      }
      if(!exists){
        return $http({
         method: 'PUT',
         url: $scope.data.school.database+'/api/assignments/'+$scope.data.class.idClass+'/',
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         },
         data: assignments
        }).then(function(res){
          return $scope.saveOverrides($scope.processOverridesList(assignment, assignments));
        }).then(function(res){
          assignment.editing = false;
          if(isNew){
            $scope.getAssignments();
            $scope.dismiss();
            alertify.success("Assignment Added");
          } else
            alertify.success("Assignment Updated");
        }).catch(function(err){
          console.error(err);
          alertify.error(`an error occured ${isNew?'creating':'updating'} Assignment`);
        });
      } else {
        alertify.error('Duplicate assignment name');
      }
    }).catch(function(err){
      console.error(err);
      alertify.error(`an error occured ${isNew?'creating':'updating'} Assignment`);
    });
  }
  $scope.deleteAssignment = function(assignment){
    bootbox.confirm("Delete "+assignment.title+" ?", function(result){
      if(result){
        $http({
         method: 'DELETE',
         url: $scope.data.school.database+'/api/assignments/'+$scope.data.class.idClass+'/'+assignment.idAssignment,
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         }
        }).then(function(res){
          var index = $scope.data.assignments.findIndex(function(x){return x.idAssignment == assignment.idAssignment;});
          $scope.data.assignments.splice(index,1);
        }).catch(function(err){
          console.error(err);
          alertify.error('Error removing assignment');
        });
      }
    });
  }
  $scope.revertAssignment = function(assignment){
    assignment.title=assignment.backup.title;
    assignment.start=assignment.backup.start;
    assignment.stop=assignment.backup.stop;
    assignment.type=assignment.backup.type;
    assignment.editing = false;
  }
  $scope.remClassLinkColumn = function(index){
    $scope.data.newAssignment.classes.splice(index,1);
  }

  $scope.editOverrides = function(c,a){
    var p;
    $scope.data.overrideClass = c;
    $scope.data.overrideAssignment = a;
    $scope.data.working++;
    if(!c.students){
      p = $http({
       method: 'GET',
       url: $scope.data.school.database+'/api/students/?idClass='+c.idClass,
       headers: {
         Authorization: 'Bearer '+localStorage.getItem('studentToken'),
       }
     });
    } else {
      p = Promise.resolve(false);
    }

    p.then(function(res){
      if(res){
        c.students = res.data.students.filter((s)=> {
          return s.fkClass &&
            (!s.hasOwnProperty('active') || s.active) &&
            (!s.hasOwnProperty('dropped') || !s.dropped)
        });
      }

      if(a){
        p = $http({
         method: 'GET',
         url: $scope.data.school.database+'/api/assignments/'+$scope.data.class.idClass+'/'+a.idAssignment+'/overrides',
         headers: {
           Authorization: 'Bearer '+localStorage.getItem('studentToken'),
         }
       });
      } else {
        p = Promise.resolve(false);
      }
      return p;
    }).then(function(res){
      $scope.data.overrides = []
      for(var i = 0; i < c.students.length; i++){
        $scope.data.overrides.push({
          fkStudent : c.students[i].idStudent,
          LastName : c.students[i].LastName,
          FirstName : c.students[i].FirstName,
          localStudentId : c.students[i].localStudentId,
          available : (!a) ? 1 : c.students[i].hasOwnProperty('available')?c.students[i].available:1,
          useOverrideStart : (!a) ? 0 : c.students[i].overrideStart?1:0,
          overrideStart : (!a) ? 0 : c.students[i].overrideStart ? new Date(c.students[i].overrideStart) : 0,
          useOverrideStop : (!a) ? 0 : c.students[i].overrideStop?1:0,
          overrideStop : (!a) ? 0 : c.students[i].overrideStop ? new Date(c.students[i].overrideStop) : 0,
          overrideOptions : (!a) ? null : c.students[i].overrideOptions || null,
        });
      }

      if(res){
        for(var i = 0; i < res.data.students.length; i++){
          var student = $scope.data.overrides.find(function(s){ return s.fkStudent==res.data.students[i].fkStudent });
          if(student){
            student.available = res.data.students[i].available;
            student.overrideStart = new Date(res.data.students[i].overrideStart||a.start);
            if(res.data.students[i].overrideStart)student.useOverrideStart = 1;
            student.overrideStop = new Date(res.data.students[i].overrideStop||a.stop);
            if(res.data.students[i].overrideStop)student.useOverrideStop = 1;
            student.overrideOptions = res.data.students[i].overrideOptions;
          }
        }
      }
      $scope.refresh(function(){
        $scope.data.working--;
        $scope.data.showBackdrop = true;
        $scope.data.showOverrides = true;
      });
    }).catch(function(err){
      $scope.data.working--;
      console.error(err);
      alertify.error('An error occured loading student options, please try again later');
    })
  }
  $scope.updateOverrides = function(){
    for(var i = 0; i < $scope.data.overrides.length; i++){
      var student = $scope.data.overrideClass.students.find(function(s){return s.idStudent==$scope.data.overrides[i].fkStudent});
      if(student){
        student.available = $scope.data.overrides[i].available;
        student.overrideStart = $scope.data.overrides[i].useOverrideStart?$scope.data.overrides[i].overrideStart.getTime():0;
        student.overrideStop = $scope.data.overrides[i].useOverrideStop?$scope.data.overrides[i].overrideStop.getTime():0;
        student.overrideOptions = $scope.data.overrides[i].overrideOptions;
      }
    }
    if($scope.data.overrideClass && $scope.data.overrideAssignment){
      $scope.saveOverrides($scope.processOverrides($scope.data.overrideClass, $scope.data.overrideAssignment))
      .then(function(){
        alertify.success('Student options updated');
      }).catch(function(err){
        alertify.success('An error occured while updating student options, please try again later');
        console.error(err);
      })
    }
    $scope.dismiss()
  }
  $scope.assignAll = function(){
    var flag = false;
    for(var i = 0; i < $scope.data.overrides.length && !flag; i++){
      if($scope.data.overrides[i].available==0) flag = true;
    }
    for(var i = 0; i < $scope.data.overrides.length; i++){
      $scope.data.overrides[i].available = flag?1:0;
    }
  }

  $scope.loadAttempts = function (assignment){
    $scope.data.working++;
    return $http({
      method: 'GET',
      url: $scope.data.school.database + '/api/assignments/' + $scope.data.class.idClass + '/' + assignment.idAssignment + '/attempts',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('studentToken'),
      }
    }).then(function (res) {
      assignment.attempts = res.data.attempts;
      assignment.avg = 0
      for (var i = 0; i < assignment.attempts.length; i++) {
        assignment.attempts[i].grade = 0;
        assignment.attempts[i].maxGrade = 0;
        assignment.attempts[i].time = 0;
        assignment.attempts[i].avg = 0;
        if (assignment.attempts[i].questions.length > 0) {
          for (var q = 0; q < assignment.attempts[i].questions.length; q++) {
            assignment.attempts[i].grade += assignment.attempts[i].questions[q].value;
            assignment.attempts[i].time += assignment.attempts[i].questions[q].time;
            assignment.attempts[i].maxGrade++;
          }
          // assignment.maxPoints = assignment.attempts[i].questions.length;

          // if(!assignment.avg){
          //   assignment.avg = assignment.attempts[i].grade;
          // } else {
          //   assignment.avg = (assignment.avg + assignment.attempts[i].grade) / 2;
          // }
          assignment.attempts[i].grade = assignment.attempts[i].grade / assignment.attempts[i].maxGrade;
          assignment.avg += assignment.attempts[i].grade;
        }
      }
      if (assignment.attempts.length > 0) assignment.avg = assignment.avg / assignment.attempts.length;

      for (var i = 0; i < $scope.data.class.students.length; i++) $scope.data.class.students[i].attempts = [];

      for (var i = 0; i < assignment.attempts.length; i++) {
        var student = $scope.data.class.students.find((s) => s.idStudent == assignment.attempts[i].fkStudent);
        if (student) {
          student.attempts.push(assignment.attempts[i]);
        }
      }
      $scope.data.working--;
    });
  }

  $scope.showAssignmentInfo = function(assignment,refresh){
    $scope.dismiss();
    $scope.data.assignment = assignment;

    if(refresh){
      $scope.loadAttempts(assignment).then(()=>{
        $scope.data.showBackdrop = true;
        $scope.data.showAssignmentInfo = true;
      });
    } else {
      $scope.data.showBackdrop = true;
      $scope.data.showAssignmentInfo = true;
    }
  }
  $scope.showAssignmentReview = function(){
    $scope.dismiss();
    $scope.data.showBackdrop = true;
    $scope.data.showAssignmentReview = true;
  }
  $scope.showAttemptInfo = function(attempt){
    $scope.dismiss();
    $scope.data.attempt = attempt;
    $scope.data.showBackdrop = true;
    $scope.data.showAttemptInfo = true;
  }
  $scope.getAssignmentAvg = function(question){
    var avg = 0;
    var count = 0;
    for(var i = 0; i < $scope.data.assignment.attempts.length; i++){
      var q = $scope.data.assignment.attempts[i].questions.find(function(r){return r.fkQuestion==question.fkQuestion});
      if(q){
        avg += q.value;
        count++;
      }
    }
    return (count == 0) ? 0 : (avg/count);
  }

  $scope.markIncomplete = function(assignment, student, silent){
    $scope.data.working++;
    return $http({
      method: 'PUT',
      url: $scope.data.school.database + '/api/assignments/' + $scope.data.class.idClass + '/' + assignment.idAssignment+ '/incomplete/' + student.idStudent,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('studentToken'),
      }
    })
    .then(function (res) {
      $scope.data.working--;
      res.data.attempt.grade = 0;
      res.data.attempt.maxGrade = 0;
      res.data.attempt.time = 0;
      res.data.attempt.avg = 0;

      student.attempts.push(res.data.attempt);
      assignment.attempts.push(res.data.attempt);
      assignment.submitted.push(student.idStudent);
      assignment.graded.push(student.idStudent);

      if (!silent) alertify.success("Assignment marked incomplete");
    })
    .catch(function(err){
      $scope.data.working--;
      console.error(err);
      if (!silent) alertify.error("An error occured while marking assignment incomplete");
    });
  }

  $scope.markAllIncomplete = function (assignment, silent) {
    function doStuff(){
      $scope.data.working++;
      var p = [];
      for (var i = 0; i < $scope.data.class.students.length; i++) {
        if ($scope.data.class.students[i].attempts.length == 0)
          p.push($scope.markIncomplete(assignment, $scope.data.class.students[i], true))
      }

      return Promise.all(p).then(function () {
        $scope.refresh(() => $scope.data.working--);
        if (!silent) alertify.success('Assignments marked incomplete');
      }).catch(function (err) {
        $scope.refresh(() => $scope.data.working--);
        console.error(err);
        if (!silent) alertify.error("An error occured while marking assignments incomplete");
      });
    }
    
    if(!silent)
      bootbox.confirm("Mark all students, that have not attempted the assignment, as incomplete?", function (result) {
        if (result) {
          doStuff();
        }
      });
    else
      doStuff();
  }

  $scope.markClassIncomplete = function () {

    function doStuff(assignment){
      return $scope.loadAttempts(assignment).then(function () {
        return $scope.markAllIncomplete(assignment, true);
      })
    }

    bootbox.confirm("Mark all students, for all closed assignments, that have not attempted the assignment, as incomplete?", function (result) {
      if (result) {
        $scope.data.working++;
        var p = [];
        
        for (var i = 0; i < $scope.data.assignments.length; i++){
          if ($scope.data.assignments[i].stop < Date.now())
            p.push(doStuff($scope.data.assignments[i]));
        }

        return Promise.all(p).then(function () {
          $scope.refresh(() => $scope.data.working--);
          alertify.success('Assignments marked incomplete');
        }).catch(function (err) {
          $scope.refresh(() => $scope.data.working--);
          console.error(err);
          alertify.error("An error occured while marking assignments incomplete");
        })
      }
    });
  }

  $scope.clearIncomplete = function (assignment, student, attempt) {
    $scope.data.working++;
    return $http({
      method: 'DELETE',
      url: $scope.data.school.database + '/api/assignments/' + $scope.data.class.idClass + '/' + attempt.fkAssignment + '/incomplete/' + attempt.idStudentAssignment,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('studentToken'),
      }
    }).then(function () {
      $scope.data.working--;
      var index = assignment.attempts.findIndex(function (x) { return x.idStudentAssignment == attempt.idStudentAssignment; });
      assignment.attempts.splice(index, 1);
      index = student.attempts.findIndex(function (x) { return x.idStudentAssignment == attempt.idStudentAssignment; });
      student.attempts.splice(index, 1);
      index = assignment.submitted.findIndex(function (x) { return x == student.idStudent; });
      assignment.submitted.splice(index, 1);
      index = assignment.graded.findIndex(function (x) { return x == student.idStudent; });
      assignment.graded.splice(index, 1);
      alertify.success("Assignment incomplete flag removed");
    })
    .catch(function (err) {
      $scope.data.working--;
      console.error(err);
      if (!silent) alertify.error("An error occured while removing incomplete flag");
    });
  }
///////////////////////////////////////

  $scope.setClassDates = function () {
    $scope.setSchoolYear($scope.data.classRange, true, function () {
      if ($scope.data.school) $scope.getClasses();
    });
  }

  $scope.setSchoolYear = function (object, classFilter, next) {
    $scope.data.showSetSchoolYear = true;
    $scope.data.showBackdrop = true
    $scope.data.schoolYear.edit = object;
    $scope.data.schoolYear.next = next;
    $scope.data.schoolYear.classFilter = classFilter;

    var date = moment(object.start);
    $scope.data.schoolYear.startMonth = date.month() + 1;
    $scope.data.schoolYear.startYear = date.year();

    date = moment(object.stop);
    $scope.data.schoolYear.stopMonth = date.month() + 1;
    $scope.data.schoolYear.stopYear = date.year();

  }

  $scope.setDates = function () {
    $scope.dismiss();

    var date = moment('1990-1-1');
    date.month($scope.data.schoolYear.startMonth - 1)
      .year($scope.data.schoolYear.startYear)
      .startOf('month');
    $scope.data.schoolYear.edit.start = date.valueOf();

    date.month($scope.data.schoolYear.stopMonth - 1)
      .year($scope.data.schoolYear.stopYear)
      .endOf('month');
    $scope.data.schoolYear.edit.stop = date.valueOf();

    if ($scope.data.schoolYear.next) $scope.data.schoolYear.next();
  }


  $rootScope.$watch('profile',function(profile){
    if(profile){
      $scope.getDistricts();
      couch.use(profile);
      exams.populate(couch);
    }
  });

  exams.ready.then(function() {
    $scope.data.exams = exams.list;
  });

  var date = moment('1990-1-1')
    .month('july')
    .year(moment().year() + ((moment().month() > 5) ? 0 : -1))
    .startOf('month');
  $scope.data.classRange.start = date.valueOf();
  date.month('june')
    .year(date.year() + 1)
    .endOf('month');
  $scope.data.classRange.stop = date.valueOf();
}
