angular.module('examgen.directive').
directive('resizeableItem', function ($timeout) {
  return {
    link: function (scope, el, attr) {
      scope.$watch(attr.ready, function (val) {
        if (val) {
          $timeout(function(){
            //scope.$emit('resizeableItemWidth', el.outerWidth());
          }, 200);
        }
      });
    }
  };
});  