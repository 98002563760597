angular.module('examgen.directive')
.directive('tokentool', function() {
  return {
    restrict: 'E',
    scope:{
      selecteduser:'=selecteduser',
    },
    templateUrl: '/app/partials/directives/tokentool.html',
    controller: 'tokenToolCtrl',
  };
})
.controller('tokenToolCtrl',function($rootScope, $scope, $window, $element, $attrs, $http, $sce, $timeout, $sce, $compile, $templateCache, $state, uuid) {

  $scope.data = {
    tokens: [],
    users: [],
    usersFiltered: [],
    shopifyProducts: [],
    selectedUser: '',
    userinfo:null,
    filteredTokens: [],
    selectedToken: undefined,
    tmpToken: undefined,
    profile: undefined,
    searchString: '',
    expiredSearch: false,
    emailList: '',
    saveLib:false,
    saveLibNext: undefined,
    search: '',
    destroyDialog:undefined,
    toggleTitle: 'Select All',
    toggleState: false,
    date_min:'',
    date_max:'',
    sortInvert:[{},{},{}]
  }

  $scope.refresh = function(next){
    $timeout(function() {
      $scope.$apply();
      if(next){
        next();
      }
    });
  }

  $rootScope.$watch('profile',function(profile){
    if(profile){
      $scope.data.profile = profile;
      $scope.getUsers();
    }
  });

  $scope.$watch('selecteduser',function(selectedUser){
    if(selectedUser){
      $scope.data.userinfo = selectedUser;
      $scope.data.selectedUser = selectedUser.UserId;
      $scope.loadTokens();
    }
  });

  $scope.$on('updateTokenList', function(event, item) {
    if($scope.data.selectedUser){
      $scope.loadTokens();
    }
  })

  $scope.dismiss = function(){
    $scope.data.showBackdrop = false;
    $scope.data.showEditToken = false;
    $scope.data.showQBSelect = false;
  }

  $scope.getUsers = function(emails){
    var url = '/api/users';
    if(emails){
      url+='?email='+emails.trim();
    }
    return $http.get(url).then(function(res) {
      console.error(res);
      $scope.data.users = res.data;
    });
  };

  $scope.loadTokens = function(){
    var url = '/api/tokens/'+$scope.data.selectedUser;
    $http.get(url)
    .then(function(res){
      $scope.data.tokens = res.data;
      for(var i = 0; i < $scope.data.tokens.length; i++){
        $scope.data.tokens[i].start = new Date($scope.data.tokens[i].start);
        $scope.data.tokens[i].stop = new Date($scope.data.tokens[i].stop);
        $scope.data.tokens[i].adminMail = $scope.data.tokens[i].admins.map(function(admin){return admin.Email}).join(', ');
      }
      $scope.tokenSort($scope.data.searchString,$scope.data.expiredSearch);
    });
  }

  $scope.loadTokenUsers = function(token,next){
    return $http.get('/api/token/'+token.idLicense+'/users').then(function(res){
      token.users = [];
      token.admins = [];
      token.invited = [];
      for(var i = 0; i < res.data.length; i++){
        if(res.data[i].active){
          token.users.push(res.data[i]);
        }
        if(res.data[i].owner || res.data[i].admin){
          token.admins.push(res.data[i]);
        }
      }
      return $http.get('/api/token/'+token.idLicense+'/invites')
    }).then(function(res){
      token.invited = res.data;
      $scope.refresh(next);
    }).catch(function(err){
      $scope.refresh(next);
    });
  }

  $scope.sortUser = function(token,key){
    $scope.refresh(()=>{
      if($scope.data.sortInvert[0][key])
        token.users = token.users.sort((a,b)=>a[key].localeCompare?a[key].localeCompare(b[key]):a[key]-b[key]);
      else
        token.users = token.users.sort((a,b)=>a[key].localeCompare?b[key].localeCompare(a[key]):b[key]-a[key]);
      $scope.data.sortInvert[0][key] = !$scope.data.sortInvert[0][key];
    });
  }
  $scope.sortAdmins = function(token,key){
    $scope.refresh(()=>{
      if($scope.data.sortInvert[0][key])
        token.admins = token.admins.sort((a,b)=>a[key].localeCompare?a[key].localeCompare(b[key]):a[key]-b[key]);
      else
        token.admins = token.admins.sort((a,b)=>a[key].localeCompare?b[key].localeCompare(a[key]):b[key]-a[key]);
      $scope.data.sortInvert[0][key] = !$scope.data.sortInvert[0][key];
    });
  }
  $scope.sortInvited = function(token,key){
    $scope.refresh(()=>{
      if($scope.data.sortInvert[0][key])
        token.invited = token.invited.sort((a,b)=>a[key].localeCompare?a[key].localeCompare(b[key]):a[key]-b[key]);
      else
        token.invited = token.invited.sort((a,b)=>a[key].localeCompare?b[key].localeCompare(a[key]):b[key]-a[key]);
      $scope.data.sortInvert[0][key] = !$scope.data.sortInvert[0][key];
    });
  }

  $scope.tokenSort = function(searchstr,expired){
    $scope.data.searchString = searchstr;
    $scope.data.expiredSearch = expired;

    $scope.data.filteredTokens = [];

    for(var t =0; t < $scope.data.tokens.length; t++){
      var flag = false;
      if(searchstr.length > 0){
        if($scope.data.tokens[t].name.includes(searchstr)){
          flag = true;
        }
      } else {
        flag = true;
      }

      if(flag){
        if((expired && $scope.data.tokens[t].expired <= 0)||!expired){
          flag = true;
        }else{
          flag = false;
        }
      }

      if(flag){
        $scope.data.filteredTokens.push($scope.data.tokens[t]);
      }
    }

    $scope.refresh();
  }

  $scope.filterTokens = function(){
    var form = $('<div style="margin:0; text-align:center;"></div>');

    form.append('<input id="search_txt" type="text" value="'+$scope.data.searchString+'"/>');
    form.append('<br>');
    form.append('Only Expired Tokens <input id="expired_cb" type="checkbox" style="vertical-align: top;"/>');

    form.children('#expired_cb').prop('checked',$scope.data.expiredSearch);

    var dlg = bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        var searchstr = form.children('#search_txt').val();
        var expired = form.children('#expired_cb').prop('checked');

        $scope.tokenSort(searchstr,expired);
      }
    }, {
      'label' : 'Cancel',
      'class' : 'btn-primary',
    }],{
      // prompts need a few extra options
      'header'  : 'Search Tokens',
    });

  }

  $scope.activateToken = function(){
    var form = $('<div style="margin:0; text-align:center;"></div>');

    form.append('<span>Please enter the code for the token that you wish to activate</span><br>');
    form.append('<input id="token_txt" type="text"/>');

    var dlg = bootbox.dialog(form, [{
      'label' : 'Ok',
      'class' : 'btn-primary',
      'callback': function() {
        var tokenCode = form.children('#token_txt').val();
        var flag = false;
        $scope.preformTokenActivation($scope.data.selectedUser,tokenCode,$scope.data.tokens);
      },
    }, {
      'label' : 'Cancel',
      'class' : 'btn-primary',
    }],{
      // prompts need a few extra options
      'header'  : 'Activate Token',
    });
  }

  $scope.preformTokenActivation = function(user,tokenCode,tokenList,noPrompt){
    var flag = false;
    for(var t = 0; tokenList.length > t; t++){
      if(tokenList[t].Code == tokenCode && tokenList[t].active){
        flag = true;
      }
    }
    var p = Promise.resolve();
    if(!flag){
      var url = '/api/token/code/'+tokenCode;
      p = $http.get(url).then(function(res){
        var p2 = Promise.resolve();
        if(res.data.error){
          alertify.error("No Tokens found with that code");
        } else if(res.data.userCount >= res.data.maxUsers) {
          alertify.error("All available users have been assigned");
        } else {
          var token = res.data;
          token.UserId = user;
          token.active = 1;
          token.admin = 0;
          token.owner = 0;
          token.userProducts = [];
          for(var t = 0; t < tokenList.length; t++){
            if(token.idLicense == tokenList[t].idLicense){
              token.active = 1;
              token.admin = tokenList[t].admin;
              token.owner = tokenList[t].owner;
            }
          }
          for(var p = 0; p < token.products.length; p++){
            token.userProducts.push({
              active:1,
              fkProduct: token.products[p].fkProduct,
            });
          }

          if(token.userProducts.length > 1 && !noPrompt){
            $scope.manageLibs(token);
          }
          p2 = $scope.saveToken(token).then(function(){
            return $http.put('/api/token/activated',{token:token});
          });
        }
        return p2;
      }).catch(function(err){
        alertify.error('Token Failed to Activate');
        console.error(err);
      });
    } else {
      alertify.error("You have already activated this token");
    }
    return p;
  }

  $scope.editToken = function(token){
    $scope.data.selectedToken = token;
    $scope.data.selectedToken.users = [];
    // alertify.tokenDialog || alertify.dialog('tokenDialog',function factory(){
    //   return{
    //     main:function(message){
    //       this.message = message;
    //       var template = $templateCache.get('tokenDialog');
    //       this.setContent(template);
    //       this.setHeader('License Information');
    //     },
    //     setup:function(){
    //         return {
    //           options:{
    //             maximizable:false,
    //             resizable:false,
    //             padding:'2px',
    //             closableByDimmer:false,
    //             transition: 'slide',
    //           },
    //           buttons:[
    //             {
    //               text: 'OK',
    //               className: 'btn btn-primary',
    //             },
    //           ],
    //         };
    //     },
    //     prepare:function(){
    //     },
    //     hooks:{
    //       // triggered when the dialog is shown, this is seperate from user defined onshow
    //       onshow: function(){
    //         var template = angular.element(document.querySelector('.ajs-modal'));
    //         $compile(template)($scope);
    //       },
    //       onclose: function(){
    //         $scope.refresh(function(){
    //           $scope.data.destroyDialog = 'tokenDialog';
    //         });
    //       },
    //     },
    //     callback:function(e){
    //       $scope.loadTokens();
    //       //The closeEvent has the following properties
    //       //
    //       // index: The index of the button triggering the event.
    //       // button: The button definition object.
    //       // cancel: When set true, prevent the dialog from closing.
    //       $scope.refresh(function(){
    //         $scope.data.destroyDialog = 'tokenDialog';
    //       });
    //    },
    // }});

    $scope.data.showBackdrop = true;
    $scope.data.showEditToken = true;
    $scope.loadTokenUsers(token);
  }

  $scope.revokeUser = function(token, user) {
    var url = '/api/token/'+token.idLicense+'/users/'+(user.fkUser||user.UserId||user);
    if(user == $scope.data.selectedUser){
      if(token.owner || token.admin){
        url+='?owner=true';
      }
    } else if(user.owner || user.admin){
      url+='?owner=true';
    }
    $http.delete(url).then(function(res){
      alertify.success('User token revoked');
      $scope.loadTokenUsers(token);
      $scope.loadTokens();
    },function(err){
      console.error(err);
      alertify.error('Failed to revoke user token');
    });
  }

  $scope.revokeAdmin = function(token, user) {
    if(user.owner || user.active){
      var url = '/api/token/'+token.idLicense+'/users/'+(user.fkUser||user.UserId||user);
      $http.put(url, {
        fkUser:user.fkUser||user.UserId||user,
        idLicense:token.idLicense,
        owner:user.owner,
        admin:0,
        active:user.active,
      }).then(function(res){
        alertify.success('Admin revoked');
        $scope.loadTokenUsers(token);
        $scope.loadTokens();
      },function(err){
        console.error(err);
        alertify.error('Failed to revoke user token');
      });
    } else {
      var url = '/api/token/'+token.idLicense+'/users/'+(user.fkUser||user.UserId||user);
      $http.delete(url).then(function(res){
        alertify.success('Admin revoked');
        $scope.loadTokenUsers(token);
        $scope.loadTokens();
      },function(err){
        console.error(err);
        alertify.error('Failed to revoke user token');
      });
    }
  }

  $scope.revokeOwner = function(token, user) {
    if(user.admin || user.active){
      var url = '/api/token/'+token.idLicense+'/users/'+(user.fkUser||user.UserId||user);
      $http.put(url, {
        fkUser:user.fkUser||user.UserId||user,
        idLicense:token.idLicense,
        owner:0,
        admin:user.admin,
        active:user.active,
      }).then(function(res){
        alertify.success('Owner revoked');
        $scope.loadTokenUsers(token);
        $scope.loadTokens();
      },function(err){
        console.error(err);
        alertify.error('Failed to revoke user token');
      });
    } else {
      var url = '/api/token/'+token.idLicense+'/users/'+(user.fkUser||user.UserId||user);
      $http.delete(url).then(function(res){
        alertify.success('Owner revoked');
        $scope.loadTokenUsers(token);
        $scope.loadTokens();
      },function(err){
        console.error(err);
        alertify.error('Failed to revoke user token');
      });
    }
  }

  $scope.addAdmin = function(token){
    bootbox.prompt("Enter new Admin's username:", function(result){
      if(result){
        $http.get('/api/user/'+result).then(function(res){
          bootbox.confirm('Are you sure you want to add '+res.data.LastName+', '+ res.data.FirstName+' ('+res.data.UserId+') as an Admin?',
          function(conf){
            if(conf){
              for(var i = 0; i < token.users.length; i++){
                if(token.users[i].UserId == res.data.UserId){
                  res.data.active = token.users[i].active;
                }
              }
              for(var i = 0; i < token.admins.length; i++){
                if(token.admins[i].UserId == res.data.UserId){
                  res.data.owner = token.admins[i].owner;
                }
              }
              var url = '/api/token/'+token.idLicense+'/users/'+res.data.UserId;
              $http.put(url, {
                fkUser:res.data.UserId,
                idLicense:token.idLicense,
                owner:res.data.owner || 0,
                admin:1,
                active:res.data.active || 0,
              }).then(function(res){
                alertify.success('Admin added');
                $scope.loadTokenUsers(token);
                $scope.loadTokens();
              },function(err){
                console.error(err);
                alertify.error('Failed add admin');
              });
            }
          });
        }).catch(function(err){
          alertify.error('User not found');
          console.error(err);
        });
      }
    });
  }

  $scope.addOwner = function(token){
    bootbox.prompt("Enter new Owner's username:", function(result){
      if(result){
        $http.get('/api/user/'+result).then(function(res){
          bootbox.confirm('Are you sure you want to add '+res.data.LastName+', '+ res.data.FirstName+' ('+res.data.UserId+') as an Owner?',
          function(conf){
            if(conf){
              for(var i = 0; i < token.users.length; i++){
                if(token.users[i].UserId == res.data.UserId){
                  res.data.active = token.users[i].active;
                }
              }
              for(var i = 0; i < token.admins.length; i++){
                if(token.admins[i].UserId == res.data.UserId){
                  res.data.admin = token.admins[i].admin;
                }
              }
              var url = '/api/token/'+token.idLicense+'/users/'+res.data.UserId;
              $http.put(url, {
                fkUser:res.data.UserId,
                idLicense:token.idLicense,
                owner:1,
                admin:res.data.admin || 0,
                active:res.data.active || 0,
              }).then(function(res){
                alertify.success('Owner added');
                $scope.loadTokenUsers(token);
                $scope.loadTokens();
              },function(err){
                console.error(err);
                alertify.error('Failed add Owner');
              });
            }
          });
        }).catch(function(err){
          alertify.error('User not found');
          console.error(err);
        });
      }
    });
  }

  $scope.toggleAll = function(state){
    for(var i = 0; i < $scope.data.selectedToken.userProducts.length; i++){
      $scope.data.selectedToken.userProducts[i].active = state ? 1 : 0;
    }
  }

  $scope.saveToken = function(token,noReload){
    return $http.put('/api/token/'+token.idLicense+'/users/'+token.UserId,token)
    .then(function(res){
      alertify.success('Token Updated');
      if(!noReload){
        $scope.loadTokens();
      }
    }).catch(function(err){
      alertify.error('Token Failed to Update');
      console.error(err);
    });
  }

  $scope.manageLibs = function(token){
    // $scope.showLibs(token,function(){
    //   $scope.saveToken(token);
    // });
    $scope.data.selectedToken = token;
    $scope.data.showBackdrop = true;
    $scope.data.showQBSelect = true;
  }

  $scope.$watch('data.destroyDialog',function(dialog){
    if(dialog){
      switch (dialog) {
        case 'licenseDialog':
          if (alertify.licenseDialog()){
            alertify.licenseDialog().destroy();
          }
          break;
        case 'libDialog':
          if (alertify.libDialog()){
            alertify.libDialog().destroy();
          }
          break;
        case 'tokenDialog':
          if (alertify.tokenDialog()){
            alertify.tokenDialog().destroy();
          }
          break;
      }
      $scope.data.destroyDialog = undefined;
    }
  });

  $scope.$watch('data.saveLib',function(saveLib){
    if(saveLib && $scope.data.selectedToken){
      $scope.data.saveLibNext();
    }
  });

  $scope.reinviteUser = function(user){
    $scope.data.emailList = user.email;
    $scope.inviteUsers();
  }
  $scope.reinviteAllUser = function(){
    $scope.data.emailList = $scope.data.selectedToken.invited.map((u)=> u.email).join(',');
    $scope.inviteUsers();
  }

  $scope.removeInvitedUser = function(user){
    return $http.delete('/api/token/'+$scope.data.selectedToken.idLicense+'/invites/'+user.email)
    .then(function(){
      alertify.success('Invited User Removed');
      $scope.loadTokenUsers($scope.data.selectedToken);
    })
  }
  $scope.removeAllInvitedUser = function(){
    Promise.all($scope.data.selectedToken.invited.map((u)=>$http.delete('/api/token/'+$scope.data.selectedToken.idLicense+'/invites/'+u.email)))
      .then(function(){
        alertify.success('Invited Users Removed');
        $scope.loadTokenUsers($scope.data.selectedToken);
      });
  }

  $scope.inviteUsers = function(){
    if($scope.data.emailList.length > 0){
      $scope.data.working = true;
      var emailStrings = $scope.data.emailList.replace(/,/g,'\n').split('\n');
      var emails = [];

      if(emailStrings.length > 0){
        for(var e = 0; e < emailStrings.length; e++){
          //emailStrings[e] = emailStrings[e].replace(/\x20/g,'').replace(/'/g,'').replace(/"/g,'').replace(/\t/g,'');
          if(emailStrings[e].includes(';')){
            emailStrings.push(...emailStrings[e].split(';'));
            emailStrings.splice(e,1); e--;
          } else if (emailStrings[e].includes('<')) {
            emailStrings.push(emailStrings[e].split('<')[1].split('>')[0]);
            emailStrings.splice(e, 1); e--;
          } else 
            emailStrings[e] = emailStrings[e].replace(/\x20/g,'').replace(/"/g,'').replace(/\t/g,'');
        }
        var re = /[^\s@]+@[\w\d-]+(?:\.[\w\d]+)+/;
        for(var e = 0; e < emailStrings.length; e++){
          var regextest = re.exec(emailStrings[e]);
          if (emailStrings[e].length > 8 && regextest.length == 1 && regextest[0] == emailStrings[e]){
            emails.push(emailStrings[e]);
          } else {
            alertify.error(emailStrings[e]+' is not a valid Email Address');
          }
        }
      }

      var addedUsers = 0;

      if(emails.length > 0){
        var filteredEmails = []
        for(var e = 0; e < emails.length; e++){
          var foundUser = false;
          for(var u = 0; u < $scope.data.selectedToken.users.length; u++){
            if($scope.data.selectedToken.users[u].Email.toLowerCase() == emails[e].toLowerCase()){
              foundUser = true;
            }
          }
          if(!foundUser){
            filteredEmails.push(emails[e]);
          } else {
            addedUsers++;
            alertify.success(emails[e]+ ' is already assigned');
          }
        }
        emails = filteredEmails;
      }

      var gup = Promise.resolve();
      if(emails.length > 0)
        gup = $scope.getUsers(emails.join(','))

      gup.then(function(){
        var ps = [];
        var filteredEmails = []
        for(var e = 0; e < emails.length; e++){
          var foundUser = false;

          for(var u = 0; u < $scope.data.users.length; u++){
            if($scope.data.users[u].Email.toLowerCase() == emails[e].toLowerCase()){
              function activate(index){
                return new Promise(function(resolve, reject) {
                  $http.get('/api/tokens/'+$scope.data.users[index].UserId)
                    .then(function(res){
                      return $scope.preformTokenActivation($scope.data.users[index].UserId,$scope.data.selectedToken.Code,res.data,true);
                    }).then(function(res){
                      resolve();
                    }).catch(function(e){
                      reject(e);
                    })
                });
              }
              ps.push(activate(u));
              foundUser = true;
            }
          }

          if(!foundUser){
            filteredEmails.push(emails[e]);
          } else {
            addedUsers++;
          }
        }
        emails = filteredEmails;
        return Promise.all(ps);
      }).then(()=>{
        var p = Promise.resolve();
        if(emails.length > 0){
          p = $http.post('/shopify/invite',{
            to:emails.join(','),
            idLicense: $scope.data.selectedToken.idLicense,
            fields:{
              code:$scope.data.selectedToken.Code,
              product:$scope.data.selectedToken.name,
            }
          }).then(function(res){
            $scope.data.emailList = '';
            alertify.success('Invitations Sent');
          })
          //send emails
        }else{
          if(addedUsers == 0){
            alertify.error('Please enter at least 1 valid Email Address');
          }
        }
        $scope.data.emailList = '';
        return p;
      }).then(()=>{
        $scope.data.working = false;

        $scope.loadTokenUsers($scope.data.selectedToken);
      }).catch(function(error){
        $scope.data.working = false;

        $scope.loadTokenUsers($scope.data.selectedToken);
        alertify.error('An error occured while sending invitations');
      })

    }else{
      alertify.error('Please enter at least 1 valid Email Address');
    }
  }

  $scope.updateTokenInfo = function(){
    if($scope.data.selectedToken){
      var url = '/api/tokens/'+$scope.data.profile.UserId;
      $http.put(url,$scope.data.selectedToken)
      .then(function(res){
        return $http.put('/api/token/products/update',[$scope.data.selectedToken.idLicense])
      })
      .then(function(res){
        alertify.success('Token Updated');
        console.error(res);
      }).catch(function(err){
        alertify.error('Token Failed to Update');
        console.error(err);
      });
    }
  }

  $scope.toDate= function(ms){
    var d;
    if(ms.toLocaleDateString){
      d = ms
     } else {
      var d = new Date(ms);
    }
    return (d.getMonth()+1)+'/'+(d.getDate())+'/'+ (d.getFullYear())
  }

  $scope.toClip = function(text){
    var el = document.createElement('input');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    if(document.execCommand('copy')){
      alertify.success('Code copied to clipboard');
      document.body.removeChild(el);
    } else {
      document.body.removeChild(el);
      var span = document.createElement('div');
      span.style.textAlign = 'center';
      var p = document.createElement('p');
      p.innerHTML='An error occured attempting to copy the code to the clipboard'
      span.appendChild(p);
      span.appendChild(el);
      bootbox.alert(span);
    }
  }
});
