(function() {
  var introjsSteps = {
    composer: {
      databaseMenu: [1, '<strong>Database Menu.</strong> This pull-down menu is used to select a Database. All available Databases are listed.'],
      chapterMenu: [2, '<strong>Chapter Menu.</strong> This pull-down menu displays a list of chapters in the active Database.'],
      chapterTopics: [3, '<strong>Chapter Topics.</strong> All of the topics in the active Chapter are displayed in the Chapter Contents. Clicking on a topic displays all of the questions that pertain to that topic in the Question Grid. You can drag entire topics into the Exam Tree.'],
      standards: [4, '<strong>Standards.</strong> If relevant standards are available for your Database, you can view them by clicking on the Standards pull-down menu. Selecting a standard will display all questions from the database that pertain to that standard.'],

      questionGrid: [5, '<strong>Question Grid.</strong> The numbered boxes display the question numbers in the active database, chapter and topic. Double-clicking on any one of these items inserts that question in to the Exam Tree. You can also drag and drop items from the Question Grid into the Exam Tree.'],
      browserWindow: [6, '<strong>Browser Window.</strong> The Browser Window is used to view Question Items on the screen. The Browser Window can display questions from the current Database or from the Exam Tree.'],
      insertButton: [7, '<strong>Insert Button.</strong> Click to add the selected Question Item(s) in the Question Grid to the Exam Tree. If multiple questions are selected, the Insert Questions Dialog Box opens. You have the option to add some or all of the selected questions in random (default) or numerical order. There are also navigation buttons on either side of the Insert button to step through questions in the question grid.'],
      examTree: [8, '<strong>Exam Tree.</strong> The Exam Tree displays the question items that you have selected for your exam. The Exam Tree shows the chapter and question number and allows you to reorder questions if you want. Clicking on question items in the Exam Tree will make the question appear in the Browser Window.'],
      examSections: [9, '<strong>Exam Sections.</strong> You can add new sections to your exam by clicking the plus sign (+) to the left of the section name. Double click the section name or click the gear icon to the right to edit the section\'s name, formatting, and additional instructions. This feature is especially helpful for organizing longer exams. The trash can icon deletes that exam section.'],

      printExam: [10, '<strong>Print.</strong> The Print button opens a series of dialog boxes that allow you to format your exam, select the number of versions you would like to create, and preview your exam before printing it.'],

      saveExam: [11, '<strong>Save.</strong> The Save button opens the Save dialog box where you can either save (overwrite) your current exam, or Save As, by renaming the exam.'],
      openExam: [12, '<strong>Open.</strong> The Open button opens a dialog box with all of your saved exams. From here you can open or delete any of your exams.'],
      newExam: [13, '<strong>New.</strong> The New button creates a new exam with no questions in it. If you are currently working on an exam and haven\'t saved it, you will be prompted to save your exam.'],
      home: [14, '<strong>Home.</strong> The Home button returns you to the Examgen Online web application home page.'],
      search: [15, '<strong>Search.</strong> The Search button allows you to search you Question banks for content.'],
    },

    home: {
      help: [1, '<strong>Help</strong> The Help button displays two kinds of help options, a Guided Tour, which you are currently using, or Complete Help, which displays the User Guide along with other help resources. The Guided Tour option is also available in the Exam Composer to describe the available features.'],
      feedback: [2, '<strong>Feedback</strong> The Feedback button opens a dialog box that allows you to quickly send a note to the Examgen team. You can use this to obtain individual help or to send feedback on the application or question bank.'],
      signout: [3, '<strong>Sign Out</strong> The Sign Out button logs you off the application. Use this to end your active session (recommended for security / academic integrity reasons).'],
      createExam: [4, '<strong>Create Exam</strong> The Create Exam button opens the Exam Composer, from which you can create, save, open, and edit exams.'],
      activeProducts: [5, '<strong>Active Products</strong> The Active Products box lists the question banks that you have access time, as well as their respective dates of expiration.'],
      billboard: [6, '<strong>Billboard</strong> The Billboard box provides important information regarding application features, question bank updates, helpful hints, and links to other useful resources.'],
      userGuide: [7, '<strong>User Guide</strong> The User Guide provides quick access to the complete, detailed description of Examgen Online\'s features.']
    }
  };

  angular.module('examgen.directive').
  directive('introjs', function($state, $rootScope, $analytics, $window) {
    return function(scope, el, attrs) {
      var introjs = attrs.introjs.split(',');
      var type = introjs[0];
      var step = introjs[1];

      // verify the introjs rule is valid
      if (!introjsSteps[type]) {
        throw "IntroJS type " + type + " is not recognized";
      }

      if (!introjsSteps[type][step]) {
        throw "InstroJS step not found for type " + type + " with idx " + step;
      }

      function updateToStateName(name) {
        var location;
        if (name === 'home') {
          location = 'home';
        } else if (name.indexOf('exam') === 0) {
          location = 'composer';
        } else {
          location = 'unknonw';
        }

        // introjs rule matches?
        if (location == type) {

          el.attr('data-step', introjsSteps[type][step][0])
            .attr('data-intro', introjsSteps[type][step][1]);

          if (introjs[2] || introjsSteps[type][step][2]) {
            el.attr('data-position', introjs[2] || introjsSteps[type][step][2]);
          }
        } else {
          el.removeAttr('data-step')
            .removeAttr('data-intro')
            .removeAttr('data-position');
        }

        $rootScope.introJsType = name;
      }

      // update with initial state
      if ($state.current) {
        updateToStateName($state.current.name);
      }

      // watch for the state change and either bind or unbind introjs based on it
      scope.$on('$destroy', $rootScope.$on('$stateChangeSuccess', function(event, toState) {
        updateToStateName(toState.name);
      }));
    };
  }).directive('guidedTour', function ($analytics, $window, $rootScope) {
    return {
      scope: 'A',
      link: function($scope, $element) {
        $element.on('click', function(ev) {
          ev.preventDefault();
          var intro = $window.introJs();
          var event = function(k) {
            var name = $rootScope.introJsType + ' guided tour - ' + k;
            $analytics.eventTrack(name);
          };

          intro.onexit(function() {
            console.log('on exit', $rootScope.introJsType, intro);
            event('hide');
          });

          event('show');
          intro.onchange(function() {
            event('step ' + intro._currentStep);
          });
          intro.start();
        });
      }
    }
  });
})();
