angular.module('examgen.directive').
/**
 * @ngdoc directive
 * @name examgen.directive:tocSection
 * @description Represents the section in the table of contents
 */
directive('tocSection',function(tabIndex){
  return function(scope, el, attr) {
    var section = scope.section;

    // prevent from dragging while questions are loading
    scope.$watch('loading', function(isLoading) {
      el.attr('draggable', !isLoading);
    });

    tabIndex.section(section).then(function(index){
      //console.log('section index',index);
      el.attr('tabindex',index);
    });
  };
});