angular.module('examgen.service').
/**
 * @ngdoc service
 * @name examgen.service:importExport
 * @description Provides helper function to load passages and items from a given path. Used to load content from serialized exams.
 */
service('importExport',function($q,$http,lazyLoad,databases){
  var _cache = {},
    _alerted = {};

  function mysqlLoadItemFromPath(path,silent){
    return $http.get(path).then(function(response){
      fullpath = response.data;
      return loadItemFromPath(fullpath,silent);
    },function(error){
      console.error(err);
      throw new Error('Malformed path: Unable to load item path (' + path + ')');
    });
  }

  function mysqlLoadItemListFromPath(paths,silent){
    var ids = paths.map(function(path){
      return path.split('/')[3];
    })

    var publicIDs = [], userIDs = [];

    var bits;
    for(var i = 0; i < ids.length; i++){
      bits = ids[i].split('?');
      if(bits[1] == 'userDB=true'){
        userIDs.push(bits[0]);
      } else {
        publicIDs.push(bits[0]);
      }
    }

    var allpaths;

    var p;
    if(publicIDs.length > 0){
      p = $http.put('/api/questionPath/list'+'?userDB=false',{ids:publicIDs})
    } else {
      p = Promise.resolve({data:[]});
    }
    return p.then(function(publicPool){
      allpaths = publicPool.data;
      if(userIDs.length > 0){
        return $http.put('/api/questionPath/list'+'?userDB=true',{ids:userIDs})
      } else {
        return {data:[]};
      }
    })
    .then(function(userPool){
      allpaths = allpaths.concat(userPool.data);
      return $q.all(allpaths.map(function(p){
        return loadItemFromPath(p,silent);
      })).then(function(items){
        return lazyLoad.itemList(items);
      });
    })
    .catch(function(error){
      console.error(error);
      alertify.error('An error occurred while loading');
      throw new Error('Malformed path: Unable to load item path (' + paths + ')');
    });
  }

  function loadItemFromPath(path, silent){
    // var needsFixing = false;
    // // for some reason, it might be an absolute url
    // if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0) {
    //   needsFixing = true;
    //   var parser = document.createElement('a');
    //   parser.href = path;
    //   path = decodeURIComponent(parser.pathname);
    // }

    // comparison helper
    function fix(a) {
      if (_cache[a]) {
        return _cache[a];
      }
      var gen = $.trim(a.replace(/[^0-9a-z]+/ig, '').toLowerCase());
      return _cache[a] = gen;
    }
    function compare(a, b) {
      a = fix(a);
      if (typeof b === "object") {
        for (var i=0; i<b.length; i++) {
          if (compare(a, b[i])) {
            return true;
          }
        }
        return false;
      } else {
        return a === fix(b);
      }
    }
    function freakout (error) {
      if (!_alerted[error.message]) {
        _alerted[error.message] = 1;
        alert(silent + ": " + error.message);
      }

      return $q.reject();
    }

    var components = path.split('/');
    // if (components[0] === 'examgen') {
    //   components.unshift('');
    // }
    var dbToLoad = components[0],
      chapterToLoad = components[1],
      topicToLoad = components[2],
      sectionToLoad = components[3],
      partToLoad = components[4],
      itemToLoad = components[5];

    //if(itemToLoad) itemToLoad = parseInt(itemToLoad.replace('.json',''),10);

    var toReturn;

    //console.log('loadItemFromPath', components);

    if(dbToLoad){
      //console.log('loading database',dbToLoad);
      toReturn = databases.all.then(function(resolvedDatabases){
        var dbFromPath = resolvedDatabases.filter(function(db){
          return db.databaseId === dbToLoad;
        })[0];

        if(!dbFromPath){
          throw new Error('Malformed path: Unable to find database ' + dbToLoad + '(' + path + ')');
        }

        return lazyLoad.database(dbFromPath);
      });

    }

    if(chapterToLoad){
      //console.log('loading chapter',chapterToLoad);
      toReturn = toReturn.then(function(db){
        return db.chapters;
      }).then(function(chaptersFromDatabase){
        var chapterFromPath = chaptersFromDatabase.filter(function(chapter){
          // return compare(chapterToLoad, [chapter.chapterName, chapter.chapterName.replace(/^chapter\s*\d+/i, '')])
          //   || compare(chapter.chapterName, chapterToLoad.replace(/^chapter\s+/i, ''));
          return chapterToLoad === chapter.chapterId;
        })[0];

        if(!chapterFromPath) {
          return freakout(new Error('Malformed path: Unable to find chapter ' + chapterToLoad));
        }
        return lazyLoad.chapter(chapterFromPath);
      });
    }

    if(topicToLoad){
      //console.log('loading topic',topicToLoad);
      toReturn = toReturn.then(function(topicsFromChapter){
        var topicFromPath = topicsFromChapter.filter(function(topic){4
          // return compare(topicToLoad, [topic.topicName, topic.topicName.replace(/^topic\s*\w+/i, '')])
          //     || compare(topic.topicName, topicToLoad.replace(/^topic\s*\w+/i, ''));
          return topicToLoad === topic.topicId;
        })[0];

        if(!topicFromPath) {
          return freakout(new Error('Malformed path: Unable to find topic ' + topicToLoad));
        }
        return lazyLoad.topic(topicFromPath);
      });
    }

    if(sectionToLoad){
      //console.log('loading section',sectionToLoad);
      toReturn = toReturn.then(function(sectionsFromTopic){
        var sectionFromPath = sectionsFromTopic.filter(function(section){
          // return compare(sectionToLoad, [section.sectionName, section.sectionName.replace(/^part\s*[\w|\d]+/i, '').replace(/\[(.+)\]+$/, '')])
          //     || compare(section.sectionName, sectionToLoad.replace(/^part\s*[\w|\d]+/i, '').replace(/\[(.+)\]+$/, ''));
          return sectionToLoad === section.sectionId;
        })[0];

        if(!sectionFromPath) {
          return freakout(Error('Malformed path: Unable to find section ' + sectionToLoad));
        }
        return lazyLoad.section(sectionFromPath);
      });
    }

    if(partToLoad){
      //console.log('loading section',sectionToLoad);
      toReturn = toReturn.then(function(partsFromSection){
        var partFromPath = partsFromSection.filter(function(part){
          // return compare(sectionToLoad, [section.sectionName, section.sectionName.replace(/^part\s*[\w|\d]+/i, '').replace(/\[(.+)\]+$/, '')])
          //     || compare(section.sectionName, sectionToLoad.replace(/^part\s*[\w|\d]+/i, '').replace(/\[(.+)\]+$/, ''));
          return partToLoad === part.partId;
        })[0];

        if(!partFromPath) {
          return freakout(Error('Malformed path: Unable to find section ' + partToLoad));
        }
        return lazyLoad.part(partFromPath);
      });
    }

    if(itemToLoad){
      //console.log('loading item',itemToLoad);
      toReturn = toReturn.then(function(itemsFromPart){
        var itemFromPath = itemsFromPart.filter(function(item){
          return item.itemId === itemToLoad;
        })[0];

        if(!itemFromPath) {
          freakout(new Error('Malformed path: Unable to find item ' + itemToLoad));
        }
        //return lazyLoad.item(itemFromPath);
        return Promise.resolve(itemFromPath);
      });
    }

    return toReturn;
  }

  function mysqlloadPassageFromPath(path){
    return $http.get(path).then(function(response){
      fullpath = response.data;
      return loadPassageFromPath(fullpath);
    },function(error){
      throw new Error('Malformed path: Unable to load passage path (' + path + ')');
    });
  }

  function loadPassageFromPath(path){
    var components = path.split('/'),
      dbToLoad = components[0],
      passageToLoad = components[1];

    var toReturn;

    if(dbToLoad){
      //console.log('loading database',dbToLoad);
      toReturn = databases.all.then(function(resolvedDatabases){
        var dbFromPath = resolvedDatabases.filter(function(db){
          return db.databaseId === dbToLoad;
        })[0];

        if(!dbFromPath) throw new Error('Malformed path: Unable to find database ' + dbToLoad);

        return lazyLoad.database(dbFromPath);
      });

    }

    if(passageToLoad){
      //console.log('loading chapter',passageToLoad);

      toReturn = toReturn.then(function(db){
        return db.passages;
      }).then(function(passagesFromDatabase){
        var passageFromPath = passagesFromDatabase.filter(function(passage){
          return passage.passageId === passageToLoad;
        })[0];

        if(!passageFromPath) throw new Error('Malformed path: Unable to find passage ' + passageToLoad);
        return lazyLoad.passage(passageFromPath);
      });
    }

    return toReturn;
  }

  return {
    loadItemFromPath : mysqlLoadItemFromPath,        //TODO: rename to loadItemFromPath
    loadItemListFromPath : mysqlLoadItemListFromPath,
    loadPassageFromPath : mysqlloadPassageFromPath
  };
});
