//we define our app "examgen" here
var app = angular.module('examgen',
            ['ui.tree','ui.router', 'ngRoute','angularMoment',
                'ngResource','ngCookies',
                'angular-bugsnag',
                'ui.event','ui.bootstrap',
                'angular-md5',
                'LocalStorageModule',
                'examgen.layout',
                'examgen.service',
                'examgen.constructors',
                'examgen.directive',
                'examgen.controller',
                'angulartics',
                'angulartics.google.analytics',
                'angulartics.mixpanel',
                'vs-repeat',
                'ngSanitize',
                ]);

angular.module('examgen.service', ['examgen.constructors']);
angular.module('examgen.directive', []);
angular.module('examgen.layout', []);
angular.module('examgen.controller', []);

app.filter('prettyDateTime', function() {
  return function(date) {
    return moment(date).calendar(null, { sameElse: 'MM/DD/YYYY, h:mm:ss a'});
  };
});

app.config(function($stateProvider, $urlRouterProvider, $httpProvider, $dialogProvider,
    $compileProvider, bugsnagProvider, $sceProvider, localStorageServiceProvider,
    $analyticsProvider) {
    $analyticsProvider.virtualPageviews(false);
    $stateProvider
        .state('home', {
            url: '/',
            templateUrl: '/app/partials/controllers/home.html',
            controller: 'HomeCtrl as homeCtrl',
            resolve: {
                profile: function(session) {
                    return session.onProfile();
                }
            }
        })
        .state('exams', {
            url: '/exams',
            template: '<div>Loading exam ...</div>',
            controller: ExamsRedirectController
        })
        .state('exam', {
            url: '/exam/:id',
            params: {
                id: ''
            },
            templateUrl: '/app/partials/controllers/composer.html',
            controller: ComposerController,
            controllerAs: 'composerCtrl',
        })
        .state('questions', {
            url: '/questions',
            templateUrl: '/app/partials/controllers/questions.html',
            controller: QuestionsController,
            controllerAs: 'QuestionsCtrl',
        })
        .state('manage/students', {
            url: '/manage/students',
            templateUrl: '/app/partials/controllers/studentManagement.html',
            controller: studentManagementController,
            controllerAs: 'studentManagmentCtrl',
        })
        .state('manage/assignments', {
            url: '/manage/assignments',
            templateUrl: '/app/partials/controllers/examManagement.html',
            controller: examManagementController,
            controllerAs: 'examManagementCtrl',
        })
        .state('grade/assignments', {
            url: '/grade/assignments?cl&sa',
            templateUrl: '/app/partials/controllers/examReview.html',
            controller: examReviewController,
            controllerAs: 'examReviewController',
        })
        .state('account', {
            url: '/account',
            templateUrl: '/app/partials/controllers/account.html',
            controller: AccountController,
            controllerAs: 'accountCtrl',
        })
        .state('reports', {
            url: '/reports',
            templateUrl: '/app/partials/controllers/reports.html',
            controller: ReportsController,
            controllerAs: 'ReportsCtrl',
        })
        .state('logout', {
            url: '/logout',
            template: '<div></div>',
            resolve: {
                dummy: function($q) {
                    // return a never-resolved promise so the route isn't processed
                    // and the browser redirects to the real logout page in the mean time
                    location.href = '/logout';
                    return $q.defer().promise;
                }
            },
            controller: function() {}
        });

    $urlRouterProvider.otherwise('/');
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|data):/);
    localStorageServiceProvider.setPrefix('examgen');

    console.log('Env:', window.ENV, window.VERSION);
    bugsnagProvider
        .apiKey(window.BUGSNAG_APIKEY)
        .releaseStage(window.ENV)
        .metaData({configured: false})
        .user({guest: true})
        .appVersion(window.VERSION)
        .beforeNotify(function ($log) {
            return function (error, metaData) {
                if (error.stacktrace.indexOf('ckeditor') !== -1) {
                    return false;
                }

                // don't report errors when exiting the application (like ajax killed because of refresh, and app freaks out)
                if (window._exiting) {
                    return;
                }

                var sev = (error.name||'');
                error.severity = sev && sev.indexOf ? (sev.indexOf('HTTP') === 0 ? 'warning' : 'error') : 'error';
                $log.debug('beforeNotify', error.name, metaData);

                if (window.ENV === 'development') {
                    return false;
                }

                return true;
            };
        });

    //var originalJSONParser = $httpProvider.defaults.transformResponse.shift();
    // attempt to recover any json errors
    var jsonErrorLogger = function(res, headers) {
        try {
            return originalJSONParser(res);
        } catch (e) {
            throw e;
        }
    };

    //$httpProvider.defaults.transformResponse = [jsonErrorLogger];

    $httpProvider.interceptors.push(function($q, bugsnag) {
        return {
            request: function(config){
              console.pushLog('request', [{
                method: config.method,
                url: config.url
              }]);
              return config;
            },
            response: function (response) {
              console.pushLog('response', [{
                method: response.config.method,
                url: response.config.url,
                status: response.status,
                statusText: response.statusText,
              }]);
              return response || $q.when(response);
            },
            requestError: function(rejection) {
              console.pushLog('request error', [rejection]);
              bugsnag.notify('requestError: ' + JSON.stringify(rejection));
              return $q.reject(rejection);
            },
            responseError: function (rejection) {
              console.pushLog('response error', [{
                method: rejection.config.method,
                url: rejection.config.url,
                status: rejection.status,
              }]);
              if (rejection.config) {
                  // ignore any HEAD request that caused an error (like HEAD /might-exist?)
                  if (rejection.config.method === 'HEAD') {
                      return $q.reject(rejection);
                  }
                  var url = rejection.config.url;
                  var error = rejection.status;
                  var message = 'HTTP ' + rejection.config.method + ' returned code ' + error + ': ' + url;
                  if (url.indexOf('/standards.json') !== -1 || url.indexOf('/standards/') !== -1) {
                      return $q.reject(rejection);
                  }
                  bugsnag.notify(message);
              } else {
                  bugsnag.notify(rejection);
              }
              return $q.reject(rejection);
            }
        };
    });

    // disable modal exists via ESC and clicking on backdrop
    $dialogProvider.options({keyboard: false, backdropClick: false});
    $.fn.modal.defaults = {keyboard: false, backdrop: 'static'};


    try {
        // disable Contextual Escaping for IE
        var ua = navigator.userAgent || '';
        var ieVersion = ua.match(/MSIE ([\d.]+)/);
        ieVersion = ieVersion ? parseInt(ieVersion[1], 10) : null;
        if (ieVersion) {
            var documentMode = window.document.documentMode;
            if (documentMode !== undefined && documentMode < 8) {
                  $sceProvider.enabled(false);
            }
        }
    } catch (e) {}
});

app.run(function($rootScope,$window,$timeout,$http,session,couch,databases,exams,flashMessages,$http,bugsnag,$q, examPrompts, constructors, $state, $sce){
    if (!$window.Promise) {
      $window.Promise = function(executor) {
        return $q(executor);
      };

      $window.Promise.all = $q.all.bind($q);
      $window.Promise.reject = $q.reject.bind($q);
      $window.Promise.resolve = $q.when.bind($q);

      $window.Promise.race = function(promises) {
        var promiseMgr = $q.defer();

        for(var i = 0; i < promises.length; i++) {
          promises[i].then(function(result) {
            if(promiseMgr) {
              promiseMgr.resolve(result);
              promiseMgr = null;
            }
          });

          promises[i].catch(function(result) {
            if(promiseMgr) {
              promiseMgr.reject(result);
              promiseMgr = null;
            }
          });
        }

        return promiseMgr.promise;
      };
    }

    // window.addEventListener('error', function(event) {
    //   $http.put('/api/log/add',{
    //     action:'General Error',
    //     tags:'error',
    //     meta:JSON.stringify({
    //       profile: $rootScope.profile,
    //       error: JSON.stringify(event,Object.getOwnPropertyNames(event),2),
    //     },null,2),
    //   });
    // });

    $rootScope.$on('$stateChangeStart', function(e, s) {
        Pace.restart();
    });

    $rootScope.version = $window.VERSION;
    $rootScope.menus = [];
    $rootScope.session = session;
    $rootScope.session.refresh();
    $rootScope.loginRequired = false;
    $rootScope.createAccount = false;
    $rootScope.noQuestionbanks = false;
    $rootScope.errorMessage = '';
    $rootScope.helpIndex = 0;

    $rootScope.$watch('session.profile',function(profilePromise){
        //console.log('logged in',profile);
        $q.when(profilePromise).then(function(profile) {
            if (!profile) {
                return;
            }
            $rootScope.profile = profile;

            $http.get('/ping?' + new Date().getTime()).then(function(res){
              if(res.data){
                localStorage.setItem('studentToken', res.data.token);
              }
            });

            var dbcount = 0;
            if(profile.Databases && profile.Databases.length > 0){
              for(var i = 0; i < profile.Databases.length; i++){
                if(!profile.Databases[i].user){
                  dbcount++;
                }
              }
            }

            if(dbcount > 0){
              bugsnag.user = {
                  id: profile.UserId,
                  name: profile.FirstName + ' ' + profile.LastName,
                  email: profile.Email
              };

              bugsnag.metaData = {
                  session: profile.session_id,
                  databases: profile.Databases
              };

              $window.ga && $window.ga('set', 'userId', profile.UserId);
              if ($window.mixpanel) {
                $window.mixpanel.identify(profile.UserId);
                $window.mixpanel.people.set({
                  '$first_name': profile.FirstName,
                  '$last_name': profile.LastName,
                  '$email': profile.Email,
                  'is_admin': !!profile.IsAdmin
                });
              }

              couch.use(profile);
              databases.fetch();
              exams.populate(couch);

              $http.get('/api/newsfeed/alert/'+profile.UserId).then(function(res){
                if(res.data.length > 0){
                  var prompt = ''

                  for(var i = 0; i < res.data.length; i++){
                    prompt+= '<h4>'+res.data[i].title+'</h4>';
                    prompt+= '<div style="padding-left:20px">';
                    prompt+= res.data[i].html;
                    prompt+= '</div>';
                    prompt+= '<br/>';
                  }

                  bootbox.alert(prompt);

                  $http.put('/api/newsfeed/alert', res.data.map(function(item){return { fkNews:item.idNews, fkUser:profile.UserId };}));
                }
              });

            } else {
              var url = '/api/tokens/'+profile.UserId;
              $http.get(url).then(function(res){
                var tokens = res.data;
                var owner = false;

                if(tokens.length > 0){
                  var errorMessage = '<b>Expired Tokens:</b> <br>';
                  for(var i = 0; i < tokens.length; i++){
                    if(tokens[i].owner || tokens[i].admin){
                      owner = true;
                    }
                    if(tokens[i].expired < 0 ){
                      errorMessage += '&nbsp;&nbsp;&nbsp;'+tokens[i].name + '<br>'
                    }
                  }
                  errorMessage += '<br><br>'
                }

                console.log(location.href);
                if(!location.href.split('/#/')[1].startsWith('account')){
                  $rootScope.noQuestionbanks=true;
                  location.href='/#/account';
                }

                $rootScope.errorMessege = $sce.trustAsHtml(errorMessage);
              });
            }

            if(!$rootScope.gapi){
              // gapi.load('client:auth2', function(){
              //   $rootScope.gapi = gapi.client.init({
              //       apiKey: 'AIzaSyBPs_0t-tWnkIPqIAFYN9GjBMG3_cvs5xw',
              //       clientId: '165602099353-6438nan3c20qsf7rb1s31r5dtvdv10da.apps.googleusercontent.com',
              //       scope: 'profile https://www.googleapis.com/auth/forms https://www.googleapis.com/auth/drive.file',
              //       discoveryDocs: ['https://content.googleapis.com/discovery/v1/apis/docs/v1/rest', 'https://script.googleapis.com/$discovery/rest?version=v1', 'https://content.googleapis.com/discovery/v1/apis/drive/v3/rest']
              //   }).then(function () {
              //     return Promise.resolve(gapi);
              //   }).catch(function(err){
              //     console.log(err);
              //   });
              // });
            
              gapi.load('client', function(){
                $rootScope.gapi = gapi.client.init({
                  // NOTE: OAuth2 'scope' and 'client_id' parameters have moved to initTokenClient().
                  // discoveryDocs: ['https://content.googleapis.com/discovery/v1/apis/docs/v1/rest', 'https://script.googleapis.com/$discovery/rest?version=v1', 'https://content.googleapis.com/discovery/v1/apis/drive/v3/rest']
                }).then(function () {  // Load the API discovery document.
                  gapi.client.load('https://content.googleapis.com/discovery/v1/apis/docs/v1/rest');
                  gapi.client.load('https://script.googleapis.com/$discovery/rest?version=v1');
                  gapi.client.load('https://content.googleapis.com/discovery/v1/apis/drive/v3/rest');
                  return gapi;
                });
              })
            }

            if(!$rootScope.googleSignin){
              // $rootScope.googleSignin = function(){
              //   var gtool;
              //   return $rootScope.gapi.then(function(res){
              //     gtool = res;
              //     var signedIn = gtool.auth2.getAuthInstance().isSignedIn.get();
              //     if(!signedIn){
              //       return gtool.auth2.getAuthInstance().signIn()
              //     } else {
              //       return new Promise(function(resolve, reject) {
              //         var profile = gtool.auth2.getAuthInstance().currentUser.get().getBasicProfile();
              //         bootbox.dialog("Signed into Google as: "+profile.getEmail()+', continue with export?',[
              //           {
              //             label : 'Cancel',
              //             class : 'btn pull-left',
              //             callback: function() {
              //               reject('cancel');
              //             }
              //           },{
              //             label : 'Change account',
              //             class : 'btn',
              //             callback: function() {
              //               gtool.auth2.getAuthInstance().disconnect()
              //               .then(function(){
              //                 return gtool.auth2.getAuthInstance().signOut()
              //               }).then(function(){
              //                 return gtool.auth2.getAuthInstance().signIn()
              //               }).then(function(){
              //                 resolve();
              //               })
              //             }
              //           },{
              //             label : 'Yes',
              //             class : 'btn btn-primary',
              //             callback: function() {
              //               resolve();
              //             }
              //           }
              //         ]);
              //       }).then(function(){
              //         if(!gtool.auth2.getAuthInstance().currentUser.get().hasGrantedScopes('profile https://www.googleapis.com/auth/forms https://www.googleapis.com/auth/drive.file')){
              //           return gtool.auth2.getAuthInstance().currentUser.get().grant({
              //             scope: 'profile https://www.googleapis.com/auth/forms https://www.googleapis.com/auth/drive.file',
              //           });
              //         }
              //         return true;
              //       }).then(function(){
              //         if(!gtool.auth2.getAuthInstance().currentUser.get().hasGrantedScopes('profile https://www.googleapis.com/auth/forms https://www.googleapis.com/auth/drive.file')){
              //           alertify.error('Please export again and grant the requested permissions');
              //           throw new Error('cancel');
              //         }
              //         return true;
              //       });
              //     }
              //   }).then(function(){
              //     return gtool;
              //   })
              // }
              var tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: '165602099353-6438nan3c20qsf7rb1s31r5dtvdv10da.apps.googleusercontent.com',
                scope: 'profile https://www.googleapis.com/auth/forms https://www.googleapis.com/auth/drive.file',
                callback: '',  // defined at request time
              });
              $rootScope.googleSignin = function(){
                var gtool;
                return $rootScope.gapi.then(function(res){
                  gtool = res;
                  
                  return new Promise(function(resolve, reject){
                    tokenClient.callback = function (res) {
                      if (res.error !== undefined) {
                        throw (res);
                      }
                      resolve(gtool);
                    };

                    if (gtool.client.getToken() === null) {
                      // Prompt the user to select an Google Account and asked for consent to share their data
                      // when establishing a new session.
                      tokenClient.requestAccessToken({ prompt: 'consent' });
                    } else {
                      // Skip display of account chooser and consent dialog for an existing session.
                      tokenClient.requestAccessToken({ prompt: '' });
                    }
                  })
                })
              }
            }

            if(!profile.toolsAccess)
              setupSessionTimeout();
        });
    });

    $rootScope.$watch('createAccount',function (createAccount){
      if(createAccount){
        $rootScope.extraInstructions = 'Password must be at least 8 Characters and contain at least one upper case letter, and one number and one of @, $, !, %, *, ?, &';
        var _captchaTries = 0;
        function recaptchaOnload() {
            console.log(document.querySelectorAll('.g-recaptcha'));
            _captchaTries++;
            if (_captchaTries > 9)
                return;
            if (document.querySelectorAll('.g-recaptcha').length > 0) {
                grecaptcha.render("recaptcha", {
                    sitekey: '6Lf-z4QcAAAAABX4YCCWHGeNx9WXxhXRBdRnWd08',
                    callback: function(a) {
                      console.log('recaptcha callback');
                      console.log(a);
                    }
                });
                return;
            }
            window.setTimeout(recaptchaOnload, 100);
        }
        recaptchaOnload();
      } else {
        $rootScope.extraInstructions = ''
      }
    });

    $http.get('/api/helpdocs?type=0').then(function(res){
      $rootScope.helpDocs = [];
      for(var i = 0; i < res.data.length; i++){
        res.data[i].html = $sce.trustAsHtml(res.data[i].html);
        if(res.data[i].title == 'homeHelp'){
          $rootScope.homeHelp = res.data[i];
        } else if(res.data[i].order >= 0){
          $rootScope.helpDocs.push(res.data[i]);
        }
      }
      $rootScope.helpDocs.sort(function(a,b){
        return a.order-b.order;
      })
    });

    $rootScope.displayHelp = function(val) {
      $rootScope.helpIndex = 0;
      $rootScope.showHelp = !!val;
    }
    $rootScope.setHelpIndex = function(i){
      $rootScope.helpIndex = i;
    }

    $rootScope.$window = $window;

    $rootScope.sendFeedback = function(feedback){
        $rootScope.sendingFeedback = true;
        $http.post('/feedback', {feedback : feedback}).then(function(){
            alertify.success('Thank you for your feedback!<br/>Your opinion is important to us.');
            $rootScope.sendingFeedback = false;
            $rootScope.showFeedbackModal = false;
        }, function(){
            $rootScope.sendingFeedback = false;
            alertify.error('An error occurred while submitting your feedback.');
        });
    };

    $rootScope.$safeApply = function(fn) {
        var phase = this.$root.$$phase;
        if(phase === '$apply' || phase === '$digest') {
            if(fn && (typeof(fn) === 'function')) {
                fn();
            }
        } else {
            this.$apply(fn);
        }
    };

    $(document).bind('keyup keydown', function (e) {
        if (e.which == 65 && e.ctrlKey) {
            if (!$(e.target).is(':input')) {
                return false;
            }
        }
    });

    function setupSessionTimeout(){
      var sessionTimeout, saveTimeout;
      var SESSION_TIMEOUT = 15 * 60 * 1000; //15 minutes

      //TODO: set capture
      if(document.documentElement.addEventListener){
        ['keyup','keydown','mouseclick','mousemove'].forEach(function(ev){
            document.documentElement.addEventListener(ev,function(e){
              renewSessionTimeout();
            }, true);
        });
      }

      function renewSessionTimeout(){
        clearTimeout(sessionTimeout);
        clearTimeout(saveTimeout);

        sessionTimeout = setTimeout(function() {
          // @todo implement differently
          if (window.ENV === 'development') {
            return;
          }

          //delete session
          $rootScope.$safeApply(function() {
            $rootScope.exitSavePrompt = false;
            window.location.pathname = '/logout';
          });
        }, SESSION_TIMEOUT);

        saveTimeout = setTimeout(function() {
          if ($rootScope.exitSavePrompt) {
            //alert('You will be automatically logged off in 5 minutes. Please save your changes or they will be disregarded.');
          }
        }, 10 * 60 * 1000);
      }

      renewSessionTimeout();
    }

    displayFlashMessages(flashMessages.get());

    $('body').addClass('page-loaded');

    setTimeout(function() {
        $('body').removeClass('page-loading').removeClass('page-loaded');
    }, 1500);

    /** Create a new exam */
    $rootScope.createExam = function() {
      var promptCb = function(newName) {
        console.log('createExam promptCb');
        if (newName === null) {
          return;
        }
        if (!newName) {
          return examPrompts.empty('Enter the new exam name', promptCb);
        }
        if('\\:<>/%'.split('').some(function(x){return newName.includes(x);})){
          return examPrompts.invalid('Enter the new exam name', promptCb);
        }
        couch.db.hasExam(newName).then(function(duplicate) {
          if (duplicate) {
            examPrompts.duplicate('Enter the new exam name', promptCb);
          } else {
            constructors.Exam.create(newName).then(function(newExam) {
                newExam.loadedExam = {
                  date_created: new Date(),
                  date_updated: new Date()
                };
                newExam.ready = $q.when(newExam);
                exams.list.push(newExam);
                $rootScope.examCreated = newExam;
                $state.go('exam', {id: newExam.name});
              },
              function() {
                alertify.error('Error creating exam');
              });
          }
        });
      };

      exams.ready.then(function() {
        examPrompts.prompt('Enter the new exam name', promptCb);
      });
    };

    var pinger;
    var ping = function() {
        $.ajax({url: '/ping?' + new Date().getTime()}).done(function( data ) {
          if(data){
            localStorage.setItem('studentToken', data.token);
            if(!data.loggedin)$timeout(function() {
              $rootScope.loginRequired = true;
              $rootScope.$apply();
            });
          }
        }).fail(function() {
          alertify.error('Unable to communicate with server, please check your internet connection');
        }).always(function() {
            pinger = setTimeout(ping, 30*1000);
        })
    };

    if(pinger)clearTimeout(pinger);
    ping();

    // (function custom_console_with_traces(){
    //     var console = window.console
    //     if (!console) return
    //     function intercept(method){
    //         var original = console[method]
    //         console[method] = function(){
    //             var message = Array.prototype.slice.apply(arguments).join(' ');
    //
    //             $http.put('/api/log/add',{
    //               action:'app logging',
    //               tags:method,
    //               meta:JSON.stringify({
    //                 profile: $rootScope.profile,
    //                 trace: new Error().stack.split('\n'),
    //                 message: message,
    //               },null,2),
    //             });
    //
    //             var args = Array.prototype.slice.apply(arguments);
    //             original.apply(this, args)
    //         }
    //     }
    //     //intercept all methods including trace
    //     var methods = ['warn', 'error', 'trace']
    //     for (var i = 0; i < methods.length; i++)
    //         intercept(methods[i])
    // })();
});

(function() {
  if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
      value: function(predicate) {
       // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        var o = Object(this);

        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;

        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }

        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        var thisArg = arguments[1];

        // 5. Let k be 0.
        var k = 0;

        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return k.
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return k;
          }
          // e. Increase k by 1.
          k++;
        }

        // 7. Return -1.
        return -1;
      }
    });
  }

  if (console.logs == undefined){
    console.log("overriding logs");
    console.logs = [];
    console.stdlog = console.log.bind(console);
    console.stdError = console.error.bind(console);
    console.pushLog = function(level,vals){
      console.logs.push({
        level: level,
        time: new Date().toJSON(),
        val: vals,
      });

      if (console.logs.length > 5000){
        console.logs.splice(0, console.logs.length - 4500);
        console.log("trimming logs");
      }
    }
    console.log = function () {
      console.pushLog('log', Array.from(arguments));
      console.stdlog.apply(console, Array.from(arguments));
    }
    console.error = function () {
      console.pushLog('error', Array.from(arguments));
      console.stdError.apply(console, Array.from(arguments));
    }
  }
})();
