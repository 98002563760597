angular.module('examgen.directive').
directive('searchModal', function searchModal(exams, $compile, $window, $state, couch, $timeout, $rootScope, $http, databases) {
  return {
    restrict: 'E',
    template: '<div id="search-modal" ng-include="\'app/partials/modals/search.html\'"></div>',
    scope: {
      show: '=',
      showModal: '@',
      all: '@',
      searchString: '@'
    },
    controllerAs: 'ctrl',
    bindToController: true,

    controller: function($rootScope, $scope, $element, $attrs, databases) {
      var ctrl = this;
      ctrl.data = {
        dbs: [],
        last: '',
        lastdb: undefined,
        searchString:'',
        question: false,
        answer: false,
        toc: false,
        passage: false,
        tags: false,
        standard: false,
        searching: false,
        error: '',
      }

      $rootScope.$watch('profile',function(profile){
        if(profile){
          ctrl.data.dbs = profile.Databases;
        }
      });

      $scope.$on('$destroy', $rootScope.$on('openSearch', function() {
        ctrl.showModal = true;
        ctrl.data.error = '';
        $timeout(function() {
          $scope.$apply();
          ctrl.data.last = $window.localStorage.getItem('lastdb');
        });
      }));

      $scope.$watch('ctrl.data.last',function(last){
        var searched = $window.localStorage.getItem('lastSearch');
        var saved = ctrl.data.dbs.find(function(e) {
          return e.name === last;
        });
        if(saved){
          ctrl.data.lastdb = saved;
        } else {
          saved = ctrl.data.dbs.find(function(e) {
            return e.name === searched;
          });
          if(saved){
            $timeout(function() {
              $scope.$apply();
              ctrl.data.last = searched;
              ctrl.data.lastdb = saved;
            });
          } else if(ctrl.data.lastdb){
            ctrl.data.last = ctrl.data.lastdb.name;
          }
        }
      });


      ctrl.working = function(text){
        var overlay = document.createElement('div');
          overlay.style.position='fixed';
          overlay.style.top='0';
          overlay.style.left='0';
          overlay.style.right='0';
          overlay.style.bottom='0';
          overlay.style.backgroundColor='rgba(0,0,0,.1)';
          overlay.style.zIndex=99999;
        var msg = document.createElement('div');
          msg.style.position='fixed';
          msg.style.top='40%';
          msg.style.left='40%';
          msg.style.right='40%';
          msg.style.textAlign='center';
          msg.style.backgroundColor='white';
          msg.style.border='solid 1px lightgrey';
          msg.style.borderRadius='6px';
          msg.style.padding='4px';
        var info = document.createElement('p');
          info.innerHTML = text;
        var spinner = document.createElement('div');
          spinner.className = 'loader';
        msg.appendChild(info);
        msg.appendChild(spinner);
        overlay.appendChild(msg);
        return overlay;
      }

      ctrl.clear = function() {
        for(var i = 0; i < ctrl.data.dbs.length; i++){
          ctrl.data.dbs[i].selected = false;
        }
      }

      ctrl.cancel = function() {
        ctrl.showModal = false;
      };

      ctrl.ok = function() {
        ctrl.data.error = '';
        ctrl.data.selected = [];
        for(var i = 0; i < ctrl.data.dbs.length; i++){
          if(ctrl.data.dbs[i].selected){
            ctrl.data.selected.push(ctrl.data.dbs[i].id);
          }
        }

        if (ctrl.data.searchString.length <= 0) {
          ctrl.data.error = 'Please enter a string to search for';
        } else if (!(ctrl.data.question || ctrl.data.toc || ctrl.data.passage || ctrl.data.answer || ctrl.data.standard || ctrl.data.tags)) {
          ctrl.data.error = 'Please select a item to search';
        } else {
          var overlay = ctrl.working('Working...');
          document.querySelector('body').appendChild(overlay);

          var searchResults = [];
          var tocPromise;

          var sstr = ctrl.data.searchString;
          var querystrings = [];
          var firstIndex = -1;
          for(var i = 0; i < sstr.length; i++){
            if(sstr.charAt(i) === '"'){
              if(firstIndex >= 0){
                querystrings.push('[[:<:]]'+sstr.slice(firstIndex,i)+'[[:>:]]');
                sstr = sstr.slice(0,firstIndex-1) + sstr.slice(i+1);
                firstIndex = -1;
                i = 0;
              } else {
                firstIndex = i+1;
              }
            }
          }
          if(sstr.length > 0){
            var splits = sstr.split(' ');
            for(var i = 0; i < splits.length; i++){
              if(splits[i].length > 0 && splits[i] != ','){
                querystrings.push(splits[i]);
              }
            }
          }
          sstr = querystrings.join('|');

          $window.localStorage.setItem('lastSearch',ctrl.data.lastdb.name);

          if(ctrl.data.toc){
            tocPromise = $http.put('/api/search/toc?searchString='+sstr+'&nc='+Date.now(),ctrl.data.selected);
          } else {
            tocPromise = Promise.resolve({data:[]});
          }

          tocPromise.then(function(res) {
            if(res.data.length > 0){
              searchResults = res.data;
            }
            if(ctrl.data.lastdb){
              if(ctrl.data.question || ctrl.data.answer || ctrl.data.passage || ctrl.data.tags){
                return $http.put('/api/search/items'+
                '?searchString='+sstr+'&searchQ='+ctrl.data.question+
                '&searchA='+ctrl.data.answer+'&searchP='+ctrl.data.passage+'&searchT='+ctrl.data.tags+
                '&userDB='+(ctrl.data.lastdb.user ? true : false) +'&nc='+Date.now(),ctrl.data.selected)
              } else {
                return Promise.resolve({data:[]});
              }
            } else {
              return Promise.resolve({data:[]});
            }
          })
          .then(function(res) {
            if(res.data.length > 0){
              var questions = {
                id:10000,
                name:'blah',
                sortOrder:0,
                chapters:[{
                  isUserDB: ctrl.data.lastdb.user,
                  chapterId:20000,
                  chapterName: 'Question search results',
                  chapterNumber: 0,
                  sortOrder:0,
                  topics:[],
                }],
              };

              for(var c = 0; c < res.data.length; c++){
                var topic = {
                  topicId:30000+c,
                  isUserDB: ctrl.data.lastdb.user,
                  topicName: res.data[c].name,
                  sortOrder:0,
                  chapter: questions.chapters[0],
                  sections: [],
                }
                for(var i = 0; i < res.data[c].questions.length; i++){
                  var section = {
                    sectionId:40000+(c*1000)+i,
                    sectionName:'Questions '+(i*250)+'-'+((1+i)*250),
                    sortOrder:0,
                    isUserDB: ctrl.data.lastdb.user,
                    topic: topic,
                  };
                  section.parts =[{
                    partId:50000+(c*1000)+i,
                    partName:'(no subpart)',
                    sortOrder:0,
                    section: section,
                    isUserDB: ctrl.data.lastdb.user,
                    items: res.data[c].questions[i],
                  }];
                  for(var q = 0; q < res.data[c].questions[i].length; q++){
                    res.data[c].questions[i][q].isUserDB = ctrl.data.lastdb.user;
                    res.data[c].questions[i][q].part = section.parts[0];
                  }
                  topic.sections.push(section);
                }
                questions.chapters[0].topics.push(topic);
              }
              searchResults.splice(0,0,questions);
            }

            var standardPromise;
            if(ctrl.data.standard){
              standardPromise = $http.put('/api/search/standards?searchString='+sstr+'&nc='+Date.now(),ctrl.data.selected);
            } else {
              standardPromise = Promise.resolve({data:[]});
            }

            return standardPromise;
          })
          .then(function(res) {

            if(res.data.length > 0){
              if(searchResults.length > 0 && searchResults[0].id == 10000){
                searchResults[0].standards = res.data;
              } else {
                searchResults.splice(0,0,{
                  id:10000,
                  name:'blah',
                  sortOrder:0,
                  standards: res.data,
                  chapters:[],
                });
              }
            }
            if(searchResults.length > 0){
              $rootScope.$broadcast("loadSearchResults", searchResults);
              ctrl.showModal = false;
              $timeout(function() {
                $scope.$apply();
              });
            } else {
              ctrl.data.error = 'No Items Found';
            }

            document.querySelector('body').removeChild(overlay);
          })
          .catch(function(err){
            console.error(err);
            document.querySelector('body').removeChild(overlay);
            ctrl.showModal = false;
            alertify.error('An Error Occurred While Searching');
            $timeout(function() {
              $scope.$apply();
            });
          });
        }
      };
    }
  }
});
