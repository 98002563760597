angular.module('examgen.directive').
directive('draggable',function(tabIndex) {
  return function(scope, el, attr) {
    el.on('selectstart', function($event) {
      if ($event.target && $event.target.dragDrop) {
        $event.target.dragDrop();
      }
      return false;
    });

    // fix ie text selection issue
    el.on('dragend', function($event) {
      if (document.selection) {
        document.selection.empty();
      }
    });
  };
});