angular.module('examgen.directive').
/**
 * @ngdoc directive
 * @name examgen.directive:autofocus
 * @description Used to focus a DOM element automatically in response to scope variable autofucs
 */
directive('autofocus',function(){
  return function(scope,el,attr){
    scope.$watch(attr.autofocus,function(shouldGiveFocus){
      if(shouldGiveFocus){ 
        //focusing on the username input field
        el.focus();
      }

    });
  };
});