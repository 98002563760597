function QuestionsController($rootScope, $scope, $http, $stateParams, $timeout, $sce, flashMessages, uuid) {

  $rootScope.inQuestions = true;
  $scope.$on('$destroy', function() {
    $rootScope.inQuestions = false;
  });

  $scope.data = {
    dbs:null,
    imports:null,
  };

  $scope.global = $rootScope;
  $scope.global.QBTHideImport = true;

  $rootScope.$watch('profile',function(profile){
    if(profile){
      var url = '/api/databases?all=true&userDB=true&userID='+profile.UserId;
      $http.get(url)
      .then(function(res){
        $scope.data.dbs = res.data.map(function(item){
          item.items = [];
          return item;
        });
        return $http.get('/api/databases');
      }).then(function(res) {
        $scope.data.imports = res.data.filter(function(item){
          return profile.Databases.findIndex(function(pdb){
            return pdb.id == item.idProduct;
          }) >= 0;
        }).map(function(item){
          item.items = [];
          return item;
        });
      });
    }
  });
}
