function getSrc(db, uid, type) {
  return '//s3.amazonaws.com/examgen/' +
    db + '/mathml/' + type + '/' + uid + '.' + type;
};


function processMathML($element, db, skipPng) {
  $element.find('math').each(function() {
    var $e = $(this);
    $e.attr('display', '');
    var rawmml = $e[0].outerHTML;
    var uid = $e.attr('uid');

    // no pre-generated image?
    if (!uid || skipPng) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, $element[0]]);
      return;
    }

    var $mmlspan = $e.before('<span class="mml"></span>');
    var image = new Image();
    image.src = getSrc(db, uid, 'png');
    var $image = $('<img />');//.attr('src', getSrc(db, uid, 'png'));
    $(image).load(function() {
      var width = image.width;
      var height = image.height;
      $image.attr('src', image.src);
      $image.attr('width', width * 0.5);
      $image.attr('height', width * 0.5);
      $e.before($image);
      $e.remove();
    });

    // if image fails to load, fallback to mathml
    $image.error(function(e) {
      var $fallback = $(rawmml);
      $image.before($fallback);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, $element[0]]);
      $image.remove();
    });
  });
}

angular.module('examgen.directive').
  /**
   * @ngdoc directive
   * @name examgen.directive:mathjaxBind
   * @description Used to render dynamic MathML content with MathJax
   */
directive('mathjaxBind', function() {
  return {
    restrict: 'A',
    controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

      $scope.$watch($attrs.mathjaxBind, function(value) {
        if (!value) {
          $element.html('');
          return;
        }
        if($scope.itemToDisplay){
          var db = $scope.itemToDisplay.databaseName;
        } else {
          var db = '';
        }

        //do some preprocessing to strip out the weird RTML formatting
        //pull out the inside of textformat
        var s1 = '<TEXTFORMAT>',
          s2 = '</TEXTFORMAT>';

        var i1 = value.search(s1),
          i2 = value.search(s2);

        var html = i1 > -1 && i2 > -1 ?
          value.substring(i1 + s1.length, i2) :
          value;

        //eliminate font tags
        html = html.replace(/<\/?FONT>/gi, '');

        //render
        $element.html(html);
        cleanHtml($element);

        processMathML($element, db, true);

        //workaround for weird bootstrap - IE9 CSS interaction
        //bootstrap sets width:auto, height:auto on all images
        //this causes IE to ignore the width and height attributs of the images
        //so we set it in JavaScript here
        $element.find('img').each(function() {
          var $e = $(this);
          $e.css({
            width: $e.attr('width') + 'px',
            height: $e.attr('height') + 'px'
          });
        });

        MathJax.Hub.Queue(function() {
          // workaround for display='block' rendering issue. Prevents text from being centered.
          $('.MathJax_Display').css({
            'text-align': 'left',
            'display': 'inline'
          });
        });
      });
    }]
  };
})

.service('MathML', function() {
  var service = {
    fix: function(examItem, elements) {
      var db = examItem.databaseName;
      processMathML(elements, db);
    }
  };

  return service;
});
