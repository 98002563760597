angular.module('examgen.directive').
/**
* @ngdoc directive
* @name examgen.directive:questionEditor
* @description Encapuslates ckEditor rich text editor
*/
directive('questionEditor', function($rootScope, $timeout) {
  return {
    require: '?ngModel',
    link: function(scope, elm, attr, ngModel) {

      attr.$set('contenteditable', true);
      CKEDITOR.disableAutoInline = true;
      CKEDITOR.config.allowedContent = true;
      CKEDITOR.config.removeButtons = '';
      CKEDITOR.config.autoParagraph = false;
      CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR;
      if(attr.isstudent){
        CKEDITOR.config.toolbar = [
          ['Bold', 'Italic', 'Underline', 'Strike',],
          ['Subscript', 'Superscript',],
          [ 'Undo', 'Redo' ],
          [ 'Image','Table','insertML','insertGraph'],
        ];
      }else{
        CKEDITOR.config.toolbar = [
          ['Bold', 'Italic', 'Underline', 'Strike',],
          ['Subscript', 'Superscript',],
          [ 'NumberedList','BulletedList',],
          [ 'Outdent','Indent'],
          ['JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
          [ 'Undo', 'Redo' ],
          [ 'Image','Table','Symbol','insertML','insertGraph'],
        ];
      }

      CKEDITOR.config.imageUploadUrl = '/api/image';

      CKEDITOR.config.autoGrow_maxHeight = 600;

      CKEDITOR.config.sharedSpaces = {
        top: 'CKEDITOR_TOOLBAR',
        bottom: '',
      };

      CKEDITOR.config.removePlugins = 'maximize,resize,elementspath';

      CKEDITOR.on('dialogDefinition', function(dialogDefinitionEvent) {
          //if ('link' == dialogDefinitionEvent.data.name) {
              var dialogDefinition = dialogDefinitionEvent.data.definition;
              // Get rid of annoying confirmation dialog on cancel
              dialogDefinition.dialog.on('cancel', function(cancelEvent) {
                  return false;
              }, this, null, -1);
          //}
      });

      var ck = CKEDITOR.replace(elm[0],{
        allowedContent:true,

        on: {
          instanceReady: function( evt ) {
            // Output self-closing tags the HTML4 way, like <br>.
      			this.dataProcessor.writer.selfClosingEnd = '>';
            CKEDITOR.document.$.querySelector('html').style.overflowY ='auto';
            
      			// Use line breaks for block elements, tables, and lists.
      			// var dtd = CKEDITOR.dtd;
      			// for ( var e in CKEDITOR.tools.extend({},
            //   dtd.$block,
            //   dtd.$blockLimit,
            //   dtd.$cdata,
            //   dtd.$editable,
            //   dtd.$empty,
            //   dtd.$inline,
            //   dtd.$intermediate,
            //   dtd.$list,
            //   dtd.$listItem,
            //   dtd.$nonBodyContent,
            //   dtd.$nonEditable,
            //   dtd.$object,
            //   dtd.$removeEmpty,
            //   dtd.$tabIndex,
            //   dtd.$tableContent,
            //   dtd.$transparent,
            //   {script: 1, svg: 1, g: 1, use: 1,})){
      			// 	this.dataProcessor.writer.setRules( e, {
      			// 		indent: true,
      			// 		breakBeforeOpen: true,
      			// 		breakAfterOpen: true,
      			// 		breakBeforeClose: true,
      			// 		breakAfterClose: true
      			// 	});
      			// }
            // this.dataProcessor.writer.setRules( 'u', {
            //   indent: false,
            //   breakBeforeOpen: false,
            //   breakAfterOpen: false,
            //   breakBeforeClose: false,
            //   breakAfterClose: false
            // });
            // this.dataProcessor.writer.setRules( 'i', {
            //   indent: false,
            //   breakBeforeOpen: false,
            //   breakAfterOpen: false,
            //   breakBeforeClose: false,
            //   breakAfterClose: false
            // });
            // this.dataProcessor.writer.setRules( 'b', {
            //   indent: false,
            //   breakBeforeOpen: false,
            //   breakAfterOpen: false,
            //   breakBeforeClose: false,
            //   breakAfterClose: false
            // });
            // this.dataProcessor.writer.setRules( 's', {
            //   indent: false,
            //   breakBeforeOpen: false,
            //   breakAfterOpen: false,
            //   breakBeforeClose: false,
            //   breakAfterClose: false
            // });

            MathJax.Hub.Config({
              showMathMenu: false,
              showMathMenuMSIE: false,
            });

            MathJax.Hub.Typeset();
            ngModel.$setViewValue(ck.getData());
            $rootScope.$broadcast("questionEditorLoaded",attr.itemtype);
          }
        },
      });

      if (!ngModel) return;

      // ck.on( 'change', function(e) {
      //   if(ck.checkDirty()){
      //     $timeout(function() {
      //       MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
      //       MathJax.Hub.Queue(["Reprocess",MathJax.Hub]);
      //     });
      //   }
      // });
      ck.on('blur',function( e ){
         return false;
      });

      ck.on('pasteState', function() {
        $rootScope.$safeApply(function() {
          ngModel.$setViewValue(ck.getData());
        });
      });

      ck.on( 'fileUploadRequest', function( evt ) {
        console.log($rootScope);
        if(attr.isstudent)
          evt.data.requestData.product = 'StudentUploads';
        else
          evt.data.requestData.product = $rootScope.product || 'UserUploads';
      });

      ngModel.$render = function(value) {
        if(ck) ck.setData(ngModel.$viewValue);
        // $timeout(function() {
        //   MathJax.Hub.Typeset();
        // });
      };

      scope.$on('$destroy', function(){
        try {
          ck.destroy();
          ngModel.$render = null;
        }catch(e){
          console.error('Error destroying ckEditor');
        }
      });
    }
  };
});
