function ComposerQuestionGridCtrl($scope, $element, $attrs, localStorageService) {
  var vm = this;
  var composerCtrl = $scope.composerCtrl;
  composerCtrl.questionGrid = vm;

  vm.setInitialSize = function() {
    var gridSize = parseInt(localStorageService.get('composerGridHeight'), 10);
    var defaultSize = 180;
    height = gridSize > 0 ? gridSize : defaultSize;
    console.log('grid size is', gridSize);
    $element.css('height', height);
    $element.find('.loading').css('height', height);
    $element.find('.loading span').css('line-height', height + 'px');
  }

  // find the largest item width and use that for all
  vm.verifyQuestionGridSize = function() {
    // set the width using CSS, so it's efficient.
    var selector = 'itemStyle';
    var $head = angular.element('head');
    var $css = $head.find('style.' + selector);

    // if css element not found (let's only create one), so create it
    if (!$css.length) {
      $css = angular.element('<style>').addClass(selector);
      $head.append($css);
    }

    $css.text('#grid .item { }');
    var width = vm.findIdealItemWidth();

    if (width < 1) {
      return;
    }

    $css.text('#grid .item { width: ' + width + 'px !important; }');
  };

  // computes the ideal size to fit nicely on the screen and not leave empty
  // gaps on the side
  vm.findIdealItemWidth = function() {
    // find the largest "box" and make it the minimum width
    var $items = $element.find('.item');
    var widths = $items
      .map(function() {
        return $(this).outerWidth();
      }).toArray();

    var max = Math.max.apply(Math, widths);

    // find out how many items fit based off this max width
    var gridWidth = $element.width() - 16;
    var gridsPerRow = Math.floor(gridWidth / max);

    if ($items.length > gridsPerRow) {
      return gridWidth / gridsPerRow;
    }

    return max;
  };
}

angular
  .module('examgen.directive')
  .directive('composerQuestionGrid', function() {
    return {
      restrict: 'A',
      bindToController: {
        composerCtrl: '=',
        loading: '='
      },
      controllerAs: 'questionGrid',
      controller: ComposerQuestionGridCtrl,
      link: function(scope, element, attrs, controller) {
        controller.setInitialSize();

        // let's wait until the question grid is done loading and ensure
        // each item's size has been calculated correctly
        scope.$watch('loading', function(val, old) {
          if (val !== old && !val) {
            controller.verifyQuestionGridSize();
          }
        });
      }
    }
  });

  window.t = 1;
