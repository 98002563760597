angular.module('examgen.layout').
/**
 * @ngdoc layout
 * @name examgen.directive:answerKeyLayout
 * @description Encapuslates the answer key layout algorithm.
 */
directive('answerKeyLayout',function($rootScope, $timeout, $q, MathML){
  return {
    template : '<div class="examPreview">',
    replace : true,
    scope : {
      exam : '=',
      $index : '=',
      totalNumberOfExamsToPrint : '='
    },
    link : function(scope,el,attr){
      var math = 0;
      var copyNumber = scope.$index;

      var cssTemplate='<link href="/css/layout.css" rel="stylesheet">'+
      '<style>'+
      '@media print {@page {size: [[SIZE]];}}'+
      '.page {width:[[WIDTH]];height:[[HEIGHT]];padding: [[MARGINTOP]] [[MARGIN]];}'+
      '.page hr {margin:[[FONT/2]] 0; border-top-color: black;}'+
      '</style>';

      var newDoc, roughDoc;

      function createPage(){
        var $page = $('<div class="page"><div class="body"></div></div>');
        newDoc.append($page);
        return $page.find('.body');
      }


      function questionHasOverflowedVertically(currentBody){
        return currentBody.outerHeight(true) > (currentBody.parent().height()-scope.exam.options.fontSize);
      }

      function render(exam){
        var defer = $q.defer();
        var currentBody = createPage();     //create a new page for each section


        // make sure all images are loaded first
        var allImages = [];
        exam.sections.forEach(function(section){
          var flattenedItems = section.items.map(function(itemOrPassage){
            return itemOrPassage.items ? [itemOrPassage].concat(itemOrPassage.items) : [itemOrPassage];
          }).reduce(function(a,b){return a.concat(b);},[]);

          // find all images and wait for them
          flattenedItems.forEach(function(item) {
            if (item.answerKeyElement) {
              item.answerKeyElement.find('img').each(function() {
                allImages.push(this);
              });
            }
          });
        });

        // wait for all images to load so we can check if DOM is vertically overflown
        //console.log('waiting for images to load', allImages.length);
        var wait = imagesLoaded(allImages);
        wait.on('always', function(t) {
          //console.log('images loaded');
          // process each section first
          exam.sections.forEach(function(section){
            var flattenedItems = section.items.map(function(itemOrPassage){
              return itemOrPassage.items ? [itemOrPassage].concat(itemOrPassage.items) : [itemOrPassage];
            }).reduce(function(a,b){return a.concat(b);},[]);

            //flow into the next column, and then repeat from the next page
            flattenedItems.forEach(function(item,i){
              //single-column layout.
              if(item.answerKeyElement){
                item.answerKeyElement.remove();

                currentBody.append(item.answerKeyElement);

                if(questionHasOverflowedVertically(currentBody)) {
                  item.answerKeyElement.remove();
                  currentBody = createPage();     //create a new page for each section
                  currentBody.append(item.answerKeyElement);
                }
              }
            });
          });

          defer.resolve();
        });

        return defer.promise;
      }

      function pathToComponents(path){
        var components = path.split('/');
        return {
          dbName : components[2],
          chapterName : components[4],
          topicName : components[5],
          sectionName : components[6],
          itemName : components[7].slice(0,-5)
        };
      }

      function examToDom(exam){

        var totalItemCount = 0;

        exam.sections.forEach(function(section){
          section.items.forEach(function(itemOrPassage){

            function processQuestion(item){
              totalItemCount++;
              var question = item.examItem.resolvedItemContent,
                  answer = [];

              var findAnswerViaItemContent = function() {
                return item
                  .examItem
                  .resolvedItemContent
                  .responses
                  .filter(function (r) {
                    return r.isCorrect
                  });
              };

              var formatAnswer = function(answers) {
                var labels = [];
                for(var i = 0; i < answers.length; i++){
                  var label = section.options.numericMc ? answerLettertoNumber(answers[i].answerLetter) : answers[i].answerLetter
                  labels.push(label + '.&nbsp;&nbsp;&nbsp;&nbsp;' + answers[i].answer);
                }
                return labels;
              };

              var answerLettertoNumber = function(answer) {
                var index = 'ABCDEFG'.indexOf(answer)+1;
                return index;
              }
              //console.info('adding question type', totalItemCount, question.itemType);
              // Short Response
              if (question.itemType === 'SR' || question.itemType === 'ER') {
                answer = [question.shortAnswer];
              } else if (question.itemType === 'TF') {
                answer = formatAnswer(findAnswerViaItemContent());
              } else {
                var extendedResponseAnswer = item.examItem.resolvedItemContent && item.examItem.resolvedItemContent.extendedResponseAnswer;

                var converted = false;
                if (!extendedResponseAnswer) {
                  var parent = item.composerParent.examPassage ? item.composerParent.composerParent : item.composerParent;
                  converted = parent.options.convertMCToShortAnswer;
                }

                if(!item.examItem.resolvedItemContent.responses && !extendedResponseAnswer) {
                  console.error('skipping question');
                  // @TODO log error
                  return;
                }

                answer = [item.correctAnswerLetter || extendedResponseAnswer];

                // if MC > ER, then print the correct answer
                if (converted) {
                  var correct = findAnswerViaItemContent();
                  if (!correct.length) {
                    return new Error('Could not find the correct answer for MC>ER');
                  }
                  // TODO: normalize this somewhere
                  answer = correct.map(function(a){
                    if(a.answer.match(/<FONT>/) || a.answer.match(/<P>/)){
                      //this is the old form: <rtml> root element, and then content inside of <P> and <FONT> elements
                      return $(a.answer).find('font, p').contents();
                    }else{
                      return a.answer;
                    }
                  });

                } else {
                  if (question.itemType === 'MC') {
                    answer = formatAnswer(findAnswerViaItemContent());
                  }
                }

                answer = answer.length ? answer : [item.correctAnswerLetter || extendedResponseAnswer];

                if (!answer.length) {
                  console.error('processQuestion', question.itemType, item, extendedResponseAnswer);
                  return new Error('Could not find correct answer');
                }
              }


              if(item.examItem.origin){
                var origin = item.examItem.origin.split('/');
                var dbName = origin[0];
                var chapterName = origin[1];
                var chapterNumber = origin[2];
                var itemName = origin[3];
              } else {
                var dbName = item.examItem.part.section.topic.chapter.database.databaseName;
                var chapterName = item.examItem.part.section.topic.chapter.chapterName;
                var chapterNumber = item.examItem.part.section.topic.chapter.chapterNumber + 1;
                var itemName = item.examItem.itemName;
              }

              var standards = item.examItem.resolvedStandards;

              standards = standards && standards.map ? standards.map(function(e){ return e.standardName; }) : [];
              var name = dbName + ', Ch ' + chapterNumber + ' #' + itemName + ' ' +
                (standards && standards.length > 0 ? 'Stds: {' + standards.join(',')  + '}' : '');

              var x = '<div class="question" style="padding-bottom: 12pt">' +
                  '<table style="width:100%">' +
                    '<tr><td valign="top" style="width: 1%; padding-right: 4pt;" class="count">' + totalItemCount + ')</td><td style="width: 99%" class="answer"></td></tr>' +
                    '<tr><td></td><td colspan="">' + name + '</td></tr>' +
                  '</table>' +
                '</div>'

              item.answerKeyElement = $(x);
              // append the answer
              for(var i = 0; i < answer.length; i++){
                var $answer;
                if(i == 0){
                  $answer = item.answerKeyElement.find('.answer').append(answer[i]);
                } else {
                  x = '<tr><td valign="top" style="width: 1%; padding-right: 4pt;" class="count"></td><td style="width: 99%" class="answer"></td></tr>';
                  nextAnswer = $(x);
                  item.answerKeyElement.find('.answer').parent().after(nextAnswer);
                  $answer = nextAnswer.find('.answer').append(answer[i]);
                }

                // fix the answer's style if there's any. each element must be rendered as inline
                $answer.find('*').attr('style', '');

                var $math = $answer.find('math');
                if ($math.length > 0) {
                  math++;
                  MathML.fix(item.examItem, $answer);
                  //MathJax.Hub.Queue(['Typeset', MathJax.Hub, $answer[0]]);
                }
              }

              roughDoc.append(item.answerKeyElement);
            }

            if(itemOrPassage.items){
                itemOrPassage.items.forEach(processQuestion);
            }else{
                processQuestion(itemOrPassage);
            }
          });
        });
      }

      function processMath() {
        if (math === 0) {
          return $q.when(true);
        }

        var defer = $q.defer();
        MathJax.Hub.Queue(function () {
          console.log('MathJax is done');
          defer.resolve();
        });

        return defer.promise;
      }

      function performLayout(exam){
        //flow in text
        examToDom(exam);
        processMath()
        .then(function() {
          return render(exam);
        })
        .then(function() {

          //TODO: eliminate duplicate code here

          //add page numbers
          el.find('.page').each(function(i, page){

            //TODO: consolidate this
            var versionText = ' V' + (copyNumber + 1);


            $(page).prepend($('<span>').css('text-align','right').text('Answer Key ' + $rootScope.documentId + versionText + ' - Page ' + (i + 1)));
          });

          $('path').each(function(){
            //console.log('removing stroke path');
            this.removeAttribute('stroke-width');
            this.setAttribute('stroke','none');
          });


          //console.log('fixing mathjax');
          // fix svg issues, this runs globaly to the whole page, not just the specific chunk of generated html
          var t = 0;
          var useTags = document.getElementsByTagName('use');
          for (var o=0; o < useTags.length; o++) {
            var useTag = useTags[o];
            var href = (useTag.getAttribute('xlink:href') || useTag.getAttribute('href') || '').substring(1);

            // fetch the MathJax path definition
            var pathDef = document.getElementById(href);
            if (!pathDef) {
              console.error('use found issue, href not found', useTag, href);
              continue;
            }

            // create the new tag
            var cloned = pathDef.cloneNode(true);
            cloned.removeAttribute('id');

            // build the transform from the use tag, and map X Y to translate()
            var x = parseInt(useTag.getAttribute('x'), 10) || 0;
            var y = parseInt(useTag.getAttribute('y'), 10) || 0;
            var transform = useTag.getAttribute('transform') || '';
            var translate =  'translate(' + x + ', ' + y + ')';
            cloned.setAttribute('transform', transform + ' ' + translate);

            // now, replace use with cloned and remove the use tag
            useTag.parentNode.appendChild(cloned);
            useTag.parentNode.removeChild(useTag);
            o--;
            //console.log('deleting useTag', t++);
          }

          $(el).find('math').remove();

          $timeout(function() {
            $rootScope.$broadcast('layoutComplete',{
              copyNumber : copyNumber,
              html : el,
              isAnswerKey : true
            });
          });
        });
      }

      scope.exam.waitForAllItemsToResolve().then(function(){
        roughDoc = $('<div class="roughDoc"></div>');
        el.append(roughDoc);
        newDoc = $('<div class="newDoc"></div>');
        el.append(newDoc);

        if(!scope.exam.options){
          scope.exam.options = {};
        }
        if(!scope.exam.options.pageSize){
          scope.exam.options.pageSize = { label: 'Letter (8.5 x 11)', width:8.5, height:11, size:'letter portrait'};
        }
        if(!scope.exam.options.fontSize){
          scope.exam.options.fontSize = defFontSize;
        }
        if(!scope.exam.options.fontFamily){
          scope.exam.options.fontFamily = defFontFam;
        }

        pageWidth = parseFloat(scope.exam.options.pageSize.width)||8.5;
        pageHeight = parseFloat(scope.exam.options.pageSize.height)||11;
        pageMargin = scope.exam.options.margin||0.5;
        pageMarginTop = scope.exam.options.marginTop||0.25;

        var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                       navigator.userAgent &&
                       navigator.userAgent.indexOf('CriOS') == -1 &&
                       navigator.userAgent.indexOf('FxiOS') == -1;

        if(isSafari){
          pageWidth -= (pageMargin*2);
          pageHeight -= (pageMargin*2)+1;
          pageMargin = 0;
          pageMarginTop = 0;
        }

        var css = cssTemplate.replace('[[SIZE]]', scope.exam.options.pageSize.size)//width height
        .replace('[[WIDTH]]', pageWidth+'in')
        .replace('[[HEIGHT]]', pageHeight+'in')
        .replace('[[MARGIN]]', pageMargin+'in')
        .replace('[[MARGINTOP]]', pageMarginTop+'in')
        .replace('[[FONT/2]]', (scope.exam.options.fontSize/2)+'pt');


        roughDoc.append(css);
        newDoc.append(css);

        performLayout(scope.exam);
      });
    }
  };
});
