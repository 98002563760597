/**

composerCtrl
  container
    topActions <-- later?
    questionArea
    questionGrid

    examTree

**/

function ComposerContainerCtrl($scope, $rootScope, $element, localStorageService) {
  var vm = this;

  var composerCtrl = $scope.composerCtrl;
  composerCtrl.container = vm;

  var el = $element;
  $element.addClass('composer');

  //JavaScript-based layout
  var left = el.find('#left'),
    chapters = el.find('#chapters'),
    topicAndStandards = el.find('#topicAndStandards'),
    tabs2 = el.find('#tabs2'), // question view
    grid = el.find('#grid'),   // questions grid
    chaptersSelect = el.find('#chaptersSelect'),
    databasesSelect = el.find('#databasesSelect'),
    insertButton = el.find('#insertButton'),
    gripper = el.find('.gripper');

  var minTabsHeight = 100;

  function availableHeightForTabs(){
    return left.height() -
      chapters.outerHeight() -
      topicAndStandards.outerHeight() -
      gripper.outerHeight() -
      grid.outerHeight() - 10;
  }

  var prevTopWidth;
  function doTopLayout() {
    var dropdownWrapper = topicAndStandards.find('div.dropdowns');
    var actions = topicAndStandards.find('div.actions');

    // do math to figure out how to organize the widths of our dropdowns
    var avail = left.width() - 20;
    var actionsWidth = actions.width();
    var newWidth = avail - actionsWidth;
    var dropdowns = dropdownWrapper.find('.dropdown-select');
    var standardDropdown = dropdownWrapper.find('dropdown.standards');
    var emptyStandards = standardDropdown.hasClass('ng-hide');

    var standardsWidth = emptyStandards ? 0 : 160;
    var remainingWidth = newWidth - 10 - standardsWidth;

    //$(dropdowns[0]).width(Math.floor((newWidth - 10) * 0.84));
    //$(dropdowns[1]).css({width: Math.floor(newWidth * 0.15), marginLeft: 10});

    if (!prevTopWidth || prevTopWidth !== remainingWidth) {
      $(dropdowns[0]).width(remainingWidth);
      $(dropdowns[1]).css({width: standardsWidth, marginLeft: 10});
      dropdownWrapper.css({'float': 'left', width: newWidth});

      prevTopWidth = remainingWidth;
      console.log(prevTopWidth);
    }
  }

  function doLayout(force) {
    if (!force && gripperState !== 'idle') {
      return;
    }

    doTopLayout();
    setQuestionGridHeight(availableHeightForTabs());
  }

  function setQuestionGridHeight(height) {
    tabs2.css('height', height);
    tabs2.find('.wrapper').css('height', height - 24);
    $element.find('.ht').css('height', $(window).height() - 90);


    localStorageService.set('composerQuestionAreaHeight', height);
  }

  var gripperState = 'idle';

  //gripper behaviour
  var tabs2StartHeight = false;
  var gridStartHeight = false;
  var availableHeightForTabsStart = false;

  gripper.mousedown(function(e) {
    // must be the click button
    if (e.button !== 0) {
      return;
    }

    // prevent gripper when it's loading
    if (gripper.hasClass('loading')) {
      return;
    }

    e.preventDefault();
    tabs2StartHeight = tabs2.height();
    gridStartHeight = grid.height();
    availableHeightForTabsStart = availableHeightForTabs() - 20;
    gripperState = 'started_dragging';
    gripper.addClass('dragging');
  });

  el.mouseup(function(e) {
    if (gripperState !== 'idle') {
      doGripper(e);
      gripperState = 'idle';
      gripper.removeClass('dragging');
    }
  });


  el.mousemove(doGripper);

  function doGripper(e) {
    if (gripperState !== 'started_dragging') {
      return;
    }

    // calculate new height
    var position = tabs2.offset();
    var height = tabs2.outerHeight();
    var newHeight = Math.round(e.pageY - position.top - (gripper.outerHeight()));
    if (newHeight < minTabsHeight) {
      return;
    }

    var gridBoxHeight = grid.find('>span:first').outerHeight();
    var gridSnapHeight = gridBoxHeight * Math.round((gridStartHeight + tabs2StartHeight - newHeight) / gridBoxHeight);

    if (!isNaN(gridSnapHeight) && gridSnapHeight > 0) {
      setQuestionGridHeight(availableHeightForTabsStart - (gridSnapHeight - gridStartHeight) + 28);
      grid.css('height', gridSnapHeight);
      localStorageService.set('composerGridHeight', gridSnapHeight);
    }
  }

  grid.scroll(function(ev) {
    // calculate the ideal height 
    var boxHeight = grid.find('> span:first').outerHeight()
    var y = grid.scrollTop();
    var height = grid.prop('scrollHeight');
    if (y < 1 || boxHeight < 1) {
      return;
    }

    var newY = parseInt(Math.round(y / boxHeight) * boxHeight, 10);

    // Y shouldn't be bigger then the available one, so round down
    if (newY > height) {
      newY = height - boxHeight;
    }

    if (newY !== y && newY >= 0) {
      grid.scrollTop(newY);
    }
  });

  // auto detect window size changes
  var timeout = window.setTimeout(doLayout, 100);
  var interval = window.setInterval(doLayout, 500);
  //$(window).bind('resize.layout', doLayout);

  // expose some functions
  this.setQuestionGridHeight = setQuestionGridHeight;

  // listen for ctrl + s
  function saveHandler(ev) {
    if (ev.which === 83 && ev.ctrlKey) {
      $scope.$apply(function() {
        $rootScope.$emit('saveExam');
        ev.preventDefault();
        ev.stopPropagation();
      });
    }
  }

  $(document).on('keydown', saveHandler);

  $scope.$on('$destroy', function() {
    window.clearTimeout(timeout);
    window.clearInterval(interval);
    $(window).unbind('resize.layout');
    $(document).unbind('keydown', saveHandler);
  });
}


angular
  .module('examgen.directive')
  .directive('composerContainer', function(localStorageService) {
    return {
      restrict: 'E',
      controllerAs: 'container',
      bindToController: {
        composerCtrl: '=',
      },
      controller: ComposerContainerCtrl
    }
  });