/**
 * Helper function to clean up some of the weird malformed HTML markup.
 */
function cleanHtml($element){
  //transform DOM content so that it renders nicer:
    //filter out font tags
    //remove newlines and tags.
  //this seems to work well
  var fontTags = $element.find('font');
  fontTags.each(function(tag){
    var $t = $(this);
    var $parent = $t.parent();

    if($parent.html()){
      //console.log('this',this);
      //console.log('html1',$parent.html());

      $t.replaceWith($t.contents());

      //console.log('html2',$parent.html());

      var newHtml = $parent.html().replace(/\n|\t/g,'');
      $parent.html(newHtml);

      //console.log('html3',$parent.html());
    }
  });

  var mathml = $element.find('math');
  mathml.attr('display','');
}


function displayFlashMessages(flashMessages){
  setTimeout(function(){
    flashMessages.then(function(response){
      console.log('flash messages',response.data);
      if(Array.isArray(response.data)){
        response.data.forEach(function(message){
          if(message.error){
            alertify.error(message.error);      //tell error message to wait
          }if(message.success){
            alertify.success(message.success);
          }if(message.log){
            alertify.warning(message.log);
          }
        });
      }
    });
  }, 1000);
}


(function($) {
  var _html = $.fn.html;
  $.fn.html = function(str){
    if (str != undefined && !$.isFunction(str) && this.attr('notify-update')) {
      return this.trigger('update',[str]);
    }
    return _html.apply(this, arguments);
  };

  var _text = $.fn.text;
  $.fn.text = function(str){
    if (str != undefined && !$.isFunction(str) && this.attr('notify-update')) {
      return this.trigger('update',[str]);
    }
    return _text.apply(this, arguments);
  };
})(jQuery);


/**
 * Defines querySelectorAll if not available
 */
(function(doc, $) {
  try {
    if (!doc.querySelectorAll) {
      doc.querySelectorAll = function(q) {
        return $(q)[0];
      };
    }
  } catch (e) {}
})(document, jQuery);


(function(window) {
  window.nextTick = (function () {
      var canSetImmediate = typeof window !== 'undefined' && window.setImmediate;
      var canPost = typeof window !== 'undefined' && window.postMessage && window.addEventListener;

      if (canSetImmediate) {
          return function (f) { return window.setImmediate(f); };
      }

      if (canPost) {
          var queue = [];
          window.addEventListener('message', function (ev) {
              var source = ev.source;
              if ((source === window || source === null) && ev.data === 'process-tick') {
                  ev.stopPropagation();
                  if (queue.length > 0) {
                      var fn = queue.shift();
                      fn();
                  }
              }
          }, true);

          return function nextTick(fn) {
              queue.push(fn);
              window.postMessage('process-tick', '*');
          };
      }

      return function nextTick(fn) {
          setTimeout(fn, 0);
      };
  })();
})(window);

function showExportDetail(){
  const str = `
  <ul>
    <li>
      <strong>QTI</strong> - Generates a zip package matching the IMS Question and
      Test Interoperability specification format. Click
      <a
        href="https://en.wikipedia.org/wiki/QTI#Applications_with_IMS_QTI_support"
        target="_blank"
        >here</a
      >
      for list of supporting platforms and their supported versions
      <ul>
        <li>
          <strong>QTI 1.2</strong> - Version 1.2 of the QTI spec. Supported by
          applications such as Canvas, Cognero, and LMS365
        </li>
        <li>
          <strong>QTI 2.1</strong> - Version 2.1 of the QTI spec. Supported by
          applications such as Learnosity, itslearning, and TAO
        </li>
      </ul>
    </li>
    <li>
      <strong>Blackboard</strong> - A variation of the QTI 1.2 specification
      designed specially for Blackboard.com
    </li>
    <li>
      <strong>Google Form</strong> - Generates a Google Forms document in the
      users Google Drive, and can be imported directly to Google Classroom
    </li>
    <li>
      <strong>HTML Form</strong> - Generates an HTML file containing a standard
      HTML form that can be embedded in any standard webpage
    </li>
  </ul>
  `;
  bootbox.alert(str);
}