function ComposerExamTreeCtrl($scope, $element, $attrs) {
  var vm = this;
  var composerCtrl = $scope.composerCtrl;
  composerCtrl.examTree = vm;

  // @todo start moving logic here
}

angular
  .module('examgen.directive')
  .directive('composerExamTree', function(localStorageService) {
    return {
      restrict: 'A',
      bindToController: {
        composerCtrl: '='
      },
      controllerAs: 'examTree',
      controller: ComposerExamTreeCtrl,
    }
  });