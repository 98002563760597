angular.module('examgen.service').
/**
 * @ngdoc service
 * @name examgen.service:flashMessages
 * @description Interface to flash messages from the server
 */
service('flashMessages',function($http){
  return { 
    get : function() { 
      return $http.get('/flashMessages');
    }
  };
})