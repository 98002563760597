angular.module('examgen.service').
/**
 * @ngdoc service
 * @name examgen.service:tabIndex
 * @description Service that generates tab indexes for sections and topics in the UI to allow keyboard navigation.
 */
service('tabIndex',function(lazyLoad,$q){
  var o = {
    topic : function(topic){
      return topic.chapter.topics.then(function(topics){
        var topicIndex = topics.indexOf(topic);
        var topicSlice = topics.slice(0,topicIndex);
        var topicPromises = topicSlice.map(function(topic){
          return lazyLoad.topic(topic).then(function(sections){
            return sections.length + 1;
          });
        }); 
        var sumPromise = $q.all(topicPromises).then(function(topicLengths){
          return topicLengths.reduce(function(a,b){
            return a + b;
          },0) + 1;
        });
        return sumPromise;
      });
    },
    section : function(section){
      return o.topic(section.topic).then(function(sumTopics){
        return section.topic.sections.then(function(sections){
          return sections.indexOf(section) + sumTopics + 1;
        });
      });
    }
  };

  return o;
});