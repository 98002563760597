angular.module('examgen.directive').
directive('savePrompt', function($rootScope, $dialog, $q) {

  // don't allow the application to redirect to other pages
  // to prevent angularjs from freaking out
  $rootScope.$on('$stateChangeStart', function(ev) {
    if (window._exiting) {
      ev.preventDefault();
    }
  });
  
  return {
    link: function(scope) {
      $(window).bind('beforeunload', function() {
        if ($rootScope.exitSavePrompt) {
          return $rootScope.exitSavePrompt;
        }

        // mark the application as 'dirty'
        window._exiting = true;
      });

      $rootScope.$on('$routeChangeSuccess', function() {
        $rootScope.exitSavePrompt = false;
      });
    }
  };
});