angular.module('examgen.service').
/**
 * @ngdoc service
 * @name examgen.service:
 * @description Front-end interface to database
 */
service('couch',function($http,$q, ajaxTransformer){
  var $get = function(url) {
    var good = function(res) {
      return res;
    };

    var bad = function(err) {
      return $q.reject(err);
    };

    // attempt to log any json errors

    return $http.get(url, {transformResponse: ajaxTransformer.create(url)}).then(good, bad);
  };

  var couch = {
    use : function(profile){

      if(!profile.UserId) throw new Error('Malformed profile');

      var examsPrefix = '/api/' + profile.UserId + '/exams/';
      var attachmentsPrefix = '/api/' + profile.UserId + '/attachments/';
      couch.db = {
       /**
       * @name examgen.service:couch.listExams
       * @description Lists user's exams
       */
        listExams : function(id){
          return $get(examsPrefix+'?nc='+Date.now());
        },
       /**
       * @name examgen.service:couch.listAttachments
       * @description List attachments for an exam.
       */
        listAttachments : function(id){
          return $get(attachmentsPrefix + encodeURIComponent(id)+'?nc='+Date.now());
        },
       /**
       * @name examgen.service:couch.getExam
       * @description Get the exam JSON
       */
        getExam : function(id){
          return $get(examsPrefix + encodeURIComponent(id)+'?nc='+Date.now());
        },

        getOldExam : function(code){
          return $get(examsPrefix + encodeURIComponent(code)+'?code=true&all=true&nc='+Date.now());
        },
       /**
       * @name examgen.service:couch.hasExam
       * @description Check if an exam exists
       */
        hasExam : function(id){
          // force a return when failure
          return $get(examsPrefix + encodeURIComponent(id)+'?name=true&nc='+Date.now()).then((res)=>res.data).catch((err)=>{
            console.log(err);
            return false;
          });
          // return $http.head(examsPrefix + encodeURIComponent(id)+'?name=true&nc='+Date.now())
          // .then(function(){ return $q.when(true);}, function(){ return $q.when(false); });
        },
       /**
       * @name examgen.service:couch.putExam
       * @description Save an exam to the database
       */
        putExam : function(id,data,email){
          return $http.put(examsPrefix + encodeURIComponent(id)+ (email ? '?email='+encodeURIComponent(email):''), data);
        },
       /**
       * @name examgen.service:couch.getAttachmentUrl
       * @description Get URL for an attachment
       */
        getAttachmentUrl : function(id,attachmentId){
        return attachmentsPrefix + encodeURIComponent(id) + '/' + encodeURIComponent(attachmentId);
        },
       /**
       * @name examgen.service:couch.putAttachment
       * @description Saves the attachment to the server
       */
        putAttachment : function(id,attachmentId,html){
          var url = couch.db.getAttachmentUrl(id,attachmentId);

          var x =
            '<!doctype html>\n' +
            '<html>\n' +
            '<head>\n' +
            '<meta charset="utf-8">\n' +
            '<link href="https://netdna.bootstrapcdn.com/twitter-bootstrap/2.3.2/css/bootstrap.no-responsive.no-icons.min.css" rel="stylesheet">\n' +
            // '<script type="text/javascript">\n' +
            // '//browser-specific layout tweaks\n'+
            // 'if(window.navigator.userAgent.indexOf("Safari") > -1 && window.navigator.userAgent.indexOf("Chrome") === -1){\n'+
            // 'document.write("<style> @media print { .examPreview .page { height : 10in !important; overflow:hidden; } }</style>")\n' +
            // '}\n' +
            // '</script>\n' +
            '</head>\n' +
            '<body>\n' +
            html +
            '</body>\n' +
            '</html>';

          return $http({
            url : url,
            data : x,
            method : 'PUT',
            headers : {
            "Content-Type" : "text/html"
            }
          });
        },
       /**
       * @name examgen.service:couch.removeExam
       * @description Removes the exam from the database
       */
        removeExam : function(id){
          return $http['delete'](examsPrefix + encodeURIComponent(id));
        },
       /**
       * @name examgen.service:couch.removeAttachment
       * @description Removes attachments from the database
       */
        removeAttachment : function(id,attachmentName){
          return $http['delete'](attachmentsPrefix + encodeURIComponent(id) + '/' + encodeURIComponent(attachmentName));
        },
        examsPrefix : examsPrefix
      };

      return couch.db;
    }
  };

  return couch;
});
