angular.module('examgen.directive').
directive('examSwitchModal', function ExamSwitchModal(exams, $compile, $window, $state, couch, $timeout, $rootScope) {
  return {
    restrict: 'E',
    template: '<div id="exam-switch-modal" ng-include="\'app/partials/modals/exam-switcher.html\'"></div>',
    scope: {
      show: '=',
      showModal: '@',
      all: '@',
      selected: '@'
    },
    controllerAs: 'ctrl',
    bindToController: true,

    controller: function($scope, $element, $attrs, $http, $q) {
      var ctrl = this;
      var verifySelected = function(error) {
        if (!ctrl.selected) {
          $window.alertify.error(error);
        } else {
          return true;
        }
      };
      var prompt = 'You can now organize your exams into folders.<br><br><ul>'+
          '<li>To create a new folder click the blue "plus" button and name your folder.</li>'+
          '<li>To add Exams to a folder, click the bars next to an exam and drag it to a folder.</li>'+
          '<li>To delete an folder, click the red "trash" button next to the folder you want to delete - any exams in the folder will be moved to the main folder.</li></ul>'


      ctrl.uncatLabel = 'zzzz-uncategorized-';
      ctrl.examsort = 'update';

      $rootScope.$watch('profile',function(profile){
        if(profile){
          ctrl.profile = profile;
        }
      });
      $scope.$on('$destroy', $rootScope.$on('openExamSwitcher', function() {
        ctrl.showModal = true;
        $timeout(function(){
          if(ctrl.profile.options.examsort){
            ctrl.examsort = ctrl.profile.options.examsort;
            if(ctrl.all){
              for(var i = 0; i < ctrl.all.length; i++){
                ctrl.sort(ctrl.all[i].items);
              }
            }
          } else {
            ctrl.profile.options.examsort = ctrl.examsort;
            $http.put('/api/user/info',ctrl.profile);
            bootbox.alert(prompt)
          }
        },100);
      }));

      $scope.refresh = function(next){
        $timeout(function() {
          $scope.$apply();
          if(next){
            next();
          }
        });
      }

      ctrl.treeOptions = {
        dropped: function(event) {
          setTimeout(function () {
            if(event.dest.nodesScope.$id != event.source.nodesScope.$id){
              event.dest.nodesScope.$modelValue[event.dest.index].folder = event.dest.nodesScope.$element[0].attributes['data-folder'].value;
              $http.put('/api/exam/folder',{id:event.dest.nodesScope.$modelValue[event.dest.index].id, folder:event.dest.nodesScope.$modelValue[event.dest.index].folder});
            }
            var list = event.dest.nodesScope.$modelValue;

            ctrl.sort(list);

            event.dest.nodesScope.$modelValue = list;
            $scope.refresh();
          }, 10);
          return true;
        },
      };

      ctrl.cancel = function() {
        ctrl.showModal = false;
      };

      ctrl.click = function(exam) {
        ctrl.selected = exam;
      };

      exams.ready.then(function() {
        var uncat = ctrl.uncatLabel;
        var tmp = {};
        var all = [];
        var list = exams.list;

        ctrl.sort(list);

        var cat = {
          name:uncat,
          items:[],
        }
        tmp[uncat] = cat;
        all.push(cat);

        for(var i = 0; i < list.length; i++ ){
          if(list[i].folder){
            if(tmp[list[i].folder]){
              tmp[list[i].folder].items.push(list[i]);
            } else {
              cat = {
                name:list[i].folder,
                items:[list[i]],
              }
              tmp[list[i].folder] = cat;
              all.push(cat);
            }
          } else {
            if(tmp[uncat]){
              tmp[uncat].items.push(list[i]);
            } else {
              cat = {
                name:uncat,
                items:[list[i]],
              }
              tmp[uncat] = cat;
              all.push(cat);
            }
          }
        }

        //tmp[uncat].name = '';

        ctrl.all = all;
      });

      ctrl.sortByUpdate = function(){
        ctrl.examsort="update";
        ctrl.profile.options.examsort = ctrl.examsort;
        $http.put('/api/user/info',ctrl.profile);
        for(var i = 0; i < ctrl.all.length; i++){
          ctrl.sort(ctrl.all[i].items);
        }
      }
      ctrl.sortByCreate = function(){
        ctrl.examsort="create";
        ctrl.profile.options.examsort = ctrl.examsort;
        $http.put('/api/user/info',ctrl.profile);
        for(var i = 0; i < ctrl.all.length; i++){
          ctrl.sort(ctrl.all[i].items);
        }
      }
      ctrl.sortByName = function(){
        ctrl.examsort="name";
        ctrl.profile.options.examsort = ctrl.examsort;
        $http.put('/api/user/info',ctrl.profile);
        for(var i = 0; i < ctrl.all.length; i++){
          ctrl.sort(ctrl.all[i].items);
        }
      }
      ctrl.sort = function(list){
        if(ctrl.examsort=="name"){
          list.sort(function(a, b){return a.name.localeCompare(b.name)});
        } else if(ctrl.examsort=="create"){
          list.sort(function(a, b){return b.date_created.getTime()-a.date_created.getTime()});
        } else{
          list.sort(function(a, b){return b.date_updated.getTime()-a.date_updated.getTime()});
        }
      }


      ctrl.addFolder = function(){
        $('.modal.exam-switch-modal').hide();
        bootbox.prompt('New Folder name:',function(value){
          if(value){
            ctrl.all.push({
              name:value,
              items:[],
            });
            $scope.refresh(function(){
              $('.angular-ui-tree-empty').remove();
            });
          }
          $('.modal.exam-switch-modal').show();
        });
      }
      ctrl.deleteFolder = function(folder){
        $('.modal.exam-switch-modal').hide();
        bootbox.confirm("Are you sure you want to delete this folder? ( your exams will NOT be deleted )",function(ok){
          if(ok){
            var uncat = ctrl.all.find(function(item){return item.name==ctrl.uncatLabel})
            for(var i = 0; i < folder.items.length; i++){
              $http.put('/api/exam/folder',{id:folder.items[i].id, folder:''});
              folder.items[i].folder = '';
              uncat.items.push(folder.items[i]);
            }
            var index = ctrl.all.findIndex(function(item){return item.name==folder.name})
            ctrl.all.splice(index,1);
            $scope.refresh();
          }
          $('.modal.exam-switch-modal').show();
        });
      }

      ctrl.toggle = function (scope) {
        scope.toggle();
      };

      ctrl.ok = function() {
        if (!(verifySelected('Please select an exam to open'))) {
          return;
        }

        ctrl.showModal = false;
        var exam = ctrl.selected;
        if(!exam.ready){
          exams.load(exam,couch);
        }

        exam.ready.then(function(){
          $timeout(function() {
            $state.go('exam', {id: exam.loadedExam.name || exam.name});
          });
        });

        ctrl.selected = false;

        // $http.put('/tools/sessions/action',{action:{name:'Load exam',metadata:JSON.stringify({examName:exam.name})}});
      };

      ctrl.del = function() {
        if (!(verifySelected('Please select an exam to delete'))) {
          return;
        }

        ctrl.showModal = false;
        var exam = ctrl.selected;
        bootbox.confirm('Are you sure you want to delete ' + exam.name + '?', function(result) {
          $timeout(function() {
            ctrl.showModal = true;
          }, 200);

          if (!result) {
            return;
          }

          couch.db.removeExam(exam.id).then(function() {
            for(var i = 0; i < ctrl.all.length; i++){
              var idx = ctrl.all[i].items.findIndex(function(e){ return e.id==exam.id;});
              if(idx>=0){
                ctrl.all[i].items.splice(idx, 1);
              }
            }
            var idx = exams.list.findIndex(function(e){ return e.id==exam.id;});
            if(idx>=0){
              exams.list.splice(idx, 1);
            }

            alertify.success('Deleted ' + exam.name);
          });
        })
      };
    }
  }
});
