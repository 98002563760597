function AccountController($rootScope, $scope, $http, $stateParams, $timeout, $sce, flashMessages, uuid) {

  $rootScope.inAccount = true;
  $scope.$on('$destroy', function() {
    $rootScope.inAccount = false;
  });

  $scope.data = {
    userinfo:undefined
  };

  $scope.global = $rootScope;

  $rootScope.$watch('profile',function(profile){
    if(profile){
      $scope.data.profile = profile;
      $http.get('/shopify/states')
      .then(function(res){
        $scope.data.states = res.data;
        return $http.get('/shopify/user/'+profile.shopifyId);
      })
      .then(function(res){
        $scope.data.userinfo = res.data;
      });

      var qs = decodeURIComponent(location.href).split('?');
      if(qs.length > 1){
        if(qs[1].includes('free=')){
          var bits = qs[1].split('&')
          var product = bits[0].replace('free=','');
          var name = bits[1].replace('name=','');
          var msg = {
            shopifyproduct:product,
            UserId:profile.UserId,
            name:name,
            email:profile.Email,
          };

          alertify.message('Activating your free trial now, please wait.');

          $http.put('/api/token/free',msg).then(function(res){
            $scope.data.instructions = 'Thank you, your free trial is now active, redirecting you to the test generator';
            bootbox.alert($scope.data.instructions, function(){
              location.href = '/';
            });
            // setTimeout(function () {
            //   location.href = '/';
            // }, 1000);
          }).catch(function(err){
            console.log(err);
            if(err.data=='EXISTING LICENSE'){
              $scope.data.instructions = 'We are sorry, it looks like you already have a license. Please check that your License has not expired. Please Contact us if you have any questions'
              bootbox.alert($scope.data.instructions);
            }
          });
        }
      }
    }
  });

  $scope.updateUser = function(){
    $http.post('/shopify/user/'+$scope.data.profile.shopifyId,$scope.data.userinfo)
    .then(function(){
      alertify.success('Information Updated');
    }).catch(function(){
      alertify.error('An error occured while updating your information');
    })
  }

  $scope.resetPassword = function(){
    $http.post('/shopify/user/reset/'+$scope.data.profile.Email)
    .then(function(){
      bootbox.alert('Please check your email for a link to reset your password')
    }).catch(function(){
      alertify.error('An error occured while reseting your password, please try again later');
    })
  }

}
