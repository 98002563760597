angular.module('examgen.directive').
directive('questionEditorView', function() {
  return {
    restrict: 'E',
    scope:{
      inhouse:'=inhouse',
      modalView:'=modalView',
      personal:'=personal',
    },
    templateUrl: '/app/partials/directives/questionEditorView.html',
    controller: function($scope, $window, $rootScope, $element, $attrs, $http, $sce, $timeout, uuid) {

      $scope.global = $rootScope;

      $rootScope.questionIsDirty = function(p){
        if(p == 'clear'){
          $scope.qv.original = undefined;
        }

        //console.log($scope.qv.localCopy, $scope.qv.original);
        if($scope.qv.original && !$scope.preview){
          //console.log(!angular.equals($scope.qv.localCopy, $scope.qv.original));
          return !angular.equals($scope.qv.localCopy, $scope.qv.original);
        } else {
          return false;
        }
      }

      $scope.qv = {
        letter:'ABCDEFGHIJKLMNOPQRSTUV'.split(''),
        localCopy:undefined,
        selectedItem: undefined,
        selectedItemString:'',
        original:undefined,
      };

      function onResize(){
        // var divs = document.getElementsByClassName('qvDiv');
        // for(var i = 0; i < divs.length; i++){
        //   h = window.innerHeight - ($(divs[i]).offset().top+35);
        //   divs[i].style.height = h + 'px';
        //   var header = divs[i].getElementsByClassName('qvDiv_header');
        //   var render = divs[i].getElementsByClassName('qvDiv_editor');
        //   var source = divs[i].getElementsByClassName('qvText_raw');
        //   if( header.length > 0 && render.length > 0){
        //     h = h - (header[0].offsetHeight+21);//+56
        //     render[0].style.height = h+'px';
        //     if(source.length > 0){
        //       source[0].style.height = (h-10)+'px';
        //     }
        //   }
        // }
        // $scope.refresh();
      };
      function scrubParent(key, value){
        return (key == 'parent' || key == 'database' || key == 'items' || key.startsWith('$$')) ? null : value;
      };

      $scope.qv.a = $scope.$on("editorQuestionSelected", function(event, item) {
        if(item ){
          if((item.isQuestion || item.isPassage)&& $scope.qv.selectedItem != item){
            $scope.loadItem(item);
            $scope.preview = false;
            $scope.qv.responseLimit = 99;
            if($scope.qv.localCopy.data.itemType == 'TF'){
              $scope.qv.responseLimit = 2;
            } if($scope.qv.localCopy.data.itemType == 'SR' ||
                $scope.qv.localCopy.data.itemType == 'ER'){
              $scope.qv.responseLimit = 1;
            }
          }
        }else{
          $scope.qv.selectedItem = undefined;
          $scope.qv.localCopy = undefined;
          $scope.qv.original = undefined;
        }
        $scope.refresh();
      });
      $scope.qv.b = $scope.$on("previewQuestionSelected", function(event, item) {
        if(item){
          if((item.isQuestion || item.isPassage) && $scope.qv.selectedItem != item){
            $scope.loadItem(item);
            $scope.preview = true;
          }
        }else{
          $scope.qv.selectedItem = undefined;
          $scope.qv.localCopy = undefined;
          $scope.qv.original = undefined;
        }
        $scope.refresh();
      });

      $scope.qv.c = $scope.$on("editorModalSave", function(event, wasUser){
        var data = $scope.qv.localCopy.data;
        var hasAnswer = false;
        for(var i = 0; i < data.responses.length; i++){
          if(data.responses[i].isCorrect){
            hasAnswer = true;
          }
        }
        if(hasAnswer){
          $scope.writeQuestion(wasUser,true);
        } else {
          alertify.error('Question must have a correct answer. Double click on a answer letter to set a correct answer');
        }
      });

      $scope.qv.d = $scope.$on("editorClean", function() {
        $scope.qv.a();
        $scope.qv.b();
        $scope.qv.c();
        $scope.qv.d();
      });


      $scope.$watch('qv.localCopy.data.itemType',function(type){
        if(type){
          $scope.qv.responseLimit = 99;
          if(type == 'TF'){
            $scope.qv.responseLimit = 2;
          } if(type == 'SR' || type == 'ER'){
            $scope.qv.responseLimit = 1;
          }
          if($scope.qv.localCopy.data.responses.length > $scope.qv.responseLimit){
            bootbox.confirm("This will drop the last " +
              ($scope.qv.localCopy.data.responses.length-$scope.qv.responseLimit)
              + " answers. Continue?",function(result) {
              if(result){
                for (var i = 0; i < $scope.qv.localCopy.data.responses.length; i++){
                  if ($scope.qv.localCopy.data.responses[i].isCorrect && i >= $scope.qv.responseLimit){
                    var tmp = $scope.qv.localCopy.data.responses[i];
                    tmp.sortOrder = $scope.qv.localCopy.data.responses[$scope.qv.responseLimit - 1].sortOrder;
                    tmp.answerLetter = $scope.qv.localCopy.data.responses[$scope.qv.responseLimit - 1].answerLetter;
                    $scope.qv.localCopy.data.responses[i] = $scope.qv.localCopy.data.responses[$scope.qv.responseLimit-1];
                    $scope.qv.localCopy.data.responses[$scope.qv.responseLimit-1] = tmp;
                  }
                }
                $scope.qv.localCopy.data.responses = $scope.qv.localCopy.data.responses.slice(0,$scope.qv.responseLimit);
              }
            });
          }

          if(type == 'TF' && $scope.qv.localCopy.data.responses.length < 2){
            while($scope.qv.localCopy.data.responses.length < 2){
              $scope.addResponse();
            }
            $scope.qv.localCopy.data.responses[0].answer = 'True';
            $scope.qv.localCopy.data.responses[0].isPositionFixed = true;
            $scope.qv.localCopy.data.responses[1].answer = 'False';
            $scope.qv.localCopy.data.responses[1].isPositionFixed = true;
          }

        }
      });

      angular.element($window).bind('resize', onResize);

      $scope.$watch(
        function () {
          var x = document.getElementsByClassName('qvDiv_header');
          if(x.length > 0){
            return [].slice.call(x).map(function(w){return w.offsetWidth + w.offsetHeight}).join(',');
          }
          return '';
        },
        function (value) {
          onResize();
        }
      );

      $scope.refresh = function( f ){
        $timeout(function() {
          $scope.$apply();
          if(f){
            f();
          }
        });
      }

      $scope.loadItem = function(item){
        $scope.qv.selectedItem = item;
        $scope.qv.localCopy = JSON.parse(JSON.stringify(item,scrubParent,0));
        if(item.parent.isPassage){
          $scope.qv.localCopy.parent = JSON.parse(JSON.stringify(item.parent,scrubParent,0));
        } else {
          $scope.qv.localCopy.parent = {};
        }

        if($scope.qv.localCopy.isQuestion){
          if($scope.qv.localCopy.data.responses){
            for(var i = 0; i < $scope.qv.localCopy.data.responses.length; i++){
              $scope.qv.localCopy.data.responses[i].sortOrder = i;
            }
          }
        }

        $scope.qv.original = JSON.parse(JSON.stringify($scope.qv.localCopy,0));
      }

      $scope.$watch('qv.sourceText',function(selectionStart){
        console.log(selectionStart);
      });


      $scope.editSource= function(){
        if($scope.qv.localCopy.parent.isPassage){
          $scope.qv.selectedItemString = JSON.stringify([$scope.qv.localCopy.parent.data,$scope.qv.localCopy.data,],null,4);
        } else {
          $scope.qv.selectedItemString = JSON.stringify([$scope.qv.localCopy.data,],null,4);
        }
        $scope.qv.selectedItemString = he.encode($scope.qv.selectedItemString,{
          'useNamedReferences': true,
          'allowUnsafeSymbols': true,
        });


        var form = $('<div style="margin:0;"></div>');
        form.append('<button onclick="addformatting(\'b\');"><b>B</b></button>');
        form.append('<button onclick="addformatting(\'i\');"><i>i</i></button>');
        form.append('<button onclick="addformatting(\'ins\');"><ins>U</ins></button>');
        form.append('<button onclick="addformatting(\'del\');"><del>S</del></button>');
        form.append('<button onclick="addformatting(\'sup\');"><sup>SUP</sup></button>');
        form.append('<button onclick="addformatting(\'sub\');"><sub>SUB</sub></button>');
        form.append('<button onclick="insertNBSP();">NBSP</button>');
        form.append('<button onclick="insertSymbolDialog();"><b>&Omega;</b></button>');
        form.append('<button onclick="imageUploadDialog();"><b>IMG</b></button>');
        form.append('<button onclick="insertImgSrc();">Tag IMG</button>');
        form.append('<button onclick="insertNotImgSrc();">"Hide" IMG</button>');
        form.append('<button onclick="beautify();">{&nbsp;}</button>');
        form.append('<span id="curPos" style="right: 40px;position: absolute;top: 50px;color: darkgray;"></span>');
        form.append('<textarea id="source_text" class="input-block-level" rows="18" style="margin:0;"></textarea>');

        function updatePos(){
          return setTimeout(function(){
            if(document.getElementById('source_text')){
              form.children('#curPos').html(form.children('#source_text').prop("selectionStart"));
              $scope.qv.textTimer = updatePos();
            }
          }, 250);
        };


        clearTimeout($scope.qv.textTimer);
        $scope.qv.textTimer = updatePos();

        // Then clear the timeout in your $watch if there is any change to the input.


        form.children('#source_text').val($scope.qv.selectedItemString);

        var dlg = bootbox.dialog(form, [{
          'label' : 'Ok',
          'class' : 'btn-primary',
          'callback': function() {
            $scope.qv.selectedItemString = form.find("textarea").val();

            try{
              $scope.qv.selectedItemString = $scope.qv.selectedItemString.replace(/\t/g,'').replace(/\n/g,'');
              var data = JSON.parse($scope.qv.selectedItemString);
              if($scope.qv.localCopy.parent.isPassage){
                $scope.updateSourcePassage($scope.qv.localCopy,data[0]);
                $scope.updateSourceQuestion($scope.qv.localCopy,data[1]);
              } else {
                if($scope.qv.selectedItem.isPassage){
                  $scope.updateSourcePassage($scope.qv.localCopy,data[0]);
                } else if($scope.qv.selectedItem.isQuestion){
                  $scope.updateSourceQuestion($scope.qv.localCopy,data[0]);
                }
              }
            }catch(err){
              console.log(err);
              alertify.error(err.message);
              throw "Prevent close";
            }
          }
        }, {
          'label' : 'Cancel',
          'class' : 'btn-primary',
        }],{
          // prompts need a few extra options
          'header'  : 'Source Edit',
        });

        dlg.css('right','10vw');
        dlg.css('left','10vw');
        dlg.css('margin','0');
        dlg.css('width','auto');
        dlg.find('.modal-body').css('max-height','400px');
      }

      $scope.getPassageUsage = function(next){
        var passageId;
        if($scope.qv.localCopy.isPassage){
          passageId = $scope.qv.localCopy.data.idPassage;
        } else if($scope.qv.localCopy.parent.isPassage){
          passageId = $scope.qv.localCopy.parent.data.idPassage;
        }
        $http.get('/api/search/usage/passage/'+passageId+'?userDB='+($scope.personal ? true : false)).then(function(res) {
          if(res.data.length > 1){
            var products = [];
            var results = [];
            res.data.forEach(function(item){
              var pname = item.productName + '('+item.version+')';
              var cname = 'Chapter '+(1+item.chapterNumber);
              var ptmp = {
                name: pname,
                chapters: [],
              };
              var product = products.find(function(p){return p.name == pname});
              if(!product){
                products.push(ptmp);
                product = ptmp;
              }

              if(product.chapters.indexOf(cname) < 0){
                product.chapters.push(cname);
              }
            });
            products.forEach(function(item){
              var str = '';
              str = item.name;
              if(item.chapters.length > 0){
                str += '<br>&nbsp;&nbsp;&nbsp;&nbsp;';
                str += item.chapters.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
              }
              results.push(str);
            })
            var str = '<strong>Updating this passage will update all items referencing it. Are you sure you wish to continue? </strong><br><br>'+results.join('<br><br>');
            next(str);
          } else {
            next('');
          }
        }).catch(function(err){
          console.log(err);
          alertify.error('An error occurred while trying to save');
        })
      }

      $scope.getQuestionUsage = function(next){
        $http.get('/api/search/usage/question/'+$scope.qv.localCopy.data.itemCode+'?userDB='+($scope.personal ? true : false)).then(function(res) {
          var source = $scope.qv.selectedItem;
          var chapter;
          while(source.parent){
            chapter = source;
            source = source.parent
          }
          
          if(res.data.length > 0){
            var products = [];
            var results = [];
            var usage = 0;

            if(source && source.version){
              products.push({
                name: '<b>' + source.name + ' <b>(' + source.version + ')',
                chapters: ['Chapter ' + (1 + chapter.sortOrder) + ' #' + $scope.qv.localCopy.name],
                standards: [],
                passages: [],
              });
            }
            res.data.forEach(function(item){
              var pname = '<b>' + item.productName + ' <b>('+item.version+')';
              var cname = 'Chapter '+(1 + item.chapterNumber)+ ' #'+item.name;
              var ptmp = {
                name: pname,
                chapters: [],
                standards: [],
                passages: [],
              };
              var product = products.find(function(p){return p.name == pname});
              if(!product){
                products.push(ptmp);
                product = ptmp;
              }

              if(product.chapters.indexOf(cname) < 0){
                product.chapters.push(cname);
              }
              if(product.passages.indexOf(item.passageName) < 0 && item.passageName != null){
                product.passages.push(item.passageName);
              }
              if(product.standards.indexOf(item.standardName) < 0 && item.standardName != null){
                product.standards.push(item.standardName);
              }
            });
            products.forEach(function(item){
              var str = '';

              usage += (item.chapters.length + item.passages.length + item.standards.length);
              

              str = item.name;
              if(item.chapters.length > 0){
                str += '<br>&nbsp;&nbsp;&nbsp;&nbsp;';
                str += item.chapters.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
              }
              if(item.passages.length > 0){
                str += '<br>&nbsp;&nbsp;Passages:<br>&nbsp;&nbsp;&nbsp;&nbsp;';
                str += item.passages.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
              }
              if(item.standards.length > 0){
                str += '<br>&nbsp;&nbsp;Standards:<br>&nbsp;&nbsp;&nbsp;&nbsp;';
                str += item.standards.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;');
              }

              results.push(str);
            })
            var str = '<strong>Updating this question will update all items referencing it. Are you sure you wish to continue? </strong><br><br>'+results.join('<br><br>');
            if(usage > 1)
              next(str);
            else
              next('');
          } else {
            next('');
          }
        }).catch(function(err){
          console.log(err);
          alertify.error('An error occurred while trying to save');
        });
      }


      $scope.saveQuestion = function(){
        $scope.getQuestionUsage(function(res){
          if(res.length > 0){
            bootbox.dialog(res, [{
              label : 'Update Question',
              class : 'btn',
              callback: function() {
                $scope.writeQuestion(true);
              }
            }, {
              label : 'Save as New Question',
              class : 'btn',
              callback: function() {
                $scope.writeQuestion(false);
              }
            }, {
              label : 'Cancel',
              class : 'btn',
            }]);
          } else {
            $scope.writeQuestion(true);
          }
        });
      }

      $scope.writeQuestion = function(update,clean){
        var data = $scope.qv.localCopy.data;
        if(data){
          if(!update){
            data.fkParent = data.idQuestion || data.itemCode,
            data.idQuestion = uuid.v4();
            if($scope.qv.localCopy.parent.isPassage)
              data.passageId = $scope.qv.localCopy.parent.data.idPassage
          }
          var question = {
            itemCode: data.idQuestion || data.itemCode,
            itemType: data.itemType,
            created: data.created,
            modified: Date.now()/1000,
            passageId: data.passageId,
            prompt: data.prompt,
            tags: data.tags,
            responses: JSON.parse(JSON.stringify(data.responses)),
          }

          if(data.fkParent){
            question.fkParent = data.fkParent;
          }
          var responseIDs = [];
          for(var r =0; r < question.responses.length; r++){
            if(question.responses[r].idResponse){
              if(!update){
                question.responses[r].idResponse = uuid.v4();
              }
              responseIDs.push(question.responses[r].idResponse);
            }
          }


          if(clean){
            function cleanHTML(text){
              var tank = document.createElement("div");
              var spans;
              tank.innerHTML = text;
              spans = $(tank).find('span.MathJax_Preview');
              if(spans)spans.remove();
              spans = $(tank).find('span.MathJax_SVG');
              if(spans)spans.remove();
              spans = $(tank).find('script[type="math/mml"]');
              for(var i = 0; i < spans.length; i++){
                $(spans[i]).after(spans[i].innerHTML);
              }
              if(spans) spans.remove()
              return tank.innerHTML;
            }

            question.prompt = cleanHTML(question.prompt);
            for(var r =0; r < question.responses.length; r++){
              question.responses[r].answer = cleanHTML(question.responses[r].answer);
            }
          }

          $http.put('/api/question'+'?userDB='+($scope.personal ? true : false), question)
          .then(function(res){
            return $http.delete('/api/responses/'+question.itemCode+'?userDB='+($scope.personal ? true : false),{responseIDs:responseIDs});
          })
          .then(function(res){
            if(question.responses){
              var respush = [];
              for(var r = 0; r < question.responses.length; r++){
                var response = {
                  idResponse: question.responses[r].idResponse,
                  itemCode: question.itemCode,
                  answerLetter: question.responses[r].answerLetter,
                  answer: question.responses[r].answer,
                  isCorrect: question.responses[r].isCorrect,
                  isPositionFixed: question.responses[r].isPositionFixed,
                  sortOrder: r,
                };
                respush.push($http.put('/api/response'+'?userDB='+($scope.personal ? true : false), response))
              }
              return Promise.all(respush)
            } else {
              return true;
            }
          })
          .then(function(res){
            $scope.updateSourceQuestion($scope.qv.selectedItem,question);
            $scope.updateSourceQuestion($scope.qv.localCopy,question);
            $scope.updateSourceQuestion($scope.qv.original,question);
            alertify.success('Question Updated');
            if($scope.modalView){
              $rootScope.$broadcast("editorModalQuestionUpdated");
            }
          })
          .catch(function(err){
            console.error('Question Update Failed',err);
            alertify.error('Question Update Failed');
          });
        } else {
          alertify.error('Question Update Failed: there is an error in your JSON object');
        }
      }

      $scope.updateSourceQuestion = function(sourceItem,question){
        Object.keys(question).forEach(function(k){
          if(!Array.isArray(question[k])){
            sourceItem.data[k] = question[k];
          } else {
            sourceItem.data[k] = [];
            for(var r = 0; r < question[k].length; r++){
              if(typeof(question[k][r]) == Object && question[k][r] != null){
                sourceItem.data[k].push({});
                Object.keys(question[k][r]).forEach(function(rk){
                  sourceItem.data[k][r][rk] = question[k][r][rk];
                })
              } else {
                sourceItem.data[k].push(question[k][r]);
              }
            }
          }
        });
        sourceItem.data.idQuestion = question.itemCode;
        sourceItem.fkQuestion = question.itemCode;
        sourceItem.idQuestion = question.itemCode;
      }


      $scope.savePassage = function (){
        $scope.getPassageUsage(function(res){
          if(res.length > 0){
            bootbox.dialog(res, [{
              label : 'Update Passage',
              class : 'btn',
              callback: function() {
                $scope.writePassage(true);
              }
            }, {
              label : 'Save as New Passage',
              class : 'btn',
              callback: function() {
                $scope.writePassage(false);
              }
            }, {
              label : 'Cancel',
              class : 'btn',
            }]);
          } else {
            $scope.writePassage(true);
          }
        });
      }

      $scope.writePassage = function (update){
        var data;
        if($scope.qv.localCopy.isPassage){
          data = $scope.qv.localCopy.data;
        } else if($scope.qv.localCopy.parent.isPassage){
          data = $scope.qv.localCopy.parent.data;
        }

        if(!update){
          data.idPassage = uuid.v4();
        }

        if(data){
          var passage = {
            idPassage: data.idPassage,
            created: data.created,
            modified: Date.now()/1000,
            content: data.content,
            tags: data.tags,
          }
          $http.put('/api/passage'+'?userDB='+($scope.personal ? true : false), passage)
          .then(function(res){
            $scope.updateSourcePassage($scope.qv.selectedItem,passage);
            if(!update){
              $scope.updateSourcePassage($scope.qv.localCopy,passage);
            }
            if($scope.qv.localCopy.isPassage){
              $scope.updateSourcePassage($scope.qv.original,passage);
            } else if($scope.qv.localCopy.parent.isPassage){
              $scope.updateSourcePassage($scope.qv.original.parent,passage);
            }

            if(!update)$rootScope.$broadcast("loadAllForPassage",data);
            alertify.success('Passage Updated');
          })
          .catch(function(err){
            console.error('Passage Update Failed',err);
            alertify.error('Question Update Failed');
          });
        }
      }

      $scope.updateSourcePassage = function(sourceItem,passage){
        var source;
        if(sourceItem.isPassage){
          source = sourceItem.data;
        } else if(sourceItem.parent.isPassage){
          source = sourceItem.parent.data;
        }

        Object.keys(passage).forEach(function(k){
          source[k] = passage[k];
        });

        if(sourceItem.isPassage){
          sourceItem.poolId = passage.idPassage;
        } else if(sourceItem.parent.isPassage){
          sourceItem.parent.poolId = passage.idPassage;
        }
      }


      $scope.addQuestion = function(){
        var id = uuid.v4();
        var item = {
          idQuestion: id,
          loaded:true,
          itemCode: id,
          modified: Date.now()/1000,
          created: Date.now()/1000,
          itemType: 'MC',
          prompt: '<p></p>',
          responses:[
            {
              idResponse: uuid.v4(),
              answer:'',
              isCorrect: true,
              isPositionFixed: false,
              sortOrder: 0,
              itemCode: id,
            },{
              idResponse: uuid.v4(),
              answer:'',
              isCorrect: false,
              isPositionFixed: false,
              sortOrder: 1,
              itemCode: id,
            },{
              idResponse: uuid.v4(),
              answer:'',
              isCorrect: false,
              isPositionFixed: false,
              sortOrder: 2,
              itemCode: id,
            },{
              idResponse: uuid.v4(),
              answer:'',
              isCorrect: false,
              isPositionFixed: false,
              sortOrder: 3,
              itemCode: id,
            },
          ],
        };
        $rootScope.$broadcast("editorAddQuestion",item);
      }
      $scope.addPassage = function(){
        var item = {
          idPassage: uuid.v4(),
          loaded:true,
          modified: Date.now()/1000,
          created: Date.now()/1000,
          content: '<p>New Passage</p>',
        };
        $rootScope.$broadcast("editorAddPassage",item);
      }

      $scope.addResponse = function(){
        var maxR = 99;
        if($scope.qv.localCopy.data.itemType == 'TF'){
          maxR = 2;
        } if($scope.qv.localCopy.data.itemType == 'SR' ||
            $scope.qv.localCopy.data.itemType == 'ER'){
          maxR = 1;
        }

        if($scope.qv.localCopy.data.responses.length+1 <= maxR){
          $scope.qv.localCopy.data.responses.push({
              idResponse: uuid.v4(),
              answerLetter: $scope.qv.letter[$scope.qv.localCopy.data.responses.length],
              answer: '',
              isCorrect: false,
              isPositionFixed: false,
              sortOrder: $scope.qv.localCopy.data.responses.length,
          });
        } else {
          alertify.error('You have reached the maximum number of responses for this question type');
        }
      }
      $scope.removeResponse = function(index){
        var minR = 2;
        if($scope.qv.localCopy.data.itemType == 'SR' ||
            $scope.qv.localCopy.data.itemType == 'ER'){
          minR = 1;
        }
        if($scope.qv.localCopy.data.responses.length > minR){
          bootbox.confirm("Do you want to delete this answer?", function(result) {
            if(result){
              $scope.qv.localCopy.data.responses.splice(index,1);
              for(var i = 0; i < $scope.qv.localCopy.data.responses.length; i++){
                $scope.qv.localCopy.data.responses[i].sortOrder=i;
                $scope.qv.localCopy.data.responses[i].answerLetter=$scope.qv.letter[i];
              }
            }
          });
        } else {
          alertify.error('You have reached the minimum number of responses for this question type');
        }
      }

      onResize();
    },
  };
});
