angular.module('examgen.directive').
directive('librarySwitchModal', function ExamSwitchModal(exams, $compile, $window, $state, couch, $timeout, $rootScope) {
  return {
    restrict: 'E',
    template: '<div id="library-switch-modal" ng-include="\'app/partials/modals/library-switcher.html\'"></div>',
    scope: {
      show: '=',
      all: '=',
      showModal: '@',
      selected: '@'
    },
    controllerAs: 'ctrl',
    bindToController: true,

    controller: function($scope, $element, $attrs, $http, $q) {
      var ctrl = this;

      $scope.$on('$destroy', $rootScope.$on('openLibrarySwitcher', function() {
        ctrl.showModal = true;
      }));

      ctrl.cancel = function() {
        ctrl.showModal = false;
      };

      ctrl.click = function(lib) {
        ctrl.selected = lib;
      };

      // exams.ready.then(function() {
      //   ctrl.all = exams.list;
      // });

      var verifySelected = function(error) {
        if (!ctrl.selected) {
          $window.alertify.error(error);
        } else {
          return true;
        }
      };

      ctrl.ok = function() {
        if (!(verifySelected('Please select an exam to open'))) {
          return;
        }

        ctrl.showModal = false;

        $rootScope.$broadcast('openSelectedLibrary',ctrl.selected.idProduct);

        ctrl.selected = false;
      };

      ctrl.del = function() {
        if (!(verifySelected('Please select an exam to delete'))) {
          return;
        }

        ctrl.showModal = false;
        var exam = ctrl.selected;
        bootbox.confirm('Are you sure you want to delete ' + exam.name + '?', function(result) {
          $timeout(function() {
            ctrl.showModal = true;
          }, 200);

          if (!result) {
            return;
          }

          // couch.db.removeExam(exam.id).then(function() {
          //   var idx = exams.list.indexOf(exam);
          //   exams.list.splice(idx, 1);
          //   alertify.success('Deleted ' + exam.name);
          // });
        })
      };
    }
  }
});
