function AjaxLoadingFactory($q) {
  return {
    request: function(config) {
      return config;
    },

    requestError: function(rejection) {
      return $q.reject(rejection);
    },

    response: function(response) {
      return response;
    },

    responseError: function(rejection) {
      return $q.reject(rejection);
    }
  };
}

angular.module('examgen.service')
  .factory('AjaxLoadingFactory', AjaxLoadingFactory)
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('AjaxLoadingFactory');
  });